import { 

  FETCH_AD_BANNER_REQUEST,
  FETCH_AD_BANNER_SUCCESS,
  FETCH_AD_BANNER_ERROR,

  AD_BANNER_BY_ID_REQUEST,
  AD_BANNER_BY_ID_SUCCESS,
  AD_BANNER_BY_ID_ERROR,

  FETCH_DISPLAY_ON_REQUEST,
  FETCH_DISPLAY_ON_SUCCESS,
  FETCH_DISPLAY_ON_ERROR, 

  UPDATE_AD_BANNER_REQUEST,
  UPDATE_AD_BANNER_SUCCESS,
  UPDATE_AD_BANNER_ERROR,  

  CREATE_AD_BANNER_REQUEST,
  CREATE_AD_BANNER_SUCCESS,
  CREATE_AD_BANNER_ERROR,  

  DELETE_AD_BANNER_REQUEST,
  DELETE_AD_BANNER_SUCCESS,
  DELETE_AD_BANNER_ERROR,

  EVENT_AD_BANNER_BY_ID_REQUEST,
  EVENT_AD_BANNER_BY_ID_SUCCESS,
  EVENT_AD_BANNER_BY_ID_ERROR,

  UPDATE_EVENT_AD_BANNER_REQUEST,
  UPDATE_EVENT_AD_BANNER_SUCCESS,
  UPDATE_EVENT_AD_BANNER_ERROR,

  CREATE_EVENT_AD_BANNER_REQUEST,
  CREATE_EVENT_AD_BANNER_SUCCESS,
  CREATE_EVENT_AD_BANNER_ERROR,

  DELETE_EVENT_AD_BANNER_REQUEST,
  DELETE_EVENT_AD_BANNER_SUCCESS,
  DELETE_EVENT_AD_BANNER_ERROR
} from '../../constants/Marketing/AdBanner.const';
  
  const initialState = {

    adBanners: [],
    isBannersLoaded: false,
    adBannersError: null,
    adBannersLoader: false,

    displayOn: [],
    isDisplayOnLoaded: false,
    displayOnError: null,
    displayOnLoader: false,

    adBanner: [],
    adBannerError: null,
    adBannerLoader: false,

    adBannerByIdLoader: false,
    adBannerByIdData: null,
    adBannerByIdError: null,

    isAdBannerCreated: false,
    isAdBannerCreatedError: null,
    isAdBannerCreatedLoader: false,

    isAdBannerUpdated: false,
    isAdBannerUpdatedError: null,
    isAdBannerUpdatedLoader: false,

    isAdBannerDeleted: false,
    isAdBannerDeletedError: null,
    isAdBannerDeletedLoader: false,

    eventAdBannerByIdLoader: false,
    eventAdBannerByIdData: null,
    eventAdBannerByIdError: null,

    createEventAdBannerLoader: false,
    createEventAdBannerData: null,
    createEventAdBannerError: null,

    updateEventAdBannerLoader: false,
    updateEventAdBannerData: null,
    updateEventAdBannerError: null,

    deleteEventAdBannerLoader: false,
    deleteEventAdBannerData: null,
    deleteEventAdBannerError: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {

        // For GET Display On Data

        case FETCH_DISPLAY_ON_REQUEST:
          return {
            ...state,
            displayOnLoader: true
          };
        case FETCH_DISPLAY_ON_SUCCESS:
          return {
            ...state,
            isDisplayOnLoaded: true,
            displayOn: action.payload,
            displayOnLoader: false
          };
        case FETCH_DISPLAY_ON_ERROR:
          return {
            ...state,
            displayOnError: action.error.message,
            displayOnLoader: false
          };
  
      // For GET AdBAnner Data
  
      case FETCH_AD_BANNER_REQUEST:
        return {
          ...state,
          adBannersLoader: true
        };
      case FETCH_AD_BANNER_SUCCESS:
        return {
          ...state,
          isBannersLoaded: true,
          adBanners: action.payload,
          adBannersLoader: false
        };
      case FETCH_AD_BANNER_ERROR:
        return {
          ...state,
          adBannersError: action.error.message,
          adBannersLoader: false
        };
      
         // For get Ad Banner by Id
      case AD_BANNER_BY_ID_REQUEST:
        return {
          ...state,
          adBannerByIdLoader: true
        };
      case AD_BANNER_BY_ID_SUCCESS:
        return {
          ...state,
          adBannerByIdData: action.payload,
        };
      case AD_BANNER_BY_ID_ERROR:
        return {
          ...state,
          adBannerByIdError: action.error,
        };

      // For CREATE AdBanner Data
  
      case CREATE_AD_BANNER_REQUEST:
        return {
          ...state,
          isAdBannerCreatedLoader: true
        };
      case CREATE_AD_BANNER_SUCCESS:
        return {
          ...state,
          isAdBannerCreated: true,
          isAdBannerCreatedLoader: false
        };
      case CREATE_AD_BANNER_ERROR:
        return {
          ...state,
          isAdBannerCreatedError: action.error.message,
          isAdBannerCreatedLoader: false
        };

      // For UPDATE AdBanner Data

      case UPDATE_AD_BANNER_REQUEST:
        return {
          ...state,
          isAdBannerUpdatedLoader: true
        };
      case UPDATE_AD_BANNER_SUCCESS:
        return {
          ...state,
          isAdBannerUpdated: true,
          isAdBannerUpdatedLoader: false
        };
      case UPDATE_AD_BANNER_ERROR:
        return {
          ...state,
          isAdBannerUpdatedError: action.error.message,
          isAdBannerUpdatedLoader: false
        };


      // For DELETE AdBanner Data

      case DELETE_AD_BANNER_REQUEST:
        return {
          ...state,
          isAdBannerDeletedLoader: true
        };
      case DELETE_AD_BANNER_SUCCESS:
        return {
          ...state,
          isAdBannerDeleted: true,
          isAdBannerDeletedLoader: false
        };
      case DELETE_AD_BANNER_ERROR:
        return {
          ...state,
          isAdBannerDeletedError: action.error.message,
          isAdBannerDeletedLoader: false
        };
  
      // For get Event Ad Banner by Id
      case EVENT_AD_BANNER_BY_ID_REQUEST:
        return {
          ...state,
          eventAdBannerByIdLoader: true
        };
      case EVENT_AD_BANNER_BY_ID_SUCCESS:
        return {
          ...state,
          eventAdBannerByIdData: action.payload,
        };
      case EVENT_AD_BANNER_BY_ID_ERROR:
        return {
          ...state,
          eventAdBannerByIdError: action.error,
        };

      // For Create Event Ad Banner
      case CREATE_EVENT_AD_BANNER_REQUEST:
        return {
          ...state,
          createEventAdBannerLoader: true
        };
      case CREATE_EVENT_AD_BANNER_SUCCESS:
        return {
          ...state,
          createEventAdBannerData: action.payload,
        };
      case CREATE_EVENT_AD_BANNER_ERROR:
        return {
          ...state,
          createEventAdBannerError: action.error,
        };

      // For update Event Ad Banner
      case UPDATE_EVENT_AD_BANNER_REQUEST:
        return {
          ...state,
          updateEventAdBannerLoader: true
        };
      case UPDATE_EVENT_AD_BANNER_SUCCESS:
        return {
          ...state,
          updateEventAdBannerData: action.payload,
        };
      case UPDATE_EVENT_AD_BANNER_ERROR:
        return {
          ...state,
          updateEventAdBannerError: action.error,
        };

      // For Delete Event Ad Banner
      case DELETE_EVENT_AD_BANNER_REQUEST:
        return {
          ...state,
          deleteEventAdBannerLoader: true
        };
      case DELETE_EVENT_AD_BANNER_SUCCESS:
        return {
          ...state,
          deleteEventAdBannerData: action.payload,
        };
      case DELETE_EVENT_AD_BANNER_ERROR:
        return {
          ...state,
          deleteEventAdBannerError: action.error,
        };

      default:
        return state;
    }
  }