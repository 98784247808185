import {
    GET_CLAIMTEAM_LIST_ERROR,
    GET_CLAIMTEAM_LIST_SUCCESS,

    GET_TEAM_LIST_REQUEST,
    GET_TEAM_LIST_SUCCESS,
    GET_TEAM_LIST_ERROR,

    SAVE_TEAM_REQUEST,
    SAVE_TEAM_SUCCESS,
    SAVE_TEAM_ERROR,

    CREATE_NEW_TEAM_REQUEST,
    CREATE_NEW_TEAM_SUCCESS,
    CREATE_NEW_TEAM_ERROR,

    REJECT_TEAM_REQUEST,
    REJECT_TEAM_SUCCESS,
    REJECT_TEAM_ERROR,
} from "../../constants/BaseballCloud/ClaimProTeam.const";

const initialState = {
    claimListData: null,
    claimListError: null,

    teamListReq: false,
    teamListData: null,
    teamListError: null,

    saveTeamReq: false,
    saveTeamData: null,
    saveTeamError: null,

    createTeamReq: false,
    createTeamData: null,
    createTeamError: null,

    rejectTeamReq: false,
    rejectTeamData: null,
    rejectTeamError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // For GET Claim List

        case GET_CLAIMTEAM_LIST_SUCCESS:
            return {
                ...state,
                claimListData: action.payload
            };
        case GET_CLAIMTEAM_LIST_ERROR:
            return {
                ...state,
                claimListError: action.error,
            };

        // For GET Team List
        case GET_TEAM_LIST_REQUEST:
            return {
                ...state,
                teamListReq: true,
            };
        case GET_TEAM_LIST_SUCCESS:
            return {
                ...state,
                teamListData: action.payload
            };
        case GET_TEAM_LIST_ERROR:
            return {
                ...state,
                teamListError: action.error,
            };

        // For Save Team
        case SAVE_TEAM_REQUEST:
            return {
                ...state,
                saveTeamReq: true,
            };
        case SAVE_TEAM_SUCCESS:
            return {
                ...state,
                saveTeamData: action.payload
            };
        case SAVE_TEAM_ERROR:
            return {
                ...state,
                saveTeamError: action.error,
            };

        // For Create New Team
        case CREATE_NEW_TEAM_REQUEST:
            return {
                ...state,
                createTeamReq: true,
            };
        case CREATE_NEW_TEAM_SUCCESS:
            return {
                ...state,
                createTeamData: action.payload
            };
        case CREATE_NEW_TEAM_ERROR:
            return {
                ...state,
                createTeamError: action.error,
            };

        // For Reject New Team
        case REJECT_TEAM_REQUEST:
            return {
                ...state,
                rejectTeamReq: true,
            };
        case REJECT_TEAM_SUCCESS:
            return {
                ...state,
                rejectTeamData: action.payload
            };
        case REJECT_TEAM_ERROR:
            return {
                ...state,
                rejectTeamError: action.error,
            };

        default:
            return state;
    }
}