export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_POLICYPAGE_SUCCESS = 'FETCH_POLICYPAGE_SUCCESS';
export const FETCH_PAGE_ERROR   = 'FETCH_PAGE_ERROR';

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_POLICYPAGE_SUCCESS = 'UPDATE_POLICYPAGE_SUCCESS';
export const UPDATE_PAGE_ERROR   = 'UPDATE_PAGE_ERROR';

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_ERROR   = 'DELETE_PAGE_ERROR';

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_ERROR   = 'CREATE_PAGE_ERROR';