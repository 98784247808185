import { 
  FETCH_LESSON_LIBRARY_REQUEST, 
  FETCH_LESSON_LIBRARY_SUCCESS, 
  FETCH_LESSON_LIBRARY_ERROR,

  DELETE_LESSON_LIBRARY_REQUEST,
  DELETE_LESSON_LIBRARY_SUCCESS,
  DELETE_LESSON_LIBRARY_ERROR,

  CREATE_LESSON_LIBRARY_REQUEST,
  CREATE_LESSON_LIBRARY_SUCCESS,
  CREATE_LESSON_LIBRARY_ERROR,

  UPDATE_LESSON_LIBRARY_REQUEST,
  UPDATE_LESSON_LIBRARY_SUCCESS,
  UPDATE_LESSON_LIBRARY_ERROR  

} from '../constants/LessonLibrary.const';

const initialState = {
  
  lessonLibrary: [],
  lessonLibraryError: null,
  lessonLibraryLoader: false,
  isLibraryLoaded: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,

  isCreated: false,
  isCreatedError: null,
  isCreatedLoader: false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false

};

export default function(state = initialState, action) {
  switch (action.type) {

    // get Lesson Library

    case FETCH_LESSON_LIBRARY_REQUEST:
      return {
        ...state,
        lessonLibraryLoader: true
      };
    case FETCH_LESSON_LIBRARY_SUCCESS:
      return {
        ...state,
        isLibraryLoaded: true,
        lessonLibrary: action.payload,
        lessonLibraryLoader: false
      };
    case FETCH_LESSON_LIBRARY_ERROR:
      return {
        ...state,
        lessonLibraryError: action.error.message,
        lessonLibraryLoader: false
      };

    // Delete Lesson Library

    case DELETE_LESSON_LIBRARY_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case DELETE_LESSON_LIBRARY_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case DELETE_LESSON_LIBRARY_ERROR:
      return {
        ...state,
        isDeletedError: action.error.message,
        isDeletedLoader: false
      };

    // Create Lesson Library

    case CREATE_LESSON_LIBRARY_REQUEST:
      return {
        ...state,
        isCreatedLoader: true
      };
    case CREATE_LESSON_LIBRARY_SUCCESS:
      return {
        ...state,
        isCreated: true,
        isCreatedLoader: false
      };
    case CREATE_LESSON_LIBRARY_ERROR:
      return {
        ...state,
        isCreatedError: action.error,
        isCreatedLoader: false
      };

    // Update Lesson Library

    case UPDATE_LESSON_LIBRARY_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPDATE_LESSON_LIBRARY_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_LESSON_LIBRARY_ERROR:
      return {
        ...state,
        isUpdatedError: action.error.message,
        isUpdatedLoader: false
      };

     

    default:
      return state;
  }
}