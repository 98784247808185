import { 
    LIST_SPONSOR_SUCCESS,
    LIST_SPONSOR_ERROR,

    FEATCH_SPONSOR_SUCCESS,
    FEATCH_SPONSOR_ERROR,

    CREATE_SPONSOR_SUCCESS,
    CREATE_SPONSOR_ERROR,

    UPDATE_SPONSOR_SUCCESS,
    UPDATE_SPONSOR_ERROR,

    DELETE_SPONSOR_SUCCESS,
    DELETE_SPONSOR_ERROR
  } from '../constants/SponsorAdBanner.const';
  
  const initialState = {
      sponsorList: [],
      sponsorListError: null,

      fetchSponsor: null,
      fetchSponsorError: null,

      createSponsor: null,
      createSponsorError: null,

      updateSponsor: null,
      updateSponsorError: null,

      deleteSponsor: null,
      deleteSponsorError: null
  };
  
export default function (state = initialState, action) {
    switch (action.type) {

        // For GET Product Data

        case LIST_SPONSOR_SUCCESS:
            return {
                ...state,
                sponsorList: action.payload
            };
        case LIST_SPONSOR_SUCCESS:
            return {
                ...state,
                sponsorListError: action.error,
            };

        // fetch Sponsor record by id

        case FEATCH_SPONSOR_SUCCESS:
            return {
                ...state,
                fetchSponsor: action.payload
            };
        case FEATCH_SPONSOR_ERROR:
            return {
                ...state,
                fetchSponsorError: action.error,
            };
        
        //create sponsor

        case CREATE_SPONSOR_SUCCESS:
            return {
                ...state,
                createSponsor: action.payload
            };
        case CREATE_SPONSOR_ERROR:
            return {
                ...state,
                createSponsorError: action.error,
            };

        // update sponsor

        case UPDATE_SPONSOR_SUCCESS:
            return {
                ...state,
                updateSponsor: action.payload
            };
        case UPDATE_SPONSOR_ERROR:
            return {
                ...state,
                updateSponsorError: action.error,
            };

        //delete sponsor
        
        case DELETE_SPONSOR_SUCCESS:
            return {
                ...state,
                deleteSponsor: action.payload
            };
        case DELETE_SPONSOR_ERROR:
            return {
                ...state,
                deleteSponsorError: action.error,
            };

        default:
            return state;
    }
}