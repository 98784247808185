import {
    BC_FETCH_ALL_COACHES_REQUEST,
    BC_FETCH_ALL_COACHES_SUCCESS,
    BC_FETCH_ALL_COACHES_ERROR,

    BC_FETCH_COACH_REQUEST,
    BC_FETCH_COACH_SUCCESS,
    BC_FETCH_COACH_ERROR,

    BC_FETCH_COACH_BY_FILTER_REQUEST,
    BC_FETCH_COACH_BY_FILTER_SUCCESS,
    BC_FETCH_COACH_BY_FILTER_ERROR,

    BALLR_LICENCE_REQUEST,
    BALLR_LICENCE_SUCCESS,
    BALLR_LICENCE_ERROR,

    ASSIGN_BALLR_LICENCE_REQUEST,
    ASSIGN_BALLR_LICENCE_SUCCESS,
    ASSIGN_BALLR_LICENCE_ERROR,

    PITCHR_LICENCE_REQUEST,
    PITCHR_LICENCE_SUCCESS,
    PITCHR_LICENCE_ERROR,

    ASSIGN_PITCHR_LICENCE_REQUEST,
    ASSIGN_PITCHR_LICENCE_SUCCESS,
    ASSIGN_PITCHR_LICENCE_ERROR,

    ISMLB_ACCESS_REQUEST,
    ISMLB_ACCESS_SUCCESS,
    ISMLB_ACCESS_ERROR,

    FILTER_PLAYER_BY_EMAIL_REQUEST,
    FILTER_PLAYER_BY_EMAIL_SUCCESS,
    FILTER_PLAYER_BY_EMAIL_ERROR,

    ASSIGN_COMPETITION_LICENCE_REQUEST,
    ASSIGN_COMPETITION_LICENCE_SUCCESS,
    ASSIGN_COMPETITION_LICENCE_ERROR

} from '../../../constants/BaseballCloud/Users/Coaches.const';

const initialState = {

    allCoaches: [],
    allCoachesError: null,
    allCoachesLoader: false,

    coach: [],
    coachError: null,
    coachLoader: false,
    isCoachLoaded: false,

    coachesByFilter: [],
    coachesByFilterError: null,
    coachesByFilterLoader: false,
    isCoachesLoaded: false,

    ballrLincenceList: [],
    ballrLincenceListError: null,
    ballrLincenceListLoader: false,

    assignBallrLic: null,
    assignBallrLicError: null,
    assignBallrLicLoader: false,

    pitchrLincenceList: [],
    pitchrLincenceListError: null,
    pitchrLincenceListLoader: false,

    assignPitchrLic: null,
    assignPitchrLicError: null,
    assignPitchrLicLoader: false,

    isMLB: null,
    isMLBError: null,
    isMLBLoader: false,

    emailList: null,
    emailListError: null,
    emailListLoader: false,

    isAnalyzr: null,
    isAnalyzrError: null,
    isAnalyzrLoader: false,

    assignCompetitionLic: null,
    assignCompetitionLicError: null,
    assignCompetitionLicLoader: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Fetch - ALL COACHES

        case BC_FETCH_ALL_COACHES_REQUEST:
            return {
                ...state,
                allCoachesLoader: true
            };
        case BC_FETCH_ALL_COACHES_SUCCESS:
            return {
                ...state,
                allCoaches: action.payload,
                allCoachesLoader: false
            };
        case BC_FETCH_ALL_COACHES_ERROR:
            return {
                ...state,
                allCoachesError: action.error.message,
                allCoachesLoader: false
            };

        // Fetch - COACH BY ID

        case BC_FETCH_COACH_REQUEST:
            return {
                ...state,
                coachLoader: true
            };
        case BC_FETCH_COACH_SUCCESS:
            return {
                ...state,
                isCoachLoaded: true,
                coach: action.payload,
                coachLoader: false
            };
        case BC_FETCH_COACH_ERROR:
            return {
                ...state,
                coachError: action.error.message,
                coachLoader: false
            };

        // Fetch - COACH BY FILTER

        case BC_FETCH_COACH_BY_FILTER_REQUEST:
            return {
                ...state,
                coachesByFilterLoader: true
            };
        case BC_FETCH_COACH_BY_FILTER_SUCCESS:
            return {
                ...state,
                isCoachesLoaded: true,
                coachesByFilter: action.payload,
                coachesByFilterLoader: false
            };
        case "ONLY_UPDATE_FILTER_DATA":
            return {
                ...state,
                coachesByFilter: action.payload,
                isMLBLoader: false
            };
        case BC_FETCH_COACH_BY_FILTER_ERROR:
            return {
                ...state,
                coachesByFilterError: action.error.message,
                coachesByFilterLoader: false
            };

        // get BollR Lincence List
        case BALLR_LICENCE_REQUEST:
            return {
                ...state,
                ballrLincenceListLoader: true
            };
        case BALLR_LICENCE_SUCCESS:
            return {
                ...state,
                ballrLincenceList: action.payload,
                ballrLincenceListLoader: true
            };
        case BALLR_LICENCE_ERROR:
            return {
                ...state,
                ballrLincenceListError: action.error,
            };

        // assign BollR Lincence
        case  ASSIGN_PITCHR_LICENCE_REQUEST:
            return {
                ...state,
                assignPitchrLicLoader: true
            };
        case ASSIGN_PITCHR_LICENCE_SUCCESS:
            return {
                ...state,
                assignPitchrLic: action.payload,
                assignPitchrLicLoader: true
            };
        case ASSIGN_PITCHR_LICENCE_ERROR:
            return {
                ...state,
                assignPitchrLicError: action.error,
            };

        // get PitchR Lincence List
        case PITCHR_LICENCE_REQUEST:
            return {
                ...state,
                pitchrLincenceListLoader: true
            };
        case PITCHR_LICENCE_SUCCESS:
            return {
                ...state,
                pitchrLincenceList: action.payload,
                pitchrLincenceListLoader: true
            };
        case PITCHR_LICENCE_ERROR:
            return {
                ...state,
                pitchrLincenceListError: action.error,
            };

        // assign PitchR Lincence
        case ASSIGN_BALLR_LICENCE_REQUEST:
            return {
                ...state,
                assignBallrLicLoader: true
            };
        case  ASSIGN_BALLR_LICENCE_SUCCESS:
            return {
                ...state,
                assignBallrLic: action.payload,
                assignBallrLicLoader: true
            };
        case ASSIGN_BALLR_LICENCE_ERROR :
            return {
                ...state,
                assignBallrLicError: action.error,
            };

        // access isMLB 
        case ISMLB_ACCESS_REQUEST:
            return {
                ...state,
                isMLBLoader: true
            };
        case ISMLB_ACCESS_SUCCESS:
            return {
                ...state,
                isMLB: action.payload,
                isMLBLoader: true
            };
        case ISMLB_ACCESS_ERROR:
            return {
                ...state,
                isMLBError: action.error,
            };

        // get Email List
        case FILTER_PLAYER_BY_EMAIL_REQUEST:
            return {
                ...state,
                emailListLoader: true
            };
        case FILTER_PLAYER_BY_EMAIL_SUCCESS:
            return {
                ...state,
                emailList: action.payload,
                emailListLoader: true
            };
        case FILTER_PLAYER_BY_EMAIL_ERROR:
            return {
                ...state,
                emailListError: action.error,
            };

        case "ISANALYR_ACCESS_REQUEST_FOR_COACH":
            return {
                ...state,
                isAnalyzrLoader: true
            };
        case "ISANALYR_ACCESS_SUCCESS_FOR_COACH":
            return {
                ...state,
                isAnalyzr: action.payload,
                isAnalyzrLoader: true
            };
        case "ISANALYR_ACCESS_ERROR_FOR_COACH":
            return {
                ...state,
                isAnalyzrError: action.error,
            };

        // assign competiotion level Lincence
        case ASSIGN_COMPETITION_LICENCE_REQUEST:
            return {
                ...state,
                assignCompetitionLicLoader: true
            };
        case ASSIGN_COMPETITION_LICENCE_SUCCESS:
            return {
                ...state,
                assignCompetitionLic: action.payload,
                assignCompetitionLicLoader: true
            };
        case ASSIGN_COMPETITION_LICENCE_ERROR:
            return {
                ...state,
                assignCompetitionLicError: action.error,
            };    

        default:
            return state;
    }
}
