import {
    BC_FOR_CREATE_USER_ADD_REQUEST,
    BC_FOR_CREATE_USER_ADD_SUCCESS,
    BC_FOR_CREATE_USER_ADD_ERROR,
  } from "../../../../constants/BaseballCloud/Users/AddUser/BasicInfo.const"
  
  const initialState = {
    basicInfo: null,
    basicInfoError: null,
    basicInfoLoader: false,
    isBasicInfoLoaded: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {

      // For create player/coach
      case BC_FOR_CREATE_USER_ADD_REQUEST:
        return {
          ...state,
          basicInfoLoader: true,
        };
      case BC_FOR_CREATE_USER_ADD_SUCCESS:
        return {
          ...state,
          basicInfo: action.payload,
          basicInfoLoader: false,
          isBasicInfoLoaded: true,
        };
      case BC_FOR_CREATE_USER_ADD_ERROR:
        return {
          ...state,
          basicInfoError: action.error.message,
          basicInfoLoader: false,
        };
      default:
        return state;
    }
  }  