import {
  FETCH_SETTINGS_DATA_ERROR,
  FETCH_SETTINGS_DATA_SUCCESS,
  POST_SETTINGS_ERROR,
  POST_SETTINGS_SUCCESS,
} from "../constants/Settings.const";

const initialState = {
  settingsDataError: null,
  settingsData: null,

  postSettingsError: null,
  postSettings: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        settingsData: action.payload,
      };
    case FETCH_SETTINGS_DATA_ERROR:
      return {
        ...state,
        settingsDataError: action.error.message,
      };

    case POST_SETTINGS_SUCCESS:
      return {
        ...state,
        postSettings: action.payload,
      };
    case POST_SETTINGS_ERROR:
      return {
        ...state,
        postSettingsError: action.error.message,
      };

    default:
      return state;
  }
}
