export const BRACKET_LIST_SUCCESS = "BRACKET_LIST_SUCCESS";
export const BRACKET_LIST_ERROR = "BRACKET_LIST_ERROR";

export const DELETE_BRACKET_SUCCESS = "DELETE_BRACKET_SUCCESS";
export const DELETE_BRACKET_ERROR = "DELETE_BRACKET_ERROR";

export const MOVE_BRACKET_SUCCESS = "MOVE_BRACKET_SUCCESS";
export const MOVE_BRACKET_ERROR = "MOVE_BRACKET_ERROR";

export const GET_ROUND_SUCCESS = "GET_ROUND_SUCCESS";
export const GET_ROUND_ERROR = "GET_ROUND_ERROR";

export const GET_BRACKET_CHART_SUCCESS = "GET_BRACKET_CHART_SUCCESS";
export const GET_BRACKET_CHART_ERROR = "GET_BRACKET_CHART_ERROR";

export const CREATE_GAME_SUCCESS = "CREATE_GAME_SUCCESS";
export const CREATE_GAME_ERROR = "CREATE_GAME_ERROR";

export const FETCH_GAME_SUCCESS = "FETCH_GAME_SUCCESS";
export const FETCH_GAME_ERROR = "FETCH_GAME_ERROR";

export const UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS";
export const UPDATE_GAME_ERROR = "UPDATE_GAME_ERROR";

export const GET_POOL_ROUND_SUCCESS = "GET_POOL_ROUND_SUCCESS";

export const CREATE_BRACKET_TEMPLATE_SUCCESS = "CREATE_BRACKET_TEMPLATE_SUCCESS";
export const CREATE_BRACKET_TEMPLATE_ERROR = "CREATE_BRACKET_TEMPLATE_ERROR";

export const GET_BRACKET_TEMPLATE_SUCCESS = "GET_BRACKET_TEMPLATE_SUCCESS";
export const GET_BRACKET_TEMPLATE_ERROR = "GET_BRACKET_TEMPLATE_ERROR";

export const GET_BRACKET_NUMBER_SUCCESS = "GET_BRACKET_NUMBER_SUCCESS";
export const GET_BRACKET_NUMBER_ERROR = "GET_BRACKET_NUMBER_ERROR";

export const GET_BRACKET_IMPORT_SUCCESS = "GET_BRACKET_IMPORT_SUCCESS";
export const GET_BRACKET_IMPORT_ERROR = "GET_BRACKET_IMPORT_ERROR";

export const GET_BRACKET_DELETE_SUCCESS = "GET_BRACKET_DELETE_SUCCESS";
export const GET_BRACKET_DELETE_ERROR = "GET_BRACKET_DELETE_ERROR";

export const GET_EDIT_TEMPLATE_VIEW_RESPONSE = "GET_EDIT_TEMPLATE_VIEW_RESPONSE";
export const GET_EDIT_TEMPLATE_VIEW_ERROR = "GET_EDIT_TEMPLATE_VIEW_ERROR";

export const EDIT_BRACKET_TEMPLATE_SUCCESS = "EDIT_BRACKET_TEMPLATE_SUCCESS";
export const EDIT_BRACKET_TEMPLATE_ERROR = "EDIT_BRACKET_TEMPLATE_ERROR";

export const GET_CO_CAMP_TEAM_SUCCESS = "GET_CO_CAMP_TEAM_SUCCESS";
export const GET_CO_CAMP_TEAM_ERROR = "GET_CO_CAMP_TEAM_ERROR";

export const GET_CO_CAMP_TEAM_LIST_SUCCESS = "GET_CO_CAMP_TEAM_LIST_SUCCESS";
export const GET_CO_CAMP_TEAM_LIST_ERROR = "GET_CO_CAMP_TEAM_LIST_ERROR";

export const SAVE_CO_CAMP_TEAM_SUCCESS = "SAVE_CO_CAMP_TEAM_SUCCESS";
export const SAVE_CO_CAMP_TEAM_ERROR = "SAVE_CO_CAMP_TEAM_ERROR";

export const BRACKET_TEAM_LIST_SUCCESS = 'BRACKET_TEAM_LIST_SUCCESS';
export const BRACKET_TEAM_LIST_ERROR = 'BRACKET_TEAM_LIST_ERROR';

export const GET_GAMES_ARRAY_SUCCESS = 'GET_GAMES_ARRAY_SUCCESS';
export const GET_GAMES_ARRAY_ERROR = 'GET_GAMES_ARRAY_ERROR';

export const BRACKET_GAME_LIST_WITHOUT_DIVISION_SUCCESS = 'BRACKET_GAME_LIST_WITHOUT_DIVISION_SUCCESS';
export const BRACKET_GAME_LIST_WITHOUT_DIVISION_ERROR = 'BRACKET_GAME_LIST_WITHOUT_DIVISION_ERROR';

export const SAVE_BRACKET_SUCCESS = 'SAVE_BRACKET_SUCCESS';
export const SAVE_BRACKET_ERROR = 'SAVE_BRACKET_ERROR';

export const GET_BRACKET_PREVIEW_SUCCESS = "GET_BRACKET_PREVIEW_SUCCESS";
export const GET_BRACKET_PREVIEW_ERROR = "GET_BRACKET_PREVIEW_ERROR";