export const GET_TEAM_LISTS_REQUEST = 'GET_TEAM_LISTS_REQUEST';
export const GET_TEAM_LISTS_SUCCESS = 'GET_TEAM_LISTS_SUCCESS';
export const GET_TEAM_LISTS_ERROR = 'GET_TEAM_LISTS_ERROR';

export const PICK_A_TEAM_REQUEST = 'PICK_A_TEAM_REQUEST';
export const PICK_A_TEAM_SUCCESS = 'PICK_A_TEAM_SUCCESS';
export const PICK_A_TEAM_ERROR = 'PICK_A_TEAM_ERROR';

export const GET_COACH_LIST_REQUEST = 'GET_COACH_LIST_REQUEST';
export const GET_COACH_LIST_SUCCESS = 'GET_COACH_LIST_SUCCESS';
export const GET_COACH_LIST_ERROR = 'GET_COACH_LIST_ERROR';

export const PICK_A_COACH_REQUEST = 'PICK_A_COACH_REQUEST';
export const PICK_A_COACH_SUCCESS = 'PICK_A_COACH_SUCCESS';
export const PICK_A_COACH_ERROR = 'PICK_A_COACH_ERROR';