export const GET_USER_PERMISSION_LIST_REQUEST = 'GET_USER_PERMISSION_LIST_REQUEST';
export const GET_USER_PERMISSION_LIST_SUCCESS = 'GET_USER_PERMISSION_LIST_SUCCESS';
export const GET_USER_PERMISSION_LIST_ERROR   = 'GET_USER_PERMISSION_LIST_ERROR';

export const EDIT_USER_PERMISSION_LIST_REQUEST = 'EDIT_USER_PERMISSION_LIST_REQUEST';
export const EDIT_USER_PERMISSION_LIST_SUCCESS = 'EDIT_USER_PERMISSION_LIST_SUCCESS';
export const EDIT_USER_PERMISSION_LIST_ERROR   = 'EDIT_USER_PERMISSION_LIST_ERROR';

export const GET_USER_PERMISSION_REQUEST = 'GET_USER_PERMISSION_REQUEST';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_ERROR   = 'GET_USER_PERMISSION_ERROR';