import {
   GET_USER_TRANSACTION_REQUEST,
   GET_USER_TRANSACTION_ERROR,
   GET_USER_TRANSACTION_SUCCESS
  } from '../constants/UserTransaction.const';
  
  const initialState = {
    userTransactionData: null,
    userTransactionError: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
  
      // For GET Product package Data
  
      case GET_USER_TRANSACTION_SUCCESS:
        return {
          ...state,
          userTransactionData: action.payload
        };
      case GET_USER_TRANSACTION_ERROR:
        return {
          ...state,
          userTransactionError: action.error,
        };
      default:
        return state;
    }
  }