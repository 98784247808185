export const GET_ANALYZER_PRODUCT_PACKAGE_SUCCESS = "GET_ANALYZER_PRODUCT_PACKAGE_SUCCESS";
export const GET_ANALYZER_PRODUCT_PACKAGE_ERROR = "GET_ANALYZER_PRODUCT_PACKAGE_ERROR";

export const UPDATE_ANALYZER_PACKAGE_SUCCESS = "UPDATE_ANALYZER_PACKAGE_SUCCESS";
export const UPDATE_ANALYZER_PACKAGE_ERROR = "UPDATE_ANALYZER_PACKAGE_ERROR";

export const DELETE_ANALYZER_PACKAGE_SUCCESS = "DELETE_ANALYZER_PACKAGE_SUCCESS";
export const DELETE_ANALYZER_PACKAGE_ERROR = "DELETE_ANALYZER_PACKAGE_ERROR";

export const CREATE_ANALYZER_PACKAGE_SUCCESS = "CREATE_ANALYZER_PACKAGE_SUCCESS";
export const CREATE_ANALYZER_PACKAGE_ERROR = "CREATE_ANALYZER_PACKAGE_ERROR";

export const FETCH_ANALYZER_PACKAGE_SUCCESS = "FETCH_ANALYZER_PACKAGE_SUCCESS";
export const FETCH_ANALYZER_PACKAGE_ERROR = "FETCH_ANALYZER_PACKAGE_ERROR";

export const FETCH_ANALYZER_PACKAGE_PLANS_STATUS_SUCCESS = "FETCH_ANALYZER_PACKAGE_PLANS_STATUS_SUCCESS";
export const FETCH_ANALYZER_PACKAGE_PLANS_STATUS_ERROR = "FETCH_ANALYZER_PACKAGE_PLANS_STATUS_ERROR";