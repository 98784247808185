import {
  GET_PRODUCT_FEATURE_LIST_SUCCESS,
  GET_PRODUCT_FEATURE_LIST_ERROR,
  CREATE_PRODUCT_FEATURE_ERROR,
  CREATE_PRODUCT_FEATURE_SUCCESS,
  DELETE_PRODUCT_FEATURE_ERROR,
  DELETE_PRODUCT_FEATURE_SUCCESS,
  FETCH_PRODUCT_FEATURE_ERROR,
  FETCH_PRODUCT_FEATURE_SUCCESS,
  UPDATE_PRODUCT_FEATURE_ERROR,
  UPDATE_PRODUCT_FEATURE_SUCCESS
} from "../constants/ProductFeature.const";

const initialState = {
  productFeatureData: null,
  producrFeatureError: null,

  createProductFeatureData: null,
  createProductFeatureError: null,

  deleteProductFeatureData: null,
  deleteProductFeatureError: null,

  fetchProductFeatureData: null,
  fetchProductFeatureError: null,

  updateProductFeatureData: null,
  updateProductFeatureError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For GET Product Feature Data

    case GET_PRODUCT_FEATURE_LIST_SUCCESS:
      return {
        ...state,
        productFeatureData: action.payload
      };
    case GET_PRODUCT_FEATURE_LIST_ERROR:
      return {
        ...state,
        producrFeatureError: action.error,
      };
    case CREATE_PRODUCT_FEATURE_SUCCESS:
      return {
        ...state,
        createProductFeatureData: action.payload
      };
    case CREATE_PRODUCT_FEATURE_ERROR:
      return {
        ...state,
        createProductFeatureError: action.error,
      };
    case DELETE_PRODUCT_FEATURE_SUCCESS:
      return {
        ...state,
        deleteProductFeatureData: action.payload
      };
    case DELETE_PRODUCT_FEATURE_ERROR:
      return {
        ...state,
        deleteProductFeatureError: action.error,
      };
    case FETCH_PRODUCT_FEATURE_SUCCESS:
      return {
        ...state,
        fetchProductFeatureData: action.payload
      };
    case FETCH_PRODUCT_FEATURE_ERROR:
      return {
        ...state,
        fetchProductFeatureError: action.error,
      };
    case UPDATE_PRODUCT_FEATURE_SUCCESS:
      return {
        ...state,
        updateProductFeatureData: action.payload
      };
    case UPDATE_PRODUCT_FEATURE_ERROR:
      return {
        ...state,
        updateProductFeatureError: action.error,
      };
    default:
      return state;
  }
}