import {
    BC_GET_TEAM_LISTS_REQUEST,
    BC_GET_TEAM_LISTS_SUCCESS,
    BC_GET_TEAM_LISTS_ERROR,

    BC_PICK_A_TEAM_REQUEST,
    BC_PICK_A_TEAM_SUCCESS,
    BC_PICK_A_TEAM_ERROR,

    BC_GET_COACH_LIST_REQUEST,
    BC_GET_COACH_LIST_SUCCESS,
    BC_GET_COACH_LIST_ERROR,

    BC_PICK_A_COACH_REQUEST,
    BC_PICK_A_COACH_SUCCESS,
    BC_PICK_A_COACH_ERROR,
  } from "../../../../constants/BaseballCloud/Users/AddUser/AddPlayer.const";
  
  const initialState = {
    teamListsReq: false,
    teamListsData: null,
    teamListsError: null,

    selectedTeamReq: false,
    selectedTeamData: null,
    selectedTeamError: null,

    coachListsReq: false,
    coachListsData: null,
    coachListsError: null,

    selectedCoachReq: false,
    selectedCoachData: null,
    selectedCoachError: null,
    selectedCoachLoader: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
  
      // For GET Team List
      case BC_GET_TEAM_LISTS_REQUEST:
        return {
          ...state,
          teamListsReq: true,
        };
      case BC_GET_TEAM_LISTS_SUCCESS:
        return {
          ...state,
          teamListsData: action.payload,
        };
      case BC_GET_TEAM_LISTS_ERROR:
        return {
          ...state,
          teamListsError: action.error,
        };

        // For player pick a team
      case BC_PICK_A_TEAM_REQUEST:
        return {
          ...state,
          selectedTeamReq: true,
        };
      case BC_PICK_A_TEAM_SUCCESS:
        return {
          ...state,
          selectedTeamData: action.payload,
        };
      case BC_PICK_A_TEAM_ERROR:
        return {
          ...state,
          selectedTeamError: action.error,
        };

        // For GET Coach List
      case BC_GET_COACH_LIST_REQUEST:
        return {
          ...state,
          coachListsReq: true,
        };
      case BC_GET_COACH_LIST_SUCCESS:
        return {
          ...state,
          coachListsData: action.payload,
        };
      case BC_GET_COACH_LIST_ERROR:
        return {
          ...state,
          coachListsError: action.error,
        };

          // For player pick a Coach
      case BC_PICK_A_COACH_REQUEST:
        return {
          ...state,
          selectedCoachReq: true,
          selectedCoachLoader: true,
        };
      case BC_PICK_A_COACH_SUCCESS:
        return {
          ...state,
          selectedCoachData: action.payload,
          selectedCoachLoader: true,
        };
      case BC_PICK_A_COACH_ERROR:
        return {
          ...state,
          selectedCoachError: action.error,
          selectedCoachLoader: true,
        };

      default:
        return state;
    }
  }
  