export const IMAGE_UPLOAD_REQUEST = 'IMAGE_UPLOAD_REQUEST';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_ERROR   = 'IMAGE_UPLOAD_ERROR';

export const IMAGE_DELETE_REQUEST = 'IMAGE_DELETE_REQUEST';
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS';
export const IMAGE_DELETE_ERROR   = 'IMAGE_DELETE_ERROR';

export const IMAGES_UPLOAD_REQUEST = 'IMAGES_UPLOAD_REQUEST';
export const IMAGES_UPLOAD_SUCCESS = 'IMAGES_UPLOAD_SUCCESS';
export const IMAGES_UPLOAD_ERROR   = 'IMAGES_UPLOAD_ERROR';

export const BANNER_UPLOAD_REQUEST = 'BANNER_UPLOAD_REQUEST';
export const BANNER_UPLOAD_SUCCESS = 'BANNER_UPLOAD_SUCCESS';
export const BANNER_UPLOAD_ERROR   = 'BANNER_UPLOAD_ERROR';

export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR   = 'FETCH_COMPANIES_ERROR';

export const FETCH_DS_COMPANIES_REQUEST = 'FETCH_DS_COMPANIES_REQUEST';
export const FETCH_DS_COMPANIES_SUCCESS = 'FETCH_DS_COMPANIES_SUCCESS';
export const FETCH_DS_COMPANIES_ERROR   = 'FETCH_DS_COMPANIES_ERROR';

export const FETCH_PRODUCT_CATEGORY_REQUEST = 'FETCH_PRODUCT_CATEGORY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_ERROR   = 'FETCH_PRODUCT_CATEGORY_ERROR';

export const PICTURE_UPLOAD_REQUEST = 'PICTURE_UPLOAD_REQUEST';
export const PICTURE_UPLOAD_SUCCESS = 'PICTURE_UPLOAD_SUCCESS';
export const PICTURE_UPLOAD_ERROR   = 'PICTURE_UPLOAD_ERROR';

export const ZIPCODE_REQUEST = 'ZIPCODE_REQUEST';
export const ZIPCODE_SUCCESS = 'ZIPCODE_SUCCESS';
export const ZIPCODE_ERROR   = 'ZIPCODE_ERROR';

