import {
    GET_ADMINLIST_SUCCESS,
    GET_ADMINLIST_ERROR,
    CREATE_ADMIN_ERROR,
    CREATE_ADMIN_SUCCESS,
    GET_ADMINID_SUCCESS,
    GET_ADMINID_ERROR,
    EDIT_ADMINLIST_SUCCESS,
    EDIT_ADMINLIST_ERROR,
    DELETE_MANAGE_ADMIN_ERROR,
    DELETE_MANAGE_ADMIN_SUCCESS,
    RESET_ADMIN_PASSWORD_ERROR,
    RESET_ADMIN_PASSWORD_SUCCESS,
    COLLEGE_LIST_SUCCESS,
    COLLEGE_LIST_ERROR
} from '../../constants/ManageAdmin/ManageAdmin.const';

const initialState = {
    adminListData: [],
    adminListError: null,

    createAdminData: null,
    createAdminError: null,

    adminIdData: null,
    adminIdError: null,

    editAdminData: null,
    editAdminError: null,

    deleteAdminData: null,
    deleteAdminError: null,

    resetPasswordData: null,
    resetPasswordError: null,

    collegeList: [],
    collegeListError: null

};

export default function (state = initialState, action) {
    switch (action.type) {

        //fetch manage admin list
        case GET_ADMINLIST_SUCCESS:
            return {
                ...state,
                adminListData: action.payload
            };
        case GET_ADMINLIST_ERROR:
            return {
                ...state,
                adminListError: action.error
            };


        //create Admin 
        case CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                createAdminData: action.payload
            };
        case CREATE_ADMIN_ERROR:
            return {
                ...state,
                createAdminError: action.error
            }

        //GET USER ID DATA
        case GET_ADMINID_SUCCESS:
            return {
                ...state,
                adminIdData: action.payload
            };
        case GET_ADMINID_ERROR:
            return {
                ...state,
                adminIdError: action.error
            };

        //edit user data
        case EDIT_ADMINLIST_SUCCESS:
            return {
                ...state,
                editAdminData: action.payload
            };
        case EDIT_ADMINLIST_ERROR:
            return {
                ...state,
                editAdminError: action.error
            };

        //delete admin data
        case DELETE_MANAGE_ADMIN_SUCCESS:
            return {
                ...state,
                deleteAdminData: action.payload
            };
        case DELETE_MANAGE_ADMIN_ERROR:
            return {
                ...state,
                deleteAdminError: action.error
            };

        //reset admin password data
        case RESET_ADMIN_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordData: action.payload
            };
        case RESET_ADMIN_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordError: action.error
            };

        // college List
        case COLLEGE_LIST_SUCCESS:
            return {
                ...state,
                collegeList: action.payload
            };
        case COLLEGE_LIST_ERROR:
            return {
                ...state,
                collegeListError: action.error
            };
        default:
            return state;
    }
}