export const GET_PRODUCT_PACKAGE_LIST_SUCCESS = "GET_PRODUCT_PACKAGE_LIST_SUCCESS";
export const GET_PRODUCT_PACKAGE_LIST_ERROR = "GET_PRODUCT_PACKAGE_LIST_ERROR";
export const GET_PRODUCT_PACKAGE_LIST_REQUEST = "GET_PRODUCT_PACKAGE_LIST_REQUEST";

export const GET_PRODUCT_FEATURE_SUCCESS = "GET_PRODUCT_FEATURE_SUCCESS";
export const GET_PRODUCT_FEATURE_ERROR = "GET_PRODUCT_FEATURE_ERROR";
export const GET_PRODUCT_FEATURE_REQUEST = "GET_PRODUCT_FEATURE_REQUEST";

export const GET_PRODUCT_MASTER_SUCCESS = "GET_PRODUCT_MASTER_SUCCESS";
export const GET_PRODUCT_MASTER_ERROR = "GET_PRODUCT_MASTER_ERROR";
export const GET_PRODUCT_MASTER_REQUEST = "GET_PRODUCT_MASTER_REQUEST";

export const CREATE_PRODUCT_PACKAGE_SUCCESS = "CREATE_PRODUCT_PACKAGE_SUCCESS";
export const CREATE_PRODUCT_PACKAGE_ERROR = "CREATE_PRODUCT_PACKAGE_ERROR";
export const CREATE_PRODUCT_PACKAGE_REQUEST = "CREATE_PRODUCT_PACKAGE_REQUEST";

export const DELETE_PRODUCT_PACKAGE_SUCCESS = "DELETE_PRODUCT_PACKAGE_SUCCESS";
export const DELETE_PRODUCT_PACKAGE_ERROR = "DELETE_PRODUCT_PACKAGE_ERROR";
export const DELETE_PRODUCT_PACKAGE_REQUEST = "DELETE_PRODUCT_PACKAGE_REQUEST";

export const FETCH_PRODUCT_PACKAGE_SUCCESS = "FETCH_PRODUCT_PACKAGE_SUCCESS";
export const FETCH_PRODUCT_PACKAGE_ERROR = "FETCH_PRODUCT_PACKAGE_ERROR";
export const FETCH_PRODUCT_PACKAGE_REQUEST = "FETCH_PRODUCT_PACKAGE_REQUEST";

export const UPDATE_PRODUCT_PACKAGE_SUCCESS = "UPDATE_PRODUCT_PACKAGE_SUCCESS";
export const UPDATE_PRODUCT_PACKAGE_ERROR = "UPDATE_PRODUCT_PACKAGE_ERROR";
export const UPDATE_PRODUCT_PACKAGE_REQUEST = "UPDATE_PRODUCT_PACKAGE_REQUEST";