export const BC_FETCH_TOP_TEAMS_REQUEST = 'BC_FETCH_TOP_TEAMS_REQUEST';
export const BC_FETCH_TOP_TEAMS_SUCCESS = 'BC_FETCH_TOP_TEAMS_SUCCESS';
export const BC_FETCH_TOP_TEAMS_ERROR   = 'BC_FETCH_TOP_TEAMS_ERROR';

export const BC_FETCH_ALL_TEAMS_REQUEST = 'BC_FETCH_ALL_TEAMS_REQUEST';
export const BC_FETCH_ALL_TEAMS_SUCCESS = 'BC_FETCH_ALL_TEAMS_SUCCESS';
export const BC_FETCH_ALL_TEAMS_ERROR   = 'BC_FETCH_ALL_TEAMS_ERROR';

export const BC_FETCH_TEAM_BY_FILTER_REQUEST = 'BC_FETCH_TEAM_BY_FILTER_REQUEST';
export const BC_FETCH_TEAM_BY_FILTER_SUCCESS = 'BC_FETCH_TEAM_BY_FILTER_SUCCESS';
export const BC_FETCH_TEAM_BY_FILTER_ERROR = 'BC_FETCH_TEAM_BY_FILTER_ERROR';

export const BC_FETCH_TEAM_BY_ID_REQUEST = 'BC_FETCH_TEAM_BY_ID_REQUEST';
export const BC_FETCH_TEAM_BY_ID_SUCCESS = 'BC_FETCH_TEAM_BY_ID_SUCCESS';
export const BC_FETCH_TEAM_BY_ID_ERROR = 'BC_FETCH_TEAM_BY_ID_ERROR';

export const BC_CHANGE_TYPE_REQUEST = 'BC_CHANGE_TYPE_REQUEST';
export const BC_CHANGE_TYPE_SUCCESS = 'BC_CHANGE_TYPE_SUCCESS';
export const BC_CHANGE_TYPE_ERROR = 'BC_CHANGE_TYPE_ERROR';

export const BCTEAM_ASSIGN_LICENCE_REQUEST = 'BCTEAM_ASSIGN_LICENCE_REQUEST';
export const BCTEAM_ASSIGN_LICENCE_SUCCESS = 'BCTEAM_ASSIGN_LICENCE_SUCCESS';
export const BCTEAM_ASSIGN_LICENCE_ERROR = 'BCTEAM_ASSIGN_LICENCE_ERROR';

export const BC_EXPORT_CSV_FOR_TEAM_REQUEST = 'BC_EXPORT_CSV_FOR_TEAM_REQUEST';
export const BC_EXPORT_CSV_FOR_TEAM_SUCCESS = 'BC_EXPORT_CSV_FOR_TEAM_SUCCESS';
export const BC_EXPORT_CSV_FOR_TEAM_ERROR = 'BC_EXPORT_CSV_FOR_TEAM_ERROR';

export const BC_NOT_SEARCH_TEAM_REQUEST = 'BC_NOT_SEARCH_TEAM_REQUEST';
export const BC_NOT_SEARCH_TEAM_SUCCESS = 'BC_NOT_SEARCH_TEAM_SUCCESS';
export const BC_NOT_SEARCH_TEAM_ERROR = 'BC_NOT_SEARCH_TEAM_ERROR';

export const BYNAME_PARENTTEAM_LIST_REQUEST = 'BYNAME_PARENTTEAM_LIST_REQUEST';
export const BYNAME_PARENTTEAM_LIST_SUCCESS = 'BYNAME_PARENTTEAM_LIST_SUCCESS';
export const BYNAME_PARENTTEAM_LIST_ERROR = 'BYNAME_PARENTTEAM_LIST_ERROR';