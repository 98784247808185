import { 
    FETCH_ALL_FANS_REQUEST,
    FETCH_ALL_FANS_SUCCESS,
    FETCH_ALL_FANS_ERROR,

    FETCH_FAN_BY_ID_REQUEST,
    FETCH_FAN_BY_ID_SUCCESS,
    FETCH_FAN_BY_ID_ERROR,

    FETCH_FAN_BY_FILTER_REQUEST,
    FETCH_FAN_BY_FILTER_SUCCESS,
    FETCH_FAN_BY_FILTER_ERROR,
    DELETE_FAN_SUCCESS,
    DELETE_FAN_ERROR,

} from '../../constants/Users/Fans.const';

const initialState = {

  allFans: [],
  allFansError: null,
  allFansLoader: false,

  fan: [],
  fanError: null,
  fanLoader: false,
  isFanLoaded: false,

  fansByFilter: [],
  fansByFilterError: null,
  fansByFilterLoader: false,
  isFansLoaded: false,

  filters: {
    page: 0,
    name: '',
    role: 'Fan',
    sortOn: '0',
    // dateType : '0',
    dateFilter: {
      createdAt: 99,
      // lastLoginTime: 0,
      startDate: "",
      endDate: ""
    },
    isParent: "",
    state: "",
    event_id:""
  },

deleteFanData:null,
deleteFanDataError:null

};

export default function(state = initialState, action) {
  switch (action.type) {

    // Fetch - FAN BY ID

    case FETCH_FAN_BY_ID_REQUEST:
    return {
        ...state,
        fanLoader: true
    };
    case FETCH_FAN_BY_ID_SUCCESS:
    return {
        ...state,
        isFanLoaded: true,
        fan: action.payload,
        fanLoader: false
    };
    case FETCH_FAN_BY_ID_ERROR:
    return {
        ...state,
        fanError: action.error.message,
        fanLoader: false
    };

    // Fetch - ALL FANS

    case FETCH_ALL_FANS_REQUEST:
    return {
        ...state,
        allFansLoader: true
    };
    case FETCH_ALL_FANS_SUCCESS:
    return {
        ...state,
        allFans: action.payload,
        allFansLoader: false
    };
    case FETCH_ALL_FANS_ERROR:
    return {
        ...state,
        allFansError: action.error.message,
        allFansLoader: false
    };

    // Fetch - FAN BY FILTER

    case FETCH_FAN_BY_FILTER_REQUEST:
    return {
        ...state,
        fansByFilterLoader: true
    };
    case FETCH_FAN_BY_FILTER_SUCCESS:
    return {
        ...state,
        isFansLoaded: true,
        fansByFilter: action.payload,
        fansByFilterLoader: false
    };
    case FETCH_FAN_BY_FILTER_ERROR:
    return {
        ...state,
        fansByFilterError: action.error.message,
        fansByFilterLoader: false
    };

    //delete fan
    case DELETE_FAN_SUCCESS:
      return {
        ...state,
        deleteFanData: action.payload,
      };

    case DELETE_FAN_ERROR:
      return {
        ...state,
        deleteFanDataError: action.error,
      };

    default:
      return state;
  }
}