export const GET_GRID_LIST_SUCCESS = "GET_GRID_LIST_SUCCESS";
export const GET_GRID_LIST_ERROR = "GET_GRID_LIST_ERROR";

export const CREATE_GRID_LIST_SUCCESS = "CREATE_GRID_LIST_SUCCESS";
export const CREATE_GRID_LIST_ERROR = "CREATE_GRID_LIST_ERROR";


export const GET_SINGLE_GRID_LIST_SUCCESS = "GET_SINGLE_GRID_LIST_SUCCESS";
export const GET_SINGLE_GRID_LIST_ERROR = "GET_SINGLE_GRID_LIST_ERROR";

export const CREATE_SINGLE_GRID_SUCCESS = "CREATE_SINGLE_GRID_SUCCESS";
export const CREATE_SINGLE_GRID_ERROR = "CREATE_SINGLE_GRID_ERROR";

export const GET_GRID_FILTER_SUCCESS = "GET_GRID_FILTER_SUCCESS";
export const GET_GRID_FILTER_ERROR = "GET_GRID_FILTER_ERROR";

//exchange team name

export const CHANGE_TEAM_NAME_SUCCESS = "CHANGE_TEAM_NAME_SUCCESS";
export const CHANGE_TEAM_NAME_ERROR = "CHANGE_TEAM_NAME_ERROR";

// =========== RBS Grid Version 2 ============

export const RBS_GRID_FILTER_SUCCESS = "RBS_GRID_FILTER_SUCCESS";
export const RBS_GRID_FILTER_ERROR = "RBS_GRID_FILTER_ERROR";

export const RBS_SINGLE_GRID_LIST_SUCCESS = "RBS_SINGLE_GRID_LIST_SUCCESS";
export const RBS_SINGLE_GRID_LIST_ERROR = "RBS_SINGLE_GRID_LIST_ERROR";

export const RBS_CREATE_SINGLE_GRID_GAME_SUCCESS = "RBS_CREATE_SINGLE_GRID_GAME_SUCCESS";
export const RBS_CREATE_SINGLE_GRID_GAME_ERROR = "RBS_CREATE_SINGLE_GRID_GAME_ERROR";

export const RBS_REMOVE_SINGLE_GRID_GAME_SUCCESS = "RBS_REMOVE_SINGLE_GRID_GAME_SUCCESS";
export const RBS_REMOVE_SINGLE_GRID_GAME_ERROR = "RBS_REMOVE_SINGLE_GRID_GAME_ERROR";

export const RBS_SWAP_TEAM_SINGLE_GRID_GAME_SUCCESS = "RBS_SWAP_TEAM_SINGLE_GRID_GAME_SUCCESS";
export const RBS_SWAP_TEAM_SINGLE_GRID_GAME_ERROR = "RBS_SWAP_TEAM_SINGLE_GRID_GAME_ERROR";

export const RBS_SPECIAL_REQUEST_SUCCESS = "RBS_SPECIAL_REQUEST_SUCCESS";
export const RBS_SPECIAL_REQUEST_ERROR= "RBS_SPECIAL_REQUEST_ERROR";

export const RBS_AUTO_GENERATE_SUCCESS = "RBS_AUTO_GENERATE_SUCCESS";
export const RBS_AUTO_GENERATE_ERROR= "RBS_AUTO_GENERATE_ERROR";

