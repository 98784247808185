import { 
    FETCH_REPORT_REQUEST,
    FETCH_REPORT_SUCCESS, 
    FETCH_REPORT_ERROR,

    FETCH_REPORT_BY_ID_REQUEST,
    FETCH_REPORT_BY_ID_SUCCESS, 
    FETCH_REPORT_BY_ID_ERROR,

    FETCH_ROSTER_LIST_REQUEST,
    FETCH_ROSTER_LIST_SUCCESS,
    FETCH_ROSTER_LIST_ERROR,  

    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS, 
    DELETE_FILE_ERROR,

    UPDATE_REPORT_REQUEST,
    UPDATE_REPORT_SUCCESS,
    UPDATE_REPORT_ERROR,

    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCESS,
    DELETE_REPORT_ERROR,

    CREATE_REPORT_REQUEST,
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_ERROR  
  
  } from '../constants/Report.const';
  
  const initialState = {
    reports: [],
    reportsError: null,
    reportsLoader: false,
    isReportsLoaded: false,

    report: [],
    reportError: null,
    reportLoader: false,
    isReportLoaded: false,

    rosters: [],
    rostersError: null,
    rostersLoader: false,
    isRostersLoaded: false,
  
    isDeleted: false,
    isDeletedError : null,
    isDeletedLoader : false,

    isFileDeleted: false,
    isFileDeletedError : null,
    isFileDeletedLoader : false,
  
    isCreated : false,
    isCreatedError : null,
    isCreatedLoader : false,
    
    isUpdated : false,
    isUpdatedError : null,
    isUpdatedLoader : false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {

      // For GET Report by Data

      case FETCH_REPORT_BY_ID_REQUEST:
        return {
          ...state,
          reportLoader: true
        };
      case FETCH_REPORT_BY_ID_SUCCESS:
        return {
          ...state,
          report: action.payload,
          reportLoader: false,
          isReportLoaded: true
        };
      case FETCH_REPORT_BY_ID_ERROR:
        return {
          ...state,
          reportError: action.error.message,
          reportLoader: false
        };
        
  
      // For GET Report Data
  
      case FETCH_REPORT_REQUEST:
        return {
          ...state,
          reportsLoader: true
        };
      case FETCH_REPORT_SUCCESS:
        return {
          ...state,
          reports: action.payload,
          reportsLoader: false,
          isReportsLoaded: true
        };
      case FETCH_REPORT_ERROR:
        return {
          ...state,
          reportsError: action.error.message,
          reportsLoader: false
        };

      // For GET Roster Data
  
      case FETCH_ROSTER_LIST_REQUEST:
        return {
          ...state,
          rostersLoader: true
        };
      case FETCH_ROSTER_LIST_SUCCESS:
        return {
          ...state,
         rosters: action.payload,
         rostersLoader: false,
         isRostersLoaded: true
        };
      case FETCH_ROSTER_LIST_ERROR:
        return {
          ...state,
          rostersError: action.error.message,
          rostersLoader: false
        };
  
      // For CREATE Report Data
  
      case CREATE_REPORT_REQUEST:
        return {
          ...state,
          isCreatedLoader: true
        };
      case CREATE_REPORT_SUCCESS:
        return {
          ...state,
          isCreated: true,
          isCreatedLoader: false
        };
      case CREATE_REPORT_ERROR:
        return {
          ...state,
          isCreatedError: action.error,
          isCreatedLoader: false
        };
  
      // For UPDATE Report Data
  
      case UPDATE_REPORT_REQUEST:
        return {
          ...state,
          isUpdatedLoader: true
        };
      case UPDATE_REPORT_SUCCESS:
        return {
          ...state,
          isUpdated: true,
          isUpdatedLoader: false
        };
      case UPDATE_REPORT_ERROR:
        return {
          ...state,
          isUpdatedError: action.error,
          isUpdatedLoader: false
        };

        // For DELETE Report Data
  
      case DELETE_REPORT_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_REPORT_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isDeletedLoader: false
        };
      case DELETE_REPORT_ERROR:
        return {
          ...state,
          isDeletedError: action.error,
          isDeletedLoader: false
        };

      // For DELETE Report Data
  
      case DELETE_FILE_REQUEST:
        return {
          ...state,
          isFileDeletedLoader: true
        };
      case DELETE_FILE_SUCCESS:
        return {
          ...state,
          isFileDeleted: true,
          isFileDeletedLoader: false
        };
      case DELETE_FILE_ERROR:
        return {
          ...state,
          isFileDeletedError: action.error,
          isFileDeletedLoader: false
        };
  
      default:
        return state;
    }
  }