export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR   = 'FETCH_REPORT_ERROR';

export const FETCH_REPORT_BY_ID_REQUEST = 'FETCH_REPORT_BY_ID_REQUEST';
export const FETCH_REPORT_BY_ID_SUCCESS = 'FETCH_REPORT_BY_ID_SUCCESS';
export const FETCH_REPORT_BY_ID_ERROR   = 'FETCH_REPORT_BY_ID_ERROR';

export const FETCH_ROSTER_LIST_REQUEST = 'FETCH_ROSTER_LIST_REQUEST';
export const FETCH_ROSTER_LIST_SUCCESS = 'FETCH_ROSTER_LIST_SUCCESS';
export const FETCH_ROSTER_LIST_ERROR   = 'FETCH_ROSTER_LIST_ERROR';

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR   = 'DELETE_FILE_ERROR';

export const UPDATE_REPORT_REQUEST = 'UPDATE_REPORT_REQUEST';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_ERROR   = 'UPDATE_REPORT_ERROR';

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERROR   = 'DELETE_REPORT_ERROR';

export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR   = 'CREATE_REPORT_ERROR';