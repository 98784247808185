export const BC_GET_TEAMLIST_FOR_COACH_REQUEST = 'BC_GET_TEAMLIST_FOR_COACH_REQUEST';
export const BC_GET_TEAMLIST_FOR_COACH_SUCCESS = 'BC_GET_TEAMLIST_FOR_COACH_SUCCESS';
export const BC_GET_TEAMLIST_FOR_COACH_ERROR = 'BC_GET_TEAMLIST_FOR_COACH_ERROR';

export const BC_UPDATE_COACH_REQUEST = 'BC_UPDATE_COACH_REQUEST';
export const BC_UPDATE_COACH_SUCCESS = 'BC_UPDATE_COACH_SUCCESS';
export const BC_UPDATE_COACH_ERROR = 'BC_UPDATE_COACH_ERROR';

export const BC_ADD_NEW_TEAM_REQUEST = 'BC_ADD_NEW_TEAM_REQUEST';
export const BC_ADD_NEW_TEAM_SUCCESS = 'BC_ADD_NEW_TEAM_SUCCESS';
export const BC_ADD_NEW_TEAM_ERROR = 'BC_ADD_NEW_TEAM_ERROR';