import {
  FETCH_SPOTLIGHT_CONTENT_REQUEST,
  FETCH_SPOTLIGHT_CONTENT_SUCCESS,
  FETCH_SPOTLIGHT_CONTENT_ERROR,
  GET_SPOTLIGHT_CONTENT_BY_ID_REQUEST,
  GET_SPOTLIGHT_CONTENT_BY_ID_SUCCESS,
  GET_SPOTLIGHT_CONTENT_BY_ID_ERROR,
  UPDATE_SPOTLIGHT_CONTENT_REQUEST,
  UPDATE_SPOTLIGHT_CONTENT_SUCCESS,
  UPDATE_SPOTLIGHT_CONTENT_ERROR,
} from "../../constants/spotlight/spotlightContent.const";

const initialState = {
  Content: [],
  isContentLoaded: false,
  ContentError: null,
  ContentLoader: false,

  contentByIdLoader: false,
  contentByIdData: null,
  contentByIdError: null,

  updateContentLoader: false,
  updateContentData: null,
  updateContentError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // For GET spotlight content Data
    case FETCH_SPOTLIGHT_CONTENT_REQUEST:
      return {
        ...state,
        ContentLoader: true,
      };
    case FETCH_SPOTLIGHT_CONTENT_SUCCESS:
      return {
        ...state,
        isContentLoaded: true,
        Content: action.payload,
        ContentLoader: false,
      };
    case FETCH_SPOTLIGHT_CONTENT_ERROR:
      return {
        ...state,
        ContentError: action.error.message,
        ContentLoader: false,
      };

    // For get spotlight content by Id
    case GET_SPOTLIGHT_CONTENT_BY_ID_REQUEST:
      return {
        ...state,
        contentByIdLoader: true,
      };
    case GET_SPOTLIGHT_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        contentByIdData: action.payload,
      };
    case GET_SPOTLIGHT_CONTENT_BY_ID_ERROR:
      return {
        ...state,
        contentByIdError: action.error,
      };

    // For update spotlight content
    case UPDATE_SPOTLIGHT_CONTENT_REQUEST:
      return {
        ...state,
        updateContentLoader: true,
      };
    case UPDATE_SPOTLIGHT_CONTENT_SUCCESS:
      return {
        ...state,
        updateContentData: action.payload,
      };
    case UPDATE_SPOTLIGHT_CONTENT_ERROR:
      return {
        ...state,
        updateContentError: action.error,
      };

    default:
      return state;
  }
}
