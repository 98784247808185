import {
    GET_ANALYZER_PRODUCT_PACKAGE_SUCCESS,
    GET_ANALYZER_PRODUCT_PACKAGE_ERROR,

    CREATE_ANALYZER_PACKAGE_SUCCESS,
    CREATE_ANALYZER_PACKAGE_ERROR,

    UPDATE_ANALYZER_PACKAGE_SUCCESS,
    UPDATE_ANALYZER_PACKAGE_ERROR,

    DELETE_ANALYZER_PACKAGE_SUCCESS,
    DELETE_ANALYZER_PACKAGE_ERROR,

    FETCH_ANALYZER_PACKAGE_SUCCESS,
    FETCH_ANALYZER_PACKAGE_ERROR,

    FETCH_ANALYZER_PACKAGE_PLANS_STATUS_SUCCESS,
    FETCH_ANALYZER_PACKAGE_PLANS_STATUS_ERROR
} from "../../constants/BaseballCloud/AnalyzerPackage.const";

const initialState = {
    analyzerPackageData: null,
    analyzerPackageError: null,

    createAnalyzerPackageData: null,
    createAnalyzerPackageError: null,

    updateAnalyzerPackageData: null,
    updateAnalyzerPackageError: null,

    deleteAnalyzerPackageData: null,
    deleteAnalyzerPackageError: null,

    fetchAnalyzerPackageData: null,
    fetchAnalyzerPackageError: null,

    statusAnalyzerPackageData: null,
    statusAnalyzerPackageError: null,
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_ANALYZER_PRODUCT_PACKAGE_SUCCESS:
            return {
                ...state,
                analyzerPackageData: action.payload
            }
        case GET_ANALYZER_PRODUCT_PACKAGE_ERROR:
            return {
                ...state,
                analyzerPackageError: action.payload
            }

        case CREATE_ANALYZER_PACKAGE_SUCCESS:
            return {
                ...state,
                createAnalyzerPackageData: action.payload
            }
        case CREATE_ANALYZER_PACKAGE_ERROR:
            return {
                ...state,
                createAnalyzerPackageError: action.payload
            }

        case UPDATE_ANALYZER_PACKAGE_SUCCESS:
            return {
                ...state,
                updateAnalyzerPackageData: action.payload
            }
        case UPDATE_ANALYZER_PACKAGE_ERROR:
            return {
                ...state,
                updateAnalyzerPackageError: action.payload
            }

        case DELETE_ANALYZER_PACKAGE_SUCCESS:
            return {
                ...state,
                deleteAnalyzerPackageData: action.payload
            }
        case DELETE_ANALYZER_PACKAGE_ERROR:
            return {
                ...state,
                deleteAnalyzerPackageError: action.payload
            }

        case FETCH_ANALYZER_PACKAGE_SUCCESS:
            return {
                ...state,
                fetchAnalyzerPackageData: action.payload
            }
        case FETCH_ANALYZER_PACKAGE_ERROR:
            return {
                ...state,
                fetchAnalyzerPackageError: action.payload
            }

        case FETCH_ANALYZER_PACKAGE_PLANS_STATUS_SUCCESS:
            return {
                ...state,
                statusAnalyzerPackageData: action.payload
            }
        case FETCH_ANALYZER_PACKAGE_PLANS_STATUS_ERROR:
            return {
                ...state,
                statusAnalyzerPackageError: action.payload
            }

        default:
            return state;
    }
}