import {
  FETCH_TOP_TEAMS_REQUEST,
  FETCH_TOP_TEAMS_SUCCESS,
  FETCH_TOP_TEAMS_ERROR,
  FETCH_ALL_TEAMS_REQUEST,
  FETCH_ALL_TEAMS_SUCCESS,
  FETCH_ALL_TEAMS_ERROR,
  FETCH_TEAM_BY_FILTER_REQUEST,
  FETCH_TEAM_BY_FILTER_SUCCESS,
  FETCH_TEAM_BY_FILTER_ERROR,
  FETCH_TEAM_BY_ID_REQUEST,
  FETCH_TEAM_BY_ID_SUCCESS,
  FETCH_TEAM_BY_ID_ERROR,
  EXPORT_CSV_FOR_TEAM_REQUEST,
  EXPORT_CSV_FOR_TEAM_SUCCESS,
  EXPORT_CSV_FOR_TEAM_ERROR,
  BYNAME_PARENTTEAM_LIST_REQUEST,
  BYNAME_PARENTTEAM_LIST_SUCCESS,
  BYNAME_PARENTTEAM_LIST_ERROR,
} from "../../constants/Users/Teams.const";

const initialState = {
  topTeams: [],
  topTeamsError: null,
  topTeamsLoader: false,

  team: [],
  teamError: null,
  teamLoader: false,
  isTeamLoaded: false,

  allTeams: [],
  allTeamsError: null,
  allTeamsLoader: false,
  isAllTeamsLoaded: false,

  teamsByFilter: [],
  teamsByFilterError: null,
  teamsByFilterLoader: false,
  isTeamsLoaded: false,

  exportCsvTeam: null,
  exportCsvTeamError: null,
  exportCsvTeamLoader: false,

  bynamePTListReq: false,
  bynamePTListData: null,
  bynamePTListError: null,

  filters: {
    page: 0,
    name: "",
    role: "Team",
    sortOn: "0",
    // dateType : '0',
    dateFilter: {
      createdAt: 99,
      // lastLoginTime: 0,
      startDate: "",
      endDate: "",
    },
    // age: "",
    // state: "",
    "game-type": "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Fetch - TEAM BY ID

    case FETCH_TEAM_BY_ID_REQUEST:
      return {
        ...state,
        teamLoader: true,
      };
    case FETCH_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        isTeamLoaded: true,
        team: action.payload,
        teamLoader: false,
      };
    case FETCH_TEAM_BY_ID_ERROR:
      return {
        ...state,
        teamError: action.error.message,
        teamLoader: false,
      };

    // Fetch - TOP TEAMS

    case FETCH_TOP_TEAMS_REQUEST:
      return {
        ...state,
        topTeamsLoader: true,
      };
    case FETCH_TOP_TEAMS_SUCCESS:
      return {
        ...state,
        topTeams: action.payload,
        topTeamsLoader: false,
      };
    case FETCH_TOP_TEAMS_ERROR:
      return {
        ...state,
        topTeamsError: action.error.message,
        topTeamsLoader: false,
      };

    // Fetch - ALL TEAMS

    case FETCH_ALL_TEAMS_REQUEST:
      return {
        ...state,
        allTeamsLoader: true,
      };
    case FETCH_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        isAllTeamsLoaded: true,
        allTeams: action.payload,
        allTeamsLoader: false,
      };
    case FETCH_ALL_TEAMS_ERROR:
      return {
        ...state,
        allTeamsError: action.error.message,
        allTeamsLoader: false,
      };

    // Fetch - TEAM BY FILTER

    case FETCH_TEAM_BY_FILTER_REQUEST:
      return {
        ...state,
        teamsByFilterLoader: true,
      };
    case FETCH_TEAM_BY_FILTER_SUCCESS:
      return {
        ...state,
        isTeamsLoaded: true,
        teamsByFilter: action.payload,
        teamsByFilterLoader: false,
      };
    case FETCH_TEAM_BY_FILTER_ERROR:
      return {
        ...state,
        teamsByFilterError: action.error.message,
        teamsByFilterLoader: false,
      };

    // EXPORT CSV FOR TEAM
    case EXPORT_CSV_FOR_TEAM_REQUEST:
      return {
        ...state,
        exportCsvTeamLoader: true,
      };
    case EXPORT_CSV_FOR_TEAM_SUCCESS:
      return {
        ...state,
        exportCsvTeam: action.payload,
        exportCsvTeamLoader: true,
      };
    case EXPORT_CSV_FOR_TEAM_ERROR:
      return {
        ...state,
        exportCsvTeamError: action.error,
      };

    // for team by name parentteam list
    case BYNAME_PARENTTEAM_LIST_REQUEST:
      return {
        ...state,
        bynamePTListReq: true,
      };
    case BYNAME_PARENTTEAM_LIST_SUCCESS:
      return {
        ...state,
        bynamePTListData: action.payload,
      };
    case BYNAME_PARENTTEAM_LIST_ERROR:
      return {
        ...state,
        bynamePTListError: action.error.message,
      };

    default:
      return state;
  }
}
