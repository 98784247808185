
import {
  GET_PRODUCT_PACKAGE_LIST_SUCCESS,
  GET_PRODUCT_PACKAGE_LIST_ERROR,
  CREATE_PRODUCT_PACKAGE_SUCCESS,
  CREATE_PRODUCT_PACKAGE_ERROR,
  DELETE_PRODUCT_PACKAGE_SUCCESS,
  DELETE_PRODUCT_PACKAGE_ERROR,
  FETCH_PRODUCT_PACKAGE_SUCCESS,
  FETCH_PRODUCT_PACKAGE_ERROR,
  UPDATE_PRODUCT_PACKAGE_SUCCESS,
  UPDATE_PRODUCT_PACKAGE_ERROR,
  GET_PRODUCT_FEATURE_ERROR,
  GET_PRODUCT_FEATURE_SUCCESS,
  GET_PRODUCT_MASTER_ERROR,
  GET_PRODUCT_MASTER_SUCCESS
} from '../constants/ProductPackage.const';

const initialState = {
  productPackageData: null,
  producrPackageError: null,

  productFeatureData: [],
  productFeatureError: null,

  productMasterData: [],
  productMasterError: null,

  productObj: {},


  createProductPackageData: null,
  createProductPackageError: null,

  deleteProductPackageData: null,
  deleteProductPackageError: null,

  fetchProductPackageData: null,
  fetchProductPackageError: null,


  updateProductPackageData: null,
  updateProductPackageError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For GET Product package Data

    case GET_PRODUCT_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        productPackageData: action.payload
      };
    case GET_PRODUCT_PACKAGE_LIST_ERROR:
      return {
        ...state,
        producrPackageError: action.error,
      };
    // get product Feature data
    case GET_PRODUCT_FEATURE_SUCCESS:
      return {
        ...state,
        productFeatureData: action.payload
      };
    case GET_PRODUCT_FEATURE_ERROR:
      return {
        ...state,
        productFeatureError: action.error,
      };
    //get Product master data 

    case GET_PRODUCT_MASTER_SUCCESS:
      let convertObject = {};
      action.payload.data.map((data) => {
        convertObject[data._id] = data;
      });
      return {
        ...state,
        productObj: convertObject,
        productMasterData: action.payload
      };
    case GET_PRODUCT_MASTER_ERROR:
      return {
        ...state,
        productMasterError: action.error,
      };

    case FETCH_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        fetchProductPackageData: action.payload
      };
    case FETCH_PRODUCT_PACKAGE_ERROR:
      return {
        ...state,
        fetchProductPackageError: action.error,
      };
    case CREATE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        createProductPackageData: action.payload
      };
    case CREATE_PRODUCT_PACKAGE_ERROR:
      return {
        ...state,
        createProductPackageError: action.error,
      };
    case DELETE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        deleteProductPackageData: action.payload
      };
    case DELETE_PRODUCT_PACKAGE_ERROR:
      return {
        ...state,
        deleteProductPackageError: action.error,
      };
    case UPDATE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        updateProductPackageData: action.payload
      };
    case UPDATE_PRODUCT_PACKAGE_ERROR:
      return {
        ...state,
        updateProductPackageError: action.error,
      };
    default:
      return state;
  }
}