import { 
    LOGIN_REQUEST,
    LOGIN_SUCCESS, 
    LOGIN_ERROR

  } from '../../constants/Authentication/Login.const';
  
  const initialState = {

    isLoginSuccess: false,
    loginError: null,
    loginLoader: false,
    authData: []

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
  
      // Get AUTHENTICATION Data
  
      case LOGIN_REQUEST:
        return {
          ...state,
          loginLoader: true
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoginSuccess: true,
          authData: action.payload,
          loginLoader: false
        };
      case LOGIN_ERROR:
        return {
          ...state,
          loginError: action.error,
          loginLoader: false
        };
  
      default:
        return state;
    }
  }