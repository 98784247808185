import { 
  FETCH_DC_OBJ_REQUEST, 
  FETCH_DC_OBJ_SUCCESS, 
  FETCH_DC_OBJ_ERROR,   
  UPDATE_DC_OBJ_SUCCESS,

  FETCH_DK_OBJ_REQUEST, 
  FETCH_DK_OBJ_SUCCESS, 
  FETCH_DK_OBJ_ERROR,   
  UPDATE_DK_OBJ_SUCCESS,

  FETCH_TM_OBJ_REQUEST, 
  FETCH_TM_OBJ_SUCCESS, 
  FETCH_TM_OBJ_ERROR,   
  UPDATE_TM_OBJ_SUCCESS,

  FETCH_FS_OBJ_REQUEST, 
  FETCH_FS_OBJ_SUCCESS, 
  FETCH_FS_OBJ_ERROR,   
  UPDATE_FS_OBJ_SUCCESS,

  FETCH_YT_OBJ_REQUEST, 
  FETCH_YT_OBJ_SUCCESS, 
  FETCH_YT_OBJ_ERROR,   
  UPDATE_YT_OBJ_SUCCESS,


  FETCH_RAPSODO_OBJ_REQUEST, 
  FETCH_RAPSODO_OBJ_SUCCESS, 
  FETCH_RAPSODO_OBJ_ERROR,   
  UPDATE_RAPSODO_OBJ_SUCCESS,

  FETCH_MEASUREMENT_OBJ_REQUEST, 
  FETCH_MEASUREMENT_OBJ_SUCCESS, 
  FETCH_MEASUREMENT_OBJ_ERROR,
  UPDATE_MEASUREMENT_OBJ_SUCCESS,

  } from '../constants/LinkRoster.const';
  
  const initialState = {
    dcObj: [],
    isDcObjLoaded: false,
    dcObjLoader: false,
    dcObjError: null,
    isDcObjUpdated: false,

    dkObj: [],
    isDkObjLoaded: false,
    dkObjLoader: false,
    dkObjError: null,
    isDkObjUpdated: false,

    tmObj: [],
    isTmObjLoaded: false,
    tmObjLoader: false,
    tmObjError: null,
    isTmObjUpdated: false,

    fsObj: [],
    isFsObjLoaded: false,
    fsObjLoader: false,
    fsObjError: null,
    isFsObjUpdated: false,

    ytObj: [],
    isYtObjLoaded: false,
    ytObjLoader: false,
    ytObjError: null,
    isYtObjUpdated: false,

    rapsodoObj: [],
    isRapsodoObjLoaded: false,
    rapsodoObjLoader: false,
    rapsodoObjError: null,
    isRapsodoObjUpdated: false,

    measurementObj: [],
    isMeasurementObjLoaded: false,
    measurementObjLoader: false,
    measurementObjError: null,
    isMeasurementObjUpdated: false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET DC Object Data
  
      case FETCH_DC_OBJ_REQUEST:
        return {
          ...state,
          dcObjLoader: true
        };
      case FETCH_DC_OBJ_SUCCESS:
        return {
          ...state,
          dcObj: action.payload,
          dcObjLoader: false,
          isDcObjLoaded: true
        };
      case FETCH_DC_OBJ_ERROR:
        return {
          ...state,
          dcObjError: action.error.message,
          dcObjLoader: false
        };

      // For GET DK Object Data
  
      case FETCH_DK_OBJ_REQUEST:
        return {
          ...state,
          dkObjLoader: true
        };
      case FETCH_DK_OBJ_SUCCESS:
        return {
          ...state,
          dkObj: action.payload,
          dkObjLoader: false,
          isDkObjLoaded: true
        };
      case FETCH_DK_OBJ_ERROR:
        return {
          ...state,
          dkObjError: action.error.message,
          dkObjLoader: false
        };

      // For GET TM Object Data
  
      case FETCH_TM_OBJ_REQUEST:
        return {
          ...state,
          tmObjLoader: true
        };
      case FETCH_TM_OBJ_SUCCESS:
        return {
          ...state,
          tmObj: action.payload,
          tmObjLoader: false,
          isTmObjLoaded: true
        };
      case FETCH_TM_OBJ_ERROR:
        return {
          ...state,
          tmObjError: action.error.message,
          tmObjLoader: false
        };

      // For GET FS Object Data
  
      case FETCH_FS_OBJ_REQUEST:
        return {
          ...state,
          fsObjLoader: true
        };
      case FETCH_FS_OBJ_SUCCESS:
        return {
          ...state,
          fsObj: action.payload,
          fsObjLoader: false,
          isFsObjLoaded: true
        };
      case FETCH_FS_OBJ_ERROR:
        return {
          ...state,
          fsObjError: action.error.message,
          fsObjLoader: false
        };

      // For GET YT Object Data
  
      case FETCH_YT_OBJ_REQUEST:
        return {
          ...state,
          ytObjLoader: true
        };
      case FETCH_YT_OBJ_SUCCESS:
        return {
          ...state,
          ytObj: action.payload,
          ytObjLoader: false,
          isYtObjLoaded: true
        };
      case FETCH_YT_OBJ_ERROR:
        return {
          ...state,
          ytObjError: action.error.message,
          ytObjLoader: false
        };

      // For GET RAPSODO Object Data
  
      case FETCH_RAPSODO_OBJ_REQUEST:
        return {
          ...state,
          rapsodoObjLoader: true
        };
      case FETCH_RAPSODO_OBJ_SUCCESS:
        return {
          ...state,
          rapsodoObj: action.payload,
          rapsodoObjLoader: false,
          isRapsodoObjLoaded: true
        };
      case FETCH_RAPSODO_OBJ_ERROR:
        return {
          ...state,
          rapsodoObjError: action.error.message,
          rapsodoObjLoader: false
        };

      // For GET MEASUREMENT Object Data
  
      case FETCH_MEASUREMENT_OBJ_REQUEST:
        return {
          ...state,
          measurementObjLoader: true
        };
      case FETCH_MEASUREMENT_OBJ_SUCCESS:
        return {
          ...state,
          measurementObj: action.payload,
          measurementObjLoader: false,
          isMeasurementObjLoaded: true
        };
      case FETCH_MEASUREMENT_OBJ_ERROR:
        return {
          ...state,
          measurementObjError: action.error.message,
          measurementObjLoader: false
        };


      // For UPDATE DC Data
  
      case UPDATE_DC_OBJ_SUCCESS:
        return {
          ...state,
         isDcObjUpdated: true
        };

      // For UPDATE DK Data
  
      case UPDATE_DK_OBJ_SUCCESS:
        return {
          ...state,
         isDkObjUpdated: true
        };

      // For Update TM Object Data

      case UPDATE_TM_OBJ_SUCCESS:
        return {
          ...state,
         isTmObjUpdated: true
        };

      // For Update FS Object Data

      case UPDATE_FS_OBJ_SUCCESS:
        return {
          ...state,
         isFsObjUpdated: true
        };

      // For Update YT Object Data

      case UPDATE_YT_OBJ_SUCCESS:
        return {
          ...state,
         isYtObjUpdated: true
        };

      // For Update RAPSODO Object Data

      case UPDATE_RAPSODO_OBJ_SUCCESS:
        return {
          ...state,
          isRapsodoObjUpdated: true
        };

      // For Update MEASUREMENT Object Data

      case UPDATE_MEASUREMENT_OBJ_SUCCESS:
        return {
          ...state,
          isMeasurementObjUpdated: true
        };
  
      default:
        return state;
    }
  }