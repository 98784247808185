export const GET_TEAMPLAYER_RANKING_SUCCESS = "GET_TEAMPLAYER_RANKING_SUCCESS";
export const GET_TEAMPLAYER_RANKING_ERROR = "GET_TEAMPLAYER_RANKING_ERROR";

export const CREATE_TEAMPLAYER_RANKING_SUCCESS = "CREATE_TEAMPLAYER_RANKING_SUCCESS";
export const CREATE_TEAMPLAYER_RANKING_ERROR = "CREATE_TEAMPLAYER_RANKING_ERROR";

export const CHANGE_TEAMPLAYER_STATE_SUCCESS = "CHANGE_TEAMPLAYER_STATE_SUCCESS";
export const CHANGE_TEAMPLAYER_STATE_ERROR = "CHANGE_TEAMPLAYER_STATE_ERROR";

export const GET_SELECTED_TEAM_LIST_SUCCESS = "GET_SELECTED_TEAM_LIST_SUCCESS";
export const GET_SELECTED_TEAM_LIST_ERROR = "GET_SELECTED_TEAM_LIST_ERROR";

export const FETCH_TEAM_PLAYER_SUCCESS = "FETCH_TEAM_PLAYER_SUCCESS";
export const FETCH_TEAM_PLAYER_ERROR = "FETCH_TEAM_PLAYER_ERROR";

export const UPDATE_TEAM_PLAYER_SUCCESS = "UPDATE_TEAM_PLAYER_SUCCESS";
export const UPDATE_TEAM_PLAYER_ERROR = "UPDATE_TEAM_PLAYER_ERROR";

export const DELETE_TEAM_PLAYER_SUCCESS = "DELETE_TEAM_PLAYER_SUCCESS";
export const DELETE_TEAM_PLAYER_ERROR = "DELETE_TEAM_PLAYER_ERROR";

export const GET_BC_REPORT_EVENT_SUCCESS = "GET_BC_REPORT_EVENT_SUCCESS";
export const GET_BC_REPORT_EVENT_ERROR = "GET_BC_REPORT_EVENT_ERROR";

