import { 
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_ERROR,
} from '../../../../constants/BaseballCloud/Users/PopUp/EditProfile.const';

const initialState = {
    userPasswordLoader: false,
    userPasswordData: null,
    userPasswordError: null,
};

export default function(state = initialState, action) {
  switch (action.type) {

    // reset pasasword of user
    case USER_RESET_PASSWORD_REQUEST:
        return {
            ...state,
            userPasswordLoader: true
        };
    case USER_RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            userPasswordData: action.payload
        };
    case USER_RESET_PASSWORD_ERROR:
        return {
            ...state,
            userPasswordError: action.error,
        };

    default:
      return state;
  }
}
