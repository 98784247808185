export const FETCH_AD_BANNER_REQUEST = 'FETCH_AD_BANNER_REQUEST';
export const FETCH_AD_BANNER_SUCCESS = 'FETCH_AD_BANNER_SUCCESS';
export const FETCH_AD_BANNER_ERROR   = 'FETCH_AD_BANNER_ERROR';

export const AD_BANNER_BY_ID_REQUEST = 'AD_BANNER_BY_ID_REQUEST';
export const AD_BANNER_BY_ID_SUCCESS = 'AD_BANNER_BY_ID_SUCCESS';
export const AD_BANNER_BY_ID_ERROR   = 'AD_BANNER_BY_ID_ERROR';

export const FETCH_DISPLAY_ON_REQUEST = 'FETCH_DISPLAY_ON_REQUEST';
export const FETCH_DISPLAY_ON_SUCCESS = 'FETCH_DISPLAY_ON_SUCCESS';
export const FETCH_DISPLAY_ON_ERROR   = 'FETCH_DISPLAY_ON_ERROR';

export const UPDATE_AD_BANNER_REQUEST = 'UPDATE_AD_BANNER_REQUEST';
export const UPDATE_AD_BANNER_SUCCESS = 'UPDATE_AD_BANNER_SUCCESS';
export const UPDATE_AD_BANNER_ERROR   = 'UPDATE_AD_BANNER_ERROR';

export const CREATE_AD_BANNER_REQUEST = 'CREATE_AD_BANNER_REQUEST';
export const CREATE_AD_BANNER_SUCCESS = 'CREATE_AD_BANNER_SUCCESS';
export const CREATE_AD_BANNER_ERROR   = 'CREATE_AD_BANNER_ERROR';

export const DELETE_AD_BANNER_REQUEST = 'DELETE_AD_BANNER_REQUEST';
export const DELETE_AD_BANNER_SUCCESS = 'DELETE_AD_BANNER_SUCCESS';
export const DELETE_AD_BANNER_ERROR   = 'DELETE_AD_BANNER_ERROR';

export const EVENT_AD_BANNER_BY_ID_REQUEST = 'EVENT_AD_BANNER_BY_ID_REQUEST';
export const EVENT_AD_BANNER_BY_ID_SUCCESS = 'EVENT_AD_BANNER_BY_ID_SUCCESS';
export const EVENT_AD_BANNER_BY_ID_ERROR   = 'EVENT_AD_BANNER_BY_ID_ERROR';

export const CREATE_EVENT_AD_BANNER_REQUEST = 'CREATE_EVENT_AD_BANNER_REQUEST';
export const CREATE_EVENT_AD_BANNER_SUCCESS = 'CREATE_EVENT_AD_BANNER_SUCCESS';
export const CREATE_EVENT_AD_BANNER_ERROR   = 'CREATE_EVENT_AD_BANNER_ERROR';

export const UPDATE_EVENT_AD_BANNER_REQUEST = 'UPDATE_EVENT_AD_BANNER_REQUEST';
export const UPDATE_EVENT_AD_BANNER_SUCCESS = 'UPDATE_EVENT_AD_BANNER_SUCCESS';
export const UPDATE_EVENT_AD_BANNER_ERROR   = 'UPDATE_EVENT_AD_BANNER_ERROR';

export const DELETE_EVENT_AD_BANNER_REQUEST = 'DELETE_EVENT_AD_BANNER_REQUEST';
export const DELETE_EVENT_AD_BANNER_SUCCESS = 'DELETE_EVENT_AD_BANNER_SUCCESS';
export const DELETE_EVENT_AD_BANNER_ERROR   = 'DELETE_EVENT_AD_BANNER_ERROR';