import {
  FETCH_ALL_LEAGUES_REQUEST,
  FETCH_ALL_LEAGUES_SUCCESS,
  FETCH_ALL_LEAGUES_ERROR,
  FETCH_LEAGUE_BY_ID_REQUEST,
  FETCH_LEAGUE_BY_ID_SUCCESS,
  FETCH_LEAGUE_BY_ID_ERROR,
  FETCH_LEAGUES_TITTLE_REQUEST,
  FETCH_LEAGUES_TITTLE_SUCCESS,
  FETCH_LEAGUES_TITTLE_ERROR,
  CREATE_LEAGUE_REQUEST,
  CREATE_LEAGUE_SUCCESS,
  CREATE_LEAGUE_ERROR,
  UPDATE_LEAGUE_REQUEST,
  UPDATE_LEAGUE_SUCCESS,
  UPDATE_LEAGUE_ERROR,
  UPDATE_LEAGUE_ACTIVATION_REQUEST,
  UPDATE_LEAGUE_ACTIVATION_SUCCESS,
  UPDATE_LEAGUE_ACTIVATION_ERROR,
  DELETE_LEAGUE_REQUEST,
  DELETE_LEAGUE_SUCCESS,
  DELETE_LEAGUE_ERROR,
  FETCH_UPCOMING_EVENT_REQUEST,
  FETCH_UPCOMING_EVENT_SUCCESS,
  FETCH_UPCOMING_EVENT_ERROR,
  FETCH_EVENT_LOCATION_2_REQUEST,
  FETCH_EVENT_LOCATION_2_SUCCESS,
  FETCH_EVENT_LOCATION_2_ERROR,
} from "../constants/League.const";

const initialState = {
  league: [],
  leagueError: null,
  leagueLoader: false,
  isLeagueLoaded: false,

  allLeagues: [],
  allLeaguesError: null,
  allLeaguesLoader: false,
  isAllLeaguesLoaded: false,

  leagueTittles: [],
  leagueTittlesError: null,
  leagueTittlesLoader: false,

  isCreated: false,
  isCreatedError: null,
  isCreatedLoader: false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false,

  isLive: false,
  isLiveFlag: true,
  isLiveError: null,
  isLiveLoader: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,

  allUpComingEvent : null,
  allUpComingEventError: null,
  allUpComingEventLoader: false,

  location2: [],
  location2Error: null,
  location2Loader: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // For GET League Data By Id

    case FETCH_LEAGUE_BY_ID_REQUEST:
      return {
        ...state,
        leagueLoader: true,
      };
    case FETCH_LEAGUE_BY_ID_SUCCESS:
      return {
        ...state,
        isLeagueLoaded: true,
        league: action.payload,
        leagueLoader: false,
      };
    case FETCH_LEAGUE_BY_ID_ERROR:
      return {
        ...state,
        leagueError: action.error.message,
        leagueLoader: false,
      };

    // For GET ALL League Data

    case FETCH_ALL_LEAGUES_REQUEST:
      return {
        ...state,
        allLeaguesLoader: true,
      };
    case FETCH_ALL_LEAGUES_SUCCESS:
      return {
        ...state,
        isAllLeaguesLoaded: true,
        allLeagues: action.payload,
        allLeaguesLoader: false,
      };
    case FETCH_ALL_LEAGUES_ERROR:
      return {
        ...state,
        allLeaguesError: action.error.message,
        allLeaguesLoader: false,
      };

    // For GET League Tittles Only

    case FETCH_LEAGUES_TITTLE_REQUEST:
      return {
        ...state,
        leagueTittlesLoader: true,
      };
    case FETCH_LEAGUES_TITTLE_SUCCESS:
      return {
        ...state,
        leagueTittles: action.payload,
        leagueTittlesLoader: false,
      };
    case FETCH_LEAGUES_TITTLE_ERROR:
      return {
        ...state,
        leagueTittlesError: action.error.message,
        leagueTittlesLoader: false,
      };

    // For CREATE League Data

    case CREATE_LEAGUE_REQUEST:
      return {
        ...state,
        isCreatedLoader: true,
      };
    case CREATE_LEAGUE_SUCCESS:
      return {
        ...state,
        isCreated: true,
        isCreatedLoader: false,
      };
    case CREATE_LEAGUE_ERROR:
      return {
        ...state,
        isCreatedError: action.error.message,
        isCreatedLoader: false,
      };

    // For UPDATE League Data

    case UPDATE_LEAGUE_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true,
      };
    case UPDATE_LEAGUE_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false,
      };
    case UPDATE_LEAGUE_ERROR:
      return {
        ...state,
        isUpdatedError: action.error.message,
        isUpdatedLoader: false,
      };

    // For UPDATE League Data

    case UPDATE_LEAGUE_ACTIVATION_REQUEST:
      return {
        ...state,
        isLiveLoader: true,
      };
    case UPDATE_LEAGUE_ACTIVATION_SUCCESS:
      return {
        ...state,
        isLive: action.liveFlg,
        isLiveFlag: action.liveFlg,
        isLiveLoader: false,
      };
    case UPDATE_LEAGUE_ACTIVATION_ERROR:
      return {
        ...state,
        isLiveError: action.error.message,
        isLiveLoader: false,
      };

    // For DELETE League Data

    case DELETE_LEAGUE_REQUEST:
      return {
        ...state,
        isDeletedLoader: true,
      };
    case DELETE_LEAGUE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false,
      };
    case DELETE_LEAGUE_ERROR:
      return {
        ...state,
        isDeletedError: action.error.message,
        isDeletedLoader: false,
      };

    // For fetch all upcoming event 

    case FETCH_UPCOMING_EVENT_REQUEST:
      return {
        ...state,
        allUpComingEventLoader: true,
      };
    case FETCH_UPCOMING_EVENT_SUCCESS:
      return {
        ...state,
        allUpComingEvent: action.payload,
        };
        
    case FETCH_UPCOMING_EVENT_ERROR:
      return {
        ...state,
        allUpComingEventError: action.error.message,
        allUpComingEventLoader: false,
      };

      // For fetch search second loaction 

    case FETCH_EVENT_LOCATION_2_REQUEST:
      return {
        ...state,
        location2Loader: true,
      };
    case FETCH_EVENT_LOCATION_2_SUCCESS:
      return {
        ...state,
        location2: action.payload,
        };
        
    case FETCH_EVENT_LOCATION_2_ERROR:
      return {
        ...state,
        location2Error: action.error.message,
        location2Loader: false,
      };

    default:
      return state;
  }
}
