import { 
  FETCH_TOOLTIP_REQUEST, 
  FETCH_TOOLTIP_SUCCESS, 
  FETCH_TOOLTIP_ERROR,

  UPDATE_TOOLTIP_REQUEST, 
  UPDATE_TOOLTIP_SUCCESS, 
  UPDATE_TOOLTIP_ERROR,

} from '../constants/Tooltip.const';

const initialState = {

  tooltips: [],
  tooltipError: null,
  tooltipLoader: false,
  isTooltipsLoaded: false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false,

};

export default function(state = initialState, action) {
  switch (action.type) {

    case FETCH_TOOLTIP_REQUEST:
      return {
        ...state,
        tooltipLoader: true
      };
    case FETCH_TOOLTIP_SUCCESS:
      return {
        ...state,
        tooltips: action.payload,
        tooltipLoader: false
      };
    case FETCH_TOOLTIP_ERROR:
      return {
        ...state,
        tooltipError: action.error.message,
        tooltipLoader: false
      };


      // For Update Tooltip

      case UPDATE_TOOLTIP_REQUEST:
        return {
          ...state,
          isUpdatedLoader: true
        };
      case UPDATE_TOOLTIP_SUCCESS:
        return {
          ...state,
          isUpdated: true,
          isUpdatedLoader: false
        };
      case UPDATE_TOOLTIP_ERROR:
        return {
          ...state,
          isUpdatedError: action.error.message,
          isUpdatedLoader: false
        };

    default:
      return state;
  }
}