//round-robin

export const GET_MANAGE_LIST_SUCCESS = "GET_MANAGE_LIST_SUCCESS";
export const GET_MANAGE_LIST_ERROR = "GET_MANAGE_LIST_ERROR";

export const GET_MANAGE_CSV_SUCCESS = "GET_MANAGE_CSV_SUCCESS";
export const GET_MANAGE_CSV_ERROR = "GET_MANAGE_CSV_ERROR";

export const GET_PRINT_PDF_SUCCESS = "GET_PRINT_PDF_SUCCESS";
export const GET_PRINT_PDF_ERROR = "GET_PRINT_PDF_ERROR";

export const FETCH_TEAM_DATA_SUCCESS = "FETCH_TEAM_DATA_SUCCESS";
export const FETCH_TEAM_DATA_ERROR = "FETCH_TEAM_DATA_ERROR";

export const CREATE_TEAM_DATA_SUCCESS = "CREATE_TEAM_DATA_SUCCESS";
export const CREATE_TEAM_DATA_ERROR = "CREATE_TEAM_DATA_ERROR";

export const SAVE_TEAM_DATA_SUCCESS = "SAVE_TEAM_DATA_SUCCESS";
export const SAVE_TEAM_DATA_ERROR = "SAVE_TEAM_DATA_ERROR";

//single elimination

export const GET_SINGLE_MANAGE_LIST_SUCCESS = "GET_SINGLE_MANAGE_LIST_SUCCESS";
export const GET_SINGLE_MANAGE_LIST_ERROR = "GET_SINGLE_MANAGE_LIST_ERROR";

export const GET_SINGLE_MANAGE_CSV_SUCCESS = "GET_SINGLE_MANAGE_CSV_SUCCESS";
export const GET_SINGLE_MANAGE_CSV_ERROR = "GET_SINGLE_MANAGE_CSV_ERROR";

export const GET_SINGLE_PRINT_PDF_SUCCESS = "GET_SINGLE_PRINT_PDF_SUCCESS";
export const GET_SINGLE_PRINT_PDF_ERROR = "GET_SINGLE_PRINT_PDF_ERROR";

// all ageGroup manage

export const GET_ALL_MANAGE_LIST_SUCCESS = "GET_ALL_MANAGE_LIST_SUCCESS";
export const GET_ALL_MANAGE_LIST_ERROR = "GET_ALL_MANAGE_LIST_ERROR";

export const PRINT_EXPORT_RESPONSE = "PRINT_EXPORT_RESPONSE"

export const POST_TEAM_SCORE_SUCCESS = "POST_TEAM_SCORE_SUCCESS";
export const POST_TEAM_SCORE_ERROR = "POST_TEAM_SCORE_ERROR";

export const GET_HOME_TEAM_SCORE_SUCCESS = "GET_HOME_TEAM_SCORE_SUCCESS";
export const GET_HOME_TEAM_SCORE_ERROR = "GET_HOME_TEAM_SCORE_ERROR";

export const GET_AWAY_TEAM_SCORE_SUCCESS = "GET_AWAY_TEAM_SCORE_SUCCESS";
export const GET_AWAY_TEAM_SCORE_ERROR = "GET_AWAY_TEAM_SCORE_ERROR";

export const EDIT_TEAM_SCORE_SUCCESS = "EDIT_TEAM_SCORE_SUCCESS";
export const EDIT_TEAM_SCORE_ERROR = "EDIT_TEAM_SCORE_ERROR";

export const GET_INNING_SCORE_SUCCESS = "GET_INNING_SCORE_SUCCESS";
export const GET_INNING_SCORE_ERROR = "GET_INNING_SCORE_ERROR";

export const EDIT_INNING_SCORE_SUCCESS = "EDIT_INNING_SCORE_SUCCESS";
export const EDIT_INNING_SCORE_ERROR = "EDIT_INNING_SCORE_ERROR";

//standing data

export const GET_FILTER_STANDING_DATA_SUCCESS = "GET_FILTER_STANDING_DATA_SUCCESS";
export const GET_FILTER_STANDING_DATA_ERROR = "GET_FILTER_STANDING_DATA_ERROR";

export const GET_FILTER_WISE_DATA_SUCCESS = "GET_FILTER_WISE_DATA_SUCCESS";
export const GET_FILTER_WISE_DATA_ERROR = "GET_FILTER_WISE_DATA_ERROR";

export const GET_STANDING_DATA_SUCCESS = "GET_STANDING_DATA_SUCCESS";
export const GET_STANDING_DATA_ERROR = "GET_STANDING_DATA_ERROR";

export const SAVE_STANDING_DATA_SUCCESS = "SAVE_STANDING_DATA_SUCCESS";
export const SAVE_STANDING_DATA_ERROR = "SAVE_STANDING_DATA_ERROR";

export const REFRESH_MATCH_END_SUCCESS = "REFRESH_MATCH_END_SUCCESS";
export const REFRESH_MATCH_END_ERROR = "REFRESH_MATCH_END_ERROR";


//edit Game Stats

export const GET_INNING_STATS_LIST_SUCCESS = "GET_INNING_STATS_LIST_SUCCESS";
export const GET_INNING_STATS_LIST_ERROR = "GET_INNING_STATS_LIST_ERROR";

export const EDIT_GAME_STATS_SUCCESS = "EDIT_GAME_STATS_SUCCESS";
export const EDIT_GAME_STATS_ERROR = "EDIT_GAME_STATS_ERROR";

export const EDIT_GAME_PLAYER_SUCCESS = "EDIT_GAME_PLAYER_SUCCESS";
export const EDIT_GAME_PLAYER_ERROR = "EDIT_GAME_PLAYER_ERROR";

export const PLAY_BY_PLAY_TEAM_LIST_SUCCESS = "PLAY_BY_PLAY_TEAM_LIST_SUCCESS";
export const PLAY_BY_PLAY_TEAM_LIST_ERROR = "PLAY_BY_PLAY_TEAM_LIST_ERROR";

export const PLAY_BY_PLAY_PLAYER_LIST_SUCCESS = "PLAY_BY_PLAY_PLAYER_LIST_SUCCESS";
export const PLAY_BY_PLAY_PLAYER_LIST_ERROR = "PLAY_BY_PLAY_PLAYER_LIST_ERROR";

export const PLAYER_SWAP_FULL_GAME_SUCCESS = "PLAYER_SWAP_FULL_GAME_SUCCESS";
export const PLAYER_SWAP_FULL_GAME_ERROR = "PLAYER_SWAP_FULL_GAME_ERROR";

export const PLAYER_SWAP_BY_PLAY_SUCCESS = "PLAYER_SWAP_BY_PLAY_SUCCESS";
export const PLAYER_SWAP_BY_PLAY_ERROR = "PLAYER_SWAP_BY_PLAY_ERROR";

export const PLAYER_WEBSCORE_SUCCESS = "PLAYER_WEBSCORE_SUCCESS";
export const PLAYER_WEBSCORE_ERROR = "PLAYER_WEBSCORE_ERROR";

export const EXPORT_BRACKET_SUCCESS = "EXPORT_BRACKET_SUCCESS";
export const EXPORT_BRACKET_ERROR = "EXPORT_BRACKET_ERROR";

export const CHECK_ISEDITED = 'CHECK_ISEDITED'
