//GET seed criteria team

export const GET_CRITERIA_TEAM_SUCCESS = "GET_CRITERIA_TEAM_SUCCESS";
export const GET_CRITERIA_TEAM_ERROR = "GET_CRITERIA_TEAM_ERROR";

export const SAVE_CRITERIA_TEAM_SUCCESS = "SAVE_CRITERIA_TEAM_SUCCESS";
export const SAVE_CRITERIA_TEAM_ERROR = "SAVE_CRITERIA_TEAM_ERROR";

export const SAVE_DIVISION_CRITERIA_TEAM_SUCCESS = "SAVE_DIVISION_CRITERIA_TEAM_SUCCESS";
export const SAVE_DIVISION_CRITERIA_TEAM_ERROR = "SAVE_DIVISION_CRITERIA_TEAM_ERROR";


export const GET_CRITERIA_WISE_DATA_SUCCESS = "GET_CRITERIA_WISE_DATA_SUCCESS";
export const GET_CRITERIA_WISE_DATA_ERROR = "GET_CRITERIA_WISE_DATA_ERROR";
