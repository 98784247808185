export const FETCH_MANAGEMENT_DATA_REQUEST = 'FETCH_MANAGEMENT_DATA_REQUEST';
export const FETCH_MANAGEMENT_DATA_SUCCESS = 'FETCH_MANAGEMENT_DATA_SUCCESS';
export const FETCH_MANAGEMENT_DATA_ERROR   = 'FETCH_MANAGEMENT_DATA_ERROR';

export const UPDATE_MANAGEMENT_DATA_REQUEST = 'UPDATE_MANAGEMENT_DATA_REQUEST';
export const UPDATE_MANAGEMENT_DATA_SUCCESS = 'UPDATE_MANAGEMENT_DATA_SUCCESS';
export const UPDATE_MANAGEMENT_DATA_ERROR   = 'UPDATE_MANAGEMENT_DATA_ERROR';

export const FETCH_TEAM_DATA_REQUEST = 'FETCH_TEAM_DATA_REQUEST';
export const FETCH_TEAM_DATA_SUCCESS = 'FETCH_TEAM_DATA_SUCCESS';
export const FETCH_TEAM_DATA_ERROR   = 'FETCH_TEAM_DATA_ERROR';

export const DELETE_MANAGEMENT_DATE_REQUEST = 'DELETE_MANAGEMENT_DATE_REQUEST';
export const DELETE_MANAGEMENT_DATE_SUCCESS = 'DELETE_MANAGEMENT_DATE_SUCCESS';
export const DELETE_MANAGEMENT_DATE_ERROR   = 'DELETE_MANAGEMENT_DATE_ERROR';

export const GENERATE_CSV_REQUEST = 'GENERATE_CSV_REQUEST';
export const GENERATE_CSV_SUCCESS = 'GENERATE_CSV_SUCCESS';
export const GENERATE_CSV_ERROR   = 'GENERATE_CSV_ERROR';

export const FETCH_DATA_TOOL_REQUEST = 'FETCH_DATA_TOOL_REQUEST';
export const FETCH_DATA_TOOL_SUCCESS = 'FETCH_DATA_TOOL_SUCCESS';
export const FETCH_DATA_TOOL_ERROR   = 'FETCH_DATA_TOOL_ERROR';