import {
    GET_EVENT_INFO_SUCCESS,
    GET_EVENT_INFO_ERROR,

    GET_VENUE_LIST_SUCCESS,
    GET_VENUE_LIST_ERROR,

    GET_VENUE_TIMETABLE_SUCCESS,
    GET_VENUE_TIMETABLE_ERROR,

    GET_VENUE_ADDEDLIST_SUCCESS,
    GET_VENUE_ADDEDLIST_ERROR,

    ADD_VENUE_SUCCESS,
    ADD_VENUE_ERROR,

    GET_SITE_DIRECTOR_LIST_REQUEST,
    GET_SITE_DIRECTOR_LIST_SUCCESS,
    GET_SITE_DIRECTOR_LIST_ERROR,

    UPDATE_TIME_REQUEST,
    UPDATE_TIME_SUCCESS,
    UPDATE_TIME_ERROR,

    GET_ALL_TEAM_LIST_SUCCESS,
    GET_ALL_TEAM_LIST_ERROR,

    CHANGE_AGE_DIVISION_SUCCESS,
    CHANGE_AGE_DIVISION_ERROR,

    GET_SHEDULING_LIST_SUCCESS,
    GET_SHEDULING_LIST_ERROR,

    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_ERROR,

    CHANGE_FIELD_STATUS_SUCCESS,
    CHANGE_FIELD_STATUS_ERROR,

    GET_VENUE_DATE_LIST_SUCCESS,
    GET_VENUE_DATE_LIST_ERROR,

    GET_FIELD_LIST_SUCCESS,

    SUBMIT_SCHEDULING_LIST_SUCCESS,

    SET_VENUE_ADDED_MANAGE_FIELD,

    SINGLE_VENUE_DIRECT_ADDED,

    GET_VENUE_TOTAL_LIST,

    APPLY_TO_ALL_DAYS,

    GET_NEW_LOCATION_SUCCESS,
    GET_NEW_LOCATION_ERROR,

    //UPDATE field name

    CHANGE_FIELD_NAME_SUCCESS,
    CHANGE_FIELD_NAME_ERROR,

    ADD_FIELD_NAME_SUCCESS,
    ADD_FIELD_NAME_ERROR,

    //ADD team win lose score

    GET_TEAM_LIST_SUCCESS,
    GET_TEAM_LIST_ERROR,

    GET_TEAM_SCORE_SUCCESS,
    GET_TEAM_SCORE_ERROR,

    ADD_TEAM_SCORE_SUCCESS,
    ADD_TEAM_SCORE_ERROR,

    //delete team
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_ERROR,

    RESET_AGE_GROUP_ROW_RESPONSE,
    RESET_AGE_GROUP_ROW_ERROR,

    EXPORT_GRID_GENERATE_PDF_SUCCESS,
    EXPORT_GRID_GENERATE_PDF_ERROR

} from '../../constants/Scheduling/Venues.const'

const initialState = {
    getAllEventdata: null,
    getAllEventdataerror: null,

    getNewLocationdata: null,
    getNewLocationdataerror: null,

    getallvenuedata: [],
    getallvenuedataerror: null,

    getvenuetimetabledata: [],
    getvenuetimetabledataerror: null,
    getEventStartDate: null,
    isExportActive: null,

    getvenueaddedlistdata: null,
    getvenueaddedlistdataerror: null,

    addvenuedata: null,
    addvenuedataerror: null,

    siteDirectorListReq: false,
    siteDirectorListData: null,
    siteDirectorListError: null,
    siteDirectorListLoader: false,

    updateTimeReq: false,
    updateTimeData: null,
    updateTimeError: null,
    updateTimeLoader: false,

    getAllTeamListData: null,
    getAllTeamListDataError: null,

    changeAllDivisionData: null,
    changeAllDivisionDataError: null,

    getSchedulingListData: null,
    getSchedulingListDataError: null,

    addSchedulerData: null,
    addSchedulerDataError: null,

    changeFieldStatusData: null,
    changeFieldStatusDataError: null,

    venueDateList: null,
    venueDateListError: null,

    setSchedulingList: null,
    setSchedulingListError: null,

    fieldDataList: null,

    submitSchedule: null,

    setVenueAddedmanageFieldResponse: null,

    singleVenueDirectAddedResponse: null,

    totalVenueList: null,

    applyToAllDaysResponse: null,

    //UPDATE field name

    updateFieldNameData: null,
    updateFieldNameDataError: null,

    addFieldNameData: null,
    addFieldNameDataError: null,

    //ADD team win lose score

    getTeamListData: null,
    getTeamListDataError: null,

    getTeamScoreData: null,
    getTeamScoreDataError: null,

    addTeamScoreData: null,
    addTeamScoreDataError: null,

    // delete team

    deleteTeamData: null,
    deleteTeamDataError: null,

    // Reset AgeGroupRow

    resetAgeGroupResponse: null,
    resetAgeGroupError: null,

    exportGridResponse: null,
    exportGridError : null
}
export default function (state = initialState, action) {
    switch (action.type) {

        case GET_EVENT_INFO_SUCCESS:
            return {
                ...state,
                getAllEventdata: action.payload
            }
        case GET_EVENT_INFO_ERROR:
            return {
                ...state,
                getAllEventdataerror: action.error
            }

        case GET_NEW_LOCATION_SUCCESS:
            return {
                ...state,
                getNewLocationdata: action.payload
            }
        case GET_NEW_LOCATION_ERROR:
            return {
                ...state,
                getNewLocationdataerror: action.error
            }

        case GET_VENUE_LIST_SUCCESS:
            return {
                ...state,
                getallvenuedata: action.payload
            }
        case GET_VENUE_LIST_ERROR:
            return {
                ...state,
                getallvenuedataerror: action.error
            }

        case GET_VENUE_TIMETABLE_SUCCESS:
            return {
                ...state,
                getvenuetimetabledata: action.payload,
                getEventStartDate: action.startDate,
                isExportActive : action.isExportActive
            }
        case GET_VENUE_TIMETABLE_ERROR:
            return {
                ...state,
                getvenuetimetabledataerror: action.error
            }

        case GET_VENUE_ADDEDLIST_SUCCESS:
            return {
                ...state,
                getvenueaddedlistdata: action.payload
            }
        case GET_VENUE_ADDEDLIST_ERROR:
            return {
                ...state,
                getvenueaddedlistdataerror: action.error
            }

        case ADD_VENUE_SUCCESS:
            return {
                ...state,
                addvenuedata: action.payload
            }
        case ADD_VENUE_ERROR:
            return {
                ...state,
                addvenuedataerror: action.error
            }

        // For get side director list in venue added
        case GET_SITE_DIRECTOR_LIST_REQUEST:
            return {
                ...state,
                siteDirectorListReq: true,
                siteDirectorListLoader: true,
            };
        case GET_SITE_DIRECTOR_LIST_SUCCESS:
            return {
                ...state,
                siteDirectorListData: action.payload,
                siteDirectorListLoader: false,
            };
        case GET_SITE_DIRECTOR_LIST_ERROR:
            return {
                ...state,
                siteDirectorListError: action.error,
                siteDirectorListLoader: false,
            };

        // For site director,date, start and end time save
        case UPDATE_TIME_REQUEST:
            return {
                ...state,
                updateTimeReq: true,
                updateTimeLoader: true,
            };
        case UPDATE_TIME_SUCCESS:
            return {
                ...state,
                updateTimeData: action.payload,
                updateTimeLoader: false,
            };
        case UPDATE_TIME_ERROR:
            return {
                ...state,
                updateTimeError: action.error,
                updateTimeLoader: false,
            };

        // for scheduling list
        case GET_SHEDULING_LIST_SUCCESS:
            return {
                ...state,
                getSchedulingListData: action.payload
            }
        case GET_SHEDULING_LIST_ERROR:
            return {
                ...state,
                getSchedulingListDataError: action.error
            }

        // for add scheduler
        case CREATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                addSchedulerData: action.payload
            }
        case CREATE_SCHEDULE_ERROR:
            return {
                ...state,
                addSchedulerDataError: action.error
            }

        // Apply To All Days
        case APPLY_TO_ALL_DAYS:
            return {
                ...state,
                applyToAllDaysResponse: action.payload
            }



        case GET_ALL_TEAM_LIST_SUCCESS:
            return {
                ...state,
                getAllTeamListData: action.payload
            }
        case GET_ALL_TEAM_LIST_ERROR:
            return {
                ...state,
                getAllTeamListDataError: action.error
            }

        case CHANGE_AGE_DIVISION_SUCCESS:
            return {
                ...state,
                changeAllDivisionData: action.payload
            }
        case CHANGE_AGE_DIVISION_ERROR:
            return {
                ...state,
                changeAllDivisionDataError: action.error
            }

        //change field status
        case CHANGE_FIELD_STATUS_SUCCESS:
            return {
                ...state,
                changeFieldStatusData: action.payload
            }
        case CHANGE_FIELD_STATUS_ERROR:
            return {
                ...state,
                changeFieldStatusDataError: action.error
            }

        // venueAdded date List
        case GET_VENUE_DATE_LIST_SUCCESS:
            return {
                ...state,
                venueDateList: action.payload
            }

        case GET_VENUE_DATE_LIST_ERROR:
            return {
                ...state,
                venueDateListError: action.error
            }

        // venueAdded SET Scheduling List
        case GET_VENUE_DATE_LIST_SUCCESS:
            return {
                ...state,
                setSchedulingList: action.payload
            }

        case GET_VENUE_DATE_LIST_ERROR:
            return {
                ...state,
                setSchedulingListError: action.error
            }

        // Field List
        case GET_FIELD_LIST_SUCCESS:
            return {
                ...state,
                fieldDataList: action.payload
            }

        // Submit Sheduling List
        case SUBMIT_SCHEDULING_LIST_SUCCESS:
            return {
                ...state,
                submitSchedule: action.payload
            }

        // setVenueAddedmanageField
        case SET_VENUE_ADDED_MANAGE_FIELD:
            return {
                ...state,
                setVenueAddedmanageFieldResponse: action.payload
            }

        // singleVenueDirectAdded
        case SINGLE_VENUE_DIRECT_ADDED:
            return {
                ...state,
                singleVenueDirectAddedResponse: action.payload
            }

        // totalVenueList
        case GET_VENUE_TOTAL_LIST:
            return {
                ...state,
                totalVenueList: action.payload
            }

        //update field name
        case CHANGE_FIELD_NAME_SUCCESS:
            return {
                ...state,
                updateFieldNameData: action.payload
            }

        case CHANGE_FIELD_NAME_ERROR:
            return {
                ...state,
                updateFieldNameDataError: action.error
            }

        case ADD_FIELD_NAME_SUCCESS:
            return {
                ...state,
                addFieldNameData: action.payload
            }

        case ADD_FIELD_NAME_ERROR:
            return {
                ...state,
                addFieldNameDataError: action.error
            }

        //ADD team win lose score

        case GET_TEAM_LIST_SUCCESS:
            return {
                ...state,
                getTeamListData: action.payload
            }

        case GET_TEAM_LIST_ERROR:
            return {
                ...state,
                getTeamListDataError: action.error
            }

        case GET_TEAM_SCORE_SUCCESS:
            return {
                ...state,
                getTeamScoreData: action.payload
            }

        case GET_TEAM_SCORE_ERROR:
            return {
                ...state,
                getTeamScoreDataError: action.error
            }

        case ADD_TEAM_SCORE_SUCCESS:
            return {
                ...state,
                addTeamScoreData: action.payload
            }

        case ADD_TEAM_SCORE_ERROR:
            return {
                ...state,
                addTeamScoreDataError: action.error
            }

        //delete team

        case DELETE_TEAM_SUCCESS:
            return {
                ...state,
                deleteTeamData: action.payload
            }

        case DELETE_TEAM_ERROR:
            return {
                ...state,
                deleteTeamDataError: action.error
            }

        // Reset Age Group Row 

        case RESET_AGE_GROUP_ROW_RESPONSE:
            return {
                ...state,
                resetAgeGroupResponse: action.payload
            }

        case RESET_AGE_GROUP_ROW_ERROR:
            return {
                ...state,
                resetAgeGroupError: action.error
            }

         // Export grid and generate pdf

         case EXPORT_GRID_GENERATE_PDF_SUCCESS:
            return {
                ...state,
                exportGridResponse: action.payload
            }

        case EXPORT_GRID_GENERATE_PDF_ERROR:
            return {
                ...state,
                exportGridError: action.error
            }

        default:
            return state;
    }
}