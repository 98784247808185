import { 
    FETCH_COUPON_CODE_REQUEST, 
    FETCH_COUPON_CODE_SUCCESS, 
    FETCH_COUPON_CODE_ERROR,

    FETCH_COUPON_CODE_BY_ID_REQUEST, 
    FETCH_COUPON_CODE_BY_ID_SUCCESS, 
    FETCH_COUPON_CODE_BY_ID_ERROR,

    FETCH_AD_BANNER_REQUEST,
    FETCH_AD_BANNER_SUCCESS,
    FETCH_AD_BANNER_ERROR,

    FETCH_REWARDS_REQUEST,
    FETCH_REWARDS_SUCCESS,
    FETCH_REWARDS_ERROR,

    FETCH_PUSH_REQUEST,
    FETCH_PUSH_SUCCESS,
    FETCH_PUSH_ERROR,

    CREATE_COUPON_CODE_REQUEST,
    CREATE_COUPON_CODE_SUCCESS,
    CREATE_COUPON_CODE_ERROR,

    UPDATE_COUPON_CODE_REQUEST,
    UPDATE_COUPON_CODE_SUCCESS,
    UPDATE_COUPON_CODE_ERROR,

    DELETE_COUPON_CODE_REQUEST,
    DELETE_COUPON_CODE_SUCCESS,
    DELETE_COUPON_CODE_ERROR

} from '../constants/Marketing.const';
  
  const initialState = {
    couponCodes: [],
    couponCodesError: null,
    couponCodesLoader: false,

    couponCode: [],
    couponCodeError: null,
    couponCodeLoader: false,

    isCouponCodeCreated: false,
    isCouponCodeCreatedError: null,
    isCouponCodeCreatedLoader: false,

    isCouponCodeUpdated: false,
    isCouponCodeUpdatedError: null,
    isCouponCodeUpdatedLoader: false,

    isCouponCodeDeleted: false,
    isCouponCodeDeletedError: null,
    isCouponCodeDeletedLoader: false,

    adBanners: [],
    adBannersError: null,
    adBannersLoader: false,

    rewards: [],
    rewardsError: null,
    rewardsLoader: false,

    pushList: [],
    pushListError: null,
    pushListLoader: false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Coupon Code Data
  
      case FETCH_COUPON_CODE_REQUEST:
        return {
          ...state,
          couponCodesLoader: true
        };
      case FETCH_COUPON_CODE_SUCCESS:
        return {
          ...state,
          couponCodes: action.payload,
          couponCodesLoader: false
        };
      case FETCH_COUPON_CODE_ERROR:
        return {
          ...state,
          couponCodesError: action.error.message,
          couponCodesLoader: false
        };

      // For GET Coupon Code By Id

      case FETCH_COUPON_CODE_BY_ID_REQUEST:
        return {
          ...state,
          couponCodeLoader: true
        };
      case FETCH_COUPON_CODE_BY_ID_SUCCESS:
        return {
          ...state,
          couponCode: action.payload,
          couponCodeLoader: false
        };
      case FETCH_COUPON_CODE_BY_ID_ERROR:
        return {
          ...state,
          couponCodeError: action.error.message,
          couponCodeLoader: false
        };

      // For CREATE Coupon Code Data
  
      case CREATE_COUPON_CODE_REQUEST:
        return {
          ...state,
          isCouponCodeCreatedLoader: true
        };
      case CREATE_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponCodeCreated: action.payload,
          isCouponCodeCreatedLoader: false
        };
      case CREATE_COUPON_CODE_ERROR:
        return {
          ...state,
          isCouponCodeCreatedError: action.error.message,
          isCouponCodeCreatedLoader: false
        };

      // For UPDATE Coupon Code Data

      case UPDATE_COUPON_CODE_REQUEST:
        return {
          ...state,
          isCouponCodeUpdatedLoader: true
        };
      case UPDATE_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponCodeUpdated: true,
          isCouponCodeUpdatedLoader: false
        };
      case UPDATE_COUPON_CODE_ERROR:
        return {
          ...state,
          isCouponCodeUpdatedError: action.error.message,
          isCouponCodeUpdatedLoader: false
        };


      // For DELETE Coupon Code Data

      case DELETE_COUPON_CODE_REQUEST:
        return {
          ...state,
          isCouponCodeDeletedLoader: true
        };
      case DELETE_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponCodeDeleted: true,
          isCouponCodeDeletedLoader: false
        };
      case DELETE_COUPON_CODE_ERROR:
        return {
          ...state,
          isCouponCodeDeletedError: action.error.message,
          isCouponCodeDeletedLoader: false
        };

      // For GET Event Data
  
      case FETCH_AD_BANNER_REQUEST:
        return {
          ...state,
          adBannersLoader: true
        };
      case FETCH_AD_BANNER_SUCCESS:
        return {
          ...state,
          adBanners: action.payload,
          adBannersLoader: false
        };
      case FETCH_AD_BANNER_ERROR:
        return {
          ...state,
          adBannersError: action.error.message,
          adBannersLoader: false
        };

      // For GET Event Data
  
      case FETCH_REWARDS_REQUEST:
        return {
          ...state,
          rewardsLoader: true
        };
      case FETCH_REWARDS_SUCCESS:
        return {
          ...state,
          rewards: action.payload,
          rewardsLoader: false
        };
      case FETCH_REWARDS_ERROR:
        return {
          ...state,
          rewardsError: action.error.message,
          rewardsLoader: false
        };

      // For GET Event Data

      case FETCH_PUSH_REQUEST:
        return {
          ...state,
          pushListLoader: true
        };
      case FETCH_PUSH_SUCCESS:
        return {
          ...state,
          pushList: action.payload,
          pushListLoader: false
        };
      case FETCH_PUSH_ERROR:
        return {
          ...state,
          pushListError: action.error.message,
          pushListLoader: false
        };  
  
      default:
        return state;
    }
  }