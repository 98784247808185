import {
    GET_TEAMPLAYER_RANKING_SUCCESS,
    GET_TEAMPLAYER_RANKING_ERROR,

    CREATE_TEAMPLAYER_RANKING_SUCCESS,
    CREATE_TEAMPLAYER_RANKING_ERROR,

    CHANGE_TEAMPLAYER_STATE_SUCCESS,
    CHANGE_TEAMPLAYER_STATE_ERROR,

    GET_SELECTED_TEAM_LIST_SUCCESS,
    GET_SELECTED_TEAM_LIST_ERROR,

    FETCH_TEAM_PLAYER_SUCCESS,
    FETCH_TEAM_PLAYER_ERROR,

    UPDATE_TEAM_PLAYER_SUCCESS,
    UPDATE_TEAM_PLAYER_ERROR,

    DELETE_TEAM_PLAYER_SUCCESS,
    DELETE_TEAM_PLAYER_ERROR,
    GET_BC_REPORT_EVENT_SUCCESS,
    GET_BC_REPORT_EVENT_ERROR

} from '../constants/TeamPlayerRanking.const'


const initialState = {
    changeteamplayerstatedata: [],
    changeteamplayerstateerror: null,

    bcReportEventData: [],
    bcReportEventError:null,

    getselectedteamdata: null,
    getselectedteamerror: null,

    getteamplayerrankingdata: null,
    getteamplayerrankingerror: null,

    createteamplayerrankingdata: null,
    createteamplayerrankingerror: null,

    fetchteamplayerrankingdata: null,
    fetchteamplayerrankingerror: null,

    updateteamplayerrankingdata: null,
    updateteamplayerrankingerror: null,

    deleteteamplayerrankingdata: null,
    deleteteamplayerrankingerror: null,
}


export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_TEAMPLAYER_STATE_SUCCESS:
            return {
                ...state,
                changeteamplayerstatedata: action.payload
            }
        case CHANGE_TEAMPLAYER_STATE_ERROR:
            return {
                ...state,
                changeteamplayerstateerror: action.error
            }
        case GET_BC_REPORT_EVENT_SUCCESS:
            return {
                ...state,
                bcReportEventData:action.payload
            }
        case GET_BC_REPORT_EVENT_ERROR:
            return {
                ...state,
                bcReportEventError:action.error
            }
        case GET_SELECTED_TEAM_LIST_SUCCESS:
            return {
                ...state,
                getselectedteamdata: action.payload
            }
        case GET_SELECTED_TEAM_LIST_ERROR:
            return {
                ...state,
                getselectedteamerror: action.error
            }

        case CREATE_TEAMPLAYER_RANKING_SUCCESS:

            return {
                ...state,
                createteamplayerrankingdata: action.payload
            }
        case CREATE_TEAMPLAYER_RANKING_ERROR:
            return {
                ...state,
                createteamplayerrankingerror: action.error
            }

        case GET_TEAMPLAYER_RANKING_SUCCESS:
            return {
                ...state,
                getteamplayerrankingdata: action.payload
            }
        case GET_TEAMPLAYER_RANKING_ERROR:
            return {
                ...state,
                getteamplayerrankingerror: action.error
            }

        case FETCH_TEAM_PLAYER_SUCCESS:
            return {
                ...state,
                fetchteamplayerrankingdata: action.payload
            }
        case FETCH_TEAM_PLAYER_ERROR:
            return {
                ...state,
                fetchteamplayerrankingerror: action.error
            }

        case UPDATE_TEAM_PLAYER_SUCCESS:
            return {
                ...state,
                updateteamplayerrankingdata: action.payload
            }
        case UPDATE_TEAM_PLAYER_ERROR:
            return {
                ...state,
                updateteamplayerrankingerror: action.error
            }

        case DELETE_TEAM_PLAYER_SUCCESS:
            return {
                ...state,
                deleteteamplayerrankingdata: action.payload
            }
        case DELETE_TEAM_PLAYER_ERROR:
            return {
                ...state,
                deleteteamplayerrankingerror: action.error
            }

        default:
            return state;
    }
}