import {
  FETCH_APP_BC_SIGNUP_GRAPH_REQUEST,
  FETCH_APP_BC_SIGNUP_GRAPH_SUCCESS,
  FETCH_APP_BC_SIGNUP_GRAPH_ERROR,

  FETCH_APP_BC_PRODUCT_GRAPH_REQUEST,
  FETCH_APP_BC_PRODUCT_GRAPH_SUCCESS,
  FETCH_APP_BC_PRODUCT_GRAPH_ERROR
} from '../../constants/BaseballCloud/Dashboard.const';

const initialState = {
  appBCSignupGraphDataLoader: false,
  appBCSignupGraphData: null,
  appBCSignupGraphDataError: null,

  appBCProGraphDataLoader: false,
  appBCProGraphData: null,
  appBCProGraphDataError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For fetch APP SIGNUP GRAPHS

    case FETCH_APP_BC_SIGNUP_GRAPH_REQUEST:
      return {
        ...state,
        appBCSignupGraphDataLoader: true
      };
    case FETCH_APP_BC_SIGNUP_GRAPH_SUCCESS:
      return {
        ...state,
        appBCSignupGraphData: action.payload,
      };
    case FETCH_APP_BC_SIGNUP_GRAPH_ERROR:
      return {
        ...state,
        appBCSignupGraphDataError: action.error.message,
      };

    // For fetch APP Product GRAPHS

    case FETCH_APP_BC_PRODUCT_GRAPH_REQUEST:
      return {
        ...state,
        appBCProGraphDataLoader: true
      };
    case FETCH_APP_BC_PRODUCT_GRAPH_SUCCESS:
      return {
        ...state,
        appBCProGraphData: action.payload,
      };
    case FETCH_APP_BC_PRODUCT_GRAPH_ERROR:
      return {
        ...state,
        appBCProGraphDataError: action.error.message,
      };

    default:
      return state;
  }
}