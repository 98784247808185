export const FETCH_ADMIN_REQUEST = 'FETCH_ADMIN_REQUEST';
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_ERROR = 'FETCH_ADMIN_ERROR';

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_ERROR = 'CREATE_ADMIN_ERROR';

export const RESET_ADMIN_REQUEST = 'RESET_ADMIN_REQUEST';
export const RESET_ADMIN_SUCCESS = 'RESET_ADMIN_SUCCESS';
export const RESET_ADMIN_ERROR = 'RESET_ADMIN_ERROR';