import {
    GET_TEAMLIST_FOR_COACH_REQUEST,
    GET_TEAMLIST_FOR_COACH_SUCCESS,
    GET_TEAMLIST_FOR_COACH_ERROR,

    UPDATE_COACH_REQUEST,
    UPDATE_COACH_SUCCESS,
    UPDATE_COACH_ERROR,

    ADD_NEW_TEAM_REQUEST,
    ADD_NEW_TEAM_SUCCESS,
    ADD_NEW_TEAM_ERROR,
  } from "../../constants/AddUser/AddCoach.const";
  
  const initialState = {
    listsReq: false,
    listsData: null,
    listsError: null,

    coachReq: false,
    coachData: null,
    coachError: null,
    coachLoader: false,

    newTeamReq: false,
    newTeamData: null,
    newTeamError: null,
    newTeamLoader: false, 
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
  
      // For GET Team List for coach
      case GET_TEAMLIST_FOR_COACH_REQUEST:
        return {
          ...state,
          listsReq: true,
        };
      case GET_TEAMLIST_FOR_COACH_SUCCESS:
        return {
          ...state,
          listsData: action.payload,
        };
      case GET_TEAMLIST_FOR_COACH_ERROR:
        return {
          ...state,
          listsError: action.error,
        };

            // For coach select a team
      case UPDATE_COACH_REQUEST:
        return {
          ...state,
          coachReq: true,
          coachLoader: true,
        };
      case UPDATE_COACH_SUCCESS:
        return {
          ...state,
          coachData: action.payload,
          coachLoader: true,
        };
      case UPDATE_COACH_ERROR:
        return {
          ...state,
          coachError: action.error,
          coachLoader: true,
        };

        // Add new team for coach user
        case ADD_NEW_TEAM_REQUEST:
        return {
          ...state,
          newTeamReq: true,
          newTeamLoader: true,
        };
      case ADD_NEW_TEAM_SUCCESS:
        return {
          ...state,
          newTeamData: action.payload,
          newTeamLoader: true,
        };
      case ADD_NEW_TEAM_ERROR:
        return {
          ...state,
          newTeamError: action.error,
          newTeamLoader: true,
        };

      default:
        return state;
    }
  }
  