
export const GET_EVENT_INFO_SUCCESS = "GET_EVENT_INFO_SUCCESS";
export const GET_EVENT_INFO_ERROR = "GET_EVENT_INFO_ERROR";

export const GET_NEW_LOCATION_SUCCESS = "GET_NEW_LOCATION_SUCCESS";
export const GET_NEW_LOCATION_ERROR = "GET_NEW_LOCATION_ERROR";

export const GET_VENUE_LIST_SUCCESS = "GET_VENUE_LIST_SUCCESS";
export const GET_VENUE_LIST_ERROR = "GET_VENUE_LIST_ERROR";

export const GET_VENUE_TIMETABLE_SUCCESS = "GET_VENUE_TIMETABLE_SUCCESS";
export const GET_VENUE_TIMETABLE_ERROR = "GET_VENUE_TIMETABLE_ERROR";

export const GET_VENUE_ADDEDLIST_SUCCESS = "GET_VENUE_ADDEDLIST_SUCCESS";
export const GET_VENUE_ADDEDLIST_ERROR = "GET_VENUE_ADDEDLIST_ERROR";

export const ADD_VENUE_SUCCESS = "ADD_VENUE_SUCCESS";
export const ADD_VENUE_ERROR = "ADD_VENUE_ERROR";

export const GET_SITE_DIRECTOR_LIST_REQUEST = "GET_SITE_DIRECTOR_LIST_REQUEST";
export const GET_SITE_DIRECTOR_LIST_SUCCESS = "GET_SITE_DIRECTOR_LIST_SUCCESS";
export const GET_SITE_DIRECTOR_LIST_ERROR = "GET_SITE_DIRECTOR_LIST_ERROR";

export const UPDATE_TIME_REQUEST = "UPDATE_TIME_REQUEST";
export const UPDATE_TIME_SUCCESS = "UPDATE_TIME_SUCCESS";
export const UPDATE_TIME_ERROR = "UPDATE_TIME_ERROR";

export const GET_ALL_TEAM_LIST_SUCCESS = "GET_ALL_TEAM_LIST_SUCCESS";
export const GET_ALL_TEAM_LIST_ERROR = "GET_ALL_TEAM_LIST_ERROR";

export const CHANGE_AGE_DIVISION_SUCCESS = "CHANGE_AGE_DIVISION_SUCCESS";
export const CHANGE_AGE_DIVISION_ERROR = "CHANGE_AGE_DIVISION_ERROR";

export const ADD_FIELD_NAME_SUCCESS = "ADD_FIELD_NAME_SUCCESS";
export const ADD_FIELD_NAME_ERROR = "ADD_FIELD_NAME_ERROR";

//ADD team win lose score
export const GET_TEAM_LIST_SUCCESS = "GET_TEAM_LIST_SUCCESS";
export const GET_TEAM_LIST_ERROR = "GET_TEAM_LIST_ERROR";

export const GET_TEAM_SCORE_SUCCESS = "GET_TEAM_SCORE_SUCCESS";
export const GET_TEAM_SCORE_ERROR = "GET_TEAM_SCORE_ERROR";

export const ADD_TEAM_SCORE_SUCCESS = "ADD_TEAM_SCORE_SUCCESS";
export const ADD_TEAM_SCORE_ERROR = "ADD_TEAM_SCORE_ERROR";

// delete team
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const GET_SHEDULING_LIST_SUCCESS = "GET_SHEDULING_LIST_SUCCESS";
export const GET_SHEDULING_LIST_ERROR = "GET_SHEDULING_LIST_ERROR";

export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_ERROR = "CREATE_SCHEDULE_ERROR";

export const CHANGE_FIELD_STATUS_SUCCESS = "CHANGE_FIELD_STATUS_SUCCESS";
export const CHANGE_FIELD_STATUS_ERROR = "CHANGE_FIELD_STATUS_ERROR";

export const GET_VENUE_DATE_LIST_SUCCESS = "GET_VENUE_DATE_LIST_SUCCESS"
export const GET_VENUE_DATE_LIST_ERROR = "GET_VENUE_DATE_LIST_ERROR"

export const SET_SCHEDULING_LIST = "SET_SCHEDULING_LIST"
export const SET_SCHEDULING_LIST_ERROR = "SET_SCHEDULING_LIST_ERROR"

export const GET_FIELD_LIST_SUCCESS = "GET_FIELD_LIST_SUCCESS";
export const GET_FIELD_LIST_ERROR = "GET_FIELD_LIST_ERROR"

export const SUBMIT_SCHEDULING_LIST_SUCCESS = "SUBMIT_SCHEDULING_LIST_SUCCESS";
export const SUBMIT_SCHEDULING_LIST_ERROR = "SUBMIT_SCHEDULING_LIST_ERROR";

export const SET_VENUE_ADDED_MANAGE_FIELD = "SET_VENUE_ADDED_MANAGE_FIELD";

export const SINGLE_VENUE_DIRECT_ADDED = "SINGLE_VENUE_DIRECT_ADDED";

export const GET_VENUE_TOTAL_LIST = "GET_VENUE_TOTAL_LIST";

export const APPLY_TO_ALL_DAYS = "APPLY_TO_ALL_DAYS";

export const CHANGE_FIELD_NAME_SUCCESS = "CHANGE_FIELD_NAME_SUCCESS";
export const CHANGE_FIELD_NAME_ERROR = "CHANGE_FIELD_NAME_ERROR";

export const RESET_AGE_GROUP_ROW_RESPONSE = "RESET_AGE_GROUP_ROW_RESPONSE";
export const RESET_AGE_GROUP_ROW_ERROR = "RESET_AGE_GROUP_ROW_ERROR";

export const EXPORT_GRID_GENERATE_PDF_SUCCESS = "EXPORT_GRID_GENERATE_PDF_SUCCESS";
export const EXPORT_GRID_GENERATE_PDF_ERROR = "EXPORT_GRID_GENERATE_PDF_ERROR";