import { CREATE_SCOUTING_LIBRARY_ERROR, CREATE_SCOUTING_LIBRARY_SUCCESS, DELETE_SCOUTING_LIBRARY_ERROR, DELETE_SCOUTING_LIBRARY_SUCCESS, FETCH_SCOUTING_LIBRARY_ERROR, FETCH_SCOUTING_LIBRARY_SUCCESS, UPDATE_SCOUTING_LIBRARY_ERROR, UPDATE_SCOUTING_LIBRARY_SUCCESS } from "../constants/ScoutingLibrary.const";

  
  const initialState = {
    
    scoutingLibrary: [],
    scoutingLibraryError: null,
    isscoutingLibraryLoaded: false,
  
    isscoutingDeleted: null,
    isscoutingDeletedError: null,
  
    isscoutingCreated: false,
    isscoutingCreatedError: null,
  
    isscoutingUpdated: false,
    isscoutingUpdatedError: null,
  
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // get Scouting Library
  
      case FETCH_SCOUTING_LIBRARY_SUCCESS:
        return {
          ...state,
          isscoutingLibraryLoaded: true,
          scoutingLibrary: action.payload,
        };
      case FETCH_SCOUTING_LIBRARY_ERROR:
        return {
          ...state,
          scoutingLibraryError: action.error.message,
        };
  
      // Delete Scouting Library
  
      case DELETE_SCOUTING_LIBRARY_SUCCESS:
        return {
          ...state,
          isscoutingDeleted:  action.payload,
        };
      case DELETE_SCOUTING_LIBRARY_ERROR:
        return {
          ...state,
          isscoutingDeletedError: action.error.message,
        };
  
      // Create Scouting Library
  
      case CREATE_SCOUTING_LIBRARY_SUCCESS:
        return {
          ...state,
          isscoutingCreated: action.payload,
        };
      case CREATE_SCOUTING_LIBRARY_ERROR:
        return {
          ...state,
          isscoutingCreatedError: action.error,
        };
  
      // Update Scouting Library
  
      case UPDATE_SCOUTING_LIBRARY_SUCCESS:
        return {
          ...state,
          isscoutingUpdated: action.payload,
        };
      case UPDATE_SCOUTING_LIBRARY_ERROR:
        return {
          ...state,
          isscoutingUpdatedError: action.error.message,
        };
  
      default:
        return state;
    }
  }