export const GET_RECAP_EVENT_LIST_REQUEST = 'GET_RECAP_EVENT_LIST_REQUEST';
export const GET_RECAP_EVENT_LIST_SUCCESS = 'GET_RECAP_EVENT_LIST_SUCCESS';
export const GET_RECAP_EVENT_LIST_ERROR   = 'GET_RECAP_EVENT_LIST_ERROR';

export const GET_RECAP_INFO_REQUEST = 'GET_RECAP_INFO_REQUEST';
export const GET_RECAP_INFO_SUCCESS = 'GET_RECAP_INFO_SUCCESS';
export const GET_RECAP_INFO_ERROR   = 'GET_RECAP_INFO_ERROR';

export const FETCH_ADD_RECAP_INFO_REQUEST = 'FETCH_ADD_RECAP_INFO_REQUEST';
export const FETCH_ADD_RECAP_INFO_SUCCESS = 'FETCH_ADD_RECAP_INFO_SUCCESS';
export const FETCH_ADD_RECAP_INFO_ERROR   = 'FETCH_ADD_RECAP_INFO_ERROR';

export const CREATE_ADD_RECAP_SCORE_REQUEST = 'CREATE_ADD_RECAP_SCORE_REQUEST';
export const CREATE_ADD_RECAP_SCORE_SUCCESS = 'CREATE_ADD_RECAP_SCORE_SUCCESS';
export const CREATE_ADD_RECAP_SCORE_ERROR   = 'CREATE_ADD_RECAP_SCORE_ERROR';

export const UPDATE_ADD_RECAP_SCORE_REQUEST = 'UPDATE_ADD_RECAP_SCORE_REQUEST';
export const UPDATE_ADD_RECAP_SCORE_SUCCESS = 'UPDATE_ADD_RECAP_SCORE_SUCCESS';
export const UPDATE_ADD_RECAP_SCORE_ERROR   = 'UPDATE_ADD_RECAP_SCORE_ERROR';

export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const TEAM_LIST_ERROR   = 'TEAM_LIST_ERROR';

export const ROSTER_LIST_REQUEST = 'ROSTER_LIST_REQUEST';
export const ROSTER_LIST_SUCCESS = 'ROSTER_LIST_SUCCESS';
export const ROSTER_LIST_ERROR   = 'ROSTER_LIST_ERROR';

export const EVENTSCORE_BY_ID_REQUEST = 'EVENTSCORE_BY_ID_REQUEST';
export const EVENTSCORE_BY_ID_SUCCESS = 'EVENTSCORE_BY_ID_SUCCESS';
export const EVENTSCORE_BY_ID_ERROR   = 'EVENTSCORE_BY_ID_ERROR';


export const DELETE_SCORE_REQUEST = 'DELETE_SCORE_REQUEST';
export const DELETE_SCORE_SUCCESS = 'DELETE_SCORE_SUCCESS';
export const DELETE_SCORE_ERROR   = 'DELETE_SCORE_ERROR';