import { 
  UPLOAD_DATA_REQUEST, 
  UPLOAD_DATA_SUCCESS, 
  UPLOAD_DATA_ERROR,

  REORDER_REQUEST, 
  REORDER_SUCCESS, 
  REORDER_ERROR,

  ONBOARDING_DATA_REQUEST, 
  ONBOARDING_DATA_SUCCESS, 
  ONBOARDING_DATA_ERROR,

} from '../constants/EditApp.const';

const initialState = {

  splashScreen: [],
  splashScreenError: null,
  splashScreenLoader: false,
  isSplashScreenLoaded: false,

  isUpdated: false,
  isUpdatedLoader: false,
  isUpdatedError: null,

  isReOrdered: false,
  isReOrderedLoader: false,
  isReOrderedError: null

};

export default function(state = initialState, action) {
  switch (action.type) {



    case REORDER_REQUEST:
      return {
        ...state,
        isReOrderedLoader: true
      };
    case REORDER_SUCCESS:
      return {
        ...state,
        isReOrdered: true,
        isReOrderedLoader: false
      };
    case REORDER_ERROR:
      return {
        ...state,
        isReOrderedError: action.error.message,
        isReOrderedLoader: false
      };

  //__________________________________________Update OnBoarding Data List___________________________________________//

    case UPLOAD_DATA_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPLOAD_DATA_ERROR:
      return {
        ...state,
        isUpdatedError: action.error.message,
        isUpdatedLoader: false
      };
//__________________________________________Get OnBoarding Data List___________________________________________//

    case ONBOARDING_DATA_REQUEST:
      return {
        ...state,
        splashScreenLoader: true
      };
    case ONBOARDING_DATA_SUCCESS:
      return {
        ...state,
        isSplashScreenLoaded: true,
        splashScreen: action.payload,
        splashScreenLoader: false
      };
    case ONBOARDING_DATA_ERROR:
      return {
        ...state,
        splashScreenError: action.error.message,
        splashScreenLoader: false
      };

  //__________________________________________Update OnBoarding Data List___________________________________________//

    // case SAVE_ONBOARDING_DATA_REQUEST:
    //   return {
    //     ...state,
    //     onBoardingDataListLoader: true
    //   };
    // case SAVE_ONBOARDING_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     onBoardingDataList: action.payload.data.result.splashscreen1,
    //     onBoardingDataListLoader: false
    //   };
    // case SAVE_ONBOARDING_DATA_ERROR:
    //   return {
    //     ...state,
    //     onBoardingDataListError: action.error.message,
    //     onBoardingDataListLoader: false
    //   };

    default:
      return state;
  }
}