export const GET_CLAIMTEAM_LIST_SUCCESS = "GET_CLAIMTEAM_LIST_SUCCESS";
export const GET_CLAIMTEAM_LIST_ERROR = "GET_CLAIMTEAM_LIST_ERROR";
export const GET_CLAIMTEAM_LIST_REQUEST = "GET_CLAIMTEAM_LIST_REQUEST";

export const GET_TEAM_LIST_SUCCESS = "GET_TEAM_LIST_SUCCESS";
export const GET_TEAM_LIST_ERROR = "GET_TEAM_LIST_ERROR";
export const GET_TEAM_LIST_REQUEST = "GET_TEAM_LIST_REQUEST";

export const SAVE_TEAM_SUCCESS = "SAVE_TEAM_SUCCESS";
export const SAVE_TEAM_ERROR = "SAVE_TEAM_ERROR";
export const SAVE_TEAM_REQUEST = "SAVE_TEAM_REQUEST";

export const CREATE_NEW_TEAM_SUCCESS = "CREATE_NEW_TEAM_SUCCESS";
export const CREATE_NEW_TEAM_ERROR = "CREATE_NEW_TEAM_ERROR";
export const CREATE_NEW_TEAM_REQUEST = "CREATE_NEW_TEAM_REQUEST";

export const REJECT_TEAM_SUCCESS = "REJECT_TEAM_SUCCESS";
export const REJECT_TEAM_ERROR = "REJECT_TEAM_ERROR";
export const REJECT_TEAM_REQUEST = "REJECT_TEAM_REQUEST";