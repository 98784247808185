import {
    GET_EVENT_LIST_ERROR,
    GET_EVENT_LIST_SUCCESS,
    GET_PARANT_TEAM_ERROR,
    GET_PARANT_TEAM_SUCCESS,
    GET_COACH_LIST_ERROR,
    GET_COACH_LIST_SUCCESS,
    POST_CREATE_EVENT_ERROR,
    POST_CREATE_EVENT_SUCCESS,
    SAVE_SOURCE_ERROR,
    SAVE_SOURCE_SUCCESS,
    GET_DEVICE_ERROR,
    GET_DEVICE_SUCCESS,
    GET_LINK_ERROR,
    GET_LINK_SUCCESS,
    GET_OPTION_ERROR,
    GET_OPTION_SUCCESS,
    GET_RMAP_ERROR,
    GET_RMAP_SUCCESS,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_SUCCESS,
    GET_SOURCE_BY_EVENT_ERROR,
    GET_SOURCE_BY_EVENT_SUCCESS,
    POST_UPLOAD_VIDEO_SUCCESS,
    POST_UPLOAD_VIDEO_ERROR,
    ROW_DEVICE_DATA_SUCCESS,
    ROW_DEVICE_DATA_REQUEST,
    ROW_DEVICE_DATA_ERROR,
    GET_VIDEO_MAPPING_LIST_REQUEST,
    GET_VIDEO_MAPPING_LIST_SUCCESS,
    GET_VIDEO_MAPPING_LIST_ERROR,
    MAP_VIDEO_FOR_PER_PITCH_REQUEST,
    MAP_VIDEO_FOR_PER_PITCH_SUCCESS,
    MAP_VIDEO_FOR_PER_PITCH_ERROR,
    SAVE_RAPSODO_SOURCE_ERROR,
    SAVE_RAPSODO_SOURCE_SUCCESS,
    SAVE_RAPSODO_SOURCE_REQUEST,
    UPDATE_DEVICE_DATA_SUCCESS,
    UPDATE_DEVICE_DATA_REQUEST,
    UPDATE_DEVICE_DATA_ERROR,
    MAP_RAPSODO_REQUEST,
    MAP_RAPSODO_SUCCESS,
    MAP_RAPSODO_ERROR,
    TEAM_SOURCE_DETAILS_REQUEST,
    TEAM_SOURCE_DETAILS_SUCCESS,
    TEAM_SOURCE_DETAILS_ERROR,
    TEAM_SOURCE_MAP_REQUEST,
    TEAM_SOURCE_MAP_SUCCESS,
    TEAM_SOURCE_MAP_ERROR,
    DEL_SELL_VIDEO_REQUEST,
    DEL_SELL_VIDEO_SUCCESS,
    DEL_SELL_VIDEO_ERROR,
    GET_TEAM_LIST_FOR_LINK_REQUEST,
    GET_TEAM_LIST_FOR_LINK_SUCCESS,
    GET_TEAM_LIST_FOR_LINK_ERROR,
    LINK_TEAM_SCOUTING_REQUEST,
    LINK_TEAM_SCOUTING_SUCCESS,
    LINK_TEAM_SCOUTING_ERROR
} from "../../../constants/BaseballCloud/UploadData/UploadData.const";

const initialState = {
    eventListData: null,
    eventListError: null,

    teamListData: null,
    teamListError: null,

    coachListData: null,
    coachListError: null,

    createEventData: null,
    createEventError: null,

    saveDatasourceData: null,
    saveDatasourceError: null,

    deviceData: null,
    deviceError: null,

    linkData: null,
    linkError: null,

    optionData: null,
    optionError: null,

    mapData: null,
    mapError: null,

    deleteEventData: null,
    deleteEventError: null,

    sourceListData: [],
    sourceListError: null,

    saveUploadVideoData: null,
    saveUploadVideoError: null,

    saveRapsodoReq: false,
    saveRapsodoData: null,
    saveRapsodoError: null,

    getRowDeviceReq: false,
    getRowDeviceData: null,
    getRowDeviceError: null,

    videoMappingListReq: false,
    videoMappingListData: null,
    videoMappingListError: null,

    mapVideoPerPitchReq: false,
    mapVideoPerPitchData: null,
    mapVideoPerPitchError: null,

    updateDeviceData: null,
    updateDeviceError: null,

    mappingRapsodoReq: false,
    mappingRapsodoData: null,
    mappingRapsodoError: null,

    sourceTeamInfoReq: false,
    sourceTeamInfoData: null,
    sourceTeamInfoError: null,

    sourceTeamMapReq: false,
    sourceTeamMapData: null,
    sourceTeamMapError: null,

    delSellVideoReq: false,
    delSellVideoData: null,
    delSellVideoError: null,

    linkTeamListData :null ,
    linkTeamListError :null ,

    scoutingLinkTeamData: null,
    scoutingLinkTeamError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // delete sell video req    
        case DEL_SELL_VIDEO_REQUEST:
            return {
                ...state,
                delSellVideoReq: true,
            };
        case DEL_SELL_VIDEO_SUCCESS:
            return {
                ...state,
                delSellVideoData: action.payload,
            };
        case DEL_SELL_VIDEO_ERROR:
            return {
                ...state,
                delSellVideoError: action.error,
            };

        // For GET Event List

        case GET_EVENT_LIST_SUCCESS:
            return {
                ...state,
                eventListData: action.payload
            };
        case GET_EVENT_LIST_ERROR:
            return {
                ...state,
                eventListError: action.error,
            };

        //for Team list
        case GET_PARANT_TEAM_SUCCESS:
            return {
                ...state,
                teamListData: action.payload
            };
        case GET_PARANT_TEAM_ERROR:
            return {
                ...state,
                teamListError: action.error,
            };
        //for Coach list
        case GET_COACH_LIST_SUCCESS:
            return {
                ...state,
                coachListData: action.payload
            };
        case GET_COACH_LIST_ERROR:
            return {
                ...state,
                coachListError: action.error,
            };

        //CREATE  EVENT
        case POST_CREATE_EVENT_SUCCESS:
            return {
                ...state,
                createEventData: action.payload
            };
        case POST_CREATE_EVENT_ERROR:
            return {
                ...state,
                createEventError: action.error,
            };

        //SAVE datasource
        case SAVE_SOURCE_SUCCESS:
            return {
                ...state,
                saveDatasourceData: action.payload
            };
        case SAVE_SOURCE_ERROR:
            return {
                ...state,
                saveDatasourceError: action.error,
            };

        //SAVE datasource for Rapsodo
        case SAVE_RAPSODO_SOURCE_SUCCESS:
            return {
                ...state,
                saveRapsodoData: action.payload
            };
        case SAVE_RAPSODO_SOURCE_ERROR:
            return {
                ...state,
                saveRapsodoError: action.error,
            };

        //get Device
        case GET_DEVICE_SUCCESS:
            return {
                ...state,
                deviceData: action.payload
            };
        case GET_DEVICE_ERROR:
            return {
                ...state,
                deviceError: action.error,
            };
        //get link List
        case GET_LINK_SUCCESS:
            return {
                ...state,
                linkData: action.payload
            };
        case GET_LINK_ERROR:
            return {
                ...state,
                linkError: action.error,
            };
        //get Device
        case GET_OPTION_SUCCESS:
            return {
                ...state,
                optionData: action.payload
            };
        case GET_OPTION_ERROR:
            return {
                ...state,
                optionError: action.error,
            };
        //get Device
        case GET_RMAP_SUCCESS:
            return {
                ...state,
                mapData: action.payload
            };
        case GET_RMAP_ERROR:
            return {
                ...state,
                mapError: action.error,
            };

        //delete Event
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                deleteEventData: action.payload
            };
        case DELETE_EVENT_ERROR:
            return {
                ...state,
                deleteEventError: action.error,
            };

        // get source by event id
        case GET_SOURCE_BY_EVENT_SUCCESS:
            return {
                ...state,
                sourceListData: action.payload.result.data.length !== 0 ? action.payload.result.data : []
            };
        case GET_SOURCE_BY_EVENT_ERROR:
            return {
                ...state,
                sourceListError: action.error,
            };

        // post upload video for entire event
        case POST_UPLOAD_VIDEO_SUCCESS:
            return {
                ...state,
                saveUploadVideoData: action.payload
            };
        case POST_UPLOAD_VIDEO_ERROR:
            return {
                ...state,
                saveUploadVideoError: action.error,
            };

        // get row device   
        case ROW_DEVICE_DATA_REQUEST:
            return {
                ...state,
                getRowDeviceReq: true,
            };
        case ROW_DEVICE_DATA_SUCCESS:
            return {
                ...state,
                getRowDeviceData: action.payload,
            };
        case ROW_DEVICE_DATA_ERROR:
            return {
                ...state,
                getRowDeviceError: action.error,
            };

        // get video mapping List for per pitch video
        case GET_VIDEO_MAPPING_LIST_REQUEST:
            return {
                ...state,
                videoMappingListReq: true,
            };
        case GET_VIDEO_MAPPING_LIST_SUCCESS:
            return {
                ...state,
                videoMappingListData: action.payload,
            };
        case GET_VIDEO_MAPPING_LIST_ERROR:
            return {
                ...state,
                videoMappingListError: action.error,
            };

        // map video per pitch 
        case MAP_VIDEO_FOR_PER_PITCH_REQUEST:
            return {
                ...state,
                mapVideoPerPitchReq: true,
            };
        case MAP_VIDEO_FOR_PER_PITCH_SUCCESS:
            return {
                ...state,
                mapVideoPerPitchData: action.payload,
            };
        case MAP_VIDEO_FOR_PER_PITCH_ERROR:
            return {
                ...state,
                mapVideoPerPitchError: action.error,
            };

        // update Device Data
        case UPDATE_DEVICE_DATA_SUCCESS:
            return {
                ...state,
                updateDeviceData: action.payload,
            };
        case UPDATE_DEVICE_DATA_ERROR:
            return {
                ...state,
                updateDeviceError: action.error,
            };

        // mapping rapsodo option req    
        case MAP_RAPSODO_REQUEST:
            return {
                ...state,
                mappingRapsodoReq: true,
            };
        case MAP_RAPSODO_SUCCESS:
            return {
                ...state,
                mappingRapsodoData: action.payload,
            };
        case MAP_RAPSODO_ERROR:
            return {
                ...state,
                mappingRapsodoError: action.error,
            };

        // source team info req    
        case TEAM_SOURCE_DETAILS_REQUEST:
            return {
                ...state,
                sourceTeamInfoReq: true,
            };
        case TEAM_SOURCE_DETAILS_SUCCESS:
            return {
                ...state,
                sourceTeamInfoData: action.payload,
            };
        case TEAM_SOURCE_DETAILS_ERROR:
            return {
                ...state,
                sourceTeamInfoError: action.error,
            };

        // source team mapping req    
        case TEAM_SOURCE_MAP_REQUEST:
            return {
                ...state,
                sourceTeamMapReq: true,
            };
        case TEAM_SOURCE_MAP_SUCCESS:
            return {
                ...state,
                sourceTeamMapData: action.payload,
            };
        case TEAM_SOURCE_MAP_ERROR:
            return {
                ...state,
                sourceTeamMapError: action.error,
            };

             //get team list for link
        case GET_TEAM_LIST_FOR_LINK_SUCCESS:
            return {
                ...state,
                linkTeamListData: action.payload
            };
        case GET_TEAM_LIST_FOR_LINK_ERROR:
            return {
                ...state,
                linkTeamListError: action.error,
            };

             //link team for scouting event
        case LINK_TEAM_SCOUTING_SUCCESS:
            return {
                ...state,
                scoutingLinkTeamData: action.payload
            };
        case LINK_TEAM_SCOUTING_ERROR:
            return {
                ...state,
                scoutingLinkTeamData: action.error,
            };

        default:
            return state;
    }
}