import {

    GET_CRITERIA_TEAM_SUCCESS,
    GET_CRITERIA_TEAM_ERROR,

    SAVE_CRITERIA_TEAM_SUCCESS,
    SAVE_CRITERIA_TEAM_ERROR,

    SAVE_DIVISION_CRITERIA_TEAM_SUCCESS,
    SAVE_DIVISION_CRITERIA_TEAM_ERROR,

    GET_CRITERIA_WISE_DATA_SUCCESS,
    GET_CRITERIA_WISE_DATA_ERROR


} from '../../constants/Scheduling/Criteria.const';

const initialState = {
    criteriaTeamData: null,
    criteriaTeamDataError: null,

    saveTeamData: null,
    saveTeamDataError: null,

    saveDivisionTeamData: null,
    saveDivisionTeamDataError: null,

    getCriteriaWiseData: null,
    getCriteriaWiseDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        //GET TEAM
        case GET_CRITERIA_TEAM_SUCCESS:
            return {
                ...state,
                criteriaTeamData: action.payload
            };
        case GET_CRITERIA_TEAM_ERROR:
            return {
                ...state,
                criteriaTeamDataError: action.error
            };

        case SAVE_CRITERIA_TEAM_SUCCESS:
            return {
                ...state,
                saveTeamData: action.payload
            };
        case SAVE_CRITERIA_TEAM_ERROR:
            return {
                ...state,
                saveTeamDataError: action.error
            };

        //divisionwise 

        case SAVE_DIVISION_CRITERIA_TEAM_SUCCESS:
            return {
                ...state,
                saveDivisionTeamData: action.payload
            };
        case SAVE_DIVISION_CRITERIA_TEAM_ERROR:
            return {
                ...state,
                saveDivisionTeamDataError: action.error
            };


        case GET_CRITERIA_WISE_DATA_SUCCESS:
            return {
                ...state,
                getCriteriaWiseData: action.payload
            };
        case GET_CRITERIA_WISE_DATA_ERROR:
            return {
                ...state,
                getCriteriaWiseDataError: action.error
            };

        default:
            return state;
    }
}