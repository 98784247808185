import {
  FETCH_TOP_BATSMAN_REQUEST,
  FETCH_TOP_BATSMAN_SUCCESS,
  FETCH_TOP_BATSMAN_ERROR,
  FETCH_ALL_BATSMAN_REQUEST,
  FETCH_ALL_BATSMAN_SUCCESS,
  FETCH_ALL_BATSMAN_ERROR,
  FETCH_TOP_PITCHER_REQUEST,
  FETCH_TOP_PITCHER_SUCCESS,
  FETCH_TOP_PITCHER_ERROR,
  FETCH_ALL_PITCHER_REQUEST,
  FETCH_ALL_PITCHER_SUCCESS,
  FETCH_ALL_PITCHER_ERROR,
  FETCH_PLAYER_BY_ID_REQUEST,
  FETCH_PLAYER_BY_ID_SUCCESS,
  FETCH_PLAYER_BY_ID_ERROR,
  FETCH_PLAYER_BY_FILTER_REQUEST,
  FETCH_PLAYER_BY_FILTER_SUCCESS,
  FETCH_PLAYER_BY_FILTER_ERROR,
  EXPORT_CSV_FOR_USER_REQUEST,
  EXPORT_CSV_FOR_USER_SUCCESS,
  EXPORT_CSV_FOR_USER_ERROR,
  ADD_ROSTER_REQUEST,
  ADD_ROSTER_SUCCESS,
  ADD_ROSTER_ERROR,
  SEND_NOTIFICATION_USER_REQUEST,
  SEND_NOTIFICATION_USER_SUCCESS,
  SEND_NOTIFICATION_USER_ERROR,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_ERROR,
} from "../../constants/Users/Players.const";

const initialState = {
  topBatsmans: [],
  topBatsmansError: null,
  topBatsmansLoader: false,

  allBatsmans: [],
  allBatsmansError: null,
  allBatsmansLoader: false,

  topPitchers: [],
  topPitchersError: null,
  topPitchersLoader: false,

  allPitchers: [],
  allPitchersError: null,
  allPitchersLoader: false,

  player: [],
  playerError: null,
  playerLoader: false,
  isPlayerLoaded: false,

  playersByFilter: [],
  playersByFilterError: null,
  playersByFilterLoader: false,
  isPlayersLoaded: false,

  exportCsv: null,
  exportCsvError: null,
  exportCsvLoader: false,

  addRoster: null,
  addRosterError: null,
  addRosterLoader: false,

  sendNotification: null,
  sendNotificationError: null,
  sendNotificationLoader: false,

  deletePlayerData: null,
  deletePlayerError: null,

  filters: {
    name: "",
    role: "Player",
    sortOn: "0",
    // dateType: "0",
    page: 0,
    dateFilter: {
      createdAt: 99,
      // lastLoginTime: 0,
      startDate: "",
      endDate: "",
    },
    height: { case: "", value: "" },
    weight: { case: "", value: "" },
    age: { case: "", value: "" },
    state: [],
    position: [],
    useHanded: [],
    "batting-pos": [],
    "event_id":""
  },
  arrFilter: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Fetch - TOP BATSMANS

    case FETCH_TOP_BATSMAN_REQUEST:
      return {
        ...state,
        topBatsmansLoader: true,
      };
    case FETCH_TOP_BATSMAN_SUCCESS:
      return {
        ...state,
        topBatsmans: action.payload,
        topBatsmansLoader: false,
      };
    case FETCH_TOP_BATSMAN_ERROR:
      return {
        ...state,
        topBatsmansError: action.error.message,
        topBatsmansLoader: false,
      };

    // Fetch - ALL BATSMANS

    case FETCH_ALL_BATSMAN_REQUEST:
      return {
        ...state,
        allBatsmansLoader: true,
      };
    case FETCH_ALL_BATSMAN_SUCCESS:
      return {
        ...state,
        allBatsmans: action.payload,
        allBatsmansLoader: false,
      };
    case FETCH_ALL_BATSMAN_ERROR:
      return {
        ...state,
        allBatsmansError: action.error.message,
        allBatsmansLoader: false,
      };

    // Fetch - TOP PITCHERS

    case FETCH_TOP_PITCHER_REQUEST:
      return {
        ...state,
        topPitchersLoader: true,
      };
    case FETCH_TOP_PITCHER_SUCCESS:
      return {
        ...state,
        topPitchers: action.payload,
        topPitchersLoader: false,
      };
    case FETCH_TOP_PITCHER_ERROR:
      return {
        ...state,
        topPitchersError: action.error.message,
        topPitchersLoader: false,
      };

    // Fetch - ALL PITCHERS

    case FETCH_ALL_PITCHER_REQUEST:
      return {
        ...state,
        allPitchersLoader: true,
      };
    case FETCH_ALL_PITCHER_SUCCESS:
      return {
        ...state,
        allPitchers: action.payload,
        allPitchersLoader: false,
      };
    case FETCH_ALL_PITCHER_ERROR:
      return {
        ...state,
        allPitchersError: action.error.message,
        allPitchersLoader: false,
      };

    // Fetch - PLAYER BY ID

    case FETCH_PLAYER_BY_ID_REQUEST:
      return {
        ...state,
        playerLoader: true,
      };
    case FETCH_PLAYER_BY_ID_SUCCESS:
      return {
        ...state,
        isPlayerLoaded: true,
        player: action.payload,
        playerLoader: false,
      };
    case FETCH_PLAYER_BY_ID_ERROR:
      return {
        ...state,
        playerError: action.error.message,
        playerLoader: false,
      };

    // Fetch - PLAYER BY FILTER

    case FETCH_PLAYER_BY_FILTER_REQUEST:
      return {
        ...state,
        playersByFilterLoader: true,
      };
    case FETCH_PLAYER_BY_FILTER_SUCCESS:
      return {
        ...state,
        isPlayersLoaded: true,
        playersByFilter: action.payload,
        playersByFilterLoader: false,
      };
    case FETCH_PLAYER_BY_FILTER_ERROR:
      return {
        ...state,
        playersByFilterError: action.error,
        playersByFilterLoader: false,
      };

    // EXPORT CSV FOR USER
    case EXPORT_CSV_FOR_USER_REQUEST:
      return {
        ...state,
        exportCsvLoader: true,
      };
    case EXPORT_CSV_FOR_USER_SUCCESS:
      return {
        ...state,
        exportCsv: action.payload,
        exportCsvLoader: true,
      };
    case EXPORT_CSV_FOR_USER_ERROR:
      return {
        ...state,
        exportCsvError: action.error,
      };

    // add new Roster
    case ADD_ROSTER_REQUEST:
      return {
        ...state,
        addRosterLoader: true,
      };
    case ADD_ROSTER_SUCCESS:
      return {
        ...state,
        addRoster: action.payload,
        addRosterLoader: true,
      };
    case ADD_ROSTER_ERROR:
      return {
        ...state,
        addRosterError: action.error,
      };

    // send notification(message) for user
    case SEND_NOTIFICATION_USER_REQUEST:
      return {
        ...state,
        sendNotificationLoader: true,
      };
    case SEND_NOTIFICATION_USER_SUCCESS:
      return {
        ...state,
        sendNotification: action.payload,
      };
    case SEND_NOTIFICATION_USER_ERROR:
      return {
        ...state,
        sendNotificationError: action.error,
      };

    //delete User
    case DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        deletePlayerData: action.payload,
      };

    case DELETE_PLAYER_ERROR:
      return {
        ...state,
        deletePlayerError: action.error,
      };
    default:
      return state;
  }
}
