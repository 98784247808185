  
import { GET_PRODUCT_MASTER_LIST_SUCCESS,
   GET_PRODUCT_MASTER_LIST_ERROR, 
   CREATE_PRODUCT_MASTER_SUCCESS, 
   CREATE_PRODUCT_MASTER_ERROR, 
   FILEUPLOAD_SUCCESS, 
   FILEUPLOAD_ERROR, 
   DELETE_PRODUCT_MASTER_SUCCESS, 
   DELETE_PRODUCT_MASTER_ERROR, 
   FETCH_PRODUCT_MASTER_SUCCESS, 
   FETCH_PRODUCT_MASTER_ERROR, 
   UPDATE_PRODUCT_MASTER_SUCCESS, 
   UPDATE_PRODUCT_MASTER_ERROR} from '../constants/ProductMaster.const';
  
  const initialState = {
      productMasterData : null,
      producrMasterError : null,

      createProductMasterData :  null,
      createProductMasterError :  null,

      deleteProductMasterData :  null,
      deleteProductMasterError :  null,

      fetchProductMasterData :  null,
      fetchProductMasterError :  null,

      updateProductMasterData :  null,
      updateProductMasterError :  null,

      fileUploadData : null,
      fileUploadError : null,


  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Product Master Data
  
      case GET_PRODUCT_MASTER_LIST_SUCCESS:
        return {
          ...state,
          productMasterData: action.payload
        };
      case GET_PRODUCT_MASTER_LIST_ERROR:
        return {
          ...state,
          producrMasterError: action.error,
        };
      case FETCH_PRODUCT_MASTER_SUCCESS:
        return {
          ...state,
          fetchProductMasterData: action.payload
        };
      case FETCH_PRODUCT_MASTER_ERROR:
        return {
          ...state,
          fetchProductMasterError: action.error,
        };
      case CREATE_PRODUCT_MASTER_SUCCESS:
        return {
          ...state,
          createProductMasterData: action.payload
        };
      case CREATE_PRODUCT_MASTER_ERROR:
        return {
          ...state,
          createProductMasterError: action.error,
        };
      case DELETE_PRODUCT_MASTER_SUCCESS:
        return {
          ...state,
          deleteProductMasterData: action.payload
        };
      case DELETE_PRODUCT_MASTER_ERROR:
        return {
          ...state,
          deleteProductMasterError: action.error,
        };
      case UPDATE_PRODUCT_MASTER_SUCCESS:
        return {
          ...state,
          updateProductMasterData: action.payload
        };
      case UPDATE_PRODUCT_MASTER_ERROR:
        return {
          ...state,
          updateProductMasterError: action.error,
        };

      case FILEUPLOAD_SUCCESS:
        return {
          ...state,
          fileUploadData: action.payload
        };
      case FILEUPLOAD_ERROR:
        return {
          ...state,
          fileUploadError: action.error,
        };  
      default:
        return state;
    }
  }