import {
    GET_BANNER_LIST_ERROR,
    GET_BANNER_LIST_SUCCESS,
    FETCH_BANNER_SUCCESS,
    FETCH_BANNER_ERROR,
    EDIT_BANNER_ERROR,
    EDIT_BANNER_SUCCESS
} from "../../constants/BaseballCloud/Marketing.const";
 
 const initialState = {
     bannerListData: null,
     bannerListError: null,
     
     fetchBannerData : null,
     fetchBannerError : null,

     updateBannerData : null,
     updateBannerError: null
 };
 
 export default function (state = initialState, action) {
     switch (action.type) {
 
         // For GET Claim List
 
         case GET_BANNER_LIST_SUCCESS:
             return {
                 ...state,
                 bannerListData: action.payload
             };
         case GET_BANNER_LIST_ERROR:
             return {
                 ...state,
                 bannerListError: action.error,
             };

             //fetch banner
         case FETCH_BANNER_SUCCESS:
             return {
                 ...state,
                 fetchBannerData: action.payload,
             };
         case FETCH_BANNER_ERROR:
             return {
                 ...state,
                 fetchBannerError: action.error,
             };   

                   //update banner
         case EDIT_BANNER_SUCCESS:
            return {
                ...state,
                updateBannerData: action.payload,
            };
        case EDIT_BANNER_ERROR:
            return {
                ...state,
                updateBannerError: action.error,
            };   
         default:
             return state;
     }
 }