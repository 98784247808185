import {

    GET_GRID_LIST_SUCCESS,
    GET_GRID_LIST_ERROR,

    CREATE_GRID_LIST_SUCCESS,
    CREATE_GRID_LIST_ERROR,


    GET_SINGLE_GRID_LIST_SUCCESS,
    GET_SINGLE_GRID_LIST_ERROR,

    CREATE_SINGLE_GRID_SUCCESS,
    CREATE_SINGLE_GRID_ERROR,

    GET_GRID_FILTER_SUCCESS,
    GET_GRID_FILTER_ERROR,

    CHANGE_TEAM_NAME_SUCCESS,
    CHANGE_TEAM_NAME_ERROR,

    RBS_GRID_FILTER_SUCCESS,
    RBS_GRID_FILTER_ERROR,

    RBS_SINGLE_GRID_LIST_SUCCESS,
    RBS_SINGLE_GRID_LIST_ERROR,

    RBS_CREATE_SINGLE_GRID_GAME_SUCCESS,
    RBS_CREATE_SINGLE_GRID_GAME_ERROR,

    RBS_REMOVE_SINGLE_GRID_GAME_SUCCESS,
    RBS_REMOVE_SINGLE_GRID_GAME_ERROR,

    RBS_SWAP_TEAM_SINGLE_GRID_GAME_SUCCESS,
    RBS_SWAP_TEAM_SINGLE_GRID_GAME_ERROR,

    RBS_SPECIAL_REQUEST_SUCCESS,
    RBS_SPECIAL_REQUEST_ERROR,

    RBS_AUTO_GENERATE_SUCCESS,
    RBS_AUTO_GENERATE_ERROR

} from '../../constants/Scheduling/Grid.const'

const initialState = {
    getGridData: null,
    getGridDataError: null,

    createGridData: null,
    createGridDataError: null,

    getSingleGridData: null,
    getSingleGridDataError: null,

    createSingleGridData: null,
    createSingleGridDataError: null,

    getGridFilterData: null,
    getGridFilterDataError: null,

    changeTeamNameData: null,
    changeTeamNameDataError: null,

    // RBS Grid Version 2

    rbsGridFilterResponse: null,
    rbsGridFilterError: null,

    rbsGridGameListResponse: null,
    rbsGridGameListError: null,

    rbsSingleGridRemoveGameResponse : null,
    rbsSingleGridRemoveGameError : null,

    rbsSpecialRequestResponse: null,
    rbsSpecialRequestError : null,

    rbsAutoGenerateResponse: null,
    rbsAutoGenerateError : null
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_GRID_LIST_SUCCESS:
            return {
                ...state,
                getGridData: action.payload
            }
        case GET_GRID_LIST_ERROR:
            return {
                ...state,
                getGridDataError: action.error
            }

        case CREATE_GRID_LIST_SUCCESS:
            return {
                ...state,
                createGridData: action.payload
            }
        case CREATE_GRID_LIST_ERROR:
            return {
                ...state,
                createGridDataError: action.error
            }


        case GET_SINGLE_GRID_LIST_SUCCESS:
            return {
                ...state,
                getSingleGridData: action.payload
            }
        case GET_SINGLE_GRID_LIST_ERROR:
            return {
                ...state,
                getSingleGridDataError: action.error
            }

        case CREATE_SINGLE_GRID_SUCCESS:
            return {
                ...state,
                createSingleGridData: action.payload
            }
        case CREATE_SINGLE_GRID_ERROR:
            return {
                ...state,
                createSingleGridDataError: action.error
            }

        case GET_GRID_FILTER_SUCCESS:
            return {
                ...state,
                getGridFilterData: action.payload
            }
        case GET_GRID_FILTER_ERROR:
            return {
                ...state,
                getGridFilterDataError: action.error
            }

        //exchange team name
        case CHANGE_TEAM_NAME_SUCCESS:
            return {
                ...state,
                changeTeamNameData: action.payload
            }
        case CHANGE_TEAM_NAME_ERROR:
            return {
                ...state,
                changeTeamNameDataError: action.error
            }

        // =========== Grid Version 2 Apis =========== RBS => ReactBigScheduler

        // Grid Filter V2 Call
        case RBS_GRID_FILTER_SUCCESS:
            return {
                ...state,
                rbsGridFilterResponse: action.payload
            }

        case RBS_GRID_FILTER_ERROR:
            return {
                ...state,
                rbsGridFilterError: action.error
            }

        // Grid GameList V2 Call
        case RBS_SINGLE_GRID_LIST_SUCCESS:
            return {
                ...state,
                rbsGridGameListResponse: action.payload
            }

        case RBS_SINGLE_GRID_LIST_ERROR:
            return {
                ...state,
                rbsGridGameListError: action.error
            }

        // Create Grid Game V2 Call
        case RBS_CREATE_SINGLE_GRID_GAME_SUCCESS:
            return {
                ...state,
                rbsSingleGridCreateGameResponse: action.payload
            }

        case RBS_CREATE_SINGLE_GRID_GAME_ERROR:
            return {
                ...state,
                rbsSingleGridCreateGameError: action.error
            }

        // Remove Grid Game V2 Call
        case RBS_REMOVE_SINGLE_GRID_GAME_SUCCESS:
            return {
                ...state,
                rbsSingleGridRemoveGameResponse: action.payload
            }

        case RBS_REMOVE_SINGLE_GRID_GAME_ERROR:
            return {
                ...state,
                rbsSingleGridRemoveGameError: action.error
            }

        // Swap Team Single Grid Game V2 Call
        case RBS_SWAP_TEAM_SINGLE_GRID_GAME_SUCCESS:
            return {
                ...state,
                rbsSwapTeamSingleGridGameResponse: action.payload
            }

        case RBS_SWAP_TEAM_SINGLE_GRID_GAME_ERROR:
            return {
                ...state,
                rbsSwapTeamSingleGridGameError: action.error
            }

        // Special Request V2 Call
        case RBS_SPECIAL_REQUEST_SUCCESS:
            return {
                ...state,
                rbsSpecialRequestResponse: action.payload
            }

        case RBS_SPECIAL_REQUEST_ERROR:
            return {
                ...state,
                rbsSpecialRequestError: action.error
            }

        // Auto Generate V2 Call
        case RBS_AUTO_GENERATE_SUCCESS:
            return {
                ...state,
                rbsAutoGenerateResponse: action.payload
            }

        case RBS_AUTO_GENERATE_ERROR:
            return {
                ...state,
                rbsAutoGenerateError: action.error
            }

        default:
            return state;
    }
}