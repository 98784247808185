import { 

    FETCH_PUSH_REQUEST,
    FETCH_PUSH_SUCCESS,
    FETCH_PUSH_ERROR,
  
    UPDATE_PUSH_REQUEST,
    UPDATE_PUSH_SUCCESS,
    UPDATE_PUSH_ERROR,  
  
    CREATE_PUSH_REQUEST,
    CREATE_PUSH_SUCCESS,
    CREATE_PUSH_ERROR,  
  
    DELETE_PUSH_REQUEST,
    DELETE_PUSH_SUCCESS,
    DELETE_PUSH_ERROR  
  
  } from '../../constants/Marketing/Push.const';
    
    const initialState = {
  
      pushes: [],
      pushesError: null,
      pushesLoader: false,
      isPushesLoaded: false,
  
      push: [],
      pushError: null,
      pushLoader: false,
  
      isPushCreated: false,
      isPushCreatedError: null,
      isPushCreatedLoader: false,
  
      isPushUpdated: false,
      isPushUpdatedError: null,
      isPushUpdatedLoader: false,
  
      isPushDeleted: false,
      isPushDeletedError: null,
      isPushDeletedLoader: false,
  
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
    
        // For GET Pushes Data
    
        case FETCH_PUSH_REQUEST:
          return {
            ...state,
            pushesLoader: true
          };
        case FETCH_PUSH_SUCCESS:
          return {
            ...state,
            isPushesLoaded: true,
            pushes: action.payload,
            pushesLoader: false
          };
        case FETCH_PUSH_ERROR:
          return {
            ...state,
            pushesError: action.error.message,
            pushesLoader: false
          };
        
        // For CREATE Push Data
    
        case CREATE_PUSH_REQUEST:
          return {
            ...state,
            isPushCreatedLoader: true
          };
        case CREATE_PUSH_SUCCESS:
          return {
            ...state,
            isPushCreated: true,
            isPushCreatedLoader: false
          };
        case CREATE_PUSH_ERROR:
          return {
            ...state,
            isPushCreatedError: action.error.message,
            isPushCreatedLoader: false
          };
  
        // For UPDATE Push Data
  
        case UPDATE_PUSH_REQUEST:
          return {
            ...state,
            isPushUpdatedLoader: true
          };
        case UPDATE_PUSH_SUCCESS:
          return {
            ...state,
            isPushUpdated: true,
            isPushUpdatedLoader: false
          };
        case UPDATE_PUSH_ERROR:
          return {
            ...state,
            isPushUpdatedError: action.error.message,
            isPushUpdatedLoader: false
          };
  
  
        // For DELETE Push Data
  
        case DELETE_PUSH_REQUEST:
          return {
            ...state,
            isPushDeletedLoader: true
          };
        case DELETE_PUSH_SUCCESS:
          return {
            ...state,
            isPushDeleted: true,
            isPushDeletedLoader: false
          };
        case DELETE_PUSH_ERROR:
          return {
            ...state,
            isPushDeletedError: action.error.message,
            isPushDeletedLoader: false
          };
    
  
        default:
          return state;
      }
    }