export const BC_FETCH_ALL_COACHES_REQUEST = 'BC_FETCH_ALL_COACHES_REQUEST';
export const BC_FETCH_ALL_COACHES_SUCCESS = 'BC_FETCH_ALL_COACHES_SUCCESS';
export const BC_FETCH_ALL_COACHES_ERROR   = 'BC_FETCH_ALL_COACHES_ERROR';

export const BC_FETCH_COACH_REQUEST = 'BC_FETCH_COACH_REQUEST';
export const BC_FETCH_COACH_SUCCESS = 'BC_FETCH_COACH_SUCCESS';
export const BC_FETCH_COACH_ERROR   = 'BC_FETCH_COACH_ERROR';

export const BC_FETCH_COACH_BY_FILTER_REQUEST = 'BC_FETCH_COACH_BY_FILTER_REQUEST';
export const BC_FETCH_COACH_BY_FILTER_SUCCESS = 'BC_FETCH_COACH_BY_FILTER_SUCCESS';
export const BC_FETCH_COACH_BY_FILTER_ERROR = 'BC_FETCH_COACH_BY_FILTER_ERROR';

export const BALLR_LICENCE_REQUEST = 'BALLR_LICENCE_REQUEST';
export const BALLR_LICENCE_SUCCESS = 'BALLR_LICENCE_SUCCESS';
export const BALLR_LICENCE_ERROR = 'BALLR_LICENCE_ERROR';

export const ASSIGN_BALLR_LICENCE_REQUEST = 'ASSIGN_BALLR_LICENCE_REQUEST';
export const ASSIGN_BALLR_LICENCE_SUCCESS = 'ASSIGN_BALLR_LICENCE_SUCCESS';
export const ASSIGN_BALLR_LICENCE_ERROR = 'ASSIGN_BALLR_LICENCE_ERROR';

export const PITCHR_LICENCE_REQUEST = 'PITCHR_LICENCE_REQUEST';
export const PITCHR_LICENCE_SUCCESS = 'PITCHR_LICENCE_SUCCESS';
export const PITCHR_LICENCE_ERROR = 'PITCHR_LICENCE_ERROR';

export const ASSIGN_PITCHR_LICENCE_REQUEST = 'ASSIGN_PITCHR_LICENCE_REQUEST';
export const ASSIGN_PITCHR_LICENCE_SUCCESS = 'ASSIGN_PITCHR_LICENCE_SUCCESS';
export const ASSIGN_PITCHR_LICENCE_ERROR = 'ASSIGN_PITCHR_LICENCE_ERROR';

export const ISMLB_ACCESS_REQUEST = 'ISMLB_ACCESS_REQUEST';
export const ISMLB_ACCESS_SUCCESS = 'ISMLB_ACCESS_SUCCESS';
export const ISMLB_ACCESS_ERROR = 'ISMLB_ACCESS_ERROR';

export const FILTER_PLAYER_BY_EMAIL_REQUEST = 'FILTER_PLAYER_BY_EMAIL_REQUEST';
export const FILTER_PLAYER_BY_EMAIL_SUCCESS = 'FILTER_PLAYER_BY_EMAIL_SUCCESS';
export const FILTER_PLAYER_BY_EMAIL_ERROR = 'FILTER_PLAYER_BY_EMAIL_ERROR';

export const ASSIGN_COMPETITION_LICENCE_REQUEST = 'ASSIGN_COMPETITION_LICENCE_REQUEST';
export const ASSIGN_COMPETITION_LICENCE_SUCCESS = 'ASSIGN_COMPETITION_LICENCE_SUCCESS';
export const ASSIGN_COMPETITION_LICENCE_ERROR = 'ASSIGN_COMPETITION_LICENCE_ERROR';