export const GET_PRODUCT_FEATURE_LIST_SUCCESS = "GET_PRODUCT_FEATURE_LIST_SUCCESS";
export const GET_PRODUCT_FEATURE_LIST_ERROR = "GET_PRODUCT_FEATURE_LIST_ERROR";
export const GET_PRODUCT_FEATURE_LIST_REQUEST = "GET_PRODUCT_FEATURE_LIST_REQUEST";

export const CREATE_PRODUCT_FEATURE_SUCCESS = "CREATE_PRODUCT_FEATURE_SUCCESS";
export const CREATE_PRODUCT_FEATURE_ERROR = "CREATE_PRODUCT_FEATURE_ERROR";
export const CREATE_PRODUCT_FEATURE_REQUEST = "CREATE_PRODUCT_FEATURE_REQUEST";

export const DELETE_PRODUCT_FEATURE_SUCCESS = "DELETE_PRODUCT_FEATURE_SUCCESS";
export const DELETE_PRODUCT_FEATURE_ERROR = "DELETE_PRODUCT_FEATURE_ERROR";
export const DELETE_PRODUCT_FEATURE_REQUEST = "DELETE_PRODUCT_FEATURE_REQUEST";

export const FETCH_PRODUCT_FEATURE_SUCCESS = "FETCH_PRODUCT_FEATURE_SUCCESS";
export const FETCH_PRODUCT_FEATURE_ERROR = "FETCH_PRODUCT_FEATURE_ERROR";
export const FETCH_PRODUCT_FEATURE_REQUEST = "FETCH_PRODUCT_FEATURE_REQUEST";

export const UPDATE_PRODUCT_FEATURE_SUCCESS = "UPDATE_PRODUCT_FEATURE_SUCCESS";
export const UPDATE_PRODUCT_FEATURE_ERROR = "UPDATE_PRODUCT_FEATURE_ERROR";
export const UPDATE_PRODUCT_FEATURE_REQUEST = "UPDATE_PRODUCT_FEATURE_REQUEST";