import { 
    BC_FETCH_ALL_FANS_REQUEST,
    BC_FETCH_ALL_FANS_SUCCESS,
    BC_FETCH_ALL_FANS_ERROR,

    FETCH_BC_FAN_BY_ID_REQUEST,
    FETCH_BC_FAN_BY_ID_SUCCESS,
    FETCH_BC_FAN_BY_ID_ERROR,

} from '../../../constants/BaseballCloud/Users/Fans.const';

const initialState = {

  BCfan: [],
  BCfanError: null,
  BCfanLoader: false,
  isFanLoaded: false,

  BCfansByFilter: [],
  BCfansByFilterError: null,
  BCfansByFilterLoader: false,
  isFansLoaded: false,

};

export default function(state = initialState, action) {
  switch (action.type) {

    // Fetch - FAN BY ID

    case FETCH_BC_FAN_BY_ID_REQUEST:
    return {
        ...state,
        BCfanLoader: true
    };
    case FETCH_BC_FAN_BY_ID_SUCCESS:
    return {
        ...state,
        isFanLoaded: true,
        BCfan: action.payload,
        BCfanLoader: false
    };
    case FETCH_BC_FAN_BY_ID_ERROR:
    return {
        ...state,
        BCfanError: action.error.message,
        BCfanLoader: false
    };

    // Fetch - FAN BY FILTER

    case BC_FETCH_ALL_FANS_REQUEST:
    return {
        ...state,
        BCfansByFilterLoader: true
    };
    case BC_FETCH_ALL_FANS_SUCCESS:
    return {
        ...state,
        isFansLoaded: true,
        BCfansByFilter: action.payload,
        BCfansByFilterLoader: false
    };
    case BC_FETCH_ALL_FANS_ERROR:
    return {
        ...state,
        BCfansByFilterError: action.error.message,
        BCfansByFilterLoader: false
    };

    default:
      return state;
  }
}
