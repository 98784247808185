import {

    GET_PUBLISH_LIST_SUCCESS,
    GET_PUBLISH_LIST_ERROR,

    CREATE_PUBLISH_LIST_SUCCESS,
    CREATE_PUBLISH_LIST_ERROR,

    UMPIRE_SCHEDULE_REPORT,
    UMPIRE_SCHEDULE_REPORT_ERROR,

    UMPIRE_CARDS_REPORT,
    UMPIRE_CARDS_REPORT_ERROR,

    FIELD_SCHEDULE_REPORT,
    FIELD_SCHEDULE_REPORT_ERROR,

    PUBLISH_RESPONSE_FILTERWISE,

    PRINT_UMPIRE_CARD_RESPONSE,

    PRINT_UMPIRE_SCHEDULE_RESPONSE,

    SINGLE_PUBLISH_RESPONSE_FILTERWISE,

    SINGLE_PRINT_UMPIRE_CARD_RESPONSE,

    SEND_SCHEDULE_NOTIY_SUCCESS
} from '../../constants/Scheduling/Publish.const';

const initialState = {

    getPublishData: null,
    getPublishDataError: null,

    createPublishData: null,
    createPublishDataError: null,

    umpireScheduleReport: null,
    umpireScheduleReportError: null,

    umpireCardReport: null,
    umpireCardReportError: null,

    fieldScheduleReport: null,
    fieldScheduleReportError: null,

    publishResponseFilterwise: null,

    umpireCardAllReport: null,

    umpireScheduleAllReport: null,

    singleUmpireCardReport: null,

    singleUmpireScheduleReport: null,

    sendScheduleNotify: null

};

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_PUBLISH_LIST_SUCCESS:
            return {
                ...state,
                getPublishData: action.payload
            }
        case GET_PUBLISH_LIST_ERROR:
            return {
                ...state,
                getPublishDataError: action.error
            }

        case CREATE_PUBLISH_LIST_SUCCESS:
            return {
                ...state,
                createPublishData: action.payload
            }
        case CREATE_PUBLISH_LIST_ERROR:
            return {
                ...state,
                createPublishDataError: action.error
            }

        //Report

        case UMPIRE_SCHEDULE_REPORT:

            return {
                ...state,
                umpireScheduleReport: action.payload
            }
        case UMPIRE_SCHEDULE_REPORT_ERROR:
            return {
                ...state,
                umpireScheduleReportError: action.error
            }

        case UMPIRE_CARDS_REPORT:
            return {
                ...state,
                umpireCardReport: action.payload
            }
        case UMPIRE_CARDS_REPORT_ERROR:
            return {
                ...state,
                umpireCardReportError: action.error
            }
        case FIELD_SCHEDULE_REPORT:
            return {
                ...state,
                fieldScheduleReport: action.payload
            }
        case FIELD_SCHEDULE_REPORT_ERROR:
            return {
                ...state,
                fieldScheduleReportError: action.error
            }

        // PUBLISH ALL
        case PUBLISH_RESPONSE_FILTERWISE:
            return {
                ...state,
                publishResponseFilterwise: action.payload
            }

        case PRINT_UMPIRE_CARD_RESPONSE:
            return {
                ...state,
                umpireCardAllReport: action.payload
            }

        case PRINT_UMPIRE_SCHEDULE_RESPONSE:
            return {
                ...state,
                umpireScheduleAllReport: action.payload
            }

        case SINGLE_PUBLISH_RESPONSE_FILTERWISE:
            return {
                ...state,
                singleUmpireScheduleReport: action.payload
            }

        case SINGLE_PRINT_UMPIRE_CARD_RESPONSE:
            return {
                ...state,
                singleUmpireCardReport: action.payload
            }


        //send Schedule notifiction
        case SEND_SCHEDULE_NOTIY_SUCCESS:
            return {
                ...state,
                sendScheduleNotify: action.payload
            }

        default:
            return state;
    }
}