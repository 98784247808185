// mlb organization

export const GET_MLB_BY_FILTER_SUCCESS = 'GET_MLB_BY_FILTER_SUCCESS';
export const GET_MLB_BY_FILTER_ERROR = 'GET_MLB_BY_FILTER_ERROR';

export const SAVE_MLB_USERS_SUCCESS = 'SAVE_MLB_USERS_SUCCESS';
export const SAVE_MLB_USERS_ERROR = 'SAVE_MLB_USERS_ERROR';

export const SAVE_MLB_ACCOUNT_SUCCESS = 'SAVE_MLB_ACCOUNT_SUCCESS';
export const SAVE_MLB_ACCOUNT_ERROR = 'SAVE_MLB_ACCOUNT_ERROR';

export const UPDATE_PRODUCT_ACCESS_SUCCESS = 'UPDATE_PRODUCT_ACCESS_SUCCESS';
export const UPDATE_PRODUCT_ACCESS_ERROR = 'UPDATE_PRODUCT_ACCESS_ERROR';

export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS';
export const GET_ORGANIZATION_LIST_ERROR = 'GET_ORGANIZATION_LIST_ERROR';

// mlb accounts

export const GET_MLB_ACCOUNT_LIST_SUCCESS = 'GET_MLB_ACCOUNT_LIST_SUCCESS';
export const GET_MLB_ACCOUNT_LIST_ERROR = 'GET_MLB_ACCOUNT_LIST_ERROR';

export const GET_EMAIL_LIST_SUCCESS = 'GET_EMAIL_LIST_SUCCESS';
export const GET_EMAIL_LIST_ERROR = 'GET_EMAIL_LIST_ERROR';

export const UPDATE_BCTEAM_PRODUCT_SUCCESS = 'UPDATE_BCTEAM_PRODUCT_SUCCESS';
export const UPDATE_BCTEAM_PRODUCT_ERROR = 'UPDATE_BCTEAM_PRODUCT_ERROR';

export const UPDATE_ACCOUNT_ACCESS_SUCCESS = 'UPDATE_ACCOUNT_ACCESS_SUCCESS';
export const UPDATE_ACCOUNT_ACCESS_ERROR = 'UPDATE_ACCOUNT_ACCESS_ERROR';

export const EXPORT_CSV_FOR_USER_SUCCESS = 'EXPORT_CSV_FOR_USER_SUCCESS';
export const EXPORT_CSV_FOR_USER_ERROR = 'EXPORT_CSV_FOR_USER_ERROR';