export const BC_FETCH_TOP_BATSMAN_REQUEST = 'BC_FETCH_TOP_BATSMAN_REQUEST';
export const BC_FETCH_TOP_BATSMAN_SUCCESS = 'BC_FETCH_TOP_BATSMAN_SUCCESS';
export const BC_FETCH_TOP_BATSMAN_ERROR   = 'BC_FETCH_TOP_BATSMAN_ERROR';

export const BC_FETCH_ALL_BATSMAN_REQUEST = 'BC_FETCH_ALL_BATSMAN_REQUEST';
export const BC_FETCH_ALL_BATSMAN_SUCCESS = 'BC_FETCH_ALL_BATSMAN_SUCCESS';
export const BC_FETCH_ALL_BATSMAN_ERROR   = 'BC_FETCH_ALL_BATSMAN_ERROR';


export const BC_FETCH_TOP_PITCHER_REQUEST = 'BC_FETCH_TOP_PITCHER_REQUEST';
export const BC_FETCH_TOP_PITCHER_SUCCESS = 'BC_FETCH_TOP_PITCHER_SUCCESS';
export const BC_FETCH_TOP_PITCHER_ERROR   = 'BC_FETCH_TOP_PITCHER_ERROR';

export const BC_FETCH_ALL_PITCHER_REQUEST = 'BC_FETCH_ALL_PITCHER_REQUEST';
export const BC_FETCH_ALL_PITCHER_SUCCESS = 'BC_FETCH_ALL_PITCHER_SUCCESS';
export const BC_FETCH_ALL_PITCHER_ERROR   = 'BC_FETCH_ALL_PITCHER_ERROR';


export const BC_FETCH_PLAYER_BY_ID_REQUEST = 'BC_FETCH_PLAYER_BY_ID_REQUEST';
export const BC_FETCH_PLAYER_BY_ID_SUCCESS = 'BC_FETCH_PLAYER_BY_ID_SUCCESS';
export const BC_FETCH_PLAYER_BY_ID_ERROR = 'BC_FETCH_PLAYER_BY_ID_ERROR';

export const BC_FETCH_PLAYER_BY_FILTER_REQUEST = 'BC_FETCH_PLAYER_BY_FILTER_REQUEST';
export const BC_FETCH_PLAYER_BY_FILTER_SUCCESS = 'BC_FETCH_PLAYER_BY_FILTER_SUCCESS';
export const BC_FETCH_PLAYER_BY_FILTER_ERROR = 'BC_FETCH_PLAYER_BY_FILTER_ERROR';

export const PLAYER_BALLR_LICENCE_REQUEST = 'PLAYER_BALLR_LICENCE_REQUEST';
export const PLAYER_BALLR_LICENCE_SUCCESS = 'PLAYER_BALLR_LICENCE_SUCCESS';
export const PLAYER_BALLR_LICENCE_ERROR = 'PLAYER_BALLR_LICENCE_ERROR';

export const PLAYER_ASSIGN_BALLR_LICENCE_REQUEST = 'PLAYER_ASSIGN_BALLR_LICENCE_REQUEST';
export const PLAYER_ASSIGN_BALLR_LICENCE_SUCCESS = 'PLAYER_ASSIGN_BALLR_LICENCE_SUCCESS';
export const PLAYER_ASSIGN_BALLR_LICENCE_ERROR = 'PLAYER_ASSIGN_BALLR_LICENCE_ERROR';

export const PLAYER_PITCHR_LICENCE_REQUEST = 'PLAYER_PITCHR_LICENCE_REQUEST';
export const PLAYER_PITCHR_LICENCE_SUCCESS = 'PLAYER_PITCHR_LICENCE_SUCCESS';
export const PLAYER_PITCHR_LICENCE_ERROR = 'PLAYER_PITCHR_LICENCE_ERROR';

export const PLAYER_ASSIGN_PITCHR_LICENCE_REQUEST = 'PLAYER_ASSIGN_PITCHR_LICENCE_REQUEST';
export const PLAYER_ASSIGN_PITCHR_LICENCE_SUCCESS = 'PLAYER_ASSIGN_PITCHR_LICENCE_SUCCESS';
export const PLAYER_ASSIGN_PITCHR_LICENCE_ERROR = 'PLAYER_ASSIGN_PITCHR_LICENCE_ERROR';

export const FILTER_PLAYER_BY_EMAIL_REQUEST = 'FILTER_PLAYER_BY_EMAIL_REQUEST';
export const FILTER_PLAYER_BY_EMAIL_SUCCESS = 'FILTER_PLAYER_BY_EMAIL_SUCCESS';
export const FILTER_PLAYER_BY_EMAIL_ERROR = 'FILTER_PLAYER_BY_EMAIL_ERROR';

export const BC_LOGIN_REQUEST = 'BC_LOGIN_REQUEST';
export const BC_LOGIN_SUCCESS = 'BC_LOGIN_SUCCESS';
export const BC_LOGIN_ERROR = 'BC_LOGIN_ERROR';

export const PLAYER_CHANGE_TEAM_REQUEST = 'PLAYER_CHANGE_TEAM_REQUEST';
export const PLAYER_CHANGE_TEAM_SUCCESS = 'PLAYER_CHANGE_TEAM_SUCCESS';
export const PLAYER_CHANGE_TEAM_ERROR = 'PLAYER_CHANGE_TEAM_ERROR';

export const GET_PLAYER_CHANGE_TEAM_REQUEST = 'GET_PLAYER_CHANGE_TEAM_REQUEST';
export const GET_PLAYER_CHANGE_TEAM_SUCCESS = 'GET_PLAYER_CHANGE_TEAM_SUCCESS';
export const GET_PLAYER_CHANGE_TEAM_ERROR = 'GET_PLAYER_CHANGE_TEAM_ERROR';

export const SET_PLAYER_DEFAULT_TEAM_REQUEST = 'SET_PLAYER_DEFAULT_TEAM_REQUEST';
export const SET_PLAYER_DEFAULT_TEAM_SUCCESS = 'SET_PLAYER_DEFAULT_TEAM_SUCCESS';
export const SET_PLAYER_DEFAULT_TEAM_ERROR = 'SET_PLAYER_DEFAULT_TEAM_ERROR';

export const REMOVE_PLAYER_TEAM_REQUEST = 'REMOVE_PLAYER_TEAM_REQUEST';
export const REMOVE_PLAYER_TEAM_SUCCESS = 'REMOVE_PLAYER_TEAM_SUCCESS';
export const REMOVE_PLAYER_TEAM_ERROR = 'REMOVE_PLAYER_TEAM_ERROR';

export const ISANALYR_ACCESS_REQUEST = 'ISANALYR_ACCESS_REQUEST';
export const ISANALYR_ACCESS_SUCCESS = 'ISANALYR_ACCESS_SUCCESS';
export const ISANALYR_ACCESS_ERROR = 'ISANALYR_ACCESS_ERROR';

export const BC_EXPORT_CSV_FOR_USER_REQUEST = 'BC_EXPORT_CSV_FOR_USER_REQUEST';
export const BC_EXPORT_CSV_FOR_USER_SUCCESS = 'BC_EXPORT_CSV_FOR_USER_SUCCESS';
export const BC_EXPORT_CSV_FOR_USER_ERROR = 'BC_EXPORT_CSV_FOR_USER_ERROR';

export const BC_ADD_NEW_ROSTER_REQUEST = 'BC_ADD_NEW_ROSTER_REQUEST';
export const BC_ADD_NEW_ROSTER_SUCCESS = 'BC_ADD_NEW_ROSTER_SUCCESS';
export const BC_ADD_NEW_ROSTER_ERROR = 'BC_ADD_NEW_ROSTER_ERROR';
