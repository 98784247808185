export const UPLOAD_DATA_REQUEST = 'UPLOAD_DATA_REQUEST';
export const UPLOAD_DATA_SUCCESS = 'UPLOAD_DATA_SUCCESS';
export const UPLOAD_DATA_ERROR   = 'UPLOAD_DATA_ERROR';

export const REORDER_REQUEST = 'REORDER_REQUEST';
export const REORDER_SUCCESS = 'REORDER_SUCCESS';
export const REORDER_ERROR   = 'REORDER_ERROR';

export const ONBOARDING_DATA_REQUEST = 'ONBOARDING_DATA_REQUEST';
export const ONBOARDING_DATA_SUCCESS = 'ONBOARDING_DATA_SUCCESS';
export const ONBOARDING_DATA_ERROR   = 'ONBOARDING_DATA_ERROR';

export const ADD_ONBOARDING_DATA_REQUEST = 'ADD_ONBOARDING_DATA_REQUEST';
export const ADD_ONBOARDING_DATA_SUCCESS = 'ADD_ONBOARDING_DATA_SUCCESS';
export const ADD_ONBOARDING_DATA_ERROR   = 'ADD_ONBOARDING_DATA_ERROR';

export const SAVE_ONBOARDING_DATA_REQUEST = 'SAVE_ONBOARDING_DATA_REQUEST';
export const SAVE_ONBOARDING_DATA_SUCCESS = 'SAVE_ONBOARDING_DATA_SUCCESS';
export const SAVE_ONBOARDING_DATA_ERROR   = 'SAVE_ONBOARDING_DATA_ERROR';