import {
  ADD_FORMATE_REQUEST,
  ADD_FORMATE_SUCCESS,
  ADD_FORMATE_ERROR,

  UPDATE_FORMATE_SUCCESS,
  UPDATE_FORMATE_ERROR,

  FETCH_FORMATE_REQUEST,
  FETCH_FORMATE_SUCCESS,
  FETCH_FORMATE_ERROR,

  GET_NUMBER_OF_TEAM_REQUEST,
  GET_NUMBER_OF_TEAM_SUCCESS,
  GET_NUMBER_OF_TEAM_ERROR,

  GET_MATCHUP_LIST_SUCCESS,
  GET_MATCHUP_LIST_ERROR,

  GET_MATCHUP_TEAM_LIST_SUCCESS,
  GET_MATCHUP_TEAM_LIST_ERROR,

  CREATE_MATCHUP_SUCCESS,
  CREATE_MATCHUP_ERROR,

  DELETE_MATCHUP_SUCCESS,
  DELETE_MATCHUP_ERROR,

  GET_DATE_TIME_LIST_SUCCESS,
  GET_DATE_TIME_LIST_ERROR,

  SPECIAL_REQUEST_TOASTER

} from '../../constants/Scheduling/Formate.const';

const initialState = {
  addFormateLoader: false,
  addFormate: null,
  addFormateError: null,

  updateFormate: null,
  updateFormateError: null,

  fetchFormateLoader: false,
  fetchFormate: null,
  fetchFormateError: null,

  getNumberOfTeamLoader: false,
  getNumberOfTeamData: null,
  getNumberOfTeamDataError: null,

  // Match Up Restriction

  getMatchupData: null,
  getMatchupDataError: null,

  getMatchupTeamData: null,
  getMatchupTeamDataError: null,

  createMatchupData: null,
  createMatchupDataError: null,

  deleteMatchupData: null,
  deleteMatchupDataError: null,

  //  Date/Time Restriction

  createDateTimeData: null,
  createDateTimeDataError: null,

  getDateTimeData: null,
  getDateTimeDataError: null,

  deleteDateTimeData: null,
  deleteDateTimeDataError: null,

  getDateTimeTeamData: null,
  getDateTimeTeamDataError: null,

};

export default function (state = initialState, action) {
  switch (action.type) {

    // For add formate
    case ADD_FORMATE_REQUEST:
      return {
        ...state,
        addFormateLoader: true
      };
    case ADD_FORMATE_SUCCESS:
      return {
        ...state,
        addFormate: action.payload
      };
    case ADD_FORMATE_ERROR:
      return {
        ...state,
        addFormateError: true,
      };

    case UPDATE_FORMATE_SUCCESS:
      return {
        ...state,
        updateFormate: action.payload
      };
    case UPDATE_FORMATE_ERROR:
      return {
        ...state,
        updateFormateError: true,
      };


    //For get formate details
    case FETCH_FORMATE_REQUEST:
      return {
        ...state,
        fetchFormateLoader: true
      };
    case FETCH_FORMATE_SUCCESS:
      return {
        ...state,
        fetchFormate: action.payload
      };
    case FETCH_FORMATE_ERROR:
      return {
        ...state,
        fetchFormateError: true,
      };

    //get number of team
    case GET_NUMBER_OF_TEAM_REQUEST:
      return {
        ...state,
        getNumberOfTeamLoader: true
      };
    case GET_NUMBER_OF_TEAM_SUCCESS:
      return {
        ...state,
        getNumberOfTeamData: action.payload
      };
    case GET_NUMBER_OF_TEAM_ERROR:
      return {
        ...state,
        getNumberOfTeamDataError: true
      };


    //Match Up Restriction

    case GET_MATCHUP_LIST_SUCCESS:
      return {
        ...state,
        getMatchupData: action.payload
      }
    case GET_MATCHUP_LIST_ERROR:
      return {
        ...state,
        getMatchupDataError: action.error
      }

    case GET_MATCHUP_TEAM_LIST_SUCCESS:
      return {
        ...state,
        getMatchupTeamData: action.payload
      }
    case GET_MATCHUP_TEAM_LIST_ERROR:
      return {
        ...state,
        getMatchupTeamDataError: action.error
      }

    case CREATE_MATCHUP_SUCCESS:
      return {
        ...state,
        createMatchupData: action.payload
      }
    case CREATE_MATCHUP_ERROR:
      return {
        ...state,
        createMatchupDataError: action.error
      }

    case DELETE_MATCHUP_SUCCESS:
      return {
        ...state,
        deleteMatchupData: action.payload
      }
    case DELETE_MATCHUP_ERROR:
      return {
        ...state,
        deleteMatchupDataError: action.error
      }

    //  Date/Time Restriction


    case GET_DATE_TIME_LIST_SUCCESS:
      return {
        ...state,
        getDateTimeData: action.payload
      }
    case GET_DATE_TIME_LIST_ERROR:
      return {
        ...state,
        getDateTimeDataError: action.error
      }

    // Save
    case SPECIAL_REQUEST_TOASTER:
      return {
        ...state,
        getSpecialRequestMsg: action.payload
      }

    default:
      return state;
  }
}