import {
    GET_STATISTICS_DATA_REQUEST,
    GET_STATISTICS_DATA_SUCCESS,
    GET_STATISTICS_DATA_ERROR,
  } from "../../constants/BaseballCloud/DataStatistics.const";
  
  const initialState = {
    statisticsData: [],
    statisticsError: null,
    statisticsLoader: false,
    isStatisticsLoaded: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      // get data statistics
      case GET_STATISTICS_DATA_REQUEST:
        return {
          ...state,
          statisticsLoader: true,
        };
      case GET_STATISTICS_DATA_SUCCESS:
        return {
          ...state,
          statisticsData: action.payload,
          statisticsLoader: false,
          isStatisticsLoaded: true,
        };
      case GET_STATISTICS_DATA_ERROR:
        return {
          ...state,
          statisticsError: action.error.message,
          statisticsLoader: false,
        };
      default:
        return state;
    }
  }
  