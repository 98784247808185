export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR   = 'USER_RESET_PASSWORD_ERROR';

export const GET_TEAM_BY_ID_REQUEST = 'GET_TEAM_BY_ID_REQUEST';
export const GET_TEAM_BY_ID_SUCCESS = 'GET_TEAM_BY_ID_SUCCESS';
export const GET_TEAM_BY_ID_ERROR   = 'GET_TEAM_BY_ID_ERROR';

export const EDIT_TEAM_INFO_REQUEST = 'EDIT_TEAM_INFO_REQUEST';
export const EDIT_TEAM_INFO_SUCCESS = 'EDIT_TEAM_INFO_SUCCESS';
export const EDIT_TEAM_INFO_ERROR   = 'EDIT_TEAM_INFO_ERROR';

export const USER_EDIT_PROFILE_REQUEST = 'USER_EDIT_PROFILE_REQUEST';
export const USER_EDIT_PROFILE_SUCCESS = 'USER_EDIT_PROFILE_SUCCESS';
export const USER_EDIT_PROFILE_ERROR   = 'USER_EDIT_PROFILE_ERROR';
