import {
  REPORT_USER_LIST_SUCCESS,
  REPORT_USER_LIST_ERROR
  } from '../constants/ReportUser.const';
  
  const initialState = {
    reportUser: [],
    reportUserError: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For report user Data
      case REPORT_USER_LIST_SUCCESS:
        return {
          ...state,
          reportUser: action.payload,
        };
      case REPORT_USER_LIST_ERROR:
        return {
          ...state,
          reportUserError: action.error,
        };
  
      default:
        return state;
    }
  }