import { 
    FETCH_PRODUCT_REQUEST,
    FETCH_PRODUCT_SUCCESS, 
    FETCH_PRODUCT_ERROR,

    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_ERROR,

    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_ERROR,

    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_ERROR  
  
  } from '../constants/Product.const';
  
  const initialState = {
    products: [],
    productsError: null,
    productsLoader: false,
    isProductsLoaded: false,
  
    isDeleted: false,
    isDeletedError : null,
    isDeletedLoader : false,
  
    isCreated : false,
    isCreatedError : null,
    isCreatedLoader : false,
    
    isUpdated : false,
    isUpdatedError : null,
    isUpdatedLoader : false  
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Product Data
  
      case FETCH_PRODUCT_REQUEST:
        return {
          ...state,
          productsLoader: true
        };
      case FETCH_PRODUCT_SUCCESS:
        return {
          ...state,
          products: action.payload,
          productsLoader: false,
          isProductsLoaded: true

        };
      case FETCH_PRODUCT_ERROR:
        return {
          ...state,
          productsError: action.error.message,
          productsLoader: false
        };
  
      // For CREATE Product Data
  
      case CREATE_PRODUCT_REQUEST:
        return {
          ...state,
          isCreatedLoader: true
        };
      case CREATE_PRODUCT_SUCCESS:
        return {
          ...state,
          isCreated: true,
          isCreatedLoader: false
        };
      case CREATE_PRODUCT_ERROR:
        return {
          ...state,
          isCreatedError: action.error,
          isCreatedLoader: false
        };
  
      // For UPDATE Product Data
  
      case UPDATE_PRODUCT_REQUEST:
        return {
          ...state,
          isUpdatedLoader: true
        };
      case UPDATE_PRODUCT_SUCCESS:
        return {
          ...state,
          isUpdated: true,
          isUpdatedLoader: false
        };
      case UPDATE_PRODUCT_ERROR:
        return {
          ...state,
          isUpdatedError: action.error,
          isUpdatedLoader: false
        };

        // For DELETE Product Data
  
      case DELETE_PRODUCT_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isDeletedLoader: false
        };
      case DELETE_PRODUCT_ERROR:
        return {
          ...state,
          isDeletedError: action.error,
          isDeletedLoader: false
        };
  
      default:
        return state;
    }
  }