import {
    TEAM_SETTING_SUCCESS,
    TEAM_SETTING_ERROR,
    GET_D1_AVERAGE_LIST_SUCCESS,
    GET_D1_AVERAGE_LIST_REQUEST,
    GET_D1_AVERAGE_LIST_ERROR,
    D1_AVERAGE_LIST_ERROR,
    D1_AVERAGE_LIST_SUCCESS,
    D1_SOFT_AVERAGE_LIST_ERROR,
    D1_SOFT_AVERAGE_LIST_SUCCESS,
    D1_IND_AVERAGE_LIST_SUCCESS,
    D1_IND_AVERAGE_LIST_ERROR
} from "../../constants/BaseballCloud/TeamSetting.const";

const initialState = {
    D1averageListData: [],
    D1averageListError: null,

    D1SoftaverageListData: [],
    D1SoftaverageListError: null,

    D1averageData: [],
    D1averageError: null,

    saveTeamSetingData: null,
    saveTeamSetingError: null,

    D1IndaverageListData: [],
    D1IndaverageListError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {


        // For All Base D1 average List
        case D1_AVERAGE_LIST_SUCCESS:
            return {
                ...state,
                D1averageListData: action.payload.data
            };
        case D1_AVERAGE_LIST_ERROR:
            return {
                ...state,
                D1averageListError: action.error,
            };

        // For All Soft D1 average List
        case D1_SOFT_AVERAGE_LIST_SUCCESS:
            return {
                ...state,
                D1SoftaverageListData: action.payload.data
            };
        case D1_SOFT_AVERAGE_LIST_ERROR:
            return {
                ...state,
                D1SoftaverageListError: action.error,
            };

        // For selected D1 Average List
        case GET_D1_AVERAGE_LIST_SUCCESS:
            return {
                ...state,
                D1averageData: action.payload.settingValue
            };
        case GET_D1_AVERAGE_LIST_ERROR:
            return {
                ...state,
                D1averageError: action.error,
            };

        // For selected D1 Average List
        case GET_D1_AVERAGE_LIST_SUCCESS:
            return {
                ...state,
                D1averageData: action.payload.settingValue
            };
        case GET_D1_AVERAGE_LIST_ERROR:
            return {
                ...state,
                D1averageError: action.error,
            };

        // For Save Team Setting
        case TEAM_SETTING_SUCCESS:
            return {
                ...state,
                saveTeamSetingData: action.payload
            };
        case TEAM_SETTING_ERROR:
            return {
                ...state,
                saveTeamSetingError: action.error,
            };

        // For All Independent D1 average List
        case D1_IND_AVERAGE_LIST_SUCCESS:
            return {
                ...state,
                D1IndaverageListData: action.payload.data
            };
        case D1_IND_AVERAGE_LIST_ERROR:
            return {
                ...state,
                D1IndaverageListError: action.error,
            };

        default:
            return state;
    }
}