export const FETCH_SCOUTING_LIBRARY_SUCCESS = 'FETCH_SCOUTING_LIBRARY_SUCCESS';
export const FETCH_SCOUTING_LIBRARY_ERROR   = 'FETCH_SCOUTING_LIBRARY_ERROR';

export const DELETE_SCOUTING_LIBRARY_SUCCESS = 'DELETE_SCOUTING_LIBRARY_SUCCESS';
export const DELETE_SCOUTING_LIBRARY_ERROR   = 'DELETE_SCOUTING_LIBRARY_ERROR';

export const UPDATE_SCOUTING_LIBRARY_SUCCESS = 'UPDATE_SCOUTING_LIBRARY_SUCCESS';
export const UPDATE_SCOUTING_LIBRARY_ERROR   = 'UPDATE_SCOUTING_LIBRARY_ERROR';

export const CREATE_SCOUTING_LIBRARY_SUCCESS = 'CREATE_SCOUTING_LIBRARY_SUCCESS';
export const CREATE_SCOUTING_LIBRARY_ERROR   = 'CREATE_SCOUTING_LIBRARY_ERROR';