export const GET_USEROLELIST_REQUEST = "GET_USEROLELIST_REQUEST"
export const GET_USEROLELIST_SUCCESS = "GET_USEROLELIST_SUCCESS"
export const GET_USEROLELIST_ERROR = "GET_USEROLELIST_ERROR"

export const FATCH_USEROLE_REQUEST = "FATCH_USEROLE_REQUEST"
export const FATCH_USEROLE_SUCCESS = "FATCH_USEROLE_SUCCESS"
export const FATCH_USEROLE_ERROR = "FATCH_USEROLE_ERROR"

export const UPDATE_USER_ROLE_REQUEST = "UPDATE_USER_ROLE_REQUEST"
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS"
export const UPDATE_USER_ROLE_ERROR = "UPDATE_USER_ROLE_ERROR"

export const CREATE_USER_ROLE_REQUEST = "CREATE_USER_ROLE_REQUEST"
export const CREATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS"
export const CREATE_USER_ROLE_ERROR = "CREATE_USER_ROLE_ERROR"