export const FETCH_TOP_TEAMS_REQUEST = 'FETCH_TOP_TEAMS_REQUEST';
export const FETCH_TOP_TEAMS_SUCCESS = 'FETCH_TOP_TEAMS_SUCCESS';
export const FETCH_TOP_TEAMS_ERROR   = 'FETCH_TOP_TEAMS_ERROR';

export const FETCH_ALL_TEAMS_REQUEST = 'FETCH_ALL_TEAMS_REQUEST';
export const FETCH_ALL_TEAMS_SUCCESS = 'FETCH_ALL_TEAMS_SUCCESS';
export const FETCH_ALL_TEAMS_ERROR   = 'FETCH_ALL_TEAMS_ERROR';

export const FETCH_TEAM_BY_FILTER_REQUEST = 'FETCH_TEAM_BY_FILTER_REQUEST';
export const FETCH_TEAM_BY_FILTER_SUCCESS = 'FETCH_TEAM_BY_FILTER_SUCCESS';
export const FETCH_TEAM_BY_FILTER_ERROR = 'FETCH_TEAM_BY_FILTER_ERROR';

export const FETCH_TEAM_BY_ID_REQUEST = 'FETCH_TEAM_BY_ID_REQUEST';
export const FETCH_TEAM_BY_ID_SUCCESS = 'FETCH_TEAM_BY_ID_SUCCESS';
export const FETCH_TEAM_BY_ID_ERROR = 'FETCH_TEAM_BY_ID_ERROR';

export const EXPORT_CSV_FOR_TEAM_REQUEST = 'EXPORT_CSV_FOR_TEAM_REQUEST';
export const EXPORT_CSV_FOR_TEAM_SUCCESS = 'EXPORT_CSV_FOR_TEAM_SUCCESS';
export const EXPORT_CSV_FOR_TEAM_ERROR = 'EXPORT_CSV_FOR_TEAM_ERROR';

export const BYNAME_PARENTTEAM_LIST_REQUEST = 'BYNAME_PARENTTEAM_LIST_REQUEST';
export const BYNAME_PARENTTEAM_LIST_SUCCESS = 'BYNAME_PARENTTEAM_LIST_SUCCESS';
export const BYNAME_PARENTTEAM_LIST_ERROR = 'BYNAME_PARENTTEAM_LIST_ERROR';