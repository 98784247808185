import {
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_ERROR,

  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_ERROR,

  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,

  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,

  RESET_ADMIN_REQUEST,
  RESET_ADMIN_SUCCESS,
  RESET_ADMIN_ERROR

} from '../constants/Admin.const';

const initialState = {
  admin: [],
  adminError: null,
  adminLoader: false,
  isAdminsLoaded: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,

  isCreated: false,
  isCreatedError: null,
  isCreatedLoader: false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false,

  isResetReq: false,
  isResetData: null,
  isResetError: null
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For GET Admin Data

    case FETCH_ADMIN_REQUEST:
      return {
        ...state,
        adminLoader: true
      };
    case FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        admin: action.payload,
        adminLoader: false,
        isAdminsLoaded: true

      };
    case FETCH_ADMIN_ERROR:
      return {
        ...state,
        adminError: action.error.message,
        adminLoader: false
      };

    // For CREATE Admin Data

    case CREATE_ADMIN_REQUEST:
      return {
        ...state,
        isCreatedLoader: true
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isCreated: true,
        isCreatedLoader: false
      };
    case CREATE_ADMIN_ERROR:
      return {
        ...state,
        isCreatedError: action.error,
        isCreatedLoader: false
      };

    // For UPDATE Admin Data

    case UPDATE_ADMIN_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_ADMIN_ERROR:
      return {
        ...state,
        isUpdatedError: action.error,
        isUpdatedLoader: false
      };

    // For DELETE Admin Data

    case DELETE_ADMIN_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case DELETE_ADMIN_ERROR:
      return {
        ...state,
        isDeletedError: action.error,
        isDeletedLoader: false
      };

    // For Reset Password

    case RESET_ADMIN_REQUEST:
      return {
        ...state,
        isResetReq: true
      };
    case RESET_ADMIN_SUCCESS:
      return {
        ...state,
        isResetData: action.payload
      };
    case RESET_ADMIN_ERROR:
      return {
        ...state,
        isResetError: action.error,
      };

    default:
      return state;
  }
}