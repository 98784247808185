import { 
    FETCH_COMPANY_SUCCESS, 
    FETCH_COMPANY_ERROR,

    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_ERROR,

    DELETE_COMPANY_REQUEST,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_ERROR,

    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_ERROR,  
  
    CREATE_VENUE_SUCCESS,
    CREATE_VENUE_ERROR
  } from '../constants/Company.const';
  
  const initialState = {
    companiesList: [],
    companiesListError: null,
  
    // isDeleted: false,
    // isDeletedError : null,
    // isDeletedLoader : false,
  
    createCompanyData : null,
    createCompanyError : false,
    
    updateCompanyData : null,
    updateCompanyError : false,
    
    createVenueData: null,
    createVenueError: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Companies Data
      case FETCH_COMPANY_SUCCESS:
        return {
          ...state,
          companiesList: action.payload,
        };
      case FETCH_COMPANY_ERROR:
        return {
          ...state,
          companiesListError: action.error,
        };
  
      // For CREATE company Data
  
      case CREATE_COMPANY_SUCCESS:
        return {
          ...state,
          createCompanyData: action.payload,
        };
      case CREATE_COMPANY_ERROR:
        return {
          ...state,
          createCompanyError: action.error,
        };
  
      // For UPDATE company Data
  
      case UPDATE_COMPANY_SUCCESS:
        return {
          ...state,
          updateCompanyData: action.payload,
        };
      case UPDATE_COMPANY_ERROR:
        return {
          ...state,
          updateCompanyError: action.error,
        };

    //     // For DELETE Division Data
  
    //   case DELETE_COMPANY_REQUEST:
    //     return {
    //       ...state,
    //       isDeletedLoader: true
    //     };
    //   case DELETE_COMPANY_SUCCESS:
    //     return {
    //       ...state,
    //       isDeleted: true,
    //       isDeletedLoader: false
    //     };
    //   case DELETE_COMPANY_ERROR:
    //     return {
    //       ...state,
    //       isDeletedError: action.error,
    //       isDeletedLoader: false
    //     };
  

       // For UPDATE company Data
  
       case CREATE_VENUE_SUCCESS:
        return {
          ...state,
          createVenueData: action.payload,
        };
      case CREATE_VENUE_ERROR:
        return {
          ...state,
          createVenueError: action.error,
        };
      default:
        return state;
    }
  }