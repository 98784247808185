import {
    GET_PRODUCT_DATA_REQUEST,
    GET_PRODUCT_DATA_SUCCESS,
    GET_PRODUCT_DATA_ERROR
  } from "../constants/ProductData.const";

const initialState = {
    productData: [],
    productError: null,
    productLoader: false,
    isProductLoaded: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        // get data product
        case GET_PRODUCT_DATA_REQUEST:
            return {
                ...state,
                productLoader: true,
            };
        case GET_PRODUCT_DATA_SUCCESS:
            return {
                ...state,
                productData: action.payload,
                productLoader: false,
                isProductLoaded: true,
            };
        case GET_PRODUCT_DATA_ERROR:
            return {
                ...state,
                productError: action.error.message,
                productLoader: false,
            };
        default:
            return state;
    }
}
