import { 
    FETCH_HOLDTEAM_REQUEST,
    FETCH_HOLDTEAM_SUCCESS, 
    FETCH_HOLDTEAM_ERROR,

    REASSIGN_HOLDTEAM_REQUEST,
    REASSIGN_HOLDTEAM_SUCCESS,
    REASSIGN_HOLDTEAM_ERROR,

  } from '../constants/HoldTeam.const';
  
  const initialState = {
    holdTeams: [],
    holdteamError: null,
    holdteamLoader: false,
    isholdteamLoaded: false,
    
    isReassigned : false,
    isReassignedError : null,
    isReassignedLoader : false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Product Data
  
      case FETCH_HOLDTEAM_REQUEST:
        return {
          ...state,
          holdteamLoader: true
        };
      case FETCH_HOLDTEAM_SUCCESS:
        return {
          ...state,
          holdTeams: action.payload,
          holdteamLoader: false,
          isholdteamLoaded: true

        };
      case FETCH_HOLDTEAM_ERROR:
        return {
          ...state,
          holdteamError: action.error.message,
          holdteamLoader: false
        };
  
        // For ReAssign Team

      case REASSIGN_HOLDTEAM_REQUEST:
        return {
          ...state,
          isReassignedLoader: true
        };
      case REASSIGN_HOLDTEAM_SUCCESS:
        return {
          ...state,
          isReassigned: true,
          isReassignedLoader: false,
        };
      case REASSIGN_HOLDTEAM_ERROR:
        return {
          ...state,
          isReassignedError: action.error.message,
          isReassignedLoader: false
        };

      default:
        return state;
    }
  }