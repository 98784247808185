import {

    GET_MLB_BY_FILTER_SUCCESS,
    GET_MLB_BY_FILTER_ERROR,

    SAVE_MLB_USERS_SUCCESS,
    SAVE_MLB_USERS_ERROR,

    SAVE_MLB_ACCOUNT_SUCCESS,
    SAVE_MLB_ACCOUNT_ERROR,

    UPDATE_PRODUCT_ACCESS_SUCCESS,
    UPDATE_PRODUCT_ACCESS_ERROR,

    GET_ORGANIZATION_LIST_SUCCESS,
    GET_ORGANIZATION_LIST_ERROR,

    GET_EMAIL_LIST_SUCCESS,
    GET_EMAIL_LIST_ERROR,

    GET_MLB_ACCOUNT_LIST_SUCCESS,
    GET_MLB_ACCOUNT_LIST_ERROR,

    UPDATE_BCTEAM_PRODUCT_SUCCESS,
    UPDATE_BCTEAM_PRODUCT_ERROR,

    UPDATE_ACCOUNT_ACCESS_SUCCESS,
    UPDATE_ACCOUNT_ACCESS_ERROR,

    EXPORT_CSV_FOR_USER_SUCCESS,
    EXPORT_CSV_FOR_USER_ERROR


} from '../../../constants/BaseballCloud/MLBUsers/MLBUsers.const';

const initialState = {

    getMLBUser: [],
    getMLBUserError: null,

    saveMLBUser: [],
    saveMLBUserError: null,

    getOrganizationList: [],
    getOrganizationListError: null,

    updateProductAccess: null,
    updateProductAccessError: null,

    getEmailList: [],
    getEmailListError: null,

    getAccountList: null,
    getAccountListError: null,

    saveMLBAccountUser: null,
    saveMLBAccountUserError: null,

    updateBcteamProductAccess: null,
    updateBcteamProductAccessError: null,

    updateAccountAccess: null,
    updateAccountAccessError: null,

    exportCSV: null,
    exportCSVError: null,
   
};

export default function (state = initialState, action) {
    switch (action.type) {

        // get MLB users
      
        case GET_MLB_BY_FILTER_SUCCESS:
            return {
                ...state,
                getMLBUser: action.payload,
            };
        case GET_MLB_BY_FILTER_ERROR:
            return {
                ...state,
                getMLBUserError: action.error.message,
            };

            //save mlb organization
        case SAVE_MLB_USERS_SUCCESS:
            return {
                ...state,
                saveMLBUser: action.payload,
            };
        case SAVE_MLB_USERS_ERROR:
            return {
                ...state,
                saveMLBUserError: action.error.message,
            };

            //update access

        case UPDATE_PRODUCT_ACCESS_SUCCESS:
            return {
                ...state,
                updateProductAccess: action.payload,
            };
        case UPDATE_PRODUCT_ACCESS_ERROR:
            return {
                ...state,
                updateProductAccessError: action.error.message,
            };

        case GET_ORGANIZATION_LIST_SUCCESS:
            return {
                ...state,
                getOrganizationList: action.payload,
            };
        case GET_ORGANIZATION_LIST_ERROR:
            return {
                ...state,
                getOrganizationListError: action.error.message,
            };

            // mlb accounts

        case GET_MLB_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                getAccountList: action.payload,
            };
        case GET_MLB_ACCOUNT_LIST_ERROR:
            return {
                ...state,
                getAccountListError: action.error.message,
            };    

            // save mlb account

        case SAVE_MLB_ACCOUNT_SUCCESS:
            return {
                ...state,
                saveMLBAccountUser: action.payload,
            };
        case SAVE_MLB_ACCOUNT_ERROR:
            return {
                ...state,
                saveMLBAccountUserError: action.error.message,
            };

        case GET_EMAIL_LIST_SUCCESS:
            return {
                ...state,
                getEmailList: action.payload,
            };
        case GET_EMAIL_LIST_ERROR:
            return {
                ...state,
                getEmailListError: action.error.message,
            };

        case UPDATE_BCTEAM_PRODUCT_SUCCESS:
            return {
                ...state,
                updateBcteamProductAccess: action.payload,
            };
        case UPDATE_BCTEAM_PRODUCT_ERROR:
            return {
                ...state,
                updateBcteamProductAccessError: action.error.message,
            };

        case UPDATE_ACCOUNT_ACCESS_SUCCESS:
            return {
                ...state,
                updateAccountAccess: action.payload,
            };
        case UPDATE_ACCOUNT_ACCESS_ERROR:
            return {
                ...state,
                updateAccountAccessError: action.error.message,
            };

          // export csv user

        case EXPORT_CSV_FOR_USER_SUCCESS:
            return {
                ...state,
                exportCSV: action.payload,
            };
        case EXPORT_CSV_FOR_USER_ERROR:
            return {
                ...state,
                exportCSVError: action.error.message,
            };

        default:
            return state;
    }
}
