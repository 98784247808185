export const FETCH_TEMPLATE_REPORT_REQUEST = 'FETCH_TEMPLATE_REPORT_REQUEST';
export const FETCH_TEMPLATE_REPORT_SUCCESS = 'FETCH_TEMPLATE_REPORT_SUCCESS';
export const FETCH_TEMPLATE_REPORT_ERROR   = 'FETCH_TEMPLATE_REPORT_ERROR';

export const DELETE_TEMPLATE_REPORT_REQUEST = 'DELETE_TEMPLATE_REPORT_REQUEST';
export const DELETE_TEMPLATE_REPORT_SUCCESS = 'DELETE_TEMPLATE_REPORT_SUCCESS';
export const DELETE_TEMPLATE_REPORT_ERROR   = 'DELETE_TEMPLATE_REPORT_ERROR';

export const FETCH_CUSTOM_REPORT_REQUEST = 'FETCH_CUSTOM_REPORT_REQUEST';
export const FETCH_CUSTOM_REPORT_SUCCESS = 'FETCH_CUSTOM_REPORT_SUCCESS';
export const FETCH_CUSTOM_REPORT_ERROR   = 'FETCH_CUSTOM_REPORT_ERROR';

export const REPORT_UPLOAD_REQUEST = 'REPORT_UPLOAD_REQUEST';
export const REPORT_UPLOAD_SUCCESS = 'REPORT_UPLOAD_SUCCESS';
export const REPORT_UPLOAD_ERROR   = 'REPORT_UPLOAD_ERROR';

export const EMAIL_CUSTOM_USER_REQUEST = 'EMAIL_CUSTOM_USER_REQUEST';
export const EMAIL_CUSTOM_USER_SUCCESS = 'EMAIL_CUSTOM_USER_SUCCESS';
export const EMAIL_CUSTOM_USER_ERROR   = 'EMAIL_CUSTOM_USER_ERROR';

export const GET_REPORT_BY_ID_REQUEST = 'GET_REPORT_BY_ID_REQUEST';
export const GET_REPORT_BY_ID_SUCCESS = 'GET_REPORT_BY_ID_SUCCESS';
export const GET_REPORT_BY_ID_ERROR   = 'GET_REPORT_BY_ID_ERROR';