import { combineReducers } from "redux";
import postReducer from "./Pages.reducer";
import dashboardReducer from "./Dashboard.reducer";
import eventsReducer from "./Events.reducer";
import editAppReducer from "./EditApp.reducer";
import commonReducer from "./Common.reducer";
import clubhouseReducer from "./ClubhouseTimeline.reducer";
import marketing from "./Marketing.reducer";
import players from "./Users/Players.reducer";
import teams from "./Users/Teams.reducer";
import coaches from "./Users/Coaches.reducer";
import fans from "./Users/Fans.reducer";
import lessonLibrary from "./LessonLibrary.reducer";
import scoutLibrary from "./ScoutingLibrary.reducer"
import college from "./College.reducer";
import couponCode from "./Marketing/CouponCode.reducer";
import adBanner from "./Marketing/AdBanner.reducer";
import reward from "./Marketing/Reward.reducer";
import push from "./Marketing/Push.reducer";
import product from "./Product.reducer";
import holdTeam from "./HoldTeam.reducer";
import admin from "./Admin.reducer";
import storeOrder from "./StoreOrder.reducer";
import report from "./Report.reducer";
import linkRoster from "./LinkRoster.reducer";
import reportedPost from "./ReportedPost.reducer";
import league from "./League.reducer";
import super8 from "./Super8.reducer";
import tooltip from "./Tooltip.reducer";
import supportReport from "./SupportReport.reducer";
import applicantList from "./ApplicantList.reducer";
import division from "./Division.reducer";
import faq from "./Faq.reducer";
import page from "./Pages.reducer";

import login from "./Authentication/Login.reducer";
import forgotPassword from "./Authentication/ForgotPassword.reducer";
import updateProfile from "./Authentication/UpdateProfile.reducer";

import ProductMaster from "./ProductMaster.reducer";
import ProductFeature from "./ProductFeature.reducer";
import ProductPackage from "./ProductPackage.reducer";
import userTransaction from "./UserTransaction.reducer";

import uploadData from "./BaseballCloud/UploadData/UploadData.reducer";
import claimProTeam from "./BaseballCloud/ClaimProTeam.reducer";
import BCmarketing from "./BaseballCloud/Marketing.reducer";

import bcplayers from "./BaseballCloud/Users/Players.reducer";
import bcteams from "./BaseballCloud/Users/Teams.reducer";
import bccoaches from "./BaseballCloud/Users/Coaches.reducer";
import bcfans from "./BaseballCloud/Users/Fans.reducer";
import bcbasicInfo from "./BaseballCloud/Users/AddUser/BasicInfo.reducer";
import bcaddPlayer from "./BaseballCloud/Users/AddUser/AddPlayer.reducer";
import bcaddCoach from "./BaseballCloud/Users/AddUser/AddCoach.reducer";
import bccoachBulk from "./BaseballCloud/Users/BulkUpload/BulkUploadCoach.reducer";
import bcplayerBulk from "./BaseballCloud/Users/BulkUpload/BulkUploadPlayer.reducer";

import bcdashboard from "./BaseballCloud/Dashboard.reducer";

import userPermission from "./ManageAdmin/UserPermission.reducer";
import userRole from "./ManageAdmin/UserRole.reducer";
import manageAdmin from "./ManageAdmin/ManageAdmin.reducer";
import teamSetting from "./BaseballCloud/TeamSetting.reducer";

import UserEdit from "./Users/PopUp/EditProfile.reducer";
import BCUserEdit from "./BaseballCloud/Users/PopUp/EditProfile.reducer";
import spotlightContent from "./spotlight/spotlightContent.reducer";
import recapInfo from "./RecapInfo.reducer";
import company from "./Company.reducer";

import basicInfo from "./AddUser/BasicInfo.reducer";
import addPlayer from "./AddUser/AddPlayer.reducer";
import addCoach from "./AddUser/AddCoach.reducer";
import coachBulk from "./BulkUpload/BulkUploadCoach.reducer";
import playerBulk from "./BulkUpload/BulkUploadPlayer.reducer";
import sponsor from "./SponsorAdBanner.reducer";
import Permission from "./Permission.reducer";

import teamplayerranking from "./TeamPlayerRanking.reducer";
import settings from "./Settings.reducer";
import dataInput from "./DataInput.reducer";

//Scheduling
import dashboardEvent from "./Scheduling/SchedulingDashboard.reducer";
import venue from "./Scheduling/Venues.reducer";
import formate from "./Scheduling/Formate.reducer";
import bracket from "./Scheduling/Bracket.reducer";
import divisiondata from "./Scheduling/DivisionData.reducer";
import matchup from "./Scheduling/Matchup.reducer";
import grid from "./Scheduling/Grid.reducer";
import manage from "./Scheduling/Manage.reducer";
import publish from "./Scheduling/Publish.reducer";
import criteria from "./Scheduling/Criteria.reducer";

// analyzer
import templateAnalyzer from "./BaseballCloud/Analyzer/Template.reducer";
import platformAnalyzer from "./BaseballCloud/Analyzer/Platform.reducer";

import reportAnalyzer from "./BaseballCloud/Analyzer/Reports.reducer";
import userAnalyzer from "./BaseballCloud/Analyzer/Users.reducer";
import reportGenerate from "./BaseballCloud/ReportGenerate.reducer";

import statisticsData from "./BaseballCloud/DataStatistics.reducer";

import datamanagment from "./BaseballCloud/DataManagment.reducer";

import analyzepackage from "./BaseballCloud/AnalyzerPackage.reducer";
import reportUser from "./ReportUser.reducer";
import venueMaster from "./Venue.reducer";

//event + applicant (T+S+B)
import allapplicant from "./EventRegister.reducer";

import eventRule from "./EventRule.reducer";
import productData from "./ProductData.reducer";

//MLB user
import mlbUsers from "./BaseballCloud/MLBUsers/MLBUsers.reducer"

export default combineReducers({
  posts: postReducer,
  topDashboardEvents: dashboardReducer,
  topEvents: eventsReducer,
  league: league,
  super8: super8,
  settings: settings,
  editApp: editAppReducer,
  common: commonReducer,
  clubhouse: clubhouseReducer,
  couponCode: couponCode,
  adBanner: adBanner,
  reward: reward,
  push: push,
  marketing: marketing,
  players: players,
  teams: teams,
  coaches: coaches,
  fans: fans,
  lessonLibrary: lessonLibrary,
  scoutLibrary:scoutLibrary,
  college: college,
  product: product,
  report: report,
  linkRoster: linkRoster,
  reportedPost: reportedPost,
  admin: admin,
  storeOrder: storeOrder,
  login: login,
  forgotPassword: forgotPassword,
  updateProfile: updateProfile,
  tooltip: tooltip,
  supportReport: supportReport,
  holdTeam: holdTeam,
  applicantList: applicantList,
  division: division,
  faq: faq,
  page: page,
  ProductMaster: ProductMaster,
  ProductFeature: ProductFeature,
  ProductPackage: ProductPackage,
  userTransaction: userTransaction,
  uploadData: uploadData,
  claimProTeam: claimProTeam,
  BCmarketing: BCmarketing,
  bcplayers: bcplayers,
  bcteams: bcteams,
  bccoaches: bccoaches,
  bcfans: bcfans,
  bcbasicInfo: bcbasicInfo,
  bcaddPlayer: bcaddPlayer,
  bcaddCoach: bcaddCoach,
  bccoachBulk: bccoachBulk,
  bcplayerBulk: bcplayerBulk,
  bcdashboard: bcdashboard,
  userRole: userRole,
  userPermission: userPermission,
  permission: Permission,
  manageAdmin: manageAdmin,
  teamSetting: teamSetting,
  UserEdit: UserEdit,
  BCUserEdit: BCUserEdit,
  spotlightContent: spotlightContent,
  recapInfo: recapInfo,
  company: company,
  basicInfo: basicInfo,
  addPlayer: addPlayer,
  addCoach: addCoach,
  sponsor: sponsor,
  coachBulk: coachBulk,
  playerBulk: playerBulk,
  teamplayerranking: teamplayerranking,
  dataInput: dataInput,
  dashboardEvent: dashboardEvent,
  venue: venue,
  formate: formate,
  bracket: bracket,
  divisiondata: divisiondata,
  matchup: matchup,
  grid: grid,
  manage: manage,
  publish: publish,
  criteria:criteria,
  templateAnalyzer: templateAnalyzer,
  reportAnalyzer: reportAnalyzer,
  userAnalyzer: userAnalyzer,
  statisticsData: statisticsData,
  dataManagment: datamanagment,
  AnalyzePackage: analyzepackage,
  reportGenerate: reportGenerate,
  platformAnalyzer: platformAnalyzer,
  allapplicant: allapplicant,
  reportUser: reportUser,
  venueMaster: venueMaster,
  eventRule: eventRule,
  productData:productData,
  mlbUsers:mlbUsers,
});
