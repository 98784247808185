export const FETCH_ALL_EVENTS_LEAGUES_REQUEST = 'FETCH_ALL_EVENTS_LEAGUES_REQUEST';
export const FETCH_ALL_EVENTS_LEAGUES_SUCCESS = 'FETCH_ALL_EVENTS_LEAGUES_SUCCESS';
export const FETCH_ALL_EVENTS_LEAGUES_ERROR   = 'FETCH_ALL_EVENTS_LEAGUES_ERROR';

export const FETCH_ALL_LEAGUES_REQUEST = 'FETCH_ALL_LEAGUES_REQUEST';
export const FETCH_ALL_LEAGUES_SUCCESS = 'FETCH_ALL_LEAGUES_SUCCESS';
export const FETCH_ALL_LEAGUES_ERROR   = 'FETCH_ALL_LEAGUES_ERROR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR   = 'DELETE_EVENT_ERROR';

export const FETCH_APP_SIGNUP_GRAPH_REQUEST = 'FETCH_APP_SIGNUP_GRAPH_REQUEST';
export const FETCH_APP_SIGNUP_GRAPH_SUCCESS = 'FETCH_APP_SIGNUP_GRAPH_SUCCESS';
export const FETCH_APP_SIGNUP_GRAPH_ERROR   = 'FETCH_APP_SIGNUP_GRAPH_ERROR';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR   = 'UPDATE_PASSWORD_ERROR';

export const FETCH_ALL_STATE_LIST_REQUEST = 'FETCH_ALL_STATE_LIST_REQUEST';
export const FETCH_ALL_STATE_LIST_SUCCESS = 'FETCH_ALL_STATE_LIST_SUCCESS';
export const FETCH_ALL_STATE_LIST_ERROR   = 'FETCH_ALL_STATE_LIST_ERROR';

export const FETCH_APP_DC_SIGNUP_GRAPH_SUCCESS = 'FETCH_APP_DC_SIGNUP_GRAPH_SUCCESS';
export const FETCH_APP_DC_SIGNUP_GRAPH_ERROR   = 'FETCH_APP_DC_SIGNUP_GRAPH_ERROR';

export const FETCH_APP_DC_PRODUCT_GRAPH_SUCCESS = 'FETCH_APP_DC_PRODUCT_GRAPH_SUCCESS';
export const FETCH_APP_DC_PRODUCT_GRAPH_ERROR   = 'FETCH_APP_DC_PRODUCT_GRAPH_ERROR';