import {

    //GET only division

    GET_DIVISION_LIST_SUCCESS,
    GET_DIVISION_LIST_ERROR,

    CHANGE_DIVISION_LIST_SUCCESS,
    CHANGE_DIVISION_LIST_ERROR,

    //GET only pool

    GET_POOL_LIST_SUCCESS,
    GET_POOL_LIST_ERROR,

    CREATE_POOL_LIST_SUCCESS,
    CREATE_POOL_LIST_ERROR,

    //GET division and pool both

    GET_DIVISION_WISE_LIST_SUCCESS,
    GET_DIVISION_WISE_LIST_ERROR,

    CREATE_DIVISION_LIST_SUCCESS,
    CREATE_DIVISION_LIST_ERROR,

    GET_POOL_COUNT_SUCCESS,
    GET_POOL_COUNT_ERROR,

    GET_POOLWISE_LIST_SUCCESS,
    GET_POOLWISE_LIST_ERROR,

    CREATE_POOLWISE_LIST_SUCCESS,
    CREATE_POOLWISE_LIST_ERROR,


    


    GET_AUTO_GENERATE_SUCCESS,
    GET_AUTO_GENERATE_ERROR,

    GET_MANUAL_LIST_SUCCESS,
    GET_MANUAL_LIST_ERROR

} from '../../constants/Scheduling/DivisionData.const'

const initialState = {

    //GET only division

    getDivisionData: null,
    getDivisionDataError: null,

    changeDivisionData: null,
    changeDivisionDataError: null,

    //GET only pool

    getPoolData: null,
    getPoolDataError: null,

    createPoolData: null,
    createPoolDataError: null,

    //GET division and pool both

    getDivisionWiseData: null,
    getDivisionWiseDataError: null,

    createDivisionData: null,
    createDivisionDataError: null,

    getPoolCountData: null,
    getPoolCountDataError: null,

    getPoolWiseData: null,
    getPoolWiseDataError: null,

    createPoolWiseData: null,
    createPoolWiseDataError: null,

 
    //POOL

    getAutoGenerateListData: null,
    getAutoGenerateListError: null,

    getManualListData: null,
    getManualListError: null,
}

export default function (state = initialState, action) {
    switch (action.type) {

        //GET only division

        case GET_DIVISION_LIST_SUCCESS:
            return {
                ...state,
                getDivisionData: action.payload
            }
        case GET_DIVISION_LIST_ERROR:
            return {
                ...state,
                getDivisionDataError: action.error
            }

        case CHANGE_DIVISION_LIST_SUCCESS:
            return {
                ...state,
                changeDivisionData: action.payload
            }
        case CHANGE_DIVISION_LIST_ERROR:
            return {
                ...state,
                changeDivisionDataError: action.error
            }

        //GET only pool

        case GET_POOL_LIST_SUCCESS:
            return {
                ...state,
                getPoolData: action.payload
            }
        case GET_POOL_LIST_ERROR:
            return {
                ...state,
                getPoolDataError: action.error
            }

        case CREATE_POOL_LIST_SUCCESS:
            return {
                ...state,
                createPoolData: action.payload
            }
        case CREATE_POOL_LIST_ERROR:
            return {
                ...state,
                createPoolDataError: action.error
            }

        //GET division and pool both

        case GET_DIVISION_WISE_LIST_SUCCESS:
            return {
                ...state,
                getDivisionWiseData: action.payload
            }
        case GET_DIVISION_WISE_LIST_ERROR:
            return {
                ...state,
                getDivisionWiseDataError: action.error
            }

        case CREATE_DIVISION_LIST_SUCCESS:
            return {
                ...state,
                createDivisionData: action.payload
            }
        case CREATE_DIVISION_LIST_ERROR:
            return {
                ...state,
                createDivisionDataError: action.error
            }


        case GET_POOL_COUNT_SUCCESS:
            return {
                ...state,
                getPoolCountData: action.payload
            }
        case GET_POOL_COUNT_ERROR:
            return {
                ...state,
                getPoolCountDataError: action.error
            }

        case GET_POOLWISE_LIST_SUCCESS:
            return {
                ...state,
                getPoolWiseData: action.payload
            }
        case GET_POOLWISE_LIST_ERROR:
            return {
                ...state,
                getPoolWiseDataError: action.error
            }

            case CREATE_POOLWISE_LIST_SUCCESS:
                return {
                    ...state,
                    createPoolWiseData: action.payload
                }
            case CREATE_POOLWISE_LIST_ERROR:
                return {
                    ...state,
                    createPoolWiseDataError: action.error
                }

       


        case GET_AUTO_GENERATE_SUCCESS:
            return {
                ...state,
                getAutoGenerateListData: action.payload
            }
        case GET_AUTO_GENERATE_ERROR:
            return {
                ...state,
                getAutoGenerateListError: action.error
            }

        case GET_MANUAL_LIST_SUCCESS:
            return {
                ...state,
                getManualListData: action.payload
            }
        case GET_MANUAL_LIST_ERROR:
            return {
                ...state,
                getManualListError: action.error
            }
        default:
            return state;
    }
}