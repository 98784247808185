import { 
    FETCH_COLLEGE_REQUEST,
    FETCH_COLLEGE_SUCCESS, 
    FETCH_COLLEGE_ERROR,

    UPDATE_COLLEGE_REQUEST,
    UPDATE_COLLEGE_SUCCESS,
    UPDATE_COLLEGE_ERROR,

    DELETE_COLLEGE_REQUEST,
    DELETE_COLLEGE_SUCCESS,
    DELETE_COLLEGE_ERROR,

    CREATE_COLLEGE_REQUEST,
    CREATE_COLLEGE_SUCCESS,
    CREATE_COLLEGE_ERROR  
  
  } from '../constants/College.const';
  
  const initialState = {
    colleges: [],
    collegesError: null,
    collegesLoader: false,
  
    isDeleted: false,
    isDeletedError : null,
    isDeletedLoader : false,
  
    isCreated : false,
    isCreatedError : null,
    isCreatedLoader : false,
    
    isUpdated : false,
    isUpdatedError : null,
    isUpdatedLoader : false  
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET College Data
  
      case FETCH_COLLEGE_REQUEST:
        return {
          ...state,
          collegesLoader: true
        };
      case FETCH_COLLEGE_SUCCESS:
        return {
          ...state,
          colleges: action.payload,
          collegesLoader: false
        };
      case FETCH_COLLEGE_ERROR:
        return {
          ...state,
          collegesError: action.error.message,
          collegesLoader: false
        };
  
      // For CREATE College Data
  
      case CREATE_COLLEGE_REQUEST:
        return {
          ...state,
          isCreatedLoader: true
        };
      case CREATE_COLLEGE_SUCCESS:
        return {
          ...state,
          isCreated: true,
          isCreatedLoader: false
        };

      case 'RESET_CREATE_COLLEGE_SUCCESS':
        return {
          ...state,
          isCreated: false,
          isCreatedLoader: false
        };

      case CREATE_COLLEGE_ERROR:
        return {
          ...state,
          isCreatedError: action.error.message,
          isCreatedLoader: false
        };
  
      // For UPDATE College Data
  
      case UPDATE_COLLEGE_REQUEST:
        return {
          ...state,
          isUpdatedLoader: true
        };
      case UPDATE_COLLEGE_SUCCESS:
        return {
          ...state,
          isUpdated: true,
          isUpdatedLoader: false
        };
      case UPDATE_COLLEGE_ERROR:
        return {
          ...state,
          isUpdatedError: action.error.message,
          isUpdatedLoader: false
        };

        // For DELETE College Data
  
      case DELETE_COLLEGE_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_COLLEGE_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isDeletedLoader: false
        };
      case DELETE_COLLEGE_ERROR:
        return {
          ...state,
          isDeletedError: action.error.message,
          isDeletedLoader: false
        };
  
      default:
        return state;
    }
  }