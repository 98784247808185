const initialState = {
    Permission: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        // For fetch TOP EVENTS
        case "PERMISSION_UPDATE":
            return { Permission: action.payload };
        default:
            return state;
    }
}