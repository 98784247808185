export const GET_ADMINLIST_REQUEST = "GET_ADMINLIST_REQUEST"
export const GET_ADMINLIST_SUCCESS = "GET_ADMINLIST_SUCCESS"
export const GET_ADMINLIST_ERROR = "GET_ADMINLIST_ERROR"

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST"
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS"
export const CREATE_ADMIN_ERROR = "CREATE_ADMIN_ERROR"

export const GET_ADMINID_REQUEST = "GET_ADMINID_REQUEST"
export const GET_ADMINID_SUCCESS = "GET_ADMINID_SUCCESS"
export const GET_ADMINID_ERROR = "GET_ADMINID_ERROR"

export const EDIT_ADMINLIST_REQUEST = "EDIT_ADMINLIST_REQUEST"
export const EDIT_ADMINLIST_SUCCESS = "EDIT_ADMINLIST_SUCCESS"
export const EDIT_ADMINLIST_ERROR = "EDIT_ADMINLIST_ERROR"

  
export const DELETE_MANAGE_ADMIN_REQUEST = "DELETE_MANAGE_ADMIN_REQUEST"
export const DELETE_MANAGE_ADMIN_SUCCESS = "DELETE_MANAGE_ADMIN_SUCCESS"
export const DELETE_MANAGE_ADMIN_ERROR = "DELETE_MANAGE_ADMIN_ERROR"

  
export const RESET_ADMIN_PASSWORD_REQUEST = "RESET_ADMIN_PASSWORD_REQUEST"
export const RESET_ADMIN_PASSWORD_SUCCESS = "RESET_ADMIN_PASSWORD_SUCCESS"
export const RESET_ADMIN_PASSWORD_ERROR = "RESET_ADMIN_PASSWORD_ERROR"

export const COLLEGE_LIST_REQUEST = "COLLEGE_LIST_REQUEST"
export const COLLEGE_LIST_SUCCESS = "COLLEGE_LIST_SUCCESS"
export const COLLEGE_LIST_ERROR = "COLLEGE_LIST_ERROR"