import {
  GET_USEROLELIST_REQUEST,
  GET_USEROLELIST_SUCCESS,
  GET_USEROLELIST_ERROR,
  FATCH_USEROLE_REQUEST,
  FATCH_USEROLE_SUCCESS,
  FATCH_USEROLE_ERROR,
  UPDATE_USER_ROLE_ERROR,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_REQUEST,
  CREATE_USER_ROLE_ERROR,
  CREATE_USER_ROLE_REQUEST,
  CREATE_USER_ROLE_SUCCESS
} from '../../constants/ManageAdmin/UserRole.const';

const initialState = {
  userRoleListData: [],
  userRoleListError: null,

  fetchUserRoleData: null,
  fetchUserRolError: null,

  updateUserRoleData: null,
  updateUserRoleError: null,

  createUserRoleData: null,
  createUserRoleError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    //fetch user Role list
    case GET_USEROLELIST_SUCCESS:
      return {
        ...state,
        userRoleListData: action.payload
      };
    case GET_USEROLELIST_ERROR:
      return {
        ...state,
        userRoleListError: action.error
      };

    //fetch user Role by id
    case FATCH_USEROLE_SUCCESS:
      return {
        ...state,
        fetchUserRoleData: action.payload
      };
    case FATCH_USEROLE_ERROR:
      return {
        ...state,
        fetchUserRolError: action.error
      };

    //update USer Role
    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updateUserRoleData: action.payload
      };
    case UPDATE_USER_ROLE_ERROR:
      return {
        ...state,
        updateUserRoleError: action.error
      };

    //create USer Role
    case CREATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        createUserRoleData: action.payload
      };
    case CREATE_USER_ROLE_ERROR:
      return {
        ...state,
        createUserRoleError: action.error
      };
    default:
      return state;
  }
}