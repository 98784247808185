export const FETCH_PUSH_REQUEST = 'FETCH_PUSH_REQUEST';
export const FETCH_PUSH_SUCCESS = 'FETCH_PUSH_SUCCESS';
export const FETCH_PUSH_ERROR   = 'FETCH_PUSH_ERROR';

export const UPDATE_PUSH_REQUEST = 'UPDATE_PUSH_REQUEST';
export const UPDATE_PUSH_SUCCESS = 'UPDATE_PUSH_SUCCESS';
export const UPDATE_PUSH_ERROR   = 'UPDATE_PUSH_ERROR';

export const CREATE_PUSH_REQUEST = 'CREATE_PUSH_REQUEST';
export const CREATE_PUSH_SUCCESS = 'CREATE_PUSH_SUCCESS';
export const CREATE_PUSH_ERROR   = 'CREATE_PUSH_ERROR';

export const DELETE_PUSH_REQUEST = 'DELETE_PUSH_REQUEST';
export const DELETE_PUSH_SUCCESS = 'DELETE_PUSH_SUCCESS';
export const DELETE_PUSH_ERROR   = 'DELETE_PUSH_ERROR';