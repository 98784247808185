import { 
    FETCH_COUPON_CODE_REQUEST, 
    FETCH_COUPON_CODE_SUCCESS, 
    FETCH_COUPON_CODE_ERROR,

    FETCH_COUPON_CODE_LIST_REQUEST, 
    FETCH_COUPON_CODE_LIST_SUCCESS, 
    FETCH_COUPON_CODE_LIST_ERROR,

    FETCH_COUPON_CODE_BY_ID_REQUEST, 
    FETCH_COUPON_CODE_BY_ID_SUCCESS, 
    FETCH_COUPON_CODE_BY_ID_ERROR,

    CREATE_COUPON_CODE_REQUEST,
    CREATE_COUPON_CODE_SUCCESS,
    CREATE_COUPON_CODE_ERROR,

    UPDATE_COUPON_CODE_REQUEST,
    UPDATE_COUPON_CODE_SUCCESS,
    UPDATE_COUPON_CODE_ERROR,

    DELETE_COUPON_CODE_REQUEST,
    DELETE_COUPON_CODE_SUCCESS,
    DELETE_COUPON_CODE_ERROR

} from '../../constants/Marketing/CouponCode.const';
  
  const initialState = {
    couponCodes: [],
    couponCodesError: null,
    couponCodesLoader: false,
    isCouponLoaded: false,

    couponCodeList: [],
    couponCodeListError: null,
    couponCodeListLoader: false,
    isCouponCodeListLoaded: false,


    couponCode: [],
    couponCodeError: null,
    couponCodeLoader: false,

    isCouponCodeCreated: false,
    isCouponCodeCreatedError: null,
    isCouponCodeCreatedLoader: false,

    isCouponCodeUpdated: false,
    isCouponCodeUpdatedError: null,
    isCouponCodeUpdatedLoader: false,

    isCouponCodeDeleted: false,
    isCouponCodeDeletedError: null,
    isCouponCodeDeletedLoader: false,

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Coupon Code Data
  
      case FETCH_COUPON_CODE_REQUEST:
        return {
          ...state,
          couponCodesLoader: true
        };
      case FETCH_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponLoaded: true,
          couponCodes: action.payload,
          couponCodesLoader: false
        };
      case FETCH_COUPON_CODE_ERROR:
        return {
          ...state,
          couponCodesError: action.error.message,
          couponCodesLoader: false
        };

      // For GET Coupon Code List

      case FETCH_COUPON_CODE_LIST_REQUEST:
        return {
          ...state,
          couponCodeListLoader: true
        };
      case FETCH_COUPON_CODE_LIST_SUCCESS:
        return {
          ...state,
          isCouponCodeListLoaded: true,
          couponCodeList: action.payload,
          couponCodeListLoader: false
        };
      case FETCH_COUPON_CODE_LIST_ERROR:
        return {
          ...state,
          couponCodeListError: action.error.message,
          couponCodeListLoader: false
        };

      // For GET Coupon Code By Id

      case FETCH_COUPON_CODE_BY_ID_REQUEST:
        return {
          ...state,
          couponCodeLoader: true
        };
      case FETCH_COUPON_CODE_BY_ID_SUCCESS:
        return {
          ...state,
          couponCode: action.payload,
          couponCodeLoader: false
        };
      case FETCH_COUPON_CODE_BY_ID_ERROR:
        return {
          ...state,
          couponCodeError: action.error.message,
          couponCodeLoader: false
        };

      // For CREATE Coupon Code Data
  
      case CREATE_COUPON_CODE_REQUEST:
        return {
          ...state,
          isCouponCodeCreatedLoader: true
        };
      case CREATE_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponCodeCreated: true,
          isCouponCodeCreatedLoader: false
        };
      case CREATE_COUPON_CODE_ERROR:
        return {
          ...state,
          isCouponCodeCreatedError: action.error.message,
          isCouponCodeCreatedLoader: false
        };

      // For UPDATE Coupon Code Data

      case UPDATE_COUPON_CODE_REQUEST:
        return {
          ...state,
          isCouponCodeUpdatedLoader: true
        };
      case UPDATE_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponCodeUpdated: true,
          isCouponCodeUpdatedLoader: false
        };
      case UPDATE_COUPON_CODE_ERROR:
        return {
          ...state,
          isCouponCodeUpdatedError: action.error.message,
          isCouponCodeUpdatedLoader: false
        };


      // For DELETE Coupon Code Data

      case DELETE_COUPON_CODE_REQUEST:
        return {
          ...state,
          isCouponCodeDeletedLoader: true
        };
      case DELETE_COUPON_CODE_SUCCESS:
        return {
          ...state,
          isCouponCodeDeleted: true,
          isCouponCodeDeletedLoader: false
        };
      case DELETE_COUPON_CODE_ERROR:
        return {
          ...state,
          isCouponCodeDeletedError: action.error.message,
          isCouponCodeDeletedLoader: false
        };
  
      default:
        return state;
    }
  }