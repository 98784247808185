export const GET_PRODUCT_MASTER_LIST_SUCCESS = "GET_PRODUCT_MASTER_LIST_SUCCESS";
export const GET_PRODUCT_MASTER_LIST_ERROR = "GET_PRODUCT_MASTER_LIST_ERROR";
export const GET_PRODUCT_MASTER_LIST_REQUEST = "GET_PRODUCT_MASTER_LIST_REQUEST";

export const CREATE_PRODUCT_MASTER_SUCCESS = "CREATE_PRODUCT_MASTER_SUCCESS";
export const CREATE_PRODUCT_MASTER_ERROR = "CREATE_PRODUCT_MASTER_ERROR";
export const CREATE_PRODUCT_MASTER_REQUEST = "CREATE_PRODUCT_MASTER_REQUEST";

export const DELETE_PRODUCT_MASTER_SUCCESS = "DELETE_PRODUCT_MASTER_SUCCESS";
export const DELETE_PRODUCT_MASTER_ERROR = "DELETE_PRODUCT_MASTER_ERROR";
export const DELETE_PRODUCT_MASTER_REQUEST = "DELETE_PRODUCT_MASTER_REQUEST";

export const FETCH_PRODUCT_MASTER_SUCCESS = "FETCH_PRODUCT_MASTER_SUCCESS";
export const FETCH_PRODUCT_MASTER_ERROR = "FETCH_PRODUCT_MASTER_ERROR";
export const FETCH_PRODUCT_MASTER_REQUEST = "FETCH_PRODUCT_MASTER_REQUEST";

export const UPDATE_PRODUCT_MASTER_SUCCESS = "UPDATE_PRODUCT_MASTER_SUCCESS";
export const UPDATE_PRODUCT_MASTER_ERROR = "UPDATE_PRODUCT_MASTER_ERROR";
export const UPDATE_PRODUCT_MASTER_REQUEST = "UPDATE_PRODUCT_MASTER_REQUEST";

export const FILEUPLOAD_REQUEST = 'FILEUPLOAD_REQUEST';
export const FILEUPLOAD_SUCCESS = 'FILEUPLOAD_SUCCESS';
export const FILEUPLOAD_ERROR   = 'FILEUPLOAD_ERROR';
