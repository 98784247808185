
//GET round robin list

export const GET_AUTO_LIST_SUCCESS = "GET_AUTO_LIST_SUCCESS";
export const GET_AUTO_LIST_ERROR = "GET_AUTO_LIST_ERROR";

export const CREATE_MATCHUP_SUCCESS = "CREATE_MATCHUP_SUCCESS";
export const CREATE_MATCHUP_ERROR = "CREATE_MATCHUP_ERROR";


//single elimination

export const GET_SINGLE_AUTO_LIST_SUCCESS = "GET_SINGLE_AUTO_LIST_SUCCESS";
export const GET_SINGLE_AUTO_LIST_ERROR = "GET_SINGLE_AUTO_LIST_ERROR";

export const CREATE_MATCHUP_SINGLE_SUCCESS = "CREATE_MATCHUP_SINGLE_SUCCESS";
export const CREATE_MATCHUP_SINGLE_ERROR = "CREATE_MATCHUP_SINGLE_ERROR";

export const GET_SINGLE_MANUAL_LIST_SUCCESS = "GET_SINGLE_MANUAL_LIST_SUCCESS"

//division and pool

export const GET_BOTH_AUTO_LIST_SUCCESS = "GET_BOTH_AUTO_LIST_SUCCESS";
export const GET_BOTH_AUTO_LIST_ERROR = "GET_BOTH_AUTO_LIST_ERROR";

export const CREATE_MATCHUP_BOTH_SUCCESS = "CREATE_MATCHUP_BOTH_SUCCESS";
export const CREATE_MATCHUP_BOTH_ERROR = "CREATE_MATCHUP_BOTH_ERROR";