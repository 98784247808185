export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR   = 'FETCH_USERS_ERROR';

export const MANAGE_CREDIT_REQUEST = 'MANAGE_CREDIT_REQUEST';
export const MANAGE_CREDIT_SUCCESS = 'MANAGE_CREDIT_SUCCESS';
export const MANAGE_CREDIT_ERROR   = 'MANAGE_CREDIT_ERROR';

export const UPDATE_MANAGE_CREDIT_REQUEST = 'UPDATE_MANAGE_CREDIT_REQUEST';
export const UPDATE_MANAGE_CREDIT_SUCCESS = 'UPDATE_MANAGE_CREDIT_SUCCESS';
export const UPDATE_MANAGE_CREDIT_ERROR   = 'UPDATE_MANAGE_CREDIT_ERROR';

export const PLAYER_MANAGE_CREDIT_REQUEST = 'PLAYER_MANAGE_CREDIT_REQUEST';
export const PLAYER_MANAGE_CREDIT_SUCCESS = 'PLAYER_MANAGE_CREDIT_SUCCESS';
export const PLAYER_MANAGE_CREDIT_ERROR   = 'PLAYER_MANAGE_CREDIT_ERROR';

export const COACH_MANAGE_CREDIT_REQUEST = 'COACH_MANAGE_CREDIT_REQUEST';
export const COACH_MANAGE_CREDIT_SUCCESS = 'COACH_MANAGE_CREDIT_SUCCESS';
export const COACH_MANAGE_CREDIT_ERROR   = 'COACH_MANAGE_CREDIT_ERROR';

export const GET_USER_TRANSACTION_REQUEST = 'GET_USER_TRANSACTION_REQUEST';
export const GET_USER_TRANSACTION_SUCCESS = 'GET_USER_TRANSACTION_SUCCESS';
export const GET_USER_TRANSACTION_ERROR   = 'GET_USER_TRANSACTION_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR   = 'DELETE_USER_ERROR';

export const SEND_USER_NOTIFICATION_REQUEST = 'SEND_USER_NOTIFICATION_REQUEST';
export const SEND_USER_NOTIFICATION_SUCCESS = 'SEND_USER_NOTIFICATION_SUCCESS';
export const SEND_USER_NOTIFICATION_ERROR   = 'SEND_USER_NOTIFICATION_ERROR';