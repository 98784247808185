import {
    LIST_PLATFORM_SUCCESS,
    LIST_PLATFORM_ERROR
} from "../../../constants/BaseballCloud/Analyzer/Platform.const";

const initialState = {
    platformList: null,
    platformListError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // get template list for analyzer
        case LIST_PLATFORM_SUCCESS:
            return {
                ...state,
                platformList: action.payload,
            };

        case LIST_PLATFORM_ERROR:
            return {
                ...state,
                platformListError: action.error,
            };

        default:
            return state;
    }
}
