export const FETCH_REWARDS_REQUEST = 'FETCH_REWARDS_REQUEST';
export const FETCH_REWARDS_SUCCESS = 'FETCH_REWARDS_SUCCESS';
export const FETCH_REWARDS_ERROR   = 'FETCH_REWARDS_ERROR';

export const FETCH_REWARDS_NAMES_REQUEST = 'FETCH_REWARDS_NAMES_REQUEST';
export const FETCH_REWARDS_NAMES_SUCCESS = 'FETCH_REWARDS_NAMES_SUCCESS';
export const FETCH_REWARDS_NAMES_ERROR   = 'FETCH_REWARDS_NAMES_ERROR';

export const UPDATE_REWARD_REQUEST = 'UPDATE_REWARD_REQUEST';
export const UPDATE_REWARD_SUCCESS = 'UPDATE_REWARD_SUCCESS';
export const UPDATE_REWARD_ERROR   = 'UPDATE_REWARD_ERROR';

export const CREATE_REWARD_REQUEST = 'CREATE_REWARD_REQUEST';
export const CREATE_REWARD_SUCCESS = 'CREATE_REWARD_SUCCESS';
export const CREATE_REWARD_ERROR   = 'CREATE_REWARD_ERROR';

export const DELETE_REWARD_REQUEST = 'DELETE_REWARD_REQUEST';
export const DELETE_REWARD_SUCCESS = 'DELETE_REWARD_SUCCESS';
export const DELETE_REWARD_ERROR   = 'DELETE_REWARD_ERROR';