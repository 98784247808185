import {
  FETCH_APPLICANT_LIST_REQUEST,
  FETCH_APPLICANT_LIST_SUCCESS,
  FETCH_APPLICANT_LIST_ERROR,

  ADD_TEAM_EVENT_SUCCESS,
  ADD_TEAM_EVENT_ERROR,

  EDIT_TEAM_EVENT_SUCCESS,
  EDIT_TEAM_EVENT_ERROR,

  SHOWCASE_PLAYER_SUCCESS,
  SHOWCASE_PLAYER_ERROR,

  SEND_MAIL_PAYMENT_SUCCESS,
  SEND_MAIL_PAYMENT_ERROR,

  INVITE_TEAM_EVENT_SUCCESS,
  INVITE_TEAM_EVENT_ERROR,

  REQUEST_TO_PAY_SUCCESS,
  REQUEST_TO_PAY_ERROR,

  REMOVE_TEAM_SUCCESS,
  REMOVE_TEAM_ERROR,

  ADD_ROSTER_PLAYER_SUCCESS,
  ADD_ROSTER_PLAYER_ERROR,

  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_ERROR,

  SEND_NOTIFY_EVENT_SUCCESS,
  SEND_NOTIFY_EVENT_ERROR,

  EVENT_EXPORT_CSV_SUCCESS,
  EVENT_EXPORT_CSV_ERROR,

  ACCEPT_REQUEST_SUCCESS,
  ACCEPT_REQUEST_ERROR,

  ROSTER_EDIT_SUCCESS,
  ROSTER_EDIT_ERROR,

  EXPORT_3RD_PARTY_CSV_SUCCESS,
  EXPORT_3RD_PARTY_CSV_ERROR,

  PERMANENT_DELETE_SUCCESS,
  PERMANENT_DELETE_ERROR,
  EDIT_NUMBER_SUCCESS,
  EDIT_NUMBER_ERROR
} from '../constants/ApplicantList.const';

const initialState = {
  applicantList: [],
  applicantListError: null,
  applicantListLoader: false,
  isApplicantListLoaded: false,

  addTeamEvent: null,
  addTeamEventError: null,

  editTeamEvent: null,
  editTeamEventError: null,

  inviteTeamEvent: null,
  inviteTeamEventError: null,

  playerList: [],
  playerListError: null,

  sendMailPayment: null,
  sendMailPaymentError: null,

  requestToPay: null,
  requestToPayError: null,

  removeTeam: null,
  removeTeamError: null,

  addRosterPlayer: null,
  addRosterPlayerError: null,

  addPayment: null,
  addPaymentError: null,

  sendNotifyEvent: null,
  sendNotifyEventError: null,

  exportCSVData: null,
  exportCSVError: null,

  acceptRequestData: null,
  acceptRequestError: null,

  rosterEditData: null,
  rosterEditError: null,

  export3rdPartyCSVData: null,
  export3rdPartyCSVError: null,

  permanentDeleteData: null,
  permanentDeleteError: null,

  editNumberResponse: null,
  editNumberError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case FETCH_APPLICANT_LIST_REQUEST:
      return {
        ...state,
        applicantListLoader: true
      };
    case FETCH_APPLICANT_LIST_SUCCESS:
      return {
        ...state,
        applicantList: action.payload,
        applicantListLoader: false,
        isApplicantListLoaded: true
      };
    case FETCH_APPLICANT_LIST_ERROR:
      return {
        ...state,
        applicantListError: action.error.message,
        applicantListLoader: false
      };

    //add team registration in event
    case ADD_TEAM_EVENT_SUCCESS:
      return {
        ...state,
        addTeamEvent: action.payload,
      }

    case ADD_TEAM_EVENT_ERROR:
      return {
        ...state,
        addTeamEventError: action.error,
      }

    //invite team registration in event
    case INVITE_TEAM_EVENT_SUCCESS:
      return {
        ...state,
        inviteTeamEvent: action.payload,
      }

    case INVITE_TEAM_EVENT_ERROR:
      return {
        ...state,
        inviteTeamEventError: action.error,
      }


    //edit team registration in event
    case EDIT_TEAM_EVENT_SUCCESS:
      return {
        ...state,
        editTeamEvent: action.payload,
      }

    case EDIT_TEAM_EVENT_ERROR:
      return {
        ...state,
        editTeamEventError: action.error,
      }


    //add player list in event
    case SHOWCASE_PLAYER_SUCCESS:
      return {
        ...state,
        playerList: action.payload,
      }

    case SHOWCASE_PLAYER_ERROR:
      return {
        ...state,
        playerListError: action.error,
      }


    //send mail for  payment
    case SEND_MAIL_PAYMENT_SUCCESS:
      return {
        ...state,
        sendMailPayment: action.payload,
      }

    case SEND_MAIL_PAYMENT_ERROR:
      return {
        ...state,
        sendMailPaymentError: action.error,
      }


    //request to pay
    case REQUEST_TO_PAY_SUCCESS:
      return {
        ...state,
        requestToPay: action.payload,
      }

    case REQUEST_TO_PAY_ERROR:
      return {
        ...state,
        requestToPayError: action.error,
      }


    //remove team
    case REMOVE_TEAM_SUCCESS:
      return {
        ...state,
        removeTeam: action.payload,
      }

    case REMOVE_TEAM_ERROR:
      return {
        ...state,
        removeTeamError: action.error,
      }

    // Add player in Roster
    case ADD_ROSTER_PLAYER_SUCCESS:
      return {
        ...state,
        addRosterPlayer: action.payload,
      }

    case ADD_ROSTER_PLAYER_ERROR:
      return {
        ...state,
        addRosterPlayerError: action.error,
      }


    // Add Payment
    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        addPayment: action.payload,
      }

    case ADD_PAYMENT_ERROR:
      return {
        ...state,
        addPaymentError: action.error,
      }

    // send notifiction via event applicant list
    case SEND_NOTIFY_EVENT_SUCCESS:
      return {
        ...state,
        sendNotifyEvent: action.payload,
      }

    case SEND_NOTIFY_EVENT_ERROR:
      return {
        ...state,
        sendNotifyEventError: action.error,
      }

    // export CSV applicant list
    case EVENT_EXPORT_CSV_SUCCESS:
      return {
        ...state,
        exportCSVData: action.payload,
      }

    case EVENT_EXPORT_CSV_ERROR:
      return {
        ...state,
        exportCSVError: action.error,
      }

    //accept request for event register
    case ACCEPT_REQUEST_SUCCESS:
      return {
        ...state,
        acceptRequestData: action.payload,
      }

    case ACCEPT_REQUEST_ERROR:
      return {
        ...state,
        acceptRequestError: action.error,
      }

    //edit roster name 
    case ROSTER_EDIT_SUCCESS:
      return {
        ...state,
        rosterEditData: action.payload,
      }

    case ROSTER_EDIT_ERROR:
      return {
        ...state,
        rosterEditError: action.error,
      }

    // export 3rd party CSV applicant list
    case EXPORT_3RD_PARTY_CSV_SUCCESS:
      return {
        ...state,
        export3rdPartyCSVData: action.payload,
      }

    case EXPORT_3RD_PARTY_CSV_ERROR:
      return {
        ...state,
        export3rdPartyCSVError: action.error,
      }

    // permanent delete record from applicant list when leave event in front side
    case PERMANENT_DELETE_SUCCESS:
      return {
        ...state,
        permanentDeleteData: action.payload,
      }

    case PERMANENT_DELETE_ERROR:
      return {
        ...state,
        permanentDeleteError: action.error,
      }

    // Edit Number
    case EDIT_NUMBER_SUCCESS:
      return {
        ...state,
        editNumberResponse: action.payload,
      }

    case EDIT_NUMBER_ERROR:
      return {
        ...state,
        editNumberError: action.error,
      }

    default:
      return state;
  }
}