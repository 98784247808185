import { 
  IMAGE_UPLOAD_REQUEST, 
  IMAGE_UPLOAD_SUCCESS, 
  IMAGE_UPLOAD_ERROR,

  IMAGE_DELETE_REQUEST, 
  IMAGE_DELETE_SUCCESS, 
  IMAGE_DELETE_ERROR,

  IMAGES_UPLOAD_REQUEST, 
  IMAGES_UPLOAD_SUCCESS, 
  IMAGES_UPLOAD_ERROR,

  BANNER_UPLOAD_REQUEST,
  BANNER_UPLOAD_SUCCESS,
  BANNER_UPLOAD_ERROR,

  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,

  FETCH_DS_COMPANIES_REQUEST,
  FETCH_DS_COMPANIES_SUCCESS,
  FETCH_DS_COMPANIES_ERROR,
  
  FETCH_PRODUCT_CATEGORY_REQUEST,
  FETCH_PRODUCT_CATEGORY_SUCCESS,
  FETCH_PRODUCT_CATEGORY_ERROR,

  PICTURE_UPLOAD_REQUEST,
  PICTURE_UPLOAD_SUCCESS,
  PICTURE_UPLOAD_ERROR,

  ZIPCODE_REQUEST,
  ZIPCODE_SUCCESS,
  ZIPCODE_ERROR
} from '../constants/Common.const';

const initialState = {
  image: null,
  imageError: null,
  imageLoader: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,

  images: null,
  imagesError: null,
  imagesLoader: false,

  companies: [],
  companiesError: null,
  companiesLoader: false,

  dsCompanies: [],
  dsCompaniesError: null,
  dsCompaniesLoader: false,

  products: [],
  productsError: null,
  productsLoader: false,

  bannerImage: null,
  bannerImageError: null,
  bannerImageLoader: false,

  picture: null,
  pictureError: null,
  pictureLoader: false,

  zipcodeData: null,
  zipcodeError: null,

};

export default function(state = initialState, action) {
  switch (action.type) {

    // Upload Event logo

    case IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        imageLoader: true
      };
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        image: action.payload,
        imageLoader: false
      };
    case IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        imageError: action.error.message,
        imageLoader: false
      };

      case PICTURE_UPLOAD_REQUEST:
      return {
        ...state,
        pictureLoader: true
      };
    case PICTURE_UPLOAD_SUCCESS:
      return {
        ...state,
        picture: action.payload,
        pictureLoader: false
      };
    case PICTURE_UPLOAD_ERROR:
      return {
        ...state,
        pictureError: action.error.message,
        pictureLoader: false
      };

    // Upload Event logo

    case IMAGE_DELETE_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case IMAGE_DELETE_ERROR:
      return {
        ...state,
        isDeletedError: action.error,
        isDeletedLoader: false
      };

     // Upload Hotel Images

    case IMAGES_UPLOAD_REQUEST:
      return {
        ...state,
        imagesLoader: true
      };
    case IMAGES_UPLOAD_SUCCESS:
      return {
        ...state,
        images: action.payload,
        imagesLoader: false
      };
    case IMAGES_UPLOAD_ERROR:
      return {
        ...state,
        imagesError: action.error.message,
        imagesLoader: false
      };

     // Upload Event Banner

     case BANNER_UPLOAD_REQUEST:
      return {
        ...state,
        bannerImageLoader: true
      };
    case BANNER_UPLOAD_SUCCESS:
      return {
        ...state,
        bannerImage: action.payload,
        bannerImageLoader: false
      };
    case BANNER_UPLOAD_ERROR:
      return {
        ...state,
        bannerImageError: action.error.message,
        bannerImageLoader: false
      };

      // Get Companies

     case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        companiesLoader: true
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        companiesLoader: false
      };
    case FETCH_COMPANIES_ERROR:
      return {
        ...state,
        companiesError: action.error.message,
        companiesLoader: false
      };

    // Get Companies

     case FETCH_DS_COMPANIES_REQUEST:
      return {
        ...state,
        dsCompaniesLoader: true
      };
    case FETCH_DS_COMPANIES_SUCCESS:
      return {
        ...state,
        dsCompanies: action.payload,
        dsCompaniesLoader: false
      };
    case FETCH_DS_COMPANIES_ERROR:
      return {
        ...state,
        dsCompaniesError: action.error.message,
        dsCompaniesLoader: false
      };

      // Get Product Categories

     case FETCH_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        productsLoader: true
      };
    case FETCH_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productsLoader: false
      };
    case FETCH_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        productsError: action.error.message,
        productsLoader: false
      };
    
    // zipcode
    case ZIPCODE_SUCCESS:
      return {
        ...state,
        zipcodeData: action.payload,
      };
    case ZIPCODE_ERROR:
      return {
        ...state,
        zipcodeError: action.error,
      };

    default:
      return state;
  }
}