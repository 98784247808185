import { 
    FETCH_REPORTED_POST_REQUEST,
    FETCH_REPORTED_POST_SUCCESS, 
    FETCH_REPORTED_POST_ERROR,

    DELETE_REPORTED_POST_REQUEST,
    DELETE_REPORTED_POST_SUCCESS,
    DELETE_REPORTED_POST_ERROR  
  } from '../constants/ReportedPost.const';
  
  const initialState = {
    posts: [],
    postsError: null,
    postsLoader: false,
    isPostsLoaded: false,

    isDeleted: false,
    isDeletedError : null,
    isDeletedLoader : false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Product Data
  
      case FETCH_REPORTED_POST_REQUEST:
        return {
          ...state,
          postsLoader: true
        };
      case FETCH_REPORTED_POST_SUCCESS:
        return {
          ...state,
          posts: action.payload,
          postsLoader: false,
          isPostsLoaded: true
        };
      case FETCH_REPORTED_POST_ERROR:
        return {
          ...state,
          postsError: action.error.message,
          postsLoader: false
        };

      // For remove reported Post

      case DELETE_REPORTED_POST_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_REPORTED_POST_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isDeletedLoader: false
        };
      case DELETE_REPORTED_POST_ERROR:
        return {
          ...state,
          isDeletedError: action.error,
          isDeletedLoader: false
        };

      default:
        return state;
    }
  }