import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  MANAGE_CREDIT_REQUEST,
  MANAGE_CREDIT_SUCCESS,
  MANAGE_CREDIT_ERROR,
  UPDATE_MANAGE_CREDIT_REQUEST,
  UPDATE_MANAGE_CREDIT_SUCCESS,
  UPDATE_MANAGE_CREDIT_ERROR,
  PLAYER_MANAGE_CREDIT_REQUEST,
  PLAYER_MANAGE_CREDIT_SUCCESS,
  PLAYER_MANAGE_CREDIT_ERROR,
  COACH_MANAGE_CREDIT_REQUEST,
  COACH_MANAGE_CREDIT_SUCCESS,
  COACH_MANAGE_CREDIT_ERROR,
  GET_USER_TRANSACTION_REQUEST,
  GET_USER_TRANSACTION_SUCCESS,
  GET_USER_TRANSACTION_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  SEND_USER_NOTIFICATION_REQUEST,
  SEND_USER_NOTIFICATION_SUCCESS,
  SEND_USER_NOTIFICATION_ERROR,
} from "../../../constants/BaseballCloud/Analyzer/Users.const";

const initialState = {
  usersData: [],
  usersError: null,
  usersLoader: false,
  isusersLoaded: false,

  managecredit: [],
  managecreditError: null,
  managecreditLoader: false,
  isManagecreditLoaded: false,

  updatecredit: [],
  updatecreditError: null,
  updatecreditLoader: false,
  isUpdatecreditLoaded: false,

  playercredit: [],
  playercreditError: null,
  playercreditLoader: false,
  isPlayercreditLoaded: false,

  coachcredit: [],
  coachcreditError: null,
  coachcreditLoader: false,
  isCoachcreditLoaded: false,

  transactionData: [],
  transactionError: null,
  transactionLoader: false,
  isTransactionLoaded: false,

  deletedUser: null,
  DeletedUserError: null,
  isDeletedUserLoader: false,

  notificationData: [],
  notificationError: null,
  notificationLoader: false,
  isNotificationLoaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // get users report data in report analyzer
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        usersLoader: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        usersData: action.payload,
        usersLoader: false,
        isusersLoaded: true,
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        usersError: action.error.message,
        usersLoader: false,
      };

    // manage credit for user
    case MANAGE_CREDIT_REQUEST:
      return {
        ...state,
        managecreditLoader: true,
      };
    case MANAGE_CREDIT_SUCCESS:
      return {
        ...state,
        managecredit: Object.assign({}, action.payload),
        managecreditLoader: false,
        isManagecreditLoaded: true,
      };
    case MANAGE_CREDIT_ERROR:
      return {
        ...state,
        managecreditError: action.error.message,
        managecreditLoader: false,
      };

    // Update(EDIT) manage credit for user
    case UPDATE_MANAGE_CREDIT_REQUEST:
      return {
        ...state,
        updatecreditLoader: true,
      };
    case UPDATE_MANAGE_CREDIT_SUCCESS:
      return {
        ...state,
        updatecredit: action.payload,
        updatecreditLoader: false,
        isUpdatecreditLoaded: true,
      };
    case UPDATE_MANAGE_CREDIT_ERROR:
      return {
        ...state,
        updatecreditError: action.error.message,
        updatecreditLoader: false,
      };

    case PLAYER_MANAGE_CREDIT_REQUEST:
      return {
        ...state,
        playercreditLoader: true,
      };
    case PLAYER_MANAGE_CREDIT_SUCCESS:
      return {
        ...state,
        playercredit: action.payload,
        playercreditLoader: false,
        isPlayercreditLoaded: true,
      };
    case PLAYER_MANAGE_CREDIT_ERROR:
      return {
        ...state,
        playercreditError: action.error.message,
        playercreditLoader: false,
      };

    case COACH_MANAGE_CREDIT_REQUEST:
      return {
        ...state,
        coachcreditLoader: true,
      };
    case COACH_MANAGE_CREDIT_SUCCESS:
      return {
        ...state,
        coachcredit: action.payload,
        coachcreditLoader: false,
        isCoachcreditLoaded: true,
      };
    case COACH_MANAGE_CREDIT_ERROR:
      return {
        ...state,
        coachcreditError: action.error.message,
        coachcreditLoader: false,
      };

    // get transaction of user
    case GET_USER_TRANSACTION_REQUEST:
      return {
        ...state,
        transactionLoader: true,
      };
    case GET_USER_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionData: action.payload,
        transactionLoader: false,
        isTransactionLoaded: true,
      };
    case GET_USER_TRANSACTION_ERROR:
      return {
        ...state,
        transactionError: action.error.message,
        transactionLoader: false,
      };

    // For delete user data

    case DELETE_USER_REQUEST:
      return {
        ...state,
        isDeletedUserLoader: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deletedUser: action.payload,
        isDeletedUserLoader: false,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        deletedUserError: action.error,
        isDeletedUserLoader: false,
      };

    // send user notification
    case SEND_USER_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationLoader: true,
      };
    case SEND_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationData: action.payload,
        notificationLoader: false,
        isNotificationLoaded: true,
      };
    case SEND_USER_NOTIFICATION_ERROR:
      return {
        ...state,
        notificationError: action.error,
        notificationLoader: false,
      };

    default:
      return state;
  }
}
