export const FETCH_SUPPORT_REPORT_REQUEST = 'FETCH_SUPPORT_REPORT_REQUEST';
export const FETCH_SUPPORT_REPORT_SUCCESS = 'FETCH_SUPPORT_REPORT_SUCCESS';
export const FETCH_SUPPORT_REPORT_ERROR   = 'FETCH_SUPPORT_REPORT_ERROR';

export const FETCH_BULLPEN_REPORT_REQUEST = 'FETCH_BULLPEN_REPORT_REQUEST';
export const FETCH_BULLPEN_REPORT_SUCCESS = 'FETCH_BULLPEN_REPORT_SUCCESS';
export const FETCH_BULLPEN_REPORT_ERROR   = 'FETCH_BULLPEN_REPORT_ERROR';

export const FETCH_BULLPEN102_REPORT_REQUEST = 'FETCH_BULLPEN102_REPORT_REQUEST';
export const FETCH_BULLPEN102_REPORT_SUCCESS = 'FETCH_BULLPEN102_REPORT_SUCCESS';
export const FETCH_BULLPEN102_REPORT_ERROR   = 'FETCH_BULLPEN102_REPORT_ERROR';

export const FETCH_BULLPEN103_REPORT_REQUEST = 'FETCH_BULLPEN103_REPORT_REQUEST';
export const FETCH_BULLPEN103_REPORT_SUCCESS = 'FETCH_BULLPEN103_REPORT_SUCCESS';
export const FETCH_BULLPEN103_REPORT_ERROR   = 'FETCH_BULLPEN103_REPORT_ERROR';

export const FETCH_PITCHING_GAME_REPORT_REQUEST = 'FETCH_PITCHING_GAME_REPORT_REQUEST';
export const FETCH_PITCHING_GAME_REPORT_SUCCESS = 'FETCH_PITCHING_GAME_REPORT_SUCCESS';
export const FETCH_PITCHING_GAME_REPORT_ERROR   = 'FETCH_PITCHING_GAME_REPORT_ERROR';

export const FETCH_PITCHING_GAME202_REPORT_REQUEST = 'FETCH_PITCHING_GAME202_REPORT_REQUEST';
export const FETCH_PITCHING_GAME202_REPORT_SUCCESS = 'FETCH_PITCHING_GAME202_REPORT_SUCCESS';
export const FETCH_PITCHING_GAME202_REPORT_ERROR   = 'FETCH_PITCHING_GAME202_REPORT_ERROR';

export const FETCH_PITCHING_GAME203_REPORT_REQUEST = 'FETCH_PITCHING_GAME203_REPORT_REQUEST';
export const FETCH_PITCHING_GAME203_REPORT_SUCCESS = 'FETCH_PITCHING_GAME203_REPORT_SUCCESS';
export const FETCH_PITCHING_GAME203_REPORT_ERROR   = 'FETCH_PITCHING_GAME203_REPORT_ERROR';

export const FETCH_PITCHING_GAME204_REPORT_REQUEST = 'FETCH_PITCHING_GAME204_REPORT_REQUEST';
export const FETCH_PITCHING_GAME204_REPORT_SUCCESS = 'FETCH_PITCHING_GAME204_REPORT_SUCCESS';
export const FETCH_PITCHING_GAME204_REPORT_ERROR   = 'FETCH_PITCHING_GAME204_REPORT_ERROR';

export const FETCH_PITCHING_GAME205_REPORT_REQUEST = 'FETCH_PITCHING_GAME205_REPORT_REQUEST';
export const FETCH_PITCHING_GAME205_REPORT_SUCCESS = 'FETCH_PITCHING_GAME205_REPORT_SUCCESS';
export const FETCH_PITCHING_GAME205_REPORT_ERROR   = 'FETCH_PITCHING_GAME205_REPORT_ERROR';

export const FETCH_BPSUMMARY_REPORT_REQUEST = 'FETCH_BPSUMMARY_REPORT_REQUEST';
export const FETCH_BPSUMMARY_REPORT_SUCCESS = 'FETCH_BPSUMMARY_REPORT_SUCCESS';
export const FETCH_BPSUMMARY_REPORT_ERROR   = 'FETCH_BPSUMMARY_REPORT_ERROR';

export const FETCH_BPSUMMARY_GRAPH_REQUEST = 'FETCH_BPSUMMARY_GRAPH_REQUEST';
export const FETCH_BPSUMMARY_GRAPH_SUCCESS = 'FETCH_BPSUMMARY_GRAPH_SUCCESS';
export const FETCH_BPSUMMARY_GRAPH_ERROR   = 'FETCH_BPSUMMARY_GRAPH_ERROR';

export const FETCH_BPSUMMARY_GRAPH_DETAIL_REQUEST = 'FETCH_BPSUMMARY_GRAPH_DETAIL_REQUEST';
export const FETCH_BPSUMMARY_GRAPH_DETAIL_SUCCESS = 'FETCH_BPSUMMARY_GRAPH_DETAIL_SUCCESS';
export const FETCH_BPSUMMARY_GRAPH_DETAIL_ERROR   = 'FETCH_BPSUMMARY_GRAPH_DETAIL_ERROR';

export const FETCH_DKTABLE_REPORT_REQUEST = 'FETCH_DKTABLE_REPORT_REQUEST';
export const FETCH_DKTABLE_REPORT_SUCCESS = 'FETCH_DKTABLE_REPORT_SUCCESS';
export const FETCH_DKTABLE_REPORT_ERROR   = 'FETCH_DKTABLE_REPORT_ERROR';

export const FETCH_HITTER_GAME_REPORT_REQUEST = 'FETCH_HITTER_GAME_REPORT_REQUEST';
export const FETCH_HITTER_GAME_REPORT_SUCCESS = 'FETCH_HITTER_GAME_REPORT_SUCCESS';
export const FETCH_HITTER_GAME_REPORT_ERROR   = 'FETCH_HITTER_GAME_REPORT_ERROR';

export const FETCH_HITTER402_GRAPH_REQUEST = 'FETCH_HITTER402_GRAPH_REQUEST';
export const FETCH_HITTER402_GRAPH_SUCCESS = 'FETCH_HITTER402_GRAPH_SUCCESS';
export const FETCH_HITTER402_GRAPH_ERROR   = 'FETCH_HITTER402_GRAPH_ERROR';

export const FETCH_HITTER403_GRAPH_REQUEST = 'FETCH_HITTER403_GRAPH_REQUEST';
export const FETCH_HITTER403_GRAPH_SUCCESS = 'FETCH_HITTER403_GRAPH_SUCCESS';
export const FETCH_HITTER403_GRAPH_ERROR   = 'FETCH_HITTER403_GRAPH_ERROR';

export const FETCH_HITTER404_GRAPH_REQUEST = 'FETCH_HITTER404_GRAPH_REQUEST';
export const FETCH_HITTER404_GRAPH_SUCCESS = 'FETCH_HITTER404_GRAPH_SUCCESS';
export const FETCH_HITTER404_GRAPH_ERROR   = 'FETCH_HITTER404_GRAPH_ERROR';

export const FETCH_HITTER405_GRAPH_REQUEST = 'FETCH_HITTER405_GRAPH_REQUEST';
export const FETCH_HITTER405_GRAPH_SUCCESS = 'FETCH_HITTER405_GRAPH_SUCCESS';
export const FETCH_HITTER405_GRAPH_ERROR   = 'FETCH_HITTER405_GRAPH_ERROR';

export const FETCH_MEASUREMENT_REQUEST = 'FETCH_MEASUREMENT_REQUEST';
export const FETCH_MEASUREMENT_SUCCESS = 'FETCH_MEASUREMENT_SUCCESS';
export const FETCH_MEASUREMENT_ERROR   = 'FETCH_MEASUREMENT_ERROR';

export const FETCH_MEASUREMENT502_REQUEST = 'FETCH_MEASUREMENT502_REQUEST';
export const FETCH_MEASUREMENT502_SUCCESS = 'FETCH_MEASUREMENT502_SUCCESS';
export const FETCH_MEASUREMENT502_ERROR   = 'FETCH_MEASUREMENT502_ERROR';