import {
  BC_FETCH_TOP_TEAMS_REQUEST,
  BC_FETCH_TOP_TEAMS_SUCCESS,
  BC_FETCH_TOP_TEAMS_ERROR,
  BC_FETCH_ALL_TEAMS_REQUEST,
  BC_FETCH_ALL_TEAMS_SUCCESS,
  BC_FETCH_ALL_TEAMS_ERROR,
  BC_FETCH_TEAM_BY_FILTER_REQUEST,
  BC_FETCH_TEAM_BY_FILTER_SUCCESS,
  BC_FETCH_TEAM_BY_FILTER_ERROR,
  BC_FETCH_TEAM_BY_ID_REQUEST,
  BC_FETCH_TEAM_BY_ID_SUCCESS,
  BC_FETCH_TEAM_BY_ID_ERROR,
  BC_CHANGE_TYPE_REQUEST,
  BC_CHANGE_TYPE_SUCCESS,
  BC_CHANGE_TYPE_ERROR,
  BCTEAM_ASSIGN_LICENCE_REQUEST,
  BCTEAM_ASSIGN_LICENCE_SUCCESS,
  BCTEAM_ASSIGN_LICENCE_ERROR,
  BC_EXPORT_CSV_FOR_TEAM_REQUEST,
  BC_EXPORT_CSV_FOR_TEAM_SUCCESS,
  BC_EXPORT_CSV_FOR_TEAM_ERROR,
  BC_NOT_SEARCH_TEAM_REQUEST,
  BC_NOT_SEARCH_TEAM_SUCCESS,
  BC_NOT_SEARCH_TEAM_ERROR,
  BYNAME_PARENTTEAM_LIST_REQUEST,
  BYNAME_PARENTTEAM_LIST_SUCCESS,
  BYNAME_PARENTTEAM_LIST_ERROR,
} from "../../../constants/BaseballCloud/Users/Teams.const";

const initialState = {
  topTeams: [],
  topTeamsError: null,
  topTeamsLoader: false,

  team: [],
  teamError: null,
  teamLoader: false,
  isTeamLoaded: false,

  allTeams: [],
  allTeamsError: null,
  allTeamsLoader: false,
  isAllTeamsLoaded: false,

  teamsByFilter: [],
  teamsByFilterError: null,
  teamsByFilterLoader: false,
  isTeamsLoaded: false,

  assignLic: null,
  assignLicError: null,
  assignLicLoader: false,

  BcExportCsvTeam: null,
  BcExportCsvTeamError: null,
  BcExportCsvTeamLoader: false,

  teamTypeReq: false,
  teamTypeData: null,
  teamTypeError: null,

  notSearchTeamReq: false,
  notSearchTeamData: null,
  notSearchTeamError: null,

  bynamePTListReq: false,
  bynamePTListData: null,
  bynamePTListError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Fetch - TEAM BY ID

    case BC_FETCH_TEAM_BY_ID_REQUEST:
      return {
        ...state,
        teamLoader: true,
      };
    case BC_FETCH_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        isTeamLoaded: true,
        team: action.payload,
        teamLoader: false,
      };
    case BC_FETCH_TEAM_BY_ID_ERROR:
      return {
        ...state,
        teamError: action.error.message,
        teamLoader: false,
      };

    // Fetch - TOP TEAMS

    case BC_FETCH_TOP_TEAMS_REQUEST:
      return {
        ...state,
        topTeamsLoader: true,
      };
    case BC_FETCH_TOP_TEAMS_SUCCESS:
      return {
        ...state,
        topTeams: action.payload,
        topTeamsLoader: false,
      };
    case BC_FETCH_TOP_TEAMS_ERROR:
      return {
        ...state,
        topTeamsError: action.error.message,
        topTeamsLoader: false,
      };

    // Fetch - ALL TEAMS

    case BC_FETCH_ALL_TEAMS_REQUEST:
      return {
        ...state,
        allTeamsLoader: true,
      };
    case BC_FETCH_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        isAllTeamsLoaded: true,
        allTeams: action.payload,
        allTeamsLoader: false,
      };
    case BC_FETCH_ALL_TEAMS_ERROR:
      return {
        ...state,
        allTeamsError: action.error.message,
        allTeamsLoader: false,
      };

    // Fetch - TEAM BY FILTER

    case BC_FETCH_TEAM_BY_FILTER_REQUEST:
      return {
        ...state,
        teamsByFilterLoader: true,
      };
    case BC_FETCH_TEAM_BY_FILTER_SUCCESS:
      return {
        ...state,
        isTeamsLoaded: true,
        teamsByFilter: action.payload,
        teamsByFilterLoader: false,
      };
    case BC_FETCH_TEAM_BY_FILTER_ERROR:
      return {
        ...state,
        teamsByFilterError: action.error.message,
        teamsByFilterLoader: false,
      };

    // Fetch - TEAM BY LICENCE

    case BCTEAM_ASSIGN_LICENCE_REQUEST:
      return {
        ...state,
        assignLicLoader: true,
      };
    case BCTEAM_ASSIGN_LICENCE_SUCCESS:
      return {
        ...state,
        assignLic: action.payload,
      };
    case BCTEAM_ASSIGN_LICENCE_ERROR:
      return {
        ...state,
        assignLicError: action.error.message,
      };

    // EXPORT CSV FOR TEAM
    case BC_EXPORT_CSV_FOR_TEAM_REQUEST:
      return {
        ...state,
        BcExportCsvTeamLoader: true,
      };
    case BC_EXPORT_CSV_FOR_TEAM_SUCCESS:
      return {
        ...state,
        BcExportCsvTeam: action.payload,
        BcExportCsvTeamLoader: true,
      };
    case BC_EXPORT_CSV_FOR_TEAM_ERROR:
      return {
        ...state,
        BcExportCsvTeamError: action.error,
      };

    // Fetch - TEAM BY TYPE
    case BC_CHANGE_TYPE_REQUEST:
      return {
        ...state,
        teamTypeReq: true,
      };
    case BC_CHANGE_TYPE_SUCCESS:
      return {
        ...state,
        teamTypeData: action.payload,
      };
    case BC_CHANGE_TYPE_ERROR:
      return {
        ...state,
        teamTypeError: action.error.message,
      };

    // not search team in baseball cloud
    case BC_NOT_SEARCH_TEAM_REQUEST:
      return {
        ...state,
        notSearchTeamReq: true,
      };
    case BC_NOT_SEARCH_TEAM_SUCCESS:
      return {
        ...state,
        notSearchTeamData: action.payload,
      };
    case BC_NOT_SEARCH_TEAM_ERROR:
      return {
        ...state,
        notSearchTeamError: action.error.message,
      };

    // for team by name parentteam list
    case BYNAME_PARENTTEAM_LIST_REQUEST:
      return {
        ...state,
        bynamePTListReq: true,
      };
    case BYNAME_PARENTTEAM_LIST_SUCCESS:
      return {
        ...state,
        bynamePTListData: action.payload,
      };
    case BYNAME_PARENTTEAM_LIST_ERROR:
      return {
        ...state,
        bynamePTListError: action.error.message,
      };

    default:
      return state;
  }
}
