import { 
    FETCH_DIVISION_REQUEST,
    FETCH_DIVISION_SUCCESS, 
    FETCH_DIVISION_ERROR,

    UPDATE_DIVISION_REQUEST,
    UPDATE_DIVISION_SUCCESS,
    UPDATE_DIVISION_ERROR,

    DELETE_DIVISION_REQUEST,
    DELETE_DIVISION_SUCCESS,
    DELETE_DIVISION_ERROR,

    CREATE_DIVISION_REQUEST,
    CREATE_DIVISION_SUCCESS,
    CREATE_DIVISION_ERROR  
  
  } from '../constants/Division.const';
  
  const initialState = {
    divisions: [],
    divisionsError: null,
    divisionsLoader: false,
    isDivisionsLoaded: false,
  
    isDeleted: false,
    isDeletedError : null,
    isDeletedLoader : false,
  
    isCreated : false,
    isCreatedError : null,
    isCreatedLoader : false,
    
    isUpdated : false,
    isUpdatedError : null,
    isUpdatedLoader : false  
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      // For GET Division Data
  
      case FETCH_DIVISION_REQUEST:
        return {
          ...state,
          divisionsLoader: true
        };
      case FETCH_DIVISION_SUCCESS:
        return {
          ...state,
          divisions: action.payload,
          divisionsLoader: false,
          isDivisionsLoaded: true

        };
      case FETCH_DIVISION_ERROR:
        return {
          ...state,
          divisionsError: action.error.message,
          divisionsLoader: false
        };
  
      // For CREATE Division Data
  
      case CREATE_DIVISION_REQUEST:
        return {
          ...state,
          isCreatedLoader: true
        };
      case CREATE_DIVISION_SUCCESS:
        return {
          ...state,
          isCreated: true,
          isCreatedLoader: false
        };
      case CREATE_DIVISION_ERROR:
        return {
          ...state,
          isCreatedError: action.error,
          isCreatedLoader: false
        };
  
      // For UPDATE Division Data
  
      case UPDATE_DIVISION_REQUEST:
        return {
          ...state,
          isUpdatedLoader: true
        };
      case UPDATE_DIVISION_SUCCESS:
        return {
          ...state,
          isUpdated: true,
          isUpdatedLoader: false
        };
      case UPDATE_DIVISION_ERROR:
        return {
          ...state,
          isUpdatedError: action.error,
          isUpdatedLoader: false
        };

        // For DELETE Division Data
  
      case DELETE_DIVISION_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_DIVISION_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isDeletedLoader: false
        };
      case DELETE_DIVISION_ERROR:
        return {
          ...state,
          isDeletedError: action.error,
          isDeletedLoader: false
        };
  
      default:
        return state;
    }
  }