export const ADD_PLAYER_BULK_REQUEST = 'ADD_PLAYER_BULK_REQUEST';
export const ADD_PLAYER_BULK_SUCCESS = 'ADD_PLAYER_BULK_SUCCESS';
export const ADD_PLAYER_BULK_ERROR = 'ADD_PLAYER_BULK_ERROR';

export const SELECTED_COACH_NAME_LIST_REQUEST = 'SELECTED_COACH_NAME_LIST_REQUEST';
export const SELECTED_COACH_NAME_LIST_SUCCESS = 'SELECTED_COACH_NAME_LIST_SUCCESS';
export const SELECTED_COACH_NAME_LIST_ERROR = 'SELECTED_COACH_NAME_LIST_ERROR';

export const SELECTED_ROSTER_NAME_LIST_REQUEST = 'SELECTED_ROSTER_NAME_LIST_REQUEST';
export const SELECTED_ROSTER_NAME_LIST_SUCCESS = 'SELECTED_ROSTER_NAME_LIST_SUCCESS';
export const SELECTED_ROSTER_NAME_LIST_ERROR = 'SELECTED_ROSTER_NAME_LIST_ERROR'; 