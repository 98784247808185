export const LIST_TEMPLATE_REQUEST = 'LIST_TEMPLATE_REQUEST';
export const LIST_TEMPLATE_SUCCESS = 'LIST_TEMPLATE_SUCCESS';
export const LIST_TEMPLATE_ERROR   = 'LIST_TEMPLATE_ERROR';

export const LIST_USER_FOR_TEMPLATE_REQUEST = 'LIST_USER_FOR_TEMPLATE_REQUEST';
export const LIST_USER_FOR_TEMPLATE_SUCCESS = 'LIST_USER_FOR_TEMPLATE_SUCCESS';
export const LIST_USER_FOR_TEMPLATE_ERROR   = 'LIST_USER_FOR_TEMPLATE_ERROR';

export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_ERROR   = 'CREATE_TEMPLATE_ERROR';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR   = 'DELETE_TEMPLATE_ERROR';

export const GET_TEMPLATE_DATA_REQUEST = 'GET_TEMPLATE_DATA_REQUEST';
export const GET_TEMPLATE_DATA_SUCCESS = 'GET_TEMPLATE_DATA_SUCCESS';
export const GET_TEMPLATE_DATA_ERROR = 'GET_TEMPLATE_DATA_ERROR';

export const EDIT_TEMPLATE_REQUEST = 'EDIT_TEMPLATE_REQUEST';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_ERROR = 'EDIT_TEMPLATE_ERROR';

export const ALL_LIST_USER_FOR_TEMPLATE_REQUEST = 'ALL_LIST_USER_FOR_TEMPLATE_REQUEST';
export const ALL_LIST_USER_FOR_TEMPLATE_SUCCESS = 'ALL_LIST_USER_FOR_TEMPLATE_SUCCESS';
export const ALL_LIST_USER_FOR_TEMPLATE_ERROR = 'ALL_LIST_USER_FOR_TEMPLATE_ERROR';
