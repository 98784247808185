import {

    //round-robin

    GET_MANAGE_LIST_SUCCESS,
    GET_MANAGE_LIST_ERROR,

    GET_MANAGE_CSV_SUCCESS,
    GET_MANAGE_CSV_ERROR,

    GET_PRINT_PDF_SUCCESS,
    GET_PRINT_PDF_ERROR,

    FETCH_TEAM_DATA_SUCCESS,
    FETCH_TEAM_DATA_ERROR,

    CREATE_TEAM_DATA_SUCCESS,
    CREATE_TEAM_DATA_ERROR,

    SAVE_TEAM_DATA_SUCCESS,
    SAVE_TEAM_DATA_ERROR,

    //single elimination


    GET_SINGLE_MANAGE_LIST_SUCCESS,
    GET_SINGLE_MANAGE_LIST_ERROR,

    GET_SINGLE_MANAGE_CSV_SUCCESS,
    GET_SINGLE_MANAGE_CSV_ERROR,

    GET_SINGLE_PRINT_PDF_SUCCESS,
    GET_SINGLE_PRINT_PDF_ERROR,

    // all ageGroup manage

    GET_ALL_MANAGE_LIST_SUCCESS,
    GET_ALL_MANAGE_LIST_ERROR,

    PRINT_EXPORT_RESPONSE,

    POST_TEAM_SCORE_ERROR,
    POST_TEAM_SCORE_SUCCESS,

    GET_HOME_TEAM_SCORE_SUCCESS,
    GET_HOME_TEAM_SCORE_ERROR,

    GET_AWAY_TEAM_SCORE_SUCCESS,
    GET_AWAY_TEAM_SCORE_ERROR,

    EDIT_TEAM_SCORE_SUCCESS,
    EDIT_TEAM_SCORE_ERROR,

    GET_INNING_SCORE_SUCCESS,
    GET_INNING_SCORE_ERROR,

    EDIT_INNING_SCORE_SUCCESS,
    EDIT_INNING_SCORE_ERROR,

    GET_FILTER_STANDING_DATA_SUCCESS,
    GET_FILTER_STANDING_DATA_ERROR,

    GET_FILTER_WISE_DATA_SUCCESS,
    GET_FILTER_WISE_DATA_ERROR,

    GET_STANDING_DATA_SUCCESS,
    GET_STANDING_DATA_ERROR,

    SAVE_STANDING_DATA_SUCCESS,
    SAVE_STANDING_DATA_ERROR,

    REFRESH_MATCH_END_SUCCESS,
    REFRESH_MATCH_END_ERROR,

    //edit Game Stats
    GET_INNING_STATS_LIST_SUCCESS,
    GET_INNING_STATS_LIST_ERROR,

    EDIT_GAME_STATS_SUCCESS,
    EDIT_GAME_STATS_ERROR,

    PLAY_BY_PLAY_TEAM_LIST_SUCCESS,
    PLAY_BY_PLAY_TEAM_LIST_ERROR,

    PLAY_BY_PLAY_PLAYER_LIST_SUCCESS,
    PLAY_BY_PLAY_PLAYER_LIST_ERROR,

    PLAYER_SWAP_FULL_GAME_SUCCESS,
    PLAYER_SWAP_FULL_GAME_ERROR,

    PLAYER_SWAP_BY_PLAY_SUCCESS,
    PLAYER_SWAP_BY_PLAY_ERROR,

    PLAYER_WEBSCORE_SUCCESS,
    PLAYER_WEBSCORE_ERROR,
    EDIT_GAME_PLAYER_SUCCESS,
    EDIT_GAME_PLAYER_ERROR,
    EXPORT_BRACKET_SUCCESS,
    EXPORT_BRACKET_ERROR,

    CHECK_ISEDITED
} from '../../constants/Scheduling/Manage.const';

const initialState = {

    //round-robin

    getManageData: null,
    getManageDataError: null,

    getManageCsvData: null,
    getManageCsvDataError: null,

    getPrintPdfData: null,
    getPrintPdfDataError: null,

    fetchTeamData: null,
    fetchTeamDataError: null,

    createTeamData: null,
    createTeamDataError: null,

    saveTeamData: null,
    saveTeamDataError: null,

    //single elimination

    getSingleManageData: null,
    getSingleManageDataError: null,

    getSingleManageCsvData: null,
    getSingleManageCsvDataError: null,

    getSinglePrintPdfData: null,
    getSinglePrintPdfDataError: null,


    // all ageGroup manage
    getAllManageData: null,
    getAllManageDataError: null,

    printExportResponse: null,

    editScoreData: null,
    editScoreError: null,

    getHomeScoreData: null,
    getHomeScoreError: null,

    getAwayScoreData: null,
    getAwayScoreError: null,

    editRunData: null,
    editRunError: null,

    getInningScoreData: null,
    getInningScoreError: null,

    editInningData: null,
    editInningError: null,

    filterStandingData: null,
    filterStandingError: null,

    filterWiseData: null,
    filterWiseError: null,

    standingData: null,
    standingError: null,

    saveStandingData: null,
    saveStandingError: null,

    refreshMatchEndSuccess: null,
    refreshMatchEndError: null,

    //edit Game Stats

    getInningStatsList: null,
    getInningStatsListError: null,

    editGameStatsData: null,
    editGameStatsError: null,

    editGamePlayerData: null,
    editGamePlayerError: null,

    teamListData: null,
    teamListError: null,

    playerListData: null,
    playerListError: null,

    playerSwapFullGame: null,
    playerSwapFullGameError: null,

    playerSwapByPlay: null,
    playerSwapByPlayError: null,

    playerWebScore: null,
    playerWebScoreError: null,

    editWebScore: null,
    editWebScoreError: null,

    exportBracketResponse: null,
    exportBracketError: null,

    isEdited:null
};

export default function (state = initialState, action) {
    switch (action.type) {

        //round-robin

        case GET_MANAGE_LIST_SUCCESS:
            return {
                ...state,
                getManageData: action.payload
            }
        case GET_MANAGE_LIST_ERROR:
            return {
                ...state,
                getManageDataError: action.error
            }

        case GET_MANAGE_CSV_SUCCESS:
            return {
                ...state,
                getManageCsvData: action.payload
            }
        case GET_MANAGE_CSV_ERROR:
            return {
                ...state,
                getManageCsvDataError: action.error
            }

        case GET_PRINT_PDF_SUCCESS:
            return {
                ...state,
                getPrintPdfData: action.payload
            }
        case GET_PRINT_PDF_ERROR:
            return {
                ...state,
                getPrintPdfDataError: action.error
            }

        case FETCH_TEAM_DATA_SUCCESS:
            return {
                ...state,
                fetchTeamData: action.payload
            }
        case FETCH_TEAM_DATA_ERROR:
            return {
                ...state,
                fetchTeamDataError: action.error
            }

        case CREATE_TEAM_DATA_SUCCESS:
            return {
                ...state,
                createTeamData: action.payload
            }
        case CREATE_TEAM_DATA_ERROR:
            return {
                ...state,
                createTeamDataError: action.error
            }

        case SAVE_TEAM_DATA_SUCCESS:
            return {
                ...state,
                saveTeamData: action.payload
            }
        case SAVE_TEAM_DATA_ERROR:
            return {
                ...state,
                saveTeamDataError: action.error
            }

        //single elimination

        case GET_SINGLE_MANAGE_LIST_SUCCESS:
            return {
                ...state,
                getSingleManageData: action.payload
            }
        case GET_SINGLE_MANAGE_LIST_ERROR:
            return {
                ...state,
                getSingleManageDataError: action.error
            }

        case GET_SINGLE_MANAGE_CSV_SUCCESS:
            return {
                ...state,
                getSingleManageCsvData: action.payload
            }
        case GET_SINGLE_MANAGE_CSV_ERROR:
            return {
                ...state,
                getSingleManageCsvDataError: action.error
            }

        case GET_SINGLE_PRINT_PDF_SUCCESS:
            return {
                ...state,
                getSinglePrintPdfData: action.payload
            }
        case GET_SINGLE_PRINT_PDF_ERROR:
            return {
                ...state,
                getSinglePrintPdfDataError: action.error
            }

        // all ageGroup manage

        case GET_ALL_MANAGE_LIST_SUCCESS:
            return {
                ...state,
                getAllManageData: action.payload
            }
        case GET_ALL_MANAGE_LIST_ERROR:
            return {
                ...state,
                getAllManageDataError: action.error
            }

        case PRINT_EXPORT_RESPONSE:
            return {
                ...state,
                printExportResponse: action.payload
            }

        //edit team score
        case POST_TEAM_SCORE_SUCCESS:
            return {
                ...state,
                editScoreData: action.payload
            }
        case POST_TEAM_SCORE_ERROR:
            return {
                ...state,
                editScoreError: action.error
            }

        case GET_HOME_TEAM_SCORE_SUCCESS:
            return {
                ...state,
                getHomeScoreData: action.payload
            }
        case GET_HOME_TEAM_SCORE_ERROR:
            return {
                ...state,
                getHomeScoreError: action.error
            }

        case GET_AWAY_TEAM_SCORE_SUCCESS:
            return {
                ...state,
                getAwayScoreData: action.payload
            }
        case GET_AWAY_TEAM_SCORE_ERROR:
            return {
                ...state,
                getAwayScoreError: action.error
            }

        case EDIT_TEAM_SCORE_SUCCESS:
            return {
                ...state,
                editRunData: action.payload
            }
        case EDIT_TEAM_SCORE_ERROR:
            return {
                ...state,
                editRunError: action.error
            }


        case GET_INNING_SCORE_SUCCESS:
            return {
                ...state,
                getInningScoreData: action.payload
            }
        case GET_INNING_SCORE_ERROR:
            return {
                ...state,
                getInningScoreError: action.error
            }


        case EDIT_INNING_SCORE_SUCCESS:
            return {
                ...state,
                editInningData: action.payload
            }
        case EDIT_INNING_SCORE_ERROR:
            return {
                ...state,
                editInningError: action.error
            }

        case REFRESH_MATCH_END_SUCCESS:
            return {
                ...state,
                refreshMatchEndSuccess: action.payload
            }
        case REFRESH_MATCH_END_ERROR:
            return {
                ...state,
                refreshMatchEndError: action.error
            }

        //standing data
        case GET_FILTER_STANDING_DATA_SUCCESS:
            return {
                ...state,
                filterStandingData: action.payload
            }
        case GET_FILTER_STANDING_DATA_ERROR:
            return {
                ...state,
                filterStandingError: action.error
            }

        case GET_FILTER_WISE_DATA_SUCCESS:
            return {
                ...state,
                filterWiseData: action.payload
            }
        case GET_FILTER_WISE_DATA_ERROR:
            return {
                ...state,
                filterWiseError: action.error
            }

        case GET_STANDING_DATA_SUCCESS:
            return {
                ...state,
                standingData: action.payload
            }
        case GET_STANDING_DATA_ERROR:
            return {
                ...state,
                standingError: action.error
            }

        case SAVE_STANDING_DATA_SUCCESS:
            return {
                ...state,
                saveStandingData: action.payload
            }
        case SAVE_STANDING_DATA_ERROR:
            return {
                ...state,
                saveStandingError: action.error
            }

        //edit Game Stats
        case GET_INNING_STATS_LIST_SUCCESS:
            return {
                ...state,
                getInningStatsList: action.payload
            }
        case GET_INNING_STATS_LIST_ERROR:
            return {
                ...state,
                getInningStatsListError: action.error
            }

        case EDIT_GAME_STATS_SUCCESS:
            return {
                ...state,
                editGameStatsData: action.payload
            }
        case EDIT_GAME_STATS_ERROR:
            return {
                ...state,
                editGameStatsError: action.error
            }

        case EDIT_GAME_PLAYER_SUCCESS:
            return {
                ...state,
                editGamePlayerData: action.payload
            }
        case EDIT_GAME_PLAYER_ERROR:
            return {
                ...state,
                editGamePlayerError: action.error
            }

        case PLAY_BY_PLAY_TEAM_LIST_SUCCESS:
            return {
                ...state,
                teamListData: action.payload
            }
        case PLAY_BY_PLAY_TEAM_LIST_ERROR:
            return {
                ...state,
                teamListError: action.error
            }

        case PLAY_BY_PLAY_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                playerListData: action.payload
            }
        case PLAY_BY_PLAY_PLAYER_LIST_ERROR:
            return {
                ...state,
                playerListError: action.error
            }

        case PLAYER_SWAP_FULL_GAME_SUCCESS:
            return {
                ...state,
                playerSwapFullGame: action.payload
            }
        case PLAYER_SWAP_FULL_GAME_ERROR:
            return {
                ...state,
                playerSwapFullGameError: action.error
            }

        case PLAYER_SWAP_BY_PLAY_SUCCESS:
            return {
                ...state,
                playerSwapByPlay: action.payload
            }
        case PLAYER_SWAP_BY_PLAY_ERROR:
            return {
                ...state,
                playerSwapByPlayError: action.error
            }


        case PLAYER_WEBSCORE_SUCCESS:
            return {
                ...state,
                playerWebScore: action.payload
            }
        case PLAYER_WEBSCORE_ERROR:
            return {
                ...state,
                playerWebScoreError: action.error
            }

        case EXPORT_BRACKET_SUCCESS:
            return {
                ...state,
                exportBracketResponse: action.payload
            }

        case EXPORT_BRACKET_ERROR:
            return {
                ...state,
                exportBracketError: action.error
            }

        case CHECK_ISEDITED:
            return {
                ...state,
                isEdited: action.payload
            }
        default:
            return state;
    }
}