export const FETCH_TOP_EVENTS_REQUEST = 'FETCH_TOP_EVENTS_REQUEST';
export const FETCH_TOP_EVENTS_SUCCESS = 'FETCH_TOP_EVENTS_SUCCESS';
export const FETCH_TOP_EVENTS_ERROR   = 'FETCH_TOP_EVENTS_ERROR';

export const FETCH_ALL_EVENTS_REQUEST = 'FETCH_ALL_EVENTS_REQUEST';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_ALL_EVENTS_ERROR   = 'FETCH_ALL_EVENTS_ERROR';

export const FETCH_EVENT_BY_ID_REQUEST = 'FETCH_EVENT_BY_ID_REQUEST';
export const FETCH_EVENT_BY_ID_SUCCESS = 'FETCH_EVENT_BY_ID_SUCCESS';
export const FETCH_EVENT_BY_ID_ERROR   = 'FETCH_EVENT_BY_ID_ERROR';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR   = 'CREATE_EVENT_ERROR';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR   = 'UPDATE_EVENT_ERROR';

export const FETCH_EVENTS_TITTLE_REQUEST = 'FETCH_EVENTS_TITTLE_REQUEST';
export const FETCH_EVENTS_TITTLE_SUCCESS = 'FETCH_EVENTS_TITTLE_SUCCESS';
export const FETCH_EVENTS_TITTLE_ERROR   = 'FETCH_EVENTS_TITTLE_ERROR';

export const FETCH_PRODUCT_LIST_REQUEST = 'FETCH_PRODUCT_LIST_REQUEST';
export const FETCH_PRODUCT_LIST_SUCCESS = 'FETCH_PRODUCT_LIST_SUCCESS';
export const FETCH_PRODUCT_LIST_ERROR   = 'FETCH_PRODUCT_LIST_ERROR';

export const EVENT_RECAPINFO_REQUEST = 'EVENT_RECAPINFO_REQUEST';
export const EVENT_RECAPINFO_SUCCESS = 'EVENT_RECAPINFO_SUCCESS';
export const EVENT_RECAPINFO_ERROR   = 'EVENT_RECAPINFO_ERROR';

export const EVENT_TAGGING_PLAYERLIST_REQUEST = 'EVENT_TAGGING_PLAYERLIST_REQUEST';
export const EVENT_TAGGING_PLAYERLIST_SUCCESS = 'EVENT_TAGGING_PLAYERLIST_SUCCESS';
export const EVENT_TAGGING_PLAYERLIST_ERROR   = 'EVENT_TAGGING_PLAYERLIST_ERROR';

export const FETCH_EVENT_BY_ID_RECAP_REQUEST = 'FETCH_EVENT_BY_ID_RECAP_REQUEST';
export const FETCH_EVENT_BY_ID_RECAP_SUCCESS = 'FETCH_EVENT_BY_ID_RECAP_SUCCESS';
export const FETCH_EVENT_BY_ID_RECAP_ERROR   = 'FETCH_EVENT_BY_ID_RECAP_ERROR';

export const LIST_RECAP_INFO_REQUEST = 'LIST_RECAP_INFO_REQUEST';
export const LIST_RECAP_INFO_SUCCESS = 'LIST_RECAP_INFO_SUCCESS';
export const LIST_RECAP_INFO_ERROR   = 'LIST_RECAP_INFO_ERROR';

export const REFRESH_SCOUT_FEED_SUCCESS = 'REFRESH_SCOUT_FEED_SUCCESS';
export const REFRESH_SCOUT_FEED_ERROR   = 'REFRESH_SCOUT_FEED_ERROR';