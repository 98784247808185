import moment from "moment";
import {
  FETCH_TOP_EVENTS_REQUEST,
  FETCH_TOP_EVENTS_SUCCESS,
  FETCH_TOP_EVENTS_ERROR,

  FETCH_ALL_EVENTS_REQUEST,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_ALL_EVENTS_ERROR,

  FETCH_EVENT_BY_ID_REQUEST,
  FETCH_EVENT_BY_ID_SUCCESS,
  FETCH_EVENT_BY_ID_ERROR,

  FETCH_EVENTS_TITTLE_REQUEST,
  FETCH_EVENTS_TITTLE_SUCCESS,
  FETCH_EVENTS_TITTLE_ERROR,

  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,

  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,

  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_ERROR,
  
  EVENT_RECAPINFO_REQUEST,
  EVENT_RECAPINFO_SUCCESS,
  EVENT_RECAPINFO_ERROR,

  EVENT_TAGGING_PLAYERLIST_REQUEST,
  EVENT_TAGGING_PLAYERLIST_SUCCESS,
  EVENT_TAGGING_PLAYERLIST_ERROR,

  FETCH_EVENT_BY_ID_RECAP_REQUEST,
  FETCH_EVENT_BY_ID_RECAP_SUCCESS,
  FETCH_EVENT_BY_ID_RECAP_ERROR,

  LIST_RECAP_INFO_REQUEST,
  LIST_RECAP_INFO_SUCCESS,
  LIST_RECAP_INFO_ERROR,

  REFRESH_SCOUT_FEED_SUCCESS,
  REFRESH_SCOUT_FEED_ERROR
} from '../constants/Events.const';

const initialState = {
  event: [],
  eventError: null,
  eventLoader: false,
  isEventLoaded: false,

  topEvents: [],
  topEventsError: null,
  topEventsLoader: false,
  isTopEventsLoaded: false,

  allEvents: [],
  allEventsError: null,
  allEventsLoader: false,
  isAllEventsLoaded: false,

  eventTittles: [],
  eventTittlesError: null,
  eventTittlesLoader: false,

  isCreated: false,
  isCreatedError: null,
  isCreatedLoader: false,
  isNewCreatedErr: false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false,

  productList: null,
  productListError: null,
  productListLoader: false,

  isRecapInfo: null,
  isRecapInfoError: null,
  isRecapInfoLoader: false,

  isPlayerList: null,
  isPlayerListError: null,
  isPlayerListLoader: false,

  eventRecap: [],
  eventRecapError: null,
  eventRecapLoader: false,
  isEventRecapLoaded: false,

  recapList:[],
  recapListError: null,
  recapListLoader: false,

  scoutFeed: null,
  scoutFeedError: null,

  tournamentFilter:{
    eventType: 0,
    isPresent: 0,
    company: "",
    state:"",
    isFeatured:"",
    name:"",
    isLiveUpcomeEvent: true
  },
  tournament1Filter:{
    eventType: 0,
    isPresent: 0,
    company: "",
    state:"",
    isFeatured: "",
    name:""
  },

  showcaseFilter:{
    eventType: 0,
    isPresent: 0,
    company: "",
    state:"",
    isFeatured: "",
    name:"",
    currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString(),
    isLiveUpcomeEvent: true
  },
  showcase1Filter:{
    eventType: 0,
    isPresent: 0,
    company: "",
    state:"",
    isFeatured: "",
    name:"",
    currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
  },
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For GET Event Data By Id

    case FETCH_EVENT_BY_ID_REQUEST:
      return {
        ...state,
        eventLoader: true
      };
    case FETCH_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        isEventLoaded: true,
        event: action.payload,
        eventLoader: false
      };
    case FETCH_EVENT_BY_ID_ERROR:
      return {
        ...state,
        eventError: action.error.message,
        eventLoader: false
      };

    // For GET Event Data

    case FETCH_TOP_EVENTS_REQUEST:
      return {
        ...state,
        topEventsLoader: true
      };
    case FETCH_TOP_EVENTS_SUCCESS:
      return {
        ...state,
        isTopEventsLoaded: true,
        topEvents: action.payload,
        topEventsLoader: false
      };
    case FETCH_TOP_EVENTS_ERROR:
      return {
        ...state,
        topEventsError: action.error.message,
        topEventsLoader: false
      };

    // For GET ALL Event Data

    case FETCH_ALL_EVENTS_REQUEST:
      return {
        ...state,
        allEventsLoader: true
      };
    case FETCH_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        isAllEventsLoaded: true,
        allEvents: action.payload,
        allEventsLoader: false
      };
    case FETCH_ALL_EVENTS_ERROR:
      return {
        ...state,
        allEventsError: action.error.message,
        allEventsLoader: false
      };


    // For GET Event Tittles Only

    case FETCH_EVENTS_TITTLE_REQUEST:
      return {
        ...state,
        eventTittlesLoader: true
      };
    case FETCH_EVENTS_TITTLE_SUCCESS:
      return {
        ...state,
        eventTittles: action.payload,
        eventTittlesLoader: false
      };
    case FETCH_EVENTS_TITTLE_ERROR:
      return {
        ...state,
        eventTittlesError: action.error.message,
        eventTittlesLoader: false
      };

    // For CREATE Event Data

    case CREATE_EVENT_REQUEST:
      return {
        ...state,
        isCreatedLoader: true
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        isCreated: true,
        isCreatedLoader: false
      };
    case CREATE_EVENT_ERROR:
      return {
        ...state,
        isCreatedError: action.error.response.data.message,
        isCreatedLoader: false,
        isNewCreatedErr: true
      };

    // For UPDATE Event Data

    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_EVENT_ERROR:
      return {
        ...state,
        isUpdatedError: action.error.response.data.message,
        isUpdatedLoader: false,
        isNewCreatedErr: true
      };
    case 'UPDATE_SCHEDULING_SUCCESS':
      return {
        ...state,
        isUpdated: false,
      };
    // For get product list

    case FETCH_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        productListLoader: true
      };
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        productListLoader: true
      };
    case FETCH_PRODUCT_LIST_ERROR:
      return {
        ...state,
        productListError: action.error.response.data.message,
        productListLoader: false,
      };

      // For update award recap info for event
    case EVENT_RECAPINFO_REQUEST:
      return {
        ...state,
        isRecapInfoLoader: true
      };
    case EVENT_RECAPINFO_SUCCESS:
      return {
        ...state,
        isRecapInfo: action.payload,
        // isRecapInfoLoader: true
      };
    case EVENT_RECAPINFO_ERROR:
      return {
        ...state,
        isRecapInfoError: action.error.response.data.message,
        isRecapInfoLoader: false,
      };

      // For award tagging player list in event
    case EVENT_TAGGING_PLAYERLIST_REQUEST:
      return {
        ...state,
        isPlayerListLoader: true
      };
    case EVENT_TAGGING_PLAYERLIST_SUCCESS:
      return {
        ...state,
        isPlayerList: action.payload,
        isPlayerListLoader: true
      };
    case EVENT_TAGGING_PLAYERLIST_ERROR:
      return {
        ...state,
        isPlayerListError: action.error.response.data.message,
        isPlayerListLoader: false,
      };

      // For GET Event recap info Data By Id 
    case FETCH_EVENT_BY_ID_RECAP_REQUEST:
      return {
        ...state,
        eventRecapLoader: true
      };
    case FETCH_EVENT_BY_ID_RECAP_SUCCESS:
      return {
        ...state,
        isEventRecapLoaded: true,
        eventRecap: action.payload,
        eventRecapLoader: false
      };
    case FETCH_EVENT_BY_ID_RECAP_ERROR:
      return {
        ...state,
        eventRecapError: action.error.message,
        eventRecapLoader: false
      };

        // get recap info list by event 
    case LIST_RECAP_INFO_REQUEST:
      return {
        ...state,
        recapListLoader: true
      };
    case LIST_RECAP_INFO_SUCCESS:
      return {
        ...state,
        recapList: action.payload,
      };
    case LIST_RECAP_INFO_ERROR:
      return {
        ...state,
        recapListError: action.error.message,
      };

    //refresh scout feed
    case REFRESH_SCOUT_FEED_SUCCESS:
      return {
        ...state,
        scoutFeed: action.payload,
      };
    case REFRESH_SCOUT_FEED_ERROR:
      return {
        ...state,
        scoutFeedError: action.error.message,
      };
      
    default:
      return state;
  }
}