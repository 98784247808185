//GET only division

export const GET_DIVISION_LIST_SUCCESS = "GET_DIVISION_LIST_SUCCESS";
export const GET_DIVISION_LIST_ERROR = "GET_DIVISION_LIST_ERROR";

export const CHANGE_DIVISION_LIST_SUCCESS = "CHANGE_DIVISION_LIST_SUCCESS";
export const CHANGE_DIVISION_LIST_ERROR = "CHANGE_DIVISION_LIST_ERROR";

//GET only pool

export const GET_POOL_LIST_SUCCESS = "GET_POOL_LIST_SUCCESS";
export const GET_POOL_LIST_ERROR = "GET_POOL_LIST_ERROR";

export const CREATE_POOL_LIST_SUCCESS = "CREATE_POOL_LIST_SUCCESS";
export const CREATE_POOL_LIST_ERROR = "CREATE_POOL_LIST_ERROR";

//GET division and pool both

export const GET_DIVISION_WISE_LIST_SUCCESS = "GET_DIVISION_WISE_LIST_SUCCESS";
export const GET_DIVISION_WISE_LIST_ERROR = "GET_DIVISION_WISE_LIST_ERROR";

export const CREATE_DIVISION_LIST_SUCCESS = "CREATE_DIVISION_LIST_SUCCESS";
export const CREATE_DIVISION_LIST_ERROR = "CREATE_DIVISION_LIST_ERROR";

export const GET_POOL_COUNT_SUCCESS = "GET_POOL_COUNT_SUCCESS";
export const GET_POOL_COUNT_ERROR = "GET_POOL_COUNT_ERROR";

export const GET_POOLWISE_LIST_SUCCESS = "GET_POOLWISE_LIST_SUCCESS";
export const GET_POOLWISE_LIST_ERROR = "GET_POOLWISE_LIST_ERROR";

export const CREATE_POOLWISE_LIST_SUCCESS = "CREATE_POOLWISE_LIST_SUCCESS";
export const CREATE_POOLWISE_LIST_ERROR = "CREATE_POOLWISE_LIST_ERROR";



//get pool 

export const GET_AUTO_GENERATE_SUCCESS = "GET_AUTO_GENERATE_SUCCESS";
export const GET_AUTO_GENERATE_ERROR = "GET_AUTO_GENERATE_ERROR";

export const GET_MANUAL_LIST_SUCCESS = "GET_MANUAL_LIST_SUCCESS";
export const GET_MANUAL_LIST_ERROR = "GET_MANUAL_LIST_ERROR";