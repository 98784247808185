import {
    DATAINPUT_LIST_REQUEST,
    DATAINPUT_LIST_SUCCESS,
    DATAINPUT_LIST_ERROR,
    ADD_DATAINPUT_REQUEST,
    ADD_DATAINPUT_SUCCESS,
    ADD_DATAINPUT_ERROR,
    EVENT_PLAYER_LIST_SUCCESS,
    EVENT_PLAYER_LIST_ERROR,
    FETCH_INPUT_DATA_SUCCESS,
    FETCH_INPUT_DATA_ERROR,
    EDIT_DATAINPUT_REQUEST,
    EDIT_DATAINPUT_SUCCESS,
    EDIT_DATAINPUT_ERROR,
    DELETE_DATAINPUT_REQUEST,
    DELETE_DATAINPUT_SUCCESS,
    DELETE_DATAINPUT_ERROR,

    INPUT_GET_EVENT_LIST_ERROR,
    INPUT_GET_EVENT_LIST_SUCCESS,
    INPUT_GET_PARANT_TEAM_ERROR,
    INPUT_GET_PARANT_TEAM_SUCCESS,
    INPUT_GET_COACH_LIST_ERROR,
    INPUT_GET_COACH_LIST_SUCCESS,
    INPUT_POST_CREATE_EVENT_ERROR,
    INPUT_POST_CREATE_EVENT_SUCCESS,
    INPUT_SAVE_SOURCE_ERROR,
    INPUT_SAVE_SOURCE_SUCCESS,
    INPUT_GET_DEVICE_ERROR,
    INPUT_GET_DEVICE_SUCCESS,
    INPUT_GET_LINK_ERROR,
    INPUT_GET_LINK_SUCCESS,
    INPUT_GET_OPTION_ERROR,
    INPUT_GET_OPTION_SUCCESS,
    INPUT_GET_RMAP_ERROR,
    INPUT_GET_RMAP_SUCCESS,
    INPUT_DELETE_EVENT_ERROR,
    INPUT_DELETE_EVENT_SUCCESS,
    INPUT_GET_SOURCE_BY_EVENT_ERROR,
    INPUT_GET_SOURCE_BY_EVENT_SUCCESS,
    INPUT_POST_UPLOAD_VIDEO_SUCCESS,
    INPUT_POST_UPLOAD_VIDEO_ERROR,
    INPUT_ROW_DEVICE_DATA_SUCCESS,
    INPUT_ROW_DEVICE_DATA_REQUEST,
    INPUT_ROW_DEVICE_DATA_ERROR,
    INPUT_GET_VIDEO_MAPPING_LIST_REQUEST,
    INPUT_GET_VIDEO_MAPPING_LIST_SUCCESS,
    INPUT_GET_VIDEO_MAPPING_LIST_ERROR,
    INPUT_MAP_VIDEO_FOR_PER_PITCH_REQUEST,
    INPUT_MAP_VIDEO_FOR_PER_PITCH_SUCCESS,
    INPUT_MAP_VIDEO_FOR_PER_PITCH_ERROR,
    INPUT_SAVE_RAPSODO_SOURCE_ERROR,
    INPUT_SAVE_RAPSODO_SOURCE_SUCCESS,
    INPUT_SAVE_RAPSODO_SOURCE_REQUEST,
    INPUT_UPDATE_DEVICE_DATA_SUCCESS,
    INPUT_UPDATE_DEVICE_DATA_REQUEST,
    INPUT_UPDATE_DEVICE_DATA_ERROR,
    INPUT_MAP_RAPSODO_REQUEST,
    INPUT_MAP_RAPSODO_SUCCESS,
    INPUT_MAP_RAPSODO_ERROR,
    REPORT_GENERATED_MAIL_REQUEST,
    REPORT_GENERATED_MAIL_SUCCESS,
    REPORT_GENERATED_MAIL_ERROR,
    SHOWCASE_LIST_PLATFORM_REQUEST,
    SHOWCASE_LIST_PLATFORM_SUCCESS,
    SHOWCASE_LIST_PLATFORM_ERROR,
    FETCH_SELECTED_TEMPLATE_REQUEST,
    FETCH_SELECTED_TEMPLATE_SUCCESS,
    FETCH_SELECTED_TEMPLATE_ERROR,
    EXPORT_CSV_SUCCESS,
    EXPORT_CSV_ERROR,
    EXPORT_CSV_REQUEST,
    IMPORT_CSV_REQUEST,
    IMPORT_CSV_SUCCESS,
    IMPORT_CSV_ERROR,
    INPUT_DEL_SELL_VIDEO_SUCCESS,
    INPUT_DEL_SELL_VIDEO_REQUEST,
    INPUT_DEL_SELL_VIDEO_ERROR,
    UPLOAD_PDF_REQUEST,
    UPLOAD_PDF_SUCCESS,
    UPLOAD_PDF_ERROR
} from '../constants/DataInput.const';

const initialState = {

    dataInputLoader: false,
    dataInputList: null,
    dataInputError: null,

    addInputLoader: false,
    addInput: null,
    addInputError: null,

    playerList: [],
    playerListError: null,

    fetchInputData: null,
    fetchInputError: null,

    editInputLoader: false,
    editInput: null,
    editInputError: null,

    deleteInputLoader: false,
    deleteInput: null,
    deleteInputError: null,

    //upload CSV
    eventListData: null,
    eventListError: null,

    teamListData: null,
    teamListError: null,

    coachListData: null,
    coachListError: null,

    createEventData: null,
    createEventError: null,

    saveDatasourceData: null,
    saveDatasourceError: null,

    deviceData: null,
    deviceError: null,

    linkData: null,
    linkError: null,

    optionData: null,
    optionError: null,

    mapData: null,
    mapError: null,

    deleteEventData: null,
    deleteEventError: null,

    sourceListData: [],
    sourceListError: null,

    saveUploadVideoData: null,
    saveUploadVideoError: null,

    saveRapsodoReq: false,
    saveRapsodoData: null,
    saveRapsodoError: null,

    getRowDeviceReq: false,
    getRowDeviceData: null,
    getRowDeviceError: null,

    videoMappingListReq: false,
    videoMappingListData: null,
    videoMappingListError: null,

    mapVideoPerPitchReq: false,
    mapVideoPerPitchData: null,
    mapVideoPerPitchError: null,

    updateDeviceData: null,
    updateDeviceError: null,

    mappingRapsodoReq: false,
    mappingRapsodoData: null,
    mappingRapsodoError: null,

    reportGeneratMailReq: false,
    reportGeneratMailData: null,
    reportGeneratMailError: null,

    showcaseTemplateList: null,
    showcaseTemplateListError: null,

    selectedTemplate: null,
    selectedTemplateError: null,

    exportCSVReq: false,
    exportCSV: null,
    exportCSVError: null,

    importCSVReq: false,
    importCSV: null,
    importCSVError: null,

    delSellVideoReq: false,
    delSellVideoData: null,
    delSellVideoError: null,

    uploadPDFReq: false,
    uploadPDFData: null,
    uploadPDFError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

           // delete sell video req    
           case INPUT_DEL_SELL_VIDEO_REQUEST:
            return {
                ...state,
                delSellVideoReq: true,
            };
        case INPUT_DEL_SELL_VIDEO_SUCCESS:
            return {
                ...state,
                delSellVideoData: action.payload,
            };
        case INPUT_DEL_SELL_VIDEO_ERROR:
            return {
                ...state,
                delSellVideoError: action.error,
            };

        // For export CSV
        case EXPORT_CSV_REQUEST:
            return {
                ...state,
                exportCSVReq: true,
            };
        case EXPORT_CSV_SUCCESS:
            return {
                ...state,
                exportCSV: action.payload,
            };
        case EXPORT_CSV_ERROR:
            return {
                ...state,
                exportCSVError: action.error,
            };


        // For import CSV
        case IMPORT_CSV_REQUEST:
            return {
                ...state,
                importCSVReq: true,
            };
        case IMPORT_CSV_SUCCESS:
            return {
                ...state,
                importCSV: action.payload,
            };
        case IMPORT_CSV_ERROR:
            return {
                ...state,
                importCSVError: action.error,
            };

        // For Data input List
        case DATAINPUT_LIST_REQUEST:
            return {
                ...state,
                dataInputLoader: true,
            };
        case DATAINPUT_LIST_SUCCESS:
            return {
                ...state,
                dataInputList: action.payload,
            };
        case DATAINPUT_LIST_ERROR:
            return {
                ...state,
                dataInputError: action.error,
            };

        // add Data input
        case ADD_DATAINPUT_REQUEST:
            return {
                ...state,
                addInputLoader: true,
            };
        case ADD_DATAINPUT_SUCCESS:
            return {
                ...state,
                addInput: action.payload,
            };
        case ADD_DATAINPUT_ERROR:
            return {
                ...state,
                addInputError: action.error,
            };

        // player List 
        case EVENT_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                playerList: action.payload,
            };
        case EVENT_PLAYER_LIST_ERROR:
            return {
                ...state,
                playerListError: action.error,
            };

        // fetch input data by id
        case FETCH_INPUT_DATA_SUCCESS:
            return {
                ...state,
                fetchInputData: action.payload,
            };
        case FETCH_INPUT_DATA_ERROR:
            return {
                ...state,
                fetchInputError: action.error,
            };

        // edit Data input
        case EDIT_DATAINPUT_REQUEST:
            return {
                ...state,
                editInputLoader: true,
            };
        case EDIT_DATAINPUT_SUCCESS:
            return {
                ...state,
                editInput: action.payload,
            };
        case EDIT_DATAINPUT_ERROR:
            return {
                ...state,
                editInputError: action.error,
            };

        // delete Data input
        case DELETE_DATAINPUT_REQUEST:
            return {
                ...state,
                deleteInputLoader: true,
            };
        case DELETE_DATAINPUT_SUCCESS:
            return {
                ...state,
                deleteInput: action.payload,
            };
        case DELETE_DATAINPUT_ERROR:
            return {
                ...state,
                deleteInputError: action.error,
            };


        //Upload CSV
        // For GET Event List

        case INPUT_GET_EVENT_LIST_SUCCESS:
            return {
                ...state,
                eventListData: action.payload
            };
        case INPUT_GET_EVENT_LIST_ERROR:
            return {
                ...state,
                eventListError: action.error,
            };

        //for Team list
        case INPUT_GET_PARANT_TEAM_SUCCESS:
            return {
                ...state,
                teamListData: action.payload
            };
        case INPUT_GET_PARANT_TEAM_ERROR:
            return {
                ...state,
                teamListError: action.error,
            };
        //for Coach list
        case INPUT_GET_COACH_LIST_SUCCESS:
            return {
                ...state,
                coachListData: action.payload
            };
        case INPUT_GET_COACH_LIST_ERROR:
            return {
                ...state,
                coachListError: action.error,
            };

        //CREATE  EVENT
        case INPUT_POST_CREATE_EVENT_SUCCESS:
            return {
                ...state,
                createEventData: action.payload
            };
        case INPUT_POST_CREATE_EVENT_ERROR:
            return {
                ...state,
                createEventError: action.error,
            };

        //SAVE datasource
        case INPUT_SAVE_SOURCE_SUCCESS:
            return {
                ...state,
                saveDatasourceData: action.payload
            };
        case INPUT_SAVE_SOURCE_ERROR:
            return {
                ...state,
                saveDatasourceError: action.error,
            };

        //SAVE datasource for Rapsodo
        case INPUT_SAVE_RAPSODO_SOURCE_SUCCESS:
            return {
                ...state,
                saveRapsodoData: action.payload
            };
        case INPUT_SAVE_RAPSODO_SOURCE_ERROR:
            return {
                ...state,
                saveRapsodoError: action.error,
            };

        //get Device
        case INPUT_GET_DEVICE_SUCCESS:
            return {
                ...state,
                deviceData: action.payload
            };
        case INPUT_GET_DEVICE_ERROR:
            return {
                ...state,
                deviceError: action.error,
            };
        //get link List
        case INPUT_GET_LINK_SUCCESS:
            return {
                ...state,
                linkData: action.payload
            };
        case INPUT_GET_LINK_ERROR:
            return {
                ...state,
                linkError: action.error,
            };
        //get Device
        case INPUT_GET_OPTION_SUCCESS:
            return {
                ...state,
                optionData: action.payload
            };
        case INPUT_GET_OPTION_ERROR:
            return {
                ...state,
                optionError: action.error,
            };
        //get Device
        case INPUT_GET_RMAP_SUCCESS:
            return {
                ...state,
                mapData: action.payload
            };
        case INPUT_GET_RMAP_ERROR:
            return {
                ...state,
                mapError: action.error,
            };

        //delete Event
        case INPUT_DELETE_EVENT_SUCCESS:
            return {
                ...state,
                deleteEventData: action.payload
            };
        case INPUT_DELETE_EVENT_ERROR:
            return {
                ...state,
                deleteEventError: action.error,
            };

        // get source by event id
        case INPUT_GET_SOURCE_BY_EVENT_SUCCESS:
            return {
                ...state,
                sourceListData: action.payload.result.data.length !== 0 ? action.payload.result.data : []
            };
        case INPUT_GET_SOURCE_BY_EVENT_ERROR:
            return {
                ...state,
                sourceListError: action.error,
            };

        // post upload video for entire event
        case INPUT_POST_UPLOAD_VIDEO_SUCCESS:
            return {
                ...state,
                saveUploadVideoData: action.payload
            };
        case INPUT_POST_UPLOAD_VIDEO_ERROR:
            return {
                ...state,
                saveUploadVideoError: action.error,
            };

        // get row device   
        case INPUT_ROW_DEVICE_DATA_REQUEST:
            return {
                ...state,
                getRowDeviceReq: true,
            };
        case INPUT_ROW_DEVICE_DATA_SUCCESS:
            return {
                ...state,
                getRowDeviceData: action.payload,
            };
        case INPUT_ROW_DEVICE_DATA_ERROR:
            return {
                ...state,
                getRowDeviceError: action.error,
            };

        // get video mapping List for per pitch video
        case INPUT_GET_VIDEO_MAPPING_LIST_REQUEST:
            return {
                ...state,
                videoMappingListReq: true,
            };
        case INPUT_GET_VIDEO_MAPPING_LIST_SUCCESS:
            return {
                ...state,
                videoMappingListData: action.payload,
            };
        case INPUT_GET_VIDEO_MAPPING_LIST_ERROR:
            return {
                ...state,
                videoMappingListError: action.error,
            };

        // map video per pitch 
        case INPUT_MAP_VIDEO_FOR_PER_PITCH_REQUEST:
            return {
                ...state,
                mapVideoPerPitchReq: true,
            };
        case INPUT_MAP_VIDEO_FOR_PER_PITCH_SUCCESS:
            return {
                ...state,
                mapVideoPerPitchData: action.payload,
            };
        case INPUT_MAP_VIDEO_FOR_PER_PITCH_ERROR:
            return {
                ...state,
                mapVideoPerPitchError: action.error,
            };

        // update Device Data
        case INPUT_UPDATE_DEVICE_DATA_SUCCESS:
            return {
                ...state,
                updateDeviceData: action.payload,
            };
        case INPUT_UPDATE_DEVICE_DATA_ERROR:
            return {
                ...state,
                updateDeviceError: action.error,
            };

        // mapping rapsodo option req    
        case INPUT_MAP_RAPSODO_REQUEST:
            return {
                ...state,
                mappingRapsodoReq: true,
            };
        case INPUT_MAP_RAPSODO_SUCCESS:
            return {
                ...state,
                mappingRapsodoData: action.payload,
            };
        case INPUT_MAP_RAPSODO_ERROR:
            return {
                ...state,
                mappingRapsodoError: action.error,
            };

        // analyzer report generated mail   
        case REPORT_GENERATED_MAIL_REQUEST:
            return {
                ...state,
                reportGeneratMailReq: true,
            };
        case REPORT_GENERATED_MAIL_SUCCESS:
            return {
                ...state,
                reportGeneratMailData: action.payload,
            };
        case REPORT_GENERATED_MAIL_ERROR:
            return {
                ...state,
                reportGeneratMailError: action.error,
            };

        // get template list for analyzer in showcase event
        case SHOWCASE_LIST_PLATFORM_SUCCESS:
            return {
                ...state,
                showcaseTemplateList: action.payload,
            };

        case SHOWCASE_LIST_PLATFORM_ERROR:
            return {
                ...state,
                showcaseTemplateListError: action.error,
            };

        // set selected template for analyzer in showcase event
        // case FETCH_SELECTED_TEMPLATE_SUCCESS:
        //     return {
        //         ...state,
        //         selectedTemplate: action.payload,
        //     };

        // case FETCH_SELECTED_TEMPLATE_ERROR:
        //     return {
        //         ...state,
        //         selectedTemplateError: action.error,
        //     };

        //for upload pdf only for pws company
        case UPLOAD_PDF_REQUEST:
            return {
                ...state,
                uploadPDFReq: true,
            };
        case UPLOAD_PDF_SUCCESS:
            return {
                ...state,
                uploadPDFData: action.payload,
            };
        case UPLOAD_PDF_ERROR:
            return {
                ...state,
                uploadPDFError: action.error,
            };
        default:
            return state;
    }
}