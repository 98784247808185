import { 
  FETCH_ALL_SUPER8_REQUEST, 
  FETCH_ALL_SUPER8_SUCCESS, 
  FETCH_ALL_SUPER8_ERROR,

  FETCH_SUPER8_BY_ID_REQUEST,
  FETCH_SUPER8_BY_ID_SUCCESS,
  FETCH_SUPER8_BY_ID_ERROR,  

  FETCH_SUPER8_TITTLE_REQUEST,
  FETCH_SUPER8_TITTLE_SUCCESS,
  FETCH_SUPER8_TITTLE_ERROR,

  CREATE_SUPER8_REQUEST,
  CREATE_SUPER8_SUCCESS,
  CREATE_SUPER8_ERROR,

  UPDATE_SUPER8_REQUEST,
  UPDATE_SUPER8_SUCCESS,
  UPDATE_SUPER8_ERROR,

  DELETE_SUPER8_REQUEST,
  DELETE_SUPER8_SUCCESS,
  DELETE_SUPER8_ERROR
} from '../constants/Super8.const';

const initialState = {
  super8: [],
  super8Error: null,
  super8Loader: false,
  isSuper8Loaded : false,

  allSuper8: [],
  allSuper8Error: null,
  allSuper8Loader: false,
  isAllSuper8Loaded : false,

  super8Tittles: [],
  super8TittlesError: null,
  super8TittlesLoader: false,

  isCreated : false,
  isCreatedError : null,
  isCreatedLoader : false,
  
  isUpdated : false,
  isUpdatedError : null,
  isUpdatedLoader : false,
  
  isDeleted : false,
  isDeletedError : null,
  isDeletedLoader : false 
};

export default function(state = initialState, action) {
  switch (action.type) {

        // For GET Event Data By Id

        case FETCH_SUPER8_BY_ID_REQUEST:
          return {
            ...state,
            super8Loader: true
          };
        case FETCH_SUPER8_BY_ID_SUCCESS:
          return {
            ...state,
            isSuper8Loaded : true,
            super8: action.payload,
            super8Loader: false
          };
        case FETCH_SUPER8_BY_ID_ERROR:
          return {
            ...state,
            super8Error: action.error.message,
            super8Loader: false
          };

    // For GET ALL Super8 Data

    case FETCH_ALL_SUPER8_REQUEST:
      return {
        ...state,
        allSuper8Loader: true
      };
    case FETCH_ALL_SUPER8_SUCCESS:
      return {
        ...state,
        isAllSuper8Loaded : true,
        allSuper8: action.payload,
        allSuper8Loader: false
      };
    case FETCH_ALL_SUPER8_ERROR:
      return {
        ...state,
        allSuper8Error: action.error.message,
        allSuper8Loader: false
      };


    // For GET Event Tittles Only

    case FETCH_SUPER8_TITTLE_REQUEST:
      return {
        ...state,
        super8TittlesLoader: true
      };
    case FETCH_SUPER8_TITTLE_SUCCESS:
      return {
        ...state,
        super8Tittles: action.payload,
        super8TittlesLoader: false
      };
    case FETCH_SUPER8_TITTLE_ERROR:
      return {
        ...state,
        super8TittlesError: action.error.message,
        super8TittlesLoader: false
      };

    // For CREATE Event Data

    case CREATE_SUPER8_REQUEST:
      return {
        ...state,
        isCreatedLoader: true
      };
    case CREATE_SUPER8_SUCCESS:
      return {
        ...state,
        isCreated: true,
        isCreatedLoader: false
      };
    case CREATE_SUPER8_ERROR:
      return {
        ...state,
        isCreatedError: action.error,
        isCreatedLoader: false
      };

    // For UPDATE Event Data

    case UPDATE_SUPER8_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPDATE_SUPER8_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_SUPER8_ERROR:
      return {
        ...state,
        isUpdatedError: action.error,
        isUpdatedLoader: false
      };

    // For DELETE Event Data

    case DELETE_SUPER8_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case DELETE_SUPER8_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case DELETE_SUPER8_ERROR:
      return {
        ...state,
        isDeletedError: action.error,
        isDeletedLoader: false
      };

    default:
      return state;
  }
}