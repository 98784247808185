export const FETCH_SPOTLIGHT_CONTENT_REQUEST = 'FETCH_SPOTLIGHT_CONTENT_REQUEST';
export const FETCH_SPOTLIGHT_CONTENT_SUCCESS = 'FETCH_SPOTLIGHT_CONTENT_SUCCESS';
export const FETCH_SPOTLIGHT_CONTENT_ERROR   = 'FETCH_SPOTLIGHT_CONTENT_ERROR';

export const GET_SPOTLIGHT_CONTENT_BY_ID_REQUEST = 'GET_SPOTLIGHT_CONTENT_BY_ID_REQUEST';
export const GET_SPOTLIGHT_CONTENT_BY_ID_SUCCESS = 'GET_SPOTLIGHT_CONTENT_BY_ID_SUCCESS';
export const GET_SPOTLIGHT_CONTENT_BY_ID_ERROR   = 'GET_SPOTLIGHT_CONTENT_BY_ID_ERROR';

export const UPDATE_SPOTLIGHT_CONTENT_REQUEST = 'UPDATE_SPOTLIGHT_CONTENT_REQUEST';
export const UPDATE_SPOTLIGHT_CONTENT_SUCCESS = 'UPDATE_SPOTLIGHT_CONTENT_SUCCESS';
export const UPDATE_SPOTLIGHT_CONTENT_ERROR   = 'UPDATE_SPOTLIGHT_CONTENT_ERROR';