import {
    ADD_PLAYER_BULK_REQUEST,
    ADD_PLAYER_BULK_SUCCESS,
    ADD_PLAYER_BULK_ERROR,

    SELECTED_COACH_NAME_LIST_REQUEST,
    SELECTED_COACH_NAME_LIST_SUCCESS,
    SELECTED_COACH_NAME_LIST_ERROR,

    SELECTED_ROSTER_NAME_LIST_REQUEST,
    SELECTED_ROSTER_NAME_LIST_SUCCESS,
    SELECTED_ROSTER_NAME_LIST_ERROR
} from "../../constants/BulkUpload/BulkUploadPlayer.const";

const initialState = {
    bulkPlayerReq: false,
    bulkPlayerData: null,
    bulkPlayerError: null,
    bulkPlayerLoader: false,

    coachListPlayerReq: false,
    coachListPlayerData: null,
    coachListPlayerError: null,
    coachListPlayerLoader: false,

    rosterListReq: false,
    rosterListData: null,
    rosterListError: null,
    rosterListLoader: false
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Add bulk upload for Player
        case ADD_PLAYER_BULK_REQUEST:
            return {
                ...state,
                bulkPlayerReq: true,
                bulkPlayerLoader: true,
            };
        case ADD_PLAYER_BULK_SUCCESS:
            return {
                ...state,
                bulkPlayerData: action.payload,
                bulkPlayerLoader: true,
            };
        case ADD_PLAYER_BULK_ERROR:
            return {
                ...state,
                bulkPlayerError: action.error,
                bulkPlayerLoader: true,
            };

        // For Select caoch Name for this player
        case SELECTED_COACH_NAME_LIST_REQUEST:
            return {
                ...state,
                coachListPlayerReq: true,
                coachListPlayerLoader: true,
            };
        case SELECTED_COACH_NAME_LIST_SUCCESS:
            return {
                ...state,
                coachListPlayerData: action.payload,
                coachListPlayerLoader: true,
            };
        case SELECTED_COACH_NAME_LIST_ERROR:
            return {
                ...state,
                coachListPlayerError: action.error,
                coachListPlayerLoader: true,
            };

        // For Select roster Name for this player
        case SELECTED_ROSTER_NAME_LIST_REQUEST:
            return {
                ...state,
                rosterListReq: true,
                rosterListLoader: true,
            };
        case SELECTED_ROSTER_NAME_LIST_SUCCESS:
            return {
                ...state,
                rosterListData: action.payload,
                rosterListLoader: true,
            };
        case SELECTED_ROSTER_NAME_LIST_ERROR:
            return {
                ...state,
                rosterListError: action.error,
                rosterListLoader: true,
            };

        default:
            return state;
    }
}