export const GET_BANNER_LIST_SUCCESS = "GET_BANNER_LIST_SUCCESS";
export const GET_BANNER_LIST_ERROR = "GET_BANNER_LIST_ERROR";
export const GET_BANNER_LIST_REQUEST = "GET_BANNER_LIST_REQUEST";

export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";
export const FETCH_BANNER_ERROR = "FETCH_BANNER_ERROR";
export const FETCH_BANNER_REQUEST = "FETCH_BANNER_REQUEST";


export const EDIT_BANNER_SUCCESS = "EDIT_BANNER_SUCCESS";
export const EDIT_BANNER_ERROR = "EDIT_BANNER_ERROR";
export const EDIT_BANNER_REQUEST = "EDIT_BANNER_REQUEST";