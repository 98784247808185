import {
  GET_RECAP_EVENT_LIST_REQUEST,
  GET_RECAP_EVENT_LIST_SUCCESS,
  GET_RECAP_EVENT_LIST_ERROR,

  GET_RECAP_INFO_REQUEST,
  GET_RECAP_INFO_SUCCESS,
  GET_RECAP_INFO_ERROR,

  FETCH_ADD_RECAP_INFO_REQUEST,
  FETCH_ADD_RECAP_INFO_SUCCESS,
  FETCH_ADD_RECAP_INFO_ERROR,

  CREATE_ADD_RECAP_SCORE_REQUEST,
  CREATE_ADD_RECAP_SCORE_SUCCESS,
  CREATE_ADD_RECAP_SCORE_ERROR,

  UPDATE_ADD_RECAP_SCORE_REQUEST,
  UPDATE_ADD_RECAP_SCORE_SUCCESS,
  UPDATE_ADD_RECAP_SCORE_ERROR,

  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_ERROR,

  ROSTER_LIST_REQUEST,
  ROSTER_LIST_SUCCESS,
  ROSTER_LIST_ERROR,

  EVENTSCORE_BY_ID_REQUEST,
  EVENTSCORE_BY_ID_SUCCESS,
  EVENTSCORE_BY_ID_ERROR,

  DELETE_SCORE_REQUEST,
  DELETE_SCORE_SUCCESS,
  DELETE_SCORE_ERROR,
} from "../constants/RecapInfo.const";

const initialState = {
  recapData: [],
  recapError: null,
  recapLoader: false,
  isRecapLoaded: false,

  recapInfo: null,
  recapInfoError: null,

  addRecapInfo: null,
  addRecapInfoError: null,
  isAddRecapInfoLoader: false,

  addScoreRecap: null,
  addScoreRecapError: null,
  isAddScoreRecapLoader: false,

  editScoreRecap: null,
  editScoreRecapError: null,
  isEditScoreRecapLoader: false,

  teamList: [],
  teamListError: null,
  isTeamListLoader: false,

  rosterList: [],
  rosterListError: null,

  eventScoreById: null,
  eventScoreByIdError: null,
  matchList: [],

  deleteScore: null,
  deleteScoreError: null
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For recap event list data
    case GET_RECAP_EVENT_LIST_REQUEST:
      return {
        ...state,
        recapLoader: true,
      };
    case GET_RECAP_EVENT_LIST_SUCCESS:
      return {
        ...state,
        isRecapLoaded: true,
        recapData: action.payload,
        recapLoader: false,
      };
    case GET_RECAP_EVENT_LIST_ERROR:
      return {
        ...state,
        recapError: action.error.message,
        recapLoader: false,
      };

    // get Recap Info 
    case GET_RECAP_INFO_SUCCESS:
      return {
        ...state,
        recapInfo: action.payload,
      };
    case GET_RECAP_INFO_ERROR:
      return {
        ...state,
        recapInfoError: action.error,
      };

    // For Add/Edit recap event info
    case FETCH_ADD_RECAP_INFO_REQUEST:
      return {
        ...state,
        isAddRecapInfoLoader: true
      };
    case FETCH_ADD_RECAP_INFO_SUCCESS:
      return {
        ...state,
        addRecapInfo: action.payload,
        isAddRecapInfoLoader: false
      };
    case FETCH_ADD_RECAP_INFO_ERROR:
      return {
        ...state,
        addRecapInfoError: action.error,
        isAddRecapInfoLoader: false
      };

    // For create recap score event info
    case CREATE_ADD_RECAP_SCORE_REQUEST:
      return {
        ...state,
        isAddScoreRecapLoader: true
      };
    case CREATE_ADD_RECAP_SCORE_SUCCESS:
      return {
        ...state,
        addScoreRecap: action.payload,
        isAddScoreRecapLoader: false
      };
    case CREATE_ADD_RECAP_SCORE_ERROR:
      return {
        ...state,
        addScoreRecapError: action.error,
        isAddScoreRecapLoader: false
      };

    // For Edit recap score event info
    case UPDATE_ADD_RECAP_SCORE_REQUEST:
      return {
        ...state,
        isEditScoreRecapLoader: true
      };
    case UPDATE_ADD_RECAP_SCORE_SUCCESS:
      return {
        ...state,
        editScoreRecap: action.payload,
        isEditScoreRecapLoader: false
      };
    case UPDATE_ADD_RECAP_SCORE_ERROR:
      return {
        ...state,
        editScoreRecapError: action.error,
        isEditScoreRecapLoader: false
      };

    // For Edit recap score event info
    case TEAM_LIST_REQUEST:
      return {
        ...state,
        isTeamListLoader: true
      };
    case TEAM_LIST_SUCCESS:
      return {
        ...state,
        teamList: action.payload,
        isTeamListLoader: false
      };
    case TEAM_LIST_ERROR:
      return {
        ...state,
        teamListError: action.error,
        isTeamListLoader: false
      };

    // get Roster Group List
    case ROSTER_LIST_SUCCESS:
      return {
        ...state,
        rosterList: action.payload,
      };
    case ROSTER_LIST_ERROR:
      return {
        ...state,
        rosterListError: action.error,
      };

    // get score detail by id
    case EVENTSCORE_BY_ID_SUCCESS:
      const matchs = action.payload ? action.payload.matchs.map((match) => {
        return {
          matchNo: match.matchNo,
          homeTeamId: match['homeTeamId']._id,
          homeTeamScore: match.homeTeamScore,
          awayTeamId: match['awayTeamId']._id,
          awayTeamScore: match.awayTeamScore,
          winner: match.winner
        }
      }) : []
      return {
        ...state,
        eventScoreById: action.payload,
        matchList: matchs
      };
    case EVENTSCORE_BY_ID_ERROR:
      return {
        ...state,
        eventScoreByIdError: action.error,
      };


    // delete Recap Score 
    case DELETE_SCORE_SUCCESS:
      return {
        ...state,
        deleteScore: action.payload,
      };
    case DELETE_SCORE_ERROR:
      return {
        ...state,
        deleteScoreError: action.error,
      };

    default:
      return state;
  }

}
