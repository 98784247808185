import {
    BC_SELECTED_TEAM_NAME_LIST_REQUEST,
    BC_SELECTED_TEAM_NAME_LIST_SUCCESS,
    BC_SELECTED_TEAM_NAME_LIST_ERROR,

    BC_ADD_COACH_BULK_REQUEST,
    BC_ADD_COACH_BULK_SUCCESS,
    BC_ADD_COACH_BULK_ERROR,
} from "../../../../constants/BaseballCloud/Users/BulkUpload/BulkUploadCoach.const";

const initialState = {
    teamListCoachReq: false,
    teamListCoachData: null,
    teamListCoachError: null,
    teamListCoachLoader: false,

    bulkCoachReq: false,
    bulkCoachData: null,
    bulkCoachError: null,
    bulkCoachLoader: false
};

export default function (state = initialState, action) {
    switch (action.type) {

        // For Select Team Name for this Coach
        case BC_SELECTED_TEAM_NAME_LIST_REQUEST:
            return {
                ...state,
                teamListCoachReq: true,
                teamListCoachLoader: true,
            };
        case BC_SELECTED_TEAM_NAME_LIST_SUCCESS:
            return {
                ...state,
                teamListCoachData: action.payload,
                teamListCoachLoader: true,
            };
        case BC_SELECTED_TEAM_NAME_LIST_ERROR:
            return {
                ...state,
                teamListCoachError: action.error,
                teamListCoachLoader: true,
            };

        // Add bulk upload for coach
        case BC_ADD_COACH_BULK_REQUEST:
            return {
                ...state,
                bulkCoachReq: true,
                bulkCoachLoader: true,
            };
        case BC_ADD_COACH_BULK_SUCCESS:
            return {
                ...state,
                bulkCoachData: action.payload,
                bulkCoachLoader: true,
            };
        case BC_ADD_COACH_BULK_ERROR:
            return {
                ...state,
                bulkCoachError: action.error,
                bulkCoachLoader: true,
            };

        default:
            return state;
    }
}