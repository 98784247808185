import {
  LIST_TEMPLATE_SUCCESS,
  LIST_TEMPLATE_ERROR,
  
  LIST_USER_FOR_TEMPLATE_SUCCESS,
  LIST_USER_FOR_TEMPLATE_ERROR,
  
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_ERROR,

  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,

  GET_TEMPLATE_DATA_SUCCESS,
  GET_TEMPLATE_DATA_ERROR,

  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_ERROR,

  ALL_LIST_USER_FOR_TEMPLATE_SUCCESS,
  ALL_LIST_USER_FOR_TEMPLATE_ERROR,
} from "../../../constants/BaseballCloud/Analyzer/Template.const";

const initialState = {
  tempList: null,
  tempListError: null,

  userList: null,
  userListError: null,

  createTemp: null,
  createTempError: null,
  
  deleteTemp: null,
  deleteTempError: null,

  getTempData: null,
  getTempDataError: null,
  
  editTemp: null,
  editTempError: null,

  alluserList: null,
  alluserListError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // get template list for analyzer
    case LIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        tempList: action.payload,
      };

    case LIST_TEMPLATE_ERROR:
      return {
        ...state,
        tempListError: action.error,
      };

    // get user list for template analyzer
    case LIST_USER_FOR_TEMPLATE_SUCCESS:
      return {
        ...state,
        userList: action.payload,
      };

    case LIST_USER_FOR_TEMPLATE_ERROR:
      return {
        ...state,
        userListError: action.error,
      };

    // create Template
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        createTemp: action.payload,
      };

    case CREATE_TEMPLATE_ERROR:
      return {
        ...state,
        createTempError: action.error,
      };

    // delete Template
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteTemp: action.payload,
      };

    case DELETE_TEMPLATE_ERROR:
      return {
        ...state,
        deleteTempError: action.error,
      };

    // get id wise Template
    case GET_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        getTempData: action.payload,
      };

    case GET_TEMPLATE_DATA_ERROR:
      return {
        ...state,
        getTempDataError: action.error,
      };

    // edit Template
    case EDIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        editTemp: action.payload,
      };

    case EDIT_TEMPLATE_ERROR:
      return {
        ...state,
        editTempError: action.error,
      };

    // get user list for template analyzer
    case ALL_LIST_USER_FOR_TEMPLATE_SUCCESS:
      return {
        ...state,
        alluserList: action.payload,
      };

    case ALL_LIST_USER_FOR_TEMPLATE_ERROR:
      return {
        ...state,
        alluserListError: action.error,
      };

    default:
      return state;
  }
}
