import { 
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_SUCCESS, 
    FETCH_ORDER_ERROR,
  } from '../constants/StoreOrder.const';
  
  const initialState = {
    orders: [],
    orderError: null,
    orderLoader: false,
    isOrdersLoaded: false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
      case FETCH_ORDER_REQUEST:
        return {
          ...state,
          orderLoader: true
        };
      case FETCH_ORDER_SUCCESS:
        return {
          ...state,
          orders: action.payload,
          orderLoader: false,
          isOrdersLoaded: true
        };
      case FETCH_ORDER_ERROR:
        return {
          ...state,
          orderError: action.error.message,
          orderLoader: false
        };
      default:
        return state;
    }
  }