
export const FETCH_ALL_LEAGUES_REQUEST = 'FETCH_ALL_LEAGUES_REQUEST';
export const FETCH_ALL_LEAGUES_SUCCESS = 'FETCH_ALL_LEAGUES_SUCCESS';
export const FETCH_ALL_LEAGUES_ERROR   = 'FETCH_ALL_LEAGUES_ERROR';

export const FETCH_LEAGUE_BY_ID_REQUEST = 'FETCH_LEAGUE_BY_ID_REQUEST';
export const FETCH_LEAGUE_BY_ID_SUCCESS = 'FETCH_LEAGUE_BY_ID_SUCCESS';
export const FETCH_LEAGUE_BY_ID_ERROR   = 'FETCH_LEAGUE_BY_ID_ERROR';

export const CREATE_LEAGUE_REQUEST = 'CREATE_LEAGUE_REQUEST';
export const CREATE_LEAGUE_SUCCESS = 'CREATE_LEAGUE_SUCCESS';
export const CREATE_LEAGUE_ERROR   = 'CREATE_LEAGUE_ERROR';

export const UPDATE_LEAGUE_REQUEST = 'UPDATE_LEAGUE_REQUEST';
export const UPDATE_LEAGUE_SUCCESS = 'UPDATE_LEAGUE_SUCCESS';
export const UPDATE_LEAGUE_ERROR   = 'UPDATE_LEAGUE_ERROR';

export const UPDATE_LEAGUE_ACTIVATION_REQUEST = 'UPDATE_LEAGUE_ACTIVATION_REQUEST';
export const UPDATE_LEAGUE_ACTIVATION_SUCCESS = 'UPDATE_LEAGUE_ACTIVATION_SUCCESS';
export const UPDATE_LEAGUE_ACTIVATION_ERROR   = 'UPDATE_LEAGUE_ACTIVATION_ERROR';

export const DELETE_LEAGUE_REQUEST = 'DELETE_LEAGUE_REQUEST';
export const DELETE_LEAGUE_SUCCESS = 'DELETE_LEAGUE_SUCCESS';
export const DELETE_LEAGUE_ERROR   = 'DELETE_LEAGUE_ERROR';

export const FETCH_LEAGUES_TITTLE_REQUEST = 'FETCH_LEAGUES_TITTLE_REQUEST';
export const FETCH_LEAGUES_TITTLE_SUCCESS = 'FETCH_LEAGUES_TITTLE_SUCCESS';
export const FETCH_LEAGUES_TITTLE_ERROR   = 'FETCH_LEAGUES_TITTLE_ERROR';

export const FETCH_UPCOMING_EVENT_REQUEST = 'FETCH_UPCOMING_EVENT_REQUEST';
export const FETCH_UPCOMING_EVENT_SUCCESS = 'FETCH_UPCOMING_EVENT_SUCCESS';
export const FETCH_UPCOMING_EVENT_ERROR   = 'FETCH_UPCOMING_EVENT_ERROR';

export const FETCH_EVENT_LOCATION_2_REQUEST = 'FETCH_EVENT_LOCATION_2_REQUEST';
export const FETCH_EVENT_LOCATION_2_SUCCESS = 'FETCH_EVENT_LOCATION_2_SUCCESS';
export const FETCH_EVENT_LOCATION_2_ERROR   = 'FETCH_EVENT_LOCATION_2_ERROR';