// For COUPON CODE.

export const FETCH_COUPON_CODE_REQUEST = 'FETCH_COUPON_CODE_REQUEST';
export const FETCH_COUPON_CODE_SUCCESS = 'FETCH_COUPON_CODE_SUCCESS';
export const FETCH_COUPON_CODE_ERROR   = 'FETCH_COUPON_CODE_ERROR';

export const FETCH_COUPON_CODE_BY_ID_REQUEST = 'FETCH_COUPON_CODE_BY_ID_REQUEST';
export const FETCH_COUPON_CODE_BY_ID_SUCCESS = 'FETCH_COUPON_CODE_BY_ID_SUCCESS';
export const FETCH_COUPON_CODE_BY_ID_ERROR   = 'FETCH_COUPON_CODE_BY_ID_ERROR';

export const UPDATE_COUPON_CODE_REQUEST = 'UPDATE_COUPON_CODE_REQUEST';
export const UPDATE_COUPON_CODE_SUCCESS = 'UPDATE_COUPON_CODE_SUCCESS';
export const UPDATE_COUPON_CODE_ERROR   = 'UPDATE_COUPON_CODE_ERROR';

export const DELETE_COUPON_CODE_REQUEST = 'DELETE_COUPON_CODE_REQUEST';
export const DELETE_COUPON_CODE_SUCCESS = 'DELETE_COUPON_CODE_SUCCESS';
export const DELETE_COUPON_CODE_ERROR   = 'DELETE_COUPON_CODE_ERROR';

export const CREATE_COUPON_CODE_REQUEST = 'CREATE_COUPON_CODE_REQUEST';
export const CREATE_COUPON_CODE_SUCCESS = 'CREATE_COUPON_CODE_SUCCESS';
export const CREATE_COUPON_CODE_ERROR   = 'CREATE_COUPON_CODE_ERROR';


// For AD BANNER.

export const FETCH_AD_BANNER_REQUEST = 'FETCH_AD_BANNER_REQUEST';
export const FETCH_AD_BANNER_SUCCESS = 'FETCH_AD_BANNER_SUCCESS';
export const FETCH_AD_BANNER_ERROR   = 'FETCH_AD_BANNER_ERROR';

export const UPDATE_AD_BANNER_REQUEST = 'UPDATE_AD_BANNER_REQUEST';
export const UPDATE_AD_BANNER_SUCCESS = 'UPDATE_AD_BANNER_SUCCESS';
export const UPDATE_AD_BANNER_ERROR   = 'UPDATE_AD_BANNER_ERROR';

export const CREATE_AD_BANNER_REQUEST = 'CREATE_AD_BANNER_REQUEST';
export const CREATE_AD_BANNER_SUCCESS = 'CREATE_AD_BANNER_SUCCESS';
export const CREATE_AD_BANNER_ERROR   = 'CREATE_AD_BANNER_ERROR';


// For REWARDS.

export const FETCH_REWARDS_REQUEST = 'FETCH_REWARDS_REQUEST';
export const FETCH_REWARDS_SUCCESS = 'FETCH_REWARDS_SUCCESS';
export const FETCH_REWARDS_ERROR   = 'FETCH_REWARDS_ERROR';

export const UPDATE_REWARDS_REQUEST = 'UPDATE_REWARDS_REQUEST'; 
export const UPDATE_REWARDS_SUCCESS = 'UPDATE_REWARDS_SUCCESS';
export const UPDATE_REWARDS_ERROR   = 'UPDATE_REWARDS_ERROR';

export const CREATE_REWARDS_REQUEST = 'CREATE_REWARDS_REQUEST';
export const CREATE_REWARDS_SUCCESS = 'CREATE_REWARDS_SUCCESS';
export const CREATE_REWARDS_ERROR   = 'CREATE_REWARDS_ERROR';


// For PUSH

export const FETCH_PUSH_REQUEST = 'FETCH_PUSH_REQUEST';
export const FETCH_PUSH_SUCCESS = 'FETCH_PUSH_SUCCESS';
export const FETCH_PUSH_ERROR   = 'FETCH_PUSH_ERROR';

export const UPDATE_PUSH_REQUEST = 'UPDATE_PUSH_REQUEST';
export const UPDATE_PUSH_SUCCESS = 'UPDATE_PUSH_SUCCESS';
export const UPDATE_PUSH_ERROR   = 'UPDATE_PUSH_ERROR';

export const CREATE_PUSH_REQUEST = 'CREATE_PUSH_REQUEST';
export const CREATE_PUSH_SUCCESS = 'CREATE_PUSH_SUCCESS';
export const CREATE_PUSH_ERROR   = 'CREATE_PUSH_ERROR';