import moment from "moment";
import {
  FETCH_ALL_EVENTS_LEAGUES_REQUEST,
  FETCH_ALL_EVENTS_LEAGUES_SUCCESS,
  FETCH_ALL_EVENTS_LEAGUES_ERROR,

  FETCH_ALL_LEAGUES_REQUEST,
  FETCH_ALL_LEAGUES_SUCCESS,
  FETCH_ALL_LEAGUES_ERROR,

  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,

  FETCH_APP_SIGNUP_GRAPH_REQUEST,
  FETCH_APP_SIGNUP_GRAPH_SUCCESS,
  FETCH_APP_SIGNUP_GRAPH_ERROR,

  FETCH_ALL_STATE_LIST_REQUEST,
  FETCH_ALL_STATE_LIST_SUCCESS,
  FETCH_ALL_STATE_LIST_ERROR,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  FETCH_APP_DC_SIGNUP_GRAPH_SUCCESS,
  FETCH_APP_DC_SIGNUP_GRAPH_ERROR,
  FETCH_APP_DC_PRODUCT_GRAPH_SUCCESS,
  FETCH_APP_DC_PRODUCT_GRAPH_ERROR
} from '../constants/Dashboard.const';

const initialState = {
  topEvents: [],
  topEventsError: null,
  topEventsLoader: false,
  isEventsLoaded: false,

  allEvents: [],
  allEventsError: null,
  allEventsLoader: false,
  isAllEventsLoaded: false,

  leagues: [],
  leaguesError: null,
  leaguesLoader: false,
  isLeaguesLoaded: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,

  appSignupGraphData: [],
  appSignupGraphDataError: null,
  appSignupGraphDataLoader: false,
  isAppSignupGraphDataLoaded: false,

  resetPassData: [],
  resetError: null,
  resetLoader: false,
  isResetLoaded: false,

  allStateListReq: false,
  allStateListData: null,
  allStateListError: null,

  appDCSignupGraphData: null,
  appDCSignupGraphDataError: null,

  appDCProGraphData: null,
  appDCProGraphDataError: null,

  eventFilter:{
    eventType: "0", // base / soft
    eventTeamType: "",
    isPresent: "0", // 2 past, 3 upcoming, 1 live
    companies: "", // id
    name: "", 
    state: "",
    currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
  },
  event1Filter:{
    eventType: 0, // base / soft
    eventTeamType: "",
    isPresent: 0, // 2 past, 3 upcoming, 1 live
    companies: "", // id
    name: "",
    state: "",
    isFeatured: "",
    currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString(),
    isLiveUpcomeEvent: true
  }

};

export default function (state = initialState, action) {
  switch (action.type) {
    // For fetch TOP EVENTS
    case FETCH_ALL_EVENTS_LEAGUES_REQUEST:
      return {
        ...state,
        topEventsLoader: true,
      };
    case FETCH_ALL_EVENTS_LEAGUES_SUCCESS:
      return {
        ...state,
        isEventsLoaded: true,
        topEvents: action.payload,
        topEventsLoader: false,
      };
    case FETCH_ALL_EVENTS_LEAGUES_ERROR:
      return {
        ...state,
        topEventsError: action.error.message,
        topEventsLoader: false,
      };

       // For fetch all EVENTS
    case "FETCH_EVENTS_LEAGUES_REQUEST":
      return {
        ...state,
        allEventsLoader: true,
      };
    case "FETCH_EVENTS_LEAGUES_SUCCESS":
      return {
        ...state,
        allEvents: action.payload,
        isAllEventsLoaded: true,
      };
    case "FETCH_EVENTS_LEAGUES_ERROR":
      return {
        ...state,
        allEventsError: action.error.message,
        allEventsLoader: false,
      };

    // For fetch LEAGUES
    case FETCH_ALL_LEAGUES_REQUEST:
      return {
        ...state,
        leaguesLoader: true,
      };
    case FETCH_ALL_LEAGUES_SUCCESS:
      return {
        ...state,
        isLeaguesLoaded: true,
        leagues: action.payload,
        leaguesLoader: false,
      };
    case FETCH_ALL_LEAGUES_ERROR:
      return {
        ...state,
        leaguesError: action.error.message,
        leaguesLoader: false,
      };

    // For fetch APP SIGNUP GRAPHS

    case FETCH_APP_SIGNUP_GRAPH_REQUEST:
      return {
        ...state,
        appSignupGraphDataLoader: true,
      };
    case FETCH_APP_SIGNUP_GRAPH_SUCCESS:
      return {
        ...state,
        isAppSignupGraphDataLoaded: true,
        appSignupGraphData: action.payload,
        appSignupGraphDataLoader: false,
      };
    case FETCH_APP_SIGNUP_GRAPH_ERROR:
      return {
        ...state,
        appSignupGraphDataError: action.error.message,
        appSignupGraphDataLoader: false,
      };

    // For DELETE Event Data

    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        isDeletedLoader: true,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false,
      };
    case DELETE_EVENT_ERROR:
      return {
        ...state,
        isDeletedError: action.error,
        isDeletedLoader: false,
      };

    // For Login reset password
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        resetLoader: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetLoaded: true,
        resetPassData: action.payload,
        resetLoader: false,
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        resetError: action.error.message,
        resetLoader: false,
      };

    // get all state req    
    case FETCH_ALL_STATE_LIST_REQUEST:
      return {
        ...state,
        allStateListReq: true,
      };
    case FETCH_ALL_STATE_LIST_SUCCESS:
      return {
        ...state,
        allStateListData: action.payload,
      };
    case FETCH_ALL_STATE_LIST_ERROR:
      return {
        ...state,
        allStateListError: action.error,
      };

      // For fetch APP SIGNUP GRAPHS

    case FETCH_APP_DC_SIGNUP_GRAPH_SUCCESS:
      return {
        ...state,
        appDCSignupGraphData: action.payload,
      };
    case FETCH_APP_DC_SIGNUP_GRAPH_ERROR:
      return {
        ...state,
        appDCSignupGraphDataError: action.error.message,
      };

    // For fetch APP Product GRAPHS
    
    case FETCH_APP_DC_PRODUCT_GRAPH_SUCCESS:
      return {
        ...state,
        appDCProGraphData: action.payload,
      };
    case FETCH_APP_DC_PRODUCT_GRAPH_ERROR:
      return {
        ...state,
        appDCProGraphDataError: action.error.message,
      };


    default:
      return state;
  }
}
