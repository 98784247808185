
export const GET_D1_AVERAGE_LIST_SUCCESS = "GET_D1_AVERAGE_LIST_SUCCESS";
export const GET_D1_AVERAGE_LIST_ERROR = "GET_D1_AVERAGE_LIST_ERROR";
export const GET_D1_AVERAGE_LIST_REQUEST = "GET_D1_AVERAGE_LIST_REQUEST";

export const TEAM_SETTING_SUCCESS = "TEAM_SETTING_SUCCESS";
export const TEAM_SETTING_ERROR = "TEAM_SETTING_ERROR";
export const TEAM_SETTING_REQUEST = "TEAM_SETTING_REQUEST";

export const D1_AVERAGE_LIST_SUCCESS = "D1_AVERAGE_LIST_SUCCESS";
export const D1_AVERAGE_LIST_ERROR = "D1_AVERAGE_LIST_ERROR";
export const D1_AVERAGE_LIST_REQUEST = "D1_AVERAGE_LIST_REQUEST";

export const D1_SOFT_AVERAGE_LIST_SUCCESS = "D1_SOFT_AVERAGE_LIST_SUCCESS";
export const D1_SOFT_AVERAGE_LIST_ERROR = "D1_SOFT_AVERAGE_LIST_ERROR";

export const D1_IND_AVERAGE_LIST_SUCCESS = "D1_IND_AVERAGE_LIST_SUCCESS";
export const D1_IND_AVERAGE_LIST_ERROR = "D1_IND_AVERAGE_LIST_ERROR";
