import {
  BRACKET_LIST_SUCCESS,
  BRACKET_LIST_ERROR,
  DELETE_BRACKET_SUCCESS,
  DELETE_BRACKET_ERROR,
  MOVE_BRACKET_SUCCESS,
  MOVE_BRACKET_ERROR,
  GET_ROUND_SUCCESS,
  GET_ROUND_ERROR,
  GET_BRACKET_CHART_SUCCESS,
  GET_BRACKET_CHART_ERROR,

  CREATE_GAME_SUCCESS,
  CREATE_GAME_ERROR,

  FETCH_GAME_SUCCESS,
  FETCH_GAME_ERROR,

  UPDATE_GAME_SUCCESS,
  UPDATE_GAME_ERROR,

  GET_POOL_ROUND_SUCCESS,

  CREATE_BRACKET_TEMPLATE_SUCCESS,
  CREATE_BRACKET_TEMPLATE_ERROR,

  GET_BRACKET_TEMPLATE_SUCCESS,
  GET_BRACKET_TEMPLATE_ERROR,

  GET_BRACKET_NUMBER_SUCCESS,
  GET_BRACKET_NUMBER_ERROR,

  GET_BRACKET_IMPORT_SUCCESS,
  GET_BRACKET_IMPORT_ERROR,

  GET_BRACKET_DELETE_SUCCESS,
  GET_BRACKET_DELETE_ERROR,

  GET_EDIT_TEMPLATE_VIEW_RESPONSE,
  GET_EDIT_TEMPLATE_VIEW_ERROR,

  EDIT_BRACKET_TEMPLATE_SUCCESS,
  EDIT_BRACKET_TEMPLATE_ERROR,

  GET_CO_CAMP_TEAM_LIST_SUCCESS,
  GET_CO_CAMP_TEAM_LIST_ERROR,

  SAVE_CO_CAMP_TEAM_SUCCESS,
  SAVE_CO_CAMP_TEAM_ERROR,

  GET_CO_CAMP_TEAM_SUCCESS,
  GET_CO_CAMP_TEAM_ERROR,

  GET_GAMES_ARRAY_SUCCESS,
  SAVE_BRACKET_SUCCESS,
  BRACKET_TEAM_LIST_SUCCESS,
  GET_BRACKET_PREVIEW_SUCCESS,
  
} from '../../constants/Scheduling/Bracket.const';

const initialState = {
  breakingList: [],
  breakingListError: null,

  deleteBracket: null,
  deleteBracketError: null,

  moveBracket: null,
  moveBracketError: null,

  rounds: null,
  roundsError: null,

  homeGames: null,
  homeGamesError: null,

  awayGames: null,
  awayGamesError: null,

  bracketChartData: null,
  bracketChartError: null,

  createGameData: null,
  createGameDataError: null,

  fetchGameData: null,
  fetchGameDataError: null,

  updateGameData: null,
  updateGameDataError: null,

  poolRounds: null,

  createBracketTemplateResposne: null,
  createBracketTemplateError: null,

  getBracketTemplateListResponse: null,
  getBracketTemplateListError: null,

  getNoOfSeedTeamResponse: null,
  getNoOfSeedTeamError: null,

  importTemplateResponse: null,
  importTemplateError: null,

  deleteTemplateResponse: null,
  deleteTemplateError: null,

  editTemplateViewResponse: null,
  editTemplateViewError: null,

  editTemplateResponse: null,
  editTemplateError: null,

  coCampTeamList: [],
  coCampTeamError: null,

  saveCoCampTeams: null,
  saveCoCampTeamsError: null,

  coCampTeam: [],
  coCampTeamError: null,

  teamApiResponse: null,
  saveBracketResponse: null,
  gamesList: null,

  getNoOfSeedTeamResponse: null,
  getNoOfSeedTeamError: null,

  getBracketTemplateListResponse: null,
  getBracketTemplateListError: null,

  previewTemplateResponse: null,
  importTemplateError: null,

  deleteTemplateResponse: null,
  deleteTemplateError: null,

  coCampTeamList: [],
  coCampTeamError: null,

  saveCoCampTeams: null,
  saveCoCampTeamsError: null,

  coCampTeam: [],
  coCampTeamError: null,

  teamApiResponse: null,
  saveBracketResponse: null,
  gamesList: null,

  getNoOfSeedTeamResponse: null,
  getNoOfSeedTeamError: null,

  getBracketTemplateListResponse: null,
  getBracketTemplateListError: null,

  importTemplateResponse: null,
  importTemplateError: null,

  previewTemplateResponse: null,
  importTemplateError: null,

  deleteTemplateResponse: null,
  deleteTemplateError: null,

  coCampTeamList: [],
  coCampTeamError: null,

  saveCoCampTeams: null,
  saveCoCampTeamsError: null,

  coCampTeam: [],
  coCampTeamError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For bracket list
    case BRACKET_LIST_SUCCESS:
      return {
        ...state,
        breakingList: action.payload
      };
    case BRACKET_LIST_ERROR:
      return {
        ...state,
        breakingListError: action.error
      };

    // For delete bracket
    case DELETE_BRACKET_SUCCESS:
      return {
        ...state,
        deleteBracket: action.payload
      };
    case DELETE_BRACKET_ERROR:
      return {
        ...state,
        deleteBracketError: action.error
      };

    // For move bracket
    case MOVE_BRACKET_SUCCESS:
      return {
        ...state,
        moveBracket: action.payload
      };
    case MOVE_BRACKET_ERROR:
      return {
        ...state,
        moveBracketError: action.error
      };

    //Get Round and game list
    case GET_ROUND_SUCCESS:
      return {
        ...state,
        rounds: action.payload
      };
    case GET_ROUND_ERROR:
      return {
        ...state,
        roundsError: action.error
      };

    //Get Pool Round and game list
    case GET_POOL_ROUND_SUCCESS:
      return {
        ...state,
        poolRounds: action.payload
      };
    // case GET_ROUND_ERROR:
    //   return {
    //     ...state,
    //     roundsError: action.error
    //   };

    //Get Game by round
    case 'GET_HOME_GAME_SUCCESS':
      return {
        ...state,
        homeGames: action.payload
      };
    case 'GET_HOME_GAME_ERROR':
      return {
        ...state,
        homeGamesError: action.error
      };

    //Get Game by round
    case 'GET_AWAY_GAME_SUCCESS':
      return {
        ...state,
        awayGames: action.payload
      };
    case 'GET_AWAY_GAME_ERROR':
      return {
        ...state,
        awayGamesError: action.error
      };


    //Get bracket Chart data
    case GET_BRACKET_CHART_SUCCESS:
      return {
        ...state,
        bracketChartData: action.payload
      };
    case GET_BRACKET_CHART_ERROR:
      return {
        ...state,
        bracketChartError: action.error
      };

    //Create Game
    case CREATE_GAME_SUCCESS:
      return {
        ...state,
        createGameData: action.payload
      };
    case CREATE_GAME_ERROR:
      return {
        ...state,
        createGameDataError: action.error
      };

    //fetch Game
    case FETCH_GAME_SUCCESS:
      return {
        ...state,
        fetchGameData: action.payload
      };
    case FETCH_GAME_ERROR:
      return {
        ...state,
        fetchGameDataError: action.error
      };

    //update Game
    case UPDATE_GAME_SUCCESS:
      return {
        ...state,
        updateGameData: action.payload
      };
    case UPDATE_GAME_ERROR:
      return {
        ...state,
        updateGameDataError: action.error
      };

    // Create Bracket Template 
    case CREATE_BRACKET_TEMPLATE_SUCCESS:
      return {
        ...state,
        createBracketTemplateResposne: action.payload
      };
    case CREATE_BRACKET_TEMPLATE_ERROR:
      return {
        ...state,
        createBracketTemplateError: action.error
      };

    // GET Bracket Template List
    case GET_BRACKET_TEMPLATE_SUCCESS:
      return {
        ...state,
        getBracketTemplateListResponse: action.payload
      };
    case GET_BRACKET_TEMPLATE_ERROR:
      return {
        ...state,
        getBracketTemplateListError: action.error
      };

    // GET Import Bracket 
    case GET_BRACKET_IMPORT_SUCCESS:
      return {
        ...state,
        importTemplateResponse: action.payload
      };
    case GET_BRACKET_IMPORT_ERROR:
      return {
        ...state,
        importTemplateError: action.error
      };

    // GET No Of Team
    case GET_BRACKET_NUMBER_SUCCESS:
      return {
        ...state,
        getNoOfSeedTeamResponse: action.payload
      };
    case GET_BRACKET_NUMBER_ERROR:
      return {
        ...state,
        getNoOfSeedTeamError: action.error
      };

    // Delete Bracket Template
    case GET_BRACKET_DELETE_SUCCESS:
      return {
        ...state,
        deleteTemplateResponse: action.payload
      };
    case GET_BRACKET_DELETE_ERROR:
      return {
        ...state,
        deleteTemplateError: action.error
      };

    // Edit Bracket Template View
    case GET_EDIT_TEMPLATE_VIEW_RESPONSE:
      return {
        ...state,
        editTemplateViewResponse: action.payload
      };
    case GET_EDIT_TEMPLATE_VIEW_ERROR:
      return {
        ...state,
        editTemplateViewError: action.error
      };

    // Edit Bracket Template Success
    case EDIT_BRACKET_TEMPLATE_SUCCESS:
      return {
        ...state,
        editTemplateResponse: action.payload
      };
    case EDIT_BRACKET_TEMPLATE_ERROR:
      return {
        ...state,
        editTemplateError: action.error
      };


    // For co campion team list
    case GET_CO_CAMP_TEAM_LIST_SUCCESS:
      return {
        ...state,
        coCampTeamList: action.payload
      };
    case GET_CO_CAMP_TEAM_LIST_ERROR:
      return {
        ...state,
        coCampTeamError: action.error
      };

    // submit select co campion team
    case SAVE_CO_CAMP_TEAM_SUCCESS:
      return {
        ...state,
        saveCoCampTeams: action.payload
      };
    case SAVE_CO_CAMP_TEAM_ERROR:
      return {
        ...state,
        saveCoCampTeamsError: action.error
      };

    // fetch selected co champion team
    case GET_CO_CAMP_TEAM_SUCCESS:
      return {
        ...state,
        coCampTeam: action.payload
      };
    case GET_CO_CAMP_TEAM_ERROR:
      return {
        ...state,
        coCampTeamError: action.error
      };

    case BRACKET_TEAM_LIST_SUCCESS: return { ...state, teamApiResponse: action.payload };

    case GET_GAMES_ARRAY_SUCCESS: return { ...state, gamesList: action.payload };

    case SAVE_BRACKET_SUCCESS: return { ...state, saveBracketResponse: action.payload };

    case GET_BRACKET_TEMPLATE_SUCCESS: return { ...state, getBracketTemplateListResponse: action.payload };

    // GET Preview Bracket 
    case GET_BRACKET_PREVIEW_SUCCESS: return { ...state, previewTemplateResponse: action.payload };

    case GET_BRACKET_IMPORT_ERROR: return { ...state, importTemplateError: action.error };

    case GET_CO_CAMP_TEAM_ERROR: return { ...state, coCampTeamError: action.error };

    default:
      return state;
  }
}

// import { BRACKET_TEAM_LIST_SUCCESS, CREATE_BRACKET_TEMPLATE_ERROR, CREATE_BRACKET_TEMPLATE_SUCCESS, GET_BRACKET_DELETE_ERROR, GET_BRACKET_DELETE_SUCCESS, GET_BRACKET_IMPORT_ERROR, GET_BRACKET_IMPORT_SUCCESS, GET_BRACKET_NUMBER_ERROR, GET_BRACKET_NUMBER_SUCCESS, GET_BRACKET_PREVIEW_SUCCESS, GET_BRACKET_TEMPLATE_ERROR, GET_BRACKET_TEMPLATE_SUCCESS, GET_CO_CAMP_TEAM_ERROR, GET_CO_CAMP_TEAM_LIST_ERROR, GET_CO_CAMP_TEAM_LIST_SUCCESS, GET_CO_CAMP_TEAM_SUCCESS, GET_GAMES_ARRAY_SUCCESS, SAVE_BRACKET_SUCCESS, SAVE_CO_CAMP_TEAM_ERROR, SAVE_CO_CAMP_TEAM_SUCCESS } from '../../constants/Scheduling/Bracket.const';

// const initialState = {

//   teamApiResponse: null,
//   saveBracketResponse: null,
//   gamesList: null,

//   getNoOfSeedTeamResponse: null,
//   getNoOfSeedTeamError: null,

//   getBracketTemplateListResponse: null,
//   getBracketTemplateListError: null,

//   importTemplateResponse: null,
//   importTemplateError: null,

//   previewTemplateResponse: null,
//   importTemplateError: null,

//   deleteTemplateResponse: null,
//   deleteTemplateError: null,

//   coCampTeamList: [],
//   coCampTeamError: null,

//   saveCoCampTeams: null,
//   saveCoCampTeamsError: null,

//   coCampTeam: [],
//   coCampTeamError: null,
// }

// export default function (state = initialState, action) {

//   const { type } = action;

//   switch (type) {

//     case BRACKET_TEAM_LIST_SUCCESS: return { ...state, teamApiResponse: action.payload };

//     case GET_GAMES_ARRAY_SUCCESS: return { ...state, gamesList: action.payload };

//     case SAVE_BRACKET_SUCCESS: return { ...state, saveBracketResponse: action.payload };

//     // // GET No Of Team
//     case GET_BRACKET_NUMBER_SUCCESS: return { ...state, getNoOfSeedTeamResponse: action.payload };

//     case GET_BRACKET_NUMBER_ERROR: return { ...state, getNoOfSeedTeamError: action.error };

//     //     // Create Bracket Template 
//     case CREATE_BRACKET_TEMPLATE_SUCCESS: return { ...state, createBracketTemplateResposne: action.payload };

//     case GET_BRACKET_TEMPLATE_SUCCESS: return { ...state, getBracketTemplateListResponse: action.payload };

//     case GET_BRACKET_TEMPLATE_ERROR: return { ...state, getBracketTemplateListError: action.error };

//     // GET Import Bracket 
//     case GET_BRACKET_IMPORT_SUCCESS: return { ...state, importTemplateResponse: action.payload };

//     case GET_BRACKET_IMPORT_ERROR: return { ...state, importTemplateError: action.error };

//     // GET Preview Bracket 
//     case GET_BRACKET_PREVIEW_SUCCESS: return { ...state, previewTemplateResponse: action.payload };

//     case GET_BRACKET_IMPORT_ERROR: return { ...state, importTemplateError: action.error };

//     //  Delete Bracket Template
//     case GET_BRACKET_DELETE_SUCCESS: return { ...state, deleteTemplateResponse: action.payload };

//     case GET_BRACKET_DELETE_ERROR: return { ...state, deleteTemplateError: action.error };

//     // For co campion team list
//     case GET_CO_CAMP_TEAM_LIST_SUCCESS: return { ...state, coCampTeamList: action.payload };

//     case GET_CO_CAMP_TEAM_LIST_ERROR: return { ...state, coCampTeamError: action.error };

//     // submit select co campion team
//     case SAVE_CO_CAMP_TEAM_SUCCESS: return { ...state, saveCoCampTeams: action.payload };

//     case SAVE_CO_CAMP_TEAM_ERROR: return { ...state, saveCoCampTeamsError: action.error };

//     // fetch selected co champion team
//     case GET_CO_CAMP_TEAM_SUCCESS: return { ...state, coCampTeam: action.payload };

//     case GET_CO_CAMP_TEAM_ERROR: return { ...state, coCampTeamError: action.error };


//     default: return state

//   }

// }