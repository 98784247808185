import { 
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS, 
  UPDATE_PROFILE_ERROR,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS, 
  UPDATE_PASSWORD_ERROR,

  USER_INFO_REQUEST,
  USER_INFO_SUCCESS, 
  USER_INFO_ERROR,

  } from '../../constants/Authentication/UpdateProfile.const';
  
  const initialState = {

    isProfileUpdated: false,
    updateProfileError: null,
    updateProfileLoader: false,

    isPasswordUpdated: false,
    updatePasswordError: null,
    updatePasswordLoader: false,

    isProfileLoaded: false,
    userProfile: null,
    userProfileError: null,
    userProfileLoader: false

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
  
      // Update User Profile Data
  
      case UPDATE_PROFILE_REQUEST:
        return {
          ...state,
          updateProfileLoader: true
        };
      case UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          isProfileUpdated: true,
          // authData: action.payload,
          updateProfileLoader: false
        };
      case UPDATE_PROFILE_ERROR:
        return {
          ...state,
          updateProfileError: action.error,
          updateProfileLoader: false
        };

      // Update Password

      case UPDATE_PASSWORD_REQUEST:
        return {
          ...state,
          updatePasswordLoader: true
        };
      case UPDATE_PASSWORD_SUCCESS:
        return {
          ...state,
          isPasswordUpdated: true,
          updatePasswordLoader: false
        };
      case UPDATE_PASSWORD_ERROR:
        return {
          ...state,
          updatePasswordError: action.error,
          updatePasswordLoader: false
        };

      // Get User Profile

      case USER_INFO_REQUEST:
        return {
          ...state,
          userProfileLoader: true
        };
      case USER_INFO_SUCCESS:
        return {
          ...state,
          isProfileLoaded: true,
          userProfile: action.payload,
          userProfileLoader: false
        };
      case USER_INFO_ERROR:
        return {
          ...state,
          userProfileError: action.error,
          userProfileLoader: false
        };
  
      default:
        return state;
    }
  }