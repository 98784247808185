export const BC_ADD_PLAYER_BULK_REQUEST = 'BC_ADD_PLAYER_BULK_REQUEST';
export const BC_ADD_PLAYER_BULK_SUCCESS = 'BC_ADD_PLAYER_BULK_SUCCESS';
export const BC_ADD_PLAYER_BULK_ERROR = 'BC_ADD_PLAYER_BULK_ERROR';

export const BC_SELECTED_COACH_NAME_LIST_REQUEST = 'BC_SELECTED_COACH_NAME_LIST_REQUEST';
export const BC_SELECTED_COACH_NAME_LIST_SUCCESS = 'BC_SELECTED_COACH_NAME_LIST_SUCCESS';
export const BC_SELECTED_COACH_NAME_LIST_ERROR = 'BC_SELECTED_COACH_NAME_LIST_ERROR';

export const BC_SELECTED_ROSTER_NAME_LIST_REQUEST = 'BC_SELECTED_ROSTER_NAME_LIST_REQUEST';
export const BC_SELECTED_ROSTER_NAME_LIST_SUCCESS = 'BC_SELECTED_ROSTER_NAME_LIST_SUCCESS';
export const BC_SELECTED_ROSTER_NAME_LIST_ERROR = 'BC_SELECTED_ROSTER_NAME_LIST_ERROR'; 