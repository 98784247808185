import {
  GET_USER_PERMISSION_LIST_REQUEST,
  GET_USER_PERMISSION_LIST_SUCCESS,
  GET_USER_PERMISSION_LIST_ERROR,
  EDIT_USER_PERMISSION_LIST_REQUEST,
  EDIT_USER_PERMISSION_LIST_SUCCESS,
  EDIT_USER_PERMISSION_LIST_ERROR,
  GET_USER_PERMISSION_REQUEST,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_ERROR
} from "../../constants/ManageAdmin/UserPermission.const";
   
   const initialState = {
       userPermissionData : [],
       userPermissionError : null,
       editUserPermission : [],
       editUserPermissionError : null,
       getUserPermission : [],
       getUserPermissionError : null
   };
   
   export default function(state = initialState, action) {
     switch (action.type) {
       // For GET user permission Data
       case GET_USER_PERMISSION_LIST_SUCCESS:
         return {
           ...state,
           userPermissionData: action.payload,
         };
       case GET_USER_PERMISSION_LIST_ERROR:
         return {
           ...state,
           userPermissionError: action.error,
         };

       // For EDIT user permission Data
       case GET_USER_PERMISSION_SUCCESS:
         return {
           ...state,
           getUserPermission: action.payload,
         };
       case GET_USER_PERMISSION_ERROR:
         return {
           ...state,
           getUserPermissionError: action.error,
         };

       // For EDIT user permission Data
       case EDIT_USER_PERMISSION_LIST_SUCCESS:
         return {
           ...state,
           editUserPermission: action.payload,
         };
       case EDIT_USER_PERMISSION_LIST_ERROR:
         return {
           ...state,
           editUserPermissionError: action.error,
         };
       default:
         return state;
     }
   }