export const ADD_FORMATE_REQUEST = "ADD_FORMATE_REQUEST";
export const ADD_FORMATE_SUCCESS = "ADD_FORMATE_SUCCESS";
export const ADD_FORMATE_ERROR = "ADD_FORMATE_ERROR";

export const UPDATE_FORMATE_SUCCESS = "UPDATE_FORMATE_SUCCESS";
export const UPDATE_FORMATE_ERROR = "UPDATE_FORMATE_ERROR";

export const FETCH_FORMATE_REQUEST = "FETCH_FORMATE_REQUEST";
export const FETCH_FORMATE_SUCCESS = "FETCH_FORMATE_SUCCESS";
export const FETCH_FORMATE_ERROR = "FETCH_FORMATE_ERROR";

export const GET_NUMBER_OF_TEAM_REQUEST = "GET_NUMBER_OF_TEAM_REQUEST";
export const GET_NUMBER_OF_TEAM_SUCCESS = "GET_NUMBER_OF_TEAM_SUCCESS";
export const GET_NUMBER_OF_TEAM_ERROR = "GET_NUMBER_OF_TEAM_ERROR";

// Match Up Restriction

export const GET_MATCHUP_LIST_SUCCESS = "GET_MATCHUP_LIST_SUCCESS";
export const GET_MATCHUP_LIST_ERROR = "GET_MATCHUP_LIST_ERROR";

export const GET_MATCHUP_TEAM_LIST_SUCCESS = "GET_MATCHUP_TEAM_LIST_SUCCESS";
export const GET_MATCHUP_TEAM_LIST_ERROR = "GET_MATCHUP_TEAM_LIST_ERROR";

export const CREATE_MATCHUP_SUCCESS = "CREATE_MATCHUP_SUCCESS";
export const CREATE_MATCHUP_ERROR = "CREATE_MATCHUP_ERROR";

export const DELETE_MATCHUP_SUCCESS = "DELETE_MATCHUP_SUCCESS";
export const DELETE_MATCHUP_ERROR = "DELETE_MATCHUP_ERROR";

//  Date/Time Restriction

export const GET_DATE_TIME_LIST_SUCCESS = "GET_DATE_TIME_LIST_SUCCESS";
export const GET_DATE_TIME_LIST_ERROR = "GET_DATE_TIME_LIST_ERROR";

// Save
export const SPECIAL_REQUEST_TOASTER = "SPECIAL_REQUEST_TOASTER";