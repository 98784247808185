export const BC_GET_TEAM_LISTS_REQUEST = 'BC_GET_TEAM_LISTS_REQUEST';
export const BC_GET_TEAM_LISTS_SUCCESS = 'BC_GET_TEAM_LISTS_SUCCESS';
export const BC_GET_TEAM_LISTS_ERROR = 'BC_GET_TEAM_LISTS_ERROR';

export const BC_PICK_A_TEAM_REQUEST = 'BC_PICK_A_TEAM_REQUEST';
export const BC_PICK_A_TEAM_SUCCESS = 'BC_PICK_A_TEAM_SUCCESS';
export const BC_PICK_A_TEAM_ERROR = 'BC_PICK_A_TEAM_ERROR';

export const BC_GET_COACH_LIST_REQUEST = 'BC_GET_COACH_LIST_REQUEST';
export const BC_GET_COACH_LIST_SUCCESS = 'BC_GET_COACH_LIST_SUCCESS';
export const BC_GET_COACH_LIST_ERROR = 'BC_GET_COACH_LIST_ERROR';

export const BC_PICK_A_COACH_REQUEST = 'BC_PICK_A_COACH_REQUEST';
export const BC_PICK_A_COACH_SUCCESS = 'BC_PICK_A_COACH_SUCCESS';
export const BC_PICK_A_COACH_ERROR = 'BC_PICK_A_COACH_ERROR';