import {
    BC_FETCH_TOP_BATSMAN_REQUEST,
    BC_FETCH_TOP_BATSMAN_SUCCESS,
    BC_FETCH_TOP_BATSMAN_ERROR,

    BC_FETCH_ALL_BATSMAN_REQUEST,
    BC_FETCH_ALL_BATSMAN_SUCCESS,
    BC_FETCH_ALL_BATSMAN_ERROR,

    BC_FETCH_TOP_PITCHER_REQUEST,
    BC_FETCH_TOP_PITCHER_SUCCESS,
    BC_FETCH_TOP_PITCHER_ERROR,

    BC_FETCH_ALL_PITCHER_REQUEST,
    BC_FETCH_ALL_PITCHER_SUCCESS,
    BC_FETCH_ALL_PITCHER_ERROR,

    BC_FETCH_PLAYER_BY_ID_REQUEST,
    BC_FETCH_PLAYER_BY_ID_SUCCESS,
    BC_FETCH_PLAYER_BY_ID_ERROR,

    BC_FETCH_PLAYER_BY_FILTER_REQUEST,
    BC_FETCH_PLAYER_BY_FILTER_SUCCESS,
    BC_FETCH_PLAYER_BY_FILTER_ERROR,

    PLAYER_BALLR_LICENCE_REQUEST,
    PLAYER_BALLR_LICENCE_SUCCESS,
    PLAYER_BALLR_LICENCE_ERROR,

    PLAYER_ASSIGN_BALLR_LICENCE_REQUEST,
    PLAYER_ASSIGN_BALLR_LICENCE_SUCCESS,
    PLAYER_ASSIGN_BALLR_LICENCE_ERROR,

    PLAYER_PITCHR_LICENCE_REQUEST,
    PLAYER_PITCHR_LICENCE_SUCCESS,
    PLAYER_PITCHR_LICENCE_ERROR,

    PLAYER_ASSIGN_PITCHR_LICENCE_REQUEST,
    PLAYER_ASSIGN_PITCHR_LICENCE_SUCCESS,
    PLAYER_ASSIGN_PITCHR_LICENCE_ERROR,

    FILTER_PLAYER_BY_EMAIL_REQUEST,
    FILTER_PLAYER_BY_EMAIL_SUCCESS,
    FILTER_PLAYER_BY_EMAIL_ERROR,

    BC_LOGIN_REQUEST,
    BC_LOGIN_SUCCESS,
    BC_LOGIN_ERROR,

    PLAYER_CHANGE_TEAM_REQUEST,
    PLAYER_CHANGE_TEAM_SUCCESS,
    PLAYER_CHANGE_TEAM_ERROR,

    GET_PLAYER_CHANGE_TEAM_REQUEST,
    GET_PLAYER_CHANGE_TEAM_SUCCESS,
    GET_PLAYER_CHANGE_TEAM_ERROR,

    SET_PLAYER_DEFAULT_TEAM_REQUEST,
    SET_PLAYER_DEFAULT_TEAM_SUCCESS,
    SET_PLAYER_DEFAULT_TEAM_ERROR,

    REMOVE_PLAYER_TEAM_REQUEST,
    REMOVE_PLAYER_TEAM_SUCCESS,
    REMOVE_PLAYER_TEAM_ERROR,

    ISANALYR_ACCESS_REQUEST,
    ISANALYR_ACCESS_SUCCESS,
    ISANALYR_ACCESS_ERROR,

    BC_EXPORT_CSV_FOR_USER_REQUEST,
    BC_EXPORT_CSV_FOR_USER_SUCCESS,
    BC_EXPORT_CSV_FOR_USER_ERROR,

    BC_ADD_NEW_ROSTER_REQUEST,
    BC_ADD_NEW_ROSTER_SUCCESS,
    BC_ADD_NEW_ROSTER_ERROR
} from '../../../constants/BaseballCloud/Users/Players.const';

const initialState = {

    topBatsmans: [],
    topBatsmansError: null,
    topBatsmansLoader: false,

    allBatsmans: [],
    allBatsmansError: null,
    allBatsmansLoader: false,

    topPitchers: [],
    topPitchersError: null,
    topPitchersLoader: false,

    allPitchers: [],
    allPitchersError: null,
    allPitchersLoader: false,

    player: [],
    playerError: null,
    playerLoader: false,
    isPlayerLoaded: false,

    playersByFilter: [],
    playersByFilterError: null,
    playersByFilterLoader: false,
    isPlayersLoaded: false,

    ballrLincenceList: [],
    ballrLincenceListError: null,
    ballrLincenceListLoader: false,

    assignBallrLic: null,
    assignBallrLicError: null,
    assignBallrLicLoader: false,

    pitchrLincenceList: [],
    pitchrLincenceListError: null,
    pitchrLincenceListLoader: false,

    assignPitchrLic: null,
    assignPitchrLicError: null,
    assignPitchrLicLoader: false,

    licForUser: null,

    emailList: null,
    emailListError: null,
    emailListLoader: false,

    bcLoginSucces: null,
    bcLoginError: null,
    bcLoginLoader: false,

    changePlayerTeam: null,
    changePlayerTeamError: null,
    changePlayerTeamLoader: false,

    getPlayerTeam: null,
    getPlayerTeamError: null,
    getPlayerTeamLoader: false,

    setDefaultTeam: null,
    setDefaultTeamError: null,
    setDefaultTeamLoader: false,

    removePlayerTeam: null,
    removePlayerTeamError: null,
    removePlayerTeamLoader: false,

    isAnalyzr: null,
    isAnalyzrError: null,
    isAnalyzrLoader: false,

    bcExportCsv: null,
    bcExportCsvError: null,
    bcExportCsvLoader: false,

    addRoster: null,
    addRosterError: null,
    addRosterLoader: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Remove team  for player

        case REMOVE_PLAYER_TEAM_REQUEST:
            return {
                ...state,
                removePlayerTeamLoader: true
            };
        case REMOVE_PLAYER_TEAM_SUCCESS:
            return {
                ...state,
                removePlayerTeam: action.payload,
            };
        case REMOVE_PLAYER_TEAM_ERROR:
            return {
                ...state,
                removePlayerTeamError: action.error.message,
            };

        // Default team  for player

        case SET_PLAYER_DEFAULT_TEAM_REQUEST:
            return {
                ...state,
                setDefaultTeamLoader: true
            };
        case SET_PLAYER_DEFAULT_TEAM_SUCCESS:
            return {
                ...state,
                setDefaultTeam: action.payload,
            };
        case SET_PLAYER_DEFAULT_TEAM_ERROR:
            return {
                ...state,
                setDefaultTeamError: action.error.message,
            };

        // Change team  for player

        case PLAYER_CHANGE_TEAM_REQUEST:
            return {
                ...state,
                changePlayerTeamLoader: true
            };
        case PLAYER_CHANGE_TEAM_SUCCESS:
            return {
                ...state,
                changePlayerTeam: action.payload,
            };
        case PLAYER_CHANGE_TEAM_ERROR:
            return {
                ...state,
                changePlayerTeamError: action.error.message,
            };

        // Get team  for player

        case GET_PLAYER_CHANGE_TEAM_REQUEST:
            return {
                ...state,
                getPlayerTeamLoader: true
            };
        case GET_PLAYER_CHANGE_TEAM_SUCCESS:
            return {
                ...state,
                getPlayerTeam: action.payload,
            };
        case GET_PLAYER_CHANGE_TEAM_ERROR:
            return {
                ...state,
                getPlayerTeamError: action.error.message,
            };

        // Fetch - TOP BATSMANS

        case BC_FETCH_TOP_BATSMAN_REQUEST:
            return {
                ...state,
                topBatsmansLoader: true
            };
        case BC_FETCH_TOP_BATSMAN_SUCCESS:
            return {
                ...state,
                topBatsmans: action.payload,
                topBatsmansLoader: false
            };
        case BC_FETCH_TOP_BATSMAN_ERROR:
            return {
                ...state,
                topBatsmansError: action.error.message,
                topBatsmansLoader: false
            };

        // Fetch - ALL BATSMANS

        case BC_FETCH_ALL_BATSMAN_REQUEST:
            return {
                ...state,
                allBatsmansLoader: true
            };
        case BC_FETCH_ALL_BATSMAN_SUCCESS:
            return {
                ...state,
                allBatsmans: action.payload,
                allBatsmansLoader: false
            };
        case BC_FETCH_ALL_BATSMAN_ERROR:
            return {
                ...state,
                allBatsmansError: action.error.message,
                allBatsmansLoader: false
            };

        // Fetch - TOP PITCHERS

        case BC_FETCH_TOP_PITCHER_REQUEST:
            return {
                ...state,
                topPitchersLoader: true
            };
        case BC_FETCH_TOP_PITCHER_SUCCESS:
            return {
                ...state,
                topPitchers: action.payload,
                topPitchersLoader: false
            };
        case BC_FETCH_TOP_PITCHER_ERROR:
            return {
                ...state,
                topPitchersError: action.error.message,
                topPitchersLoader: false
            };

        // Fetch - ALL PITCHERS

        case BC_FETCH_ALL_PITCHER_REQUEST:
            return {
                ...state,
                allPitchersLoader: true
            };
        case BC_FETCH_ALL_PITCHER_SUCCESS:
            return {
                ...state,
                allPitchers: action.payload,
                allPitchersLoader: false
            };
        case BC_FETCH_ALL_PITCHER_ERROR:
            return {
                ...state,
                allPitchersError: action.error.message,
                allPitchersLoader: false
            };

        // Fetch - PLAYER BY ID

        case BC_FETCH_PLAYER_BY_ID_REQUEST:
            return {
                ...state,
                playerLoader: true
            };
        case BC_FETCH_PLAYER_BY_ID_SUCCESS:
            return {
                ...state,
                isPlayerLoaded: true,
                player: action.payload,
                playerLoader: false
            };
        case BC_FETCH_PLAYER_BY_ID_ERROR:
            return {
                ...state,
                playerError: action.error.message,
                playerLoader: false
            };

        // Fetch - PLAYER BY FILTER

        case BC_FETCH_PLAYER_BY_FILTER_REQUEST:
            return {
                ...state,
                playersByFilterLoader: true
            };
        case BC_FETCH_PLAYER_BY_FILTER_SUCCESS:
            return {
                ...state,
                isPlayersLoaded: true,
                playersByFilter: action.payload,
                playersByFilterLoader: false
            };
        case BC_FETCH_PLAYER_BY_FILTER_ERROR:
            return {
                ...state,
                playersByFilterError: action.error,
                playersByFilterLoader: false
            };

        // get BollR Lincence List
        case PLAYER_BALLR_LICENCE_REQUEST:
            return {
                ...state,
                ballrLincenceListLoader: true
            };
        case PLAYER_BALLR_LICENCE_SUCCESS:
            return {
                ...state,
                ballrLincenceList: action.payload,
                ballrLincenceListLoader: true
            };
        case PLAYER_BALLR_LICENCE_ERROR:
            return {
                ...state,
                ballrLincenceListError: action.error,
            };

        // assign BollR Lincence
        case PLAYER_ASSIGN_BALLR_LICENCE_REQUEST:
            return {
                ...state,
                assignBallrLicLoader: true
            };
        case PLAYER_ASSIGN_BALLR_LICENCE_SUCCESS:
            return {
                ...state,
                assignBallrLic: action.payload,
                assignBallrLicLoader: true
            };
        case PLAYER_ASSIGN_BALLR_LICENCE_ERROR:
            return {
                ...state,
                assignBallrLicError: action.error,
            };

        // get PitchR Lincence List
        case PLAYER_PITCHR_LICENCE_REQUEST:
            return {
                ...state,
                pitchrLincenceListLoader: true
            };
        case PLAYER_PITCHR_LICENCE_SUCCESS:
            return {
                ...state,
                pitchrLincenceList: action.payload,
                pitchrLincenceListLoader: true
            };
        case PLAYER_PITCHR_LICENCE_ERROR:
            return {
                ...state,
                pitchrLincenceListError: action.error,
            };

        // assign PitchR Lincence
        case PLAYER_ASSIGN_PITCHR_LICENCE_REQUEST:
            return {
                ...state,
                assignPitchrLicLoader: true
            };
        case PLAYER_ASSIGN_PITCHR_LICENCE_SUCCESS:
            return {
                ...state,
                assignPitchrLic: action.payload,
                assignPitchrLicLoader: true
            };
        case PLAYER_ASSIGN_PITCHR_LICENCE_ERROR:
            return {
                ...state,
                assignPitchrLicError: action.error,
            };

        case "ASSIGN_LICENCE_USER":
            return {
                ...state,
                licForUser: action.payload,
            };


        // get Email List
        case FILTER_PLAYER_BY_EMAIL_REQUEST:
            return {
                ...state,
                emailListLoader: true
            };
        case FILTER_PLAYER_BY_EMAIL_SUCCESS:
            return {
                ...state,
                emailList: action.payload,
                emailListLoader: true
            };
        case FILTER_PLAYER_BY_EMAIL_ERROR:
            return {
                ...state,
                emailListError: action.error,
            };


        // bcLogin user 
        case BC_LOGIN_REQUEST:
            return {
                ...state,
                bcLoginLoader: true
            };
        case BC_LOGIN_SUCCESS:
            return {
                ...state,
                bcLoginSucces: action.payload,
                bcLoginLoader: true
            };
        case BC_LOGIN_ERROR:
            return {
                ...state,
                bcLoginError: action.error,
            };

        // access isAnalyzr
        case ISANALYR_ACCESS_REQUEST:
            return {
                ...state,
                isAnalyzrLoader: true
            };
        case ISANALYR_ACCESS_SUCCESS:
            return {
                ...state,
                isAnalyzr: action.payload,
                isAnalyzrLoader: true
            };
        case ISANALYR_ACCESS_ERROR:
            return {
                ...state,
                isAnalyzrError: action.error,
            };

        case "ISANALYR_ACCESS_REQUEST_FOR_PLAYER":
            return {
                ...state,
                isAnalyzrLoader: true
            };
        case "ISANALYR_ACCESS_SUCCESS_FOR_PLAYER":
            return {
                ...state,
                isAnalyzr: action.payload,
                isAnalyzrLoader: true
            };
        case "ISANALYR_ACCESS_ERROR_FOR_PLAYER":
            return {
                ...state,
                isAnalyzrError: action.error,
            };

        // EXPORT CSV FOR BC USER
        case BC_EXPORT_CSV_FOR_USER_REQUEST:
            return {
                ...state,
                bcExportCsvLoader: true
            };
        case BC_EXPORT_CSV_FOR_USER_SUCCESS:
            return {
                ...state,
                bcExportCsv: action.payload,
                bcExportCsvLoader: true
            };
        case BC_EXPORT_CSV_FOR_USER_ERROR:
            return {
                ...state,
                bcExportCsvError: action.error,
            };

        // add new Roster
        case BC_ADD_NEW_ROSTER_REQUEST:
            return {
                ...state,
                addRosterLoader: true
            };
        case BC_ADD_NEW_ROSTER_SUCCESS:
            return {
                ...state,
                addRoster: action.payload,
                addRosterLoader: true
            };
        case BC_ADD_NEW_ROSTER_ERROR:
            return {
                ...state,
                addRosterError: action.error,
            };

        default:
            return state;
    }
}
