import {
  GET_EVENT_RULE_SUCCESS,
  GET_EVENT_RULE_ERROR,

  UPDATE_EVENT_RULE_SUCCESS,
  UPDATE_EVENT_RULE_ERROR,

} from '../constants/EventRule.const';

const initialState = {
  getEventRuleData: null,
  getEventRuleError: null,

  updateEventRuleData: null,
  updateEventRuleError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For GET Admin Data
    case GET_EVENT_RULE_SUCCESS:
      return {
        ...state,
        getEventRuleData: action.payload,
      };
    case GET_EVENT_RULE_ERROR:
      return {
        ...state,
        getEventRuleError: action.error,
      };
    case UPDATE_EVENT_RULE_SUCCESS:
      return {
        ...state,
        updateEventRuleData: action.payload,
      };

    case UPDATE_EVENT_RULE_ERROR:
      return {
        ...state,
        updateEventRuleError: action.error,
      };

    default:
      return state;
  }
}