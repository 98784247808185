import { 
    FETCH_SUPPORT_REPORT_REQUEST,
    FETCH_SUPPORT_REPORT_SUCCESS, 
    FETCH_SUPPORT_REPORT_ERROR,

    FETCH_BULLPEN_REPORT_REQUEST,
    FETCH_BULLPEN_REPORT_SUCCESS, 
    FETCH_BULLPEN_REPORT_ERROR,

    FETCH_BULLPEN102_REPORT_REQUEST,
    FETCH_BULLPEN102_REPORT_SUCCESS, 
    FETCH_BULLPEN102_REPORT_ERROR,

    FETCH_BULLPEN103_REPORT_REQUEST,
    FETCH_BULLPEN103_REPORT_SUCCESS, 
    FETCH_BULLPEN103_REPORT_ERROR,

    FETCH_PITCHING_GAME_REPORT_REQUEST,
    FETCH_PITCHING_GAME_REPORT_SUCCESS, 
    FETCH_PITCHING_GAME_REPORT_ERROR,

    FETCH_PITCHING_GAME202_REPORT_REQUEST,
    FETCH_PITCHING_GAME202_REPORT_SUCCESS, 
    FETCH_PITCHING_GAME202_REPORT_ERROR,

    FETCH_PITCHING_GAME203_REPORT_REQUEST,
    FETCH_PITCHING_GAME203_REPORT_SUCCESS, 
    FETCH_PITCHING_GAME203_REPORT_ERROR,

    FETCH_PITCHING_GAME204_REPORT_REQUEST,
    FETCH_PITCHING_GAME204_REPORT_SUCCESS, 
    FETCH_PITCHING_GAME204_REPORT_ERROR,

    FETCH_PITCHING_GAME205_REPORT_REQUEST,
    FETCH_PITCHING_GAME205_REPORT_SUCCESS, 
    FETCH_PITCHING_GAME205_REPORT_ERROR,

    FETCH_BPSUMMARY_REPORT_REQUEST,
    FETCH_BPSUMMARY_REPORT_SUCCESS, 
    FETCH_BPSUMMARY_REPORT_ERROR,

    FETCH_BPSUMMARY_GRAPH_REQUEST,
    FETCH_BPSUMMARY_GRAPH_SUCCESS, 
    FETCH_BPSUMMARY_GRAPH_ERROR,

    FETCH_BPSUMMARY_GRAPH_DETAIL_REQUEST,
    FETCH_BPSUMMARY_GRAPH_DETAIL_SUCCESS, 
    FETCH_BPSUMMARY_GRAPH_DETAIL_ERROR,

    FETCH_DKTABLE_REPORT_REQUEST,
    FETCH_DKTABLE_REPORT_SUCCESS,
    FETCH_DKTABLE_REPORT_ERROR,

    FETCH_HITTER_GAME_REPORT_REQUEST,
    FETCH_HITTER_GAME_REPORT_SUCCESS, 
    FETCH_HITTER_GAME_REPORT_ERROR,

    FETCH_HITTER402_GRAPH_REQUEST,
    FETCH_HITTER402_GRAPH_SUCCESS, 
    FETCH_HITTER402_GRAPH_ERROR,

    FETCH_HITTER403_GRAPH_REQUEST,
    FETCH_HITTER403_GRAPH_SUCCESS, 
    FETCH_HITTER403_GRAPH_ERROR,

    FETCH_HITTER404_GRAPH_REQUEST,
    FETCH_HITTER404_GRAPH_SUCCESS, 
    FETCH_HITTER404_GRAPH_ERROR,

    FETCH_HITTER405_GRAPH_REQUEST,
    FETCH_HITTER405_GRAPH_SUCCESS, 
    FETCH_HITTER405_GRAPH_ERROR,

    FETCH_MEASUREMENT_REQUEST,
    FETCH_MEASUREMENT_SUCCESS,
    FETCH_MEASUREMENT_ERROR,

    FETCH_MEASUREMENT502_REQUEST,
    FETCH_MEASUREMENT502_SUCCESS,
    FETCH_MEASUREMENT502_ERROR 

  } from '../constants/SupportReport.const';
  
  const initialState = {
    report: [],
    reportError: null,
    reportLoader: false,
    isReportLoaded: false,

    bullpenReport: [],
    bullpenReportError: null,
    bullpenReportLoader: false,
    isBullpenReportLoaded: false,

    bullpen102Report: [],
    bullpen102ReportError: null,
    bullpen102ReportLoader: false,
    isBullpen102ReportLoaded: false,

    bullpen103Report: [],
    bullpen103ReportError: null,
    bullpen103ReportLoader: false,
    isBullpen103ReportLoaded: false,

    pitchingGameReport: [],
    pitchingGameReportError: null,
    pitchingGameReportLoader: false,
    isPitchingGameReportLoaded: false,

    pitchingGame202Report: [],
    pitchingGame202ReportError: null,
    pitchingGame202ReportLoader: false,
    isPitchingGame202ReportLoaded: false,

    pitchingGame203Report: [],
    pitchingGame203ReportError: null,
    pitchingGame203ReportLoader: false,
    isPitchingGame203ReportLoaded: false,

    pitchingGame204Report: [],
    pitchingGame204ReportError: null,
    pitchingGame204ReportLoader: false,
    isPitchingGame204ReportLoaded: false,

    pitchingGame205Report: [],
    pitchingGame205ReportError: null,
    pitchingGame205ReportLoader: false,
    isPitchingGame205ReportLoaded: false,

    bpSummaryReport: [],
    bpSummaryReportError: null,
    bpSummaryReportLoader: false,
    isBPSummaryReportLoaded: false,

    bpSummaryGraph: [],
    bpSummaryGraphError: null,
    bpSummaryGraphLoader: false,
    isBPSummaryGraphLoaded: false,

    bpSummaryGraphDetail: [],
    bpSummaryGraphDetailError: null,
    bpSummaryGraphDetailLoader: false,
    isBPSummaryGraphDetailLoaded: false,

    dkTableReport: [],
    dkTableReportError: null,
    dkTableReportLoader: false,
    isDKTableReportLoaded: false,


    hitter401Graph: [],
    hitter401GraphError: null,
    hitter401GraphLoader: false,
    isHitter401GraphLoaded: false,

    hitter402Graph: [],
    hitter402GraphError: null,
    hitter402GraphLoader: false,
    isHitter402GraphLoaded: false,

    hitter403Graph: [],
    hitter403GraphError: null,
    hitter403GraphLoader: false,
    isHitter403GraphLoaded: false,

    hitter404Graph: [],
    hitter404GraphError: null,
    hitter404GraphLoader: false,
    isHitter404GraphLoaded: false,

    hitter405Graph: [],
    hitter405GraphError: null,
    hitter405GraphLoader: false,
    isHitter405GraphLoaded: false,

    measurement: [],
    measurementError: null,
    measurementLoader: false,
    isMeasurementLoaded: false,

    measurement502: [],
    measurement502Error: null,
    measurement502Loader: false,
    isMeasurement502Loaded: false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  

      // Fetch Simple Report
      case FETCH_SUPPORT_REPORT_REQUEST:
        return {
          ...state,
          reportLoader: true
        };
      case FETCH_SUPPORT_REPORT_SUCCESS:
        return {
          ...state,
          report: action.payload,
          reportLoader: false,
          isReportLoaded: true
        };
      case FETCH_SUPPORT_REPORT_ERROR:
        return {
          ...state,
          reportError: action.error,
          reportLoader: false
        };


      // Fetch Bullpen Report
      case FETCH_BULLPEN_REPORT_REQUEST:
        return {
          ...state,
          bullpenReportLoader: true
        };
      case FETCH_BULLPEN_REPORT_SUCCESS:
        return {
          ...state,
          bullpenReport: action.payload,
          bullpenReportLoader: false,
          isBullpenReportLoaded: true
        };
      case FETCH_BULLPEN_REPORT_ERROR:
        return {
          ...state,
          bullpenReportError: action.error.message,
          bullpenReportLoader: false
        };


      // Fetch Bullpen 102 Report
      case FETCH_BULLPEN102_REPORT_REQUEST:
        return {
          ...state,
          bullpen102ReportLoader: true
        };
      case FETCH_BULLPEN102_REPORT_SUCCESS:
        return {
          ...state,
          bullpen102Report: action.payload,
          bullpen102ReportLoader: false,
          isBullpen102ReportLoaded: true
        };
      case FETCH_BULLPEN102_REPORT_ERROR:
        return {
          ...state,
          bullpen102ReportError: action.error.message,
          bullpen102ReportLoader: false
        };


      // Fetch Bullpen 102 Report
      case FETCH_BULLPEN103_REPORT_REQUEST:
        return {
          ...state,
          bullpen103ReportLoader: true
        };
      case FETCH_BULLPEN103_REPORT_SUCCESS:
        return {
          ...state,
          bullpen103Report: action.payload,
          bullpen103ReportLoader: false,
          isBullpen103ReportLoaded: true
        };
      case FETCH_BULLPEN103_REPORT_ERROR:
        return {
          ...state,
          bullpen103ReportError: action.error.message,
          bullpen103ReportLoader: false
        };


      // Fetch Pitching Game Report
      case FETCH_PITCHING_GAME_REPORT_REQUEST:
        return {
          ...state,
          pitchingGameReportLoader: true
        };
      case FETCH_PITCHING_GAME_REPORT_SUCCESS:
        return {
          ...state,
          pitchingGameReport: action.payload,
          pitchingGameReportLoader: false,
          isPitchingGameReportLoaded: true
        };
      case FETCH_PITCHING_GAME_REPORT_ERROR:
        return {
          ...state,
          pitchingGameReportError: action.error.message,
          pitchingGameReportLoader: false
        };


      // Fetch Pitching 202 Game Report
      case FETCH_PITCHING_GAME202_REPORT_REQUEST:
        return {
          ...state,
          pitchingGame202ReportLoader: true
        };
      case FETCH_PITCHING_GAME202_REPORT_SUCCESS:
        return {
          ...state,
          pitchingGame202Report: action.payload,
          pitchingGame202ReportLoader: false,
          isPitchingGame202ReportLoaded: true
        };
      case FETCH_PITCHING_GAME202_REPORT_ERROR:
        return {
          ...state,
          pitchingGame202ReportError: action.error.message,
          pitchingGame202ReportLoader: false
        };
        

      // Fetch Pitching 203 Game Report
      case FETCH_PITCHING_GAME203_REPORT_REQUEST:
        return {
          ...state,
          pitchingGame203ReportLoader: true
        };
      case FETCH_PITCHING_GAME203_REPORT_SUCCESS:
        return {
          ...state,
          pitchingGame203Report: action.payload,
          pitchingGame203ReportLoader: false,
          isPitchingGame203ReportLoaded: true
        };
      case FETCH_PITCHING_GAME203_REPORT_ERROR:
        return {
          ...state,
          pitchingGame203ReportError: action.error.message,
          pitchingGame203ReportLoader: false
        };


      // Fetch Pitching Game Report
      case FETCH_PITCHING_GAME204_REPORT_REQUEST:
        return {
          ...state,
          pitchingGame204ReportLoader: true
        };
      case FETCH_PITCHING_GAME204_REPORT_SUCCESS:
        return {
          ...state,
          pitchingGame204Report: action.payload,
          pitchingGame204ReportLoader: false,
          isPitchingGame204ReportLoaded: true
        };
      case FETCH_PITCHING_GAME204_REPORT_ERROR:
        return {
          ...state,
          pitchingGame204ReportError: action.error.message,
          pitchingGame204ReportLoader: false
        };


      // Fetch Pitching Game Report
      case FETCH_PITCHING_GAME205_REPORT_REQUEST:
        return {
          ...state,
          pitchingGame205ReportLoader: true
        };
      case FETCH_PITCHING_GAME205_REPORT_SUCCESS:
        return {
          ...state,
          pitchingGame205Report: action.payload,
          pitchingGame205ReportLoader: false,
          isPitchingGame205ReportLoaded: true
        };
      case FETCH_PITCHING_GAME205_REPORT_ERROR:
        return {
          ...state,
          pitchingGame205ReportError: action.error.message,
          pitchingGame205ReportLoader: false
        };


      // Fetch Bullpen Report
      case FETCH_BPSUMMARY_REPORT_REQUEST:
        return {
          ...state,
          bpSummaryReportLoader: true
        };
      case FETCH_BPSUMMARY_REPORT_SUCCESS:
        return {
          ...state,
          bpSummaryReport: action.payload,
          bpSummaryReportLoader: false,
          isBPSummaryReportLoaded: true
        };
      case FETCH_BPSUMMARY_REPORT_ERROR:
        return {
          ...state,
          bpSummaryReportError: action.error.message,
          bpSummaryReportLoader: false
        };


      // Fetch Bullpen Report
      case FETCH_BPSUMMARY_GRAPH_REQUEST:
        return {
          ...state,
          bpSummaryGraphLoader: true
        };
      case FETCH_BPSUMMARY_GRAPH_SUCCESS:
        return {
          ...state,
          bpSummaryGraph: action.payload,
          bpSummaryGraphLoader: false,
          isBPSummaryGraphLoaded: true
        };
      case FETCH_BPSUMMARY_GRAPH_ERROR:
        return {
          ...state,
          bpSummaryGraphError: action.error.message,
          bpSummaryGraphLoader: false
        };

      // Fetch Bullpen Report Detail
      case FETCH_BPSUMMARY_GRAPH_DETAIL_REQUEST:
        return {
          ...state,
          bpSummaryGraphDetailLoader: true
        };
      case FETCH_BPSUMMARY_GRAPH_DETAIL_SUCCESS:
        return {
          ...state,
          bpSummaryGraphDetail: action.payload,
          bpSummaryGraphDetailLoader: false,
          isBPSummaryGraphDetailLoaded: true
        };
      case FETCH_BPSUMMARY_GRAPH_DETAIL_ERROR:
        return {
          ...state,
          bpSummaryGraphDetailError: action.error.message,
          bpSummaryGraphDetailLoader: false
        };

      // Fetch Dk Table Report Detail
      case FETCH_DKTABLE_REPORT_REQUEST:
        return {
          ...state,
          dkTableReportLoader: true
        };
      case FETCH_DKTABLE_REPORT_SUCCESS:
        return {
          ...state,
          dkTableReport: action.payload,
          dkTableReportLoader: false,
          isDKTableReportLoaded: true
        };
      case FETCH_DKTABLE_REPORT_ERROR:
        return {
          ...state,
          dkTableReportError: action.error.message,
          dkTableReportLoader: false
        };


      // Fetch Hitter Game Graph
      case FETCH_HITTER_GAME_REPORT_REQUEST:
        return {
          ...state,
          hitter401GraphLoader: true
        };
      case FETCH_HITTER_GAME_REPORT_SUCCESS:
        return {
          ...state,
          hitter401Graph: action.payload,
          hitter401GraphLoader: false,
          isHitter401GraphLoaded: true
        };
      case FETCH_HITTER_GAME_REPORT_ERROR:
        return {
          ...state,
          hitter401GraphError: action.error.message,
          hitter401GraphLoader: false
        };

      // Fetch Hitter Game Graph
      case FETCH_HITTER402_GRAPH_REQUEST:
        return {
          ...state,
          hitter402GraphLoader: true
        };
      case FETCH_HITTER402_GRAPH_SUCCESS:
        return {
          ...state,
          hitter402Graph: action.payload,
          hitter402GraphLoader: false,
          isHitter402GraphLoaded: true
        };
      case FETCH_HITTER402_GRAPH_ERROR:
        return {
          ...state,
          hitter402GraphError: action.error.message,
          hitter402GraphLoader: false
        };


      // Fetch Hitter Game Graph
      case FETCH_HITTER403_GRAPH_REQUEST:
        return {
          ...state,
          hitter403GraphLoader: true
        };
      case FETCH_HITTER403_GRAPH_SUCCESS:
        return {
          ...state,
          hitter403Graph: action.payload,
          hitter403GraphLoader: false,
          isHitter403GraphLoaded: true
        };
      case FETCH_HITTER403_GRAPH_ERROR:
        return {
          ...state,
          hitter403GraphError: action.error.message,
          hitter403GraphLoader: false
        };

      // Fetch Hitter Game 404 Graph
      case FETCH_HITTER404_GRAPH_REQUEST:
        return {
          ...state,
          hitter404GraphLoader: true
        };
      case FETCH_HITTER404_GRAPH_SUCCESS:
        return {
          ...state,
          hitter404Graph: action.payload,
          hitter404GraphLoader: false,
          isHitter404GraphLoaded: true
        };
      case FETCH_HITTER404_GRAPH_ERROR:
        return {
          ...state,
          hitter404GraphError: action.error.message,
          hitter404GraphLoader: false
        };


      // Fetch Hitter Game 405 Graph
      case FETCH_HITTER405_GRAPH_REQUEST:
        return {
          ...state,
          hitter405GraphLoader: true
        };
      case FETCH_HITTER405_GRAPH_SUCCESS:
        return {
          ...state,
          hitter405Graph: action.payload,
          hitter405GraphLoader: false,
          isHitter405GraphLoaded: true
        };
      case FETCH_HITTER405_GRAPH_ERROR:
        return {
          ...state,
          hitter405GraphError: action.error.message,
          hitter405GraphLoader: false
        };

      // Fetch Measurement
      case FETCH_MEASUREMENT_REQUEST:
        return {
          ...state,
          measurementLoader: true
        };
      case FETCH_MEASUREMENT_SUCCESS:
        return {
          ...state,
          measurement: action.payload,
          measurementLoader: false,
          isMeasurementLoaded: true
        };
      case FETCH_MEASUREMENT_ERROR:
        return {
          ...state,
          measurementError: action.error.message,
          measurementLoader: false
        };
        
      // Fetch Glossary
      case FETCH_MEASUREMENT502_REQUEST:
        return {
          ...state,
          measurement502Loader: true
        };
      case FETCH_MEASUREMENT502_SUCCESS:
        return {
          ...state,
          measurement502: action.payload,
          measurement502Loader: false,
          isMeasurement502Loaded: true
        };
      case FETCH_MEASUREMENT502_ERROR:
        return {
          ...state,
          measurement502Error: action.error.message,
          measurement502Loader: false
        };

      default:
        return state;
    }
  }