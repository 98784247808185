export const FETCH_TOP_BATSMAN_REQUEST = 'FETCH_TOP_BATSMAN_REQUEST';
export const FETCH_TOP_BATSMAN_SUCCESS = 'FETCH_TOP_BATSMAN_SUCCESS';
export const FETCH_TOP_BATSMAN_ERROR   = 'FETCH_TOP_BATSMAN_ERROR';

export const FETCH_ALL_BATSMAN_REQUEST = 'FETCH_ALL_BATSMAN_REQUEST';
export const FETCH_ALL_BATSMAN_SUCCESS = 'FETCH_ALL_BATSMAN_SUCCESS';
export const FETCH_ALL_BATSMAN_ERROR   = 'FETCH_ALL_BATSMAN_ERROR';


export const FETCH_TOP_PITCHER_REQUEST = 'FETCH_TOP_PITCHER_REQUEST';
export const FETCH_TOP_PITCHER_SUCCESS = 'FETCH_TOP_PITCHER_SUCCESS';
export const FETCH_TOP_PITCHER_ERROR   = 'FETCH_TOP_PITCHER_ERROR';

export const FETCH_ALL_PITCHER_REQUEST = 'FETCH_ALL_PITCHER_REQUEST';
export const FETCH_ALL_PITCHER_SUCCESS = 'FETCH_ALL_PITCHER_SUCCESS';
export const FETCH_ALL_PITCHER_ERROR   = 'FETCH_ALL_PITCHER_ERROR';


export const FETCH_PLAYER_BY_ID_REQUEST = 'FETCH_PLAYER_BY_ID_REQUEST';
export const FETCH_PLAYER_BY_ID_SUCCESS = 'FETCH_PLAYER_BY_ID_SUCCESS';
export const FETCH_PLAYER_BY_ID_ERROR = 'FETCH_PLAYER_BY_ID_ERROR';

export const FETCH_PLAYER_BY_FILTER_REQUEST = 'FETCH_PLAYER_BY_FILTER_REQUEST';
export const FETCH_PLAYER_BY_FILTER_SUCCESS = 'FETCH_PLAYER_BY_FILTER_SUCCESS';
export const FETCH_PLAYER_BY_FILTER_ERROR = 'FETCH_PLAYER_BY_FILTER_ERROR';

export const EXPORT_CSV_FOR_USER_REQUEST = 'EXPORT_CSV_FOR_USER_REQUEST';
export const EXPORT_CSV_FOR_USER_SUCCESS = 'EXPORT_CSV_FOR_USER_SUCCESS';
export const EXPORT_CSV_FOR_USER_ERROR = 'EXPORT_CSV_FOR_USER_ERROR';

export const ADD_ROSTER_REQUEST = 'ADD_ROSTER_REQUEST';
export const ADD_ROSTER_SUCCESS = 'ADD_ROSTER_SUCCESS';
export const ADD_ROSTER_ERROR = 'ADD_ROSTER_ERROR';

export const SEND_NOTIFICATION_USER_REQUEST = 'SEND_NOTIFICATION_USER_REQUEST';
export const SEND_NOTIFICATION_USER_SUCCESS = 'SEND_NOTIFICATION_USER_SUCCESS';
export const SEND_NOTIFICATION_USER_ERROR = 'SEND_NOTIFICATION_USER_ERROR';

export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_ERROR = 'DELETE_PLAYER_ERROR';