import {

    //GET round-robin list

    GET_AUTO_LIST_SUCCESS,
    GET_AUTO_LIST_ERROR,

    CREATE_MATCHUP_SUCCESS,
    CREATE_MATCHUP_ERROR,

    //single elimination

    GET_SINGLE_AUTO_LIST_SUCCESS,
    GET_SINGLE_AUTO_LIST_ERROR,

    GET_SINGLE_MANUAL_LIST_SUCCESS,

    CREATE_MATCHUP_SINGLE_SUCCESS,
    CREATE_MATCHUP_SINGLE_ERROR,

    //division and pool both

    GET_BOTH_AUTO_LIST_SUCCESS,
    GET_BOTH_AUTO_LIST_ERROR,

    CREATE_MATCHUP_BOTH_SUCCESS,
    CREATE_MATCHUP_BOTH_ERROR


} from '../../constants/Scheduling/Matchup.const'

const initialState = {

    //GET round-robin list

    getAutoGenerateData: null,
    getAutoGenerateDataError: null,

    createMatchupData: null,
    createMatchupDataError: null,

    //single elimination

    getAutoSingleData: null,
    getAutoSingleDataError: null,

    getManualPoolData: null,

    createMatchupSingleData: null,
    createMatchupSingleDataError: null,

    //division and pool both

    getBothMatchupData: null,
    getBothMatchupDataError: null,

    createBothMatchupData: null,
    createBothMatchupDataError: null,

}
export default function (state = initialState, action) {
    switch (action.type) {

        //GET round-robin list

        case GET_AUTO_LIST_SUCCESS:
            return {
                ...state,
                getAutoGenerateData: action.payload
            }
        case GET_AUTO_LIST_ERROR:
            return {
                ...state,
                getAutoGenerateDataError: action.error
            }

        case CREATE_MATCHUP_SUCCESS:
            return {
                ...state,
                createMatchupData: action.payload
            }
        case CREATE_MATCHUP_ERROR:
            return {
                ...state,
                createMatchupDataError: action.error
            }

        //single elimination

        case GET_SINGLE_AUTO_LIST_SUCCESS:
            return {
                ...state,
                getAutoSingleData: action.payload
            }
        case GET_SINGLE_AUTO_LIST_ERROR:
            return {
                ...state,
                getAutoSingleDataError: action.error
            }

        // MANUAL SE LIST
        case GET_SINGLE_MANUAL_LIST_SUCCESS:
            return {
                ...state,
                getManualPoolData: action.payload
            }

        case CREATE_MATCHUP_SINGLE_SUCCESS:
            return {
                ...state,
                createMatchupSingleData: action.payload
            }
        case CREATE_MATCHUP_SINGLE_ERROR:
            return {
                ...state,
                createMatchupSingleDataError: action.error
            }


            //division and pool both

            case GET_BOTH_AUTO_LIST_SUCCESS:
            return {
                ...state,
                getBothMatchupData: action.payload
            }
        case GET_BOTH_AUTO_LIST_ERROR:
            return {
                ...state,
                getBothMatchupDataError: action.error
            }

        case CREATE_MATCHUP_BOTH_SUCCESS:
            return {
                ...state,
                createBothMatchupData: action.payload
            }
        case CREATE_MATCHUP_BOTH_ERROR:
            return {
                ...state,
                createBothMatchupDataError: action.error
            }

        default:
            return state;
    }
}