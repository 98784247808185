export const DATAINPUT_LIST_REQUEST = 'DATAINPUT_LIST_REQUEST';
export const DATAINPUT_LIST_SUCCESS = 'DATAINPUT_LIST_SUCCESS';
export const DATAINPUT_LIST_ERROR   = 'DATAINPUT_LIST_ERROR';

export const ADD_DATAINPUT_REQUEST = 'ADD_DATAINPUT_REQUEST';
export const ADD_DATAINPUT_SUCCESS = 'ADD_DATAINPUT_SUCCESS';
export const ADD_DATAINPUT_ERROR   = 'ADD_DATAINPUT_ERROR';

export const EVENT_PLAYER_LIST_SUCCESS = 'EVENT_PLAYER_LIST_SUCCESS';
export const EVENT_PLAYER_LIST_ERROR   = 'EVENT_PLAYER_LIST_ERROR';

export const FETCH_INPUT_DATA_SUCCESS = 'FETCH_INPUT_DATA_SUCCESS';
export const FETCH_INPUT_DATA_ERROR   = 'FETCH_INPUT_DATA_ERROR';

export const EDIT_DATAINPUT_REQUEST = 'EDIT_DATAINPUT_REQUEST';
export const EDIT_DATAINPUT_SUCCESS = 'EDIT_DATAINPUT_SUCCESS';
export const EDIT_DATAINPUT_ERROR   = 'EDIT_DATAINPUT_ERROR';

export const DELETE_DATAINPUT_REQUEST = 'DELETE_DATAINPUT_REQUEST';
export const DELETE_DATAINPUT_SUCCESS = 'DELETE_DATAINPUT_SUCCESS';
export const DELETE_DATAINPUT_ERROR   = 'DELETE_DATAINPUT_ERROR';

export const INPUT_GET_EVENT_LIST_SUCCESS = "INPUT_GET_EVENT_LIST_SUCCESS";
export const INPUT_GET_EVENT_LIST_ERROR = "INPUT_GET_EVENT_LIST_ERROR";
export const INPUT_GET_EVENT_LIST_REQUEST = "INPUT_GET_EVENT_LIST_REQUEST";

export const INPUT_GET_PARANT_TEAM_SUCCESS = "INPUT_GET_PARANT_TEAM_SUCCESS";
export const INPUT_GET_PARANT_TEAM_ERROR = "INPUT_GET_PARANT_TEAM_ERROR";
export const INPUT_GET_PARANT_TEAM_REQUEST = "INPUT_GET_PARANT_TEAM_REQUEST";

export const INPUT_GET_COACH_LIST_SUCCESS = "INPUT_GET_COACH_LIST_SUCCESS";
export const INPUT_GET_COACH_LIST_ERROR = "INPUT_GET_COACH_LIST_ERROR";
export const INPUT_GET_COACH_LIST_REQUEST = "INPUT_GET_COACH_LIST_REQUEST";

export const INPUT_POST_CREATE_EVENT_SUCCESS = "INPUT_POST_CREATE_EVENT_SUCCESS";
export const INPUT_POST_CREATE_EVENT_ERROR = "INPUT_POST_CREATE_EVENT_ERROR";
export const INPUT_POST_CREATE_EVENT_REQUEST = "INPUT_POST_CREATE_EVENT_REQUEST";

export const INPUT_SAVE_SOURCE_SUCCESS = "INPUT_SAVE_SOURCE_SUCCESS";
export const INPUT_SAVE_SOURCE_ERROR = "INPUT_SAVE_SOURCE_ERROR";
export const INPUT_SAVE_SOURCE_REQUEST = "INPUT_SAVE_SOURCE_REQUEST";

export const INPUT_SAVE_RAPSODO_SOURCE_SUCCESS = "INPUT_SAVE_RAPSODO_SOURCE_SUCCESS";
export const INPUT_SAVE_RAPSODO_SOURCE_ERROR = "INPUT_SAVE_RAPSODO_SOURCE_ERROR";
export const INPUT_SAVE_RAPSODO_SOURCE_REQUEST = "INPUT_SAVE_RAPSODO_SOURCE_REQUEST";


export const INPUT_GET_DEVICE_SUCCESS = "INPUT_GET_DEVICE_SUCCESS";
export const INPUT_GET_DEVICE_ERROR = "INPUT_GET_DEVICE_ERROR";
export const INPUT_GET_DEVICE_REQUEST = "INPUT_GET_DEVICE_REQUEST";

export const INPUT_GET_LINK_SUCCESS = "INPUT_GET_LINK_SUCCESS";
export const INPUT_GET_LINK_ERROR = "INPUT_GET_LINK_ERROR";
export const INPUT_GET_LINK_REQUEST = "INPUT_GET_LINK_REQUEST";

export const INPUT_GET_OPTION_SUCCESS = "INPUT_GET_OPTION_SUCCESS";
export const INPUT_GET_OPTION_ERROR = "INPUT_GET_OPTION_ERROR";
export const INPUT_GET_OPTION_REQUEST = "INPUT_GET_OPTION_REQUEST";

export const INPUT_GET_RMAP_SUCCESS = "INPUT_GET_RMAP_SUCCESS";
export const INPUT_GET_RMAP_ERROR = "INPUT_GET_RMAP_ERROR";
export const INPUT_GET_RMAP_REQUEST = "INPUT_GET_RMAP_REQUEST";

export const INPUT_DELETE_EVENT_SUCCESS = "INPUT_DELETE_EVENT_SUCCESS";
export const INPUT_DELETE_EVENT_ERROR = "INPUT_DELETE_EVENT_ERROR";
export const INPUT_DELETE_EVENT_REQUEST = "INPUT_DELETE_EVENT_REQUEST";

export const INPUT_GET_SOURCE_BY_EVENT_SUCCESS = "INPUT_GET_SOURCE_BY_EVENT_SUCCESS";
export const INPUT_GET_SOURCE_BY_EVENT_ERROR = "INPUT_GET_SOURCE_BY_EVENT_ERROR";
export const INPUT_GET_SOURCE_BY_EVENT_REQUEST = "INPUT_GET_SOURCE_BY_EVENT_REQUEST";

export const INPUT_POST_UPLOAD_VIDEO_SUCCESS = "INPUT_POST_UPLOAD_VIDEO_SUCCESS";
export const INPUT_POST_UPLOAD_VIDEO_ERROR = "INPUT_POST_UPLOAD_VIDEO_ERROR";
export const INPUT_POST_UPLOAD_VIDEO_REQUEST = "INPUT_POST_UPLOAD_VIDEO_REQUEST";

export const INPUT_ROW_DEVICE_DATA_SUCCESS = "INPUT_ROW_DEVICE_DATA_SUCCESS";
export const INPUT_ROW_DEVICE_DATA_ERROR = "INPUT_ROW_DEVICE_DATA_ERROR";
export const INPUT_ROW_DEVICE_DATA_REQUEST = "INPUT_ROW_DEVICE_DATA_REQUEST";

export const INPUT_GET_VIDEO_MAPPING_LIST_SUCCESS = "INPUT_GET_VIDEO_MAPPING_LIST_SUCCESS";
export const INPUT_GET_VIDEO_MAPPING_LIST_ERROR = "INPUT_GET_VIDEO_MAPPING_LIST_ERROR";
export const INPUT_GET_VIDEO_MAPPING_LIST_REQUEST = "INPUT_GET_VIDEO_MAPPING_LIST_REQUEST";

export const INPUT_MAP_VIDEO_FOR_PER_PITCH_SUCCESS = "INPUT_MAP_VIDEO_FOR_PER_PITCH_SUCCESS";
export const INPUT_MAP_VIDEO_FOR_PER_PITCH_ERROR = "INPUT_MAP_VIDEO_FOR_PER_PITCH_ERROR";
export const INPUT_MAP_VIDEO_FOR_PER_PITCH_REQUEST = "INPUT_MAP_VIDEO_FOR_PER_PITCH_REQUEST";

export const INPUT_UPDATE_DEVICE_DATA_SUCCESS = "INPUT_UPDATE_DEVICE_DATA_SUCCESS";
export const INPUT_UPDATE_DEVICE_DATA_ERROR = "INPUT_UPDATE_DEVICE_DATA_ERROR";
export const INPUT_UPDATE_DEVICE_DATA_REQUEST = "INPUT_UPDATE_DEVICE_DATA_REQUEST";

export const INPUT_GET_RAPSODO_OPTION_SUCCESS = "INPUT_GET_RAPSODO_OPTION_SUCCESS";
export const INPUT_GET_RAPSODO_OPTION_ERROR = "INPUT_GET_RAPSODO_OPTION_ERROR";
export const INPUT_GET_RAPSODO_OPTION_REQUEST = "INPUT_GET_RAPSODO_OPTION_REQUEST";

export const INPUT_MAP_RAPSODO_SUCCESS = "INPUT_MAP_RAPSODO_SUCCESS";
export const INPUT_MAP_RAPSODO_ERROR = "INPUT_MAP_RAPSODO_ERROR";
export const INPUT_MAP_RAPSODO_REQUEST = "INPUT_MAP_RAPSODO_REQUEST";

export const REPORT_GENERATED_MAIL_SUCCESS = "REPORT_GENERATED_MAIL_SUCCESS";
export const REPORT_GENERATED_MAIL_ERROR = "REPORT_GENERATED_MAIL_ERROR";
export const REPORT_GENERATED_MAIL_REQUEST = "REPORT_GENERATED_MAIL_REQUEST";

export const SHOWCASE_LIST_PLATFORM_SUCCESS = "SHOWCASE_LIST_PLATFORM_SUCCESS";
export const SHOWCASE_LIST_PLATFORM_ERROR = "SHOWCASE_LIST_PLATFORM_ERROR";
export const SHOWCASE_LIST_PLATFORM_REQUEST = "SHOWCASE_LIST_PLATFORM_REQUEST";

// export const FETCH_SELECTED_TEMPLATE_SUCCESS = "FETCH_SELECTED_TEMPLATE_SUCCESS";
// export const FETCH_SELECTED_TEMPLATE_ERROR = "FETCH_SELECTED_TEMPLATE_ERROR";
// export const FETCH_SELECTED_TEMPLATE_REQUEST = "FETCH_SELECTED_TEMPLATE_REQUEST";

export const EXPORT_CSV_SUCCESS = "EXPORT_CSV_SUCCESS";
export const EXPORT_CSV_ERROR = "EXPORT_CSV_ERROR";
export const EXPORT_CSV_REQUEST = "EXPORT_CSV_REQUEST";

export const IMPORT_CSV_SUCCESS = "IMPORT_CSV_SUCCESS";
export const IMPORT_CSV_ERROR = "IMPORT_CSV_ERROR";
export const IMPORT_CSV_REQUEST = "IMPORT_CSV_REQUEST";

export const INPUT_DEL_SELL_VIDEO_SUCCESS = "INPUT_DEL_SELL_VIDEO_SUCCESS";
export const INPUT_DEL_SELL_VIDEO_ERROR = "INPUT_DEL_SELL_VIDEO_ERROR";
export const INPUT_DEL_SELL_VIDEO_REQUEST = "INPUT_DEL_SELL_VIDEO_REQUEST";

export const UPLOAD_PDF_REQUEST = "UPLOAD_PDF_REQUEST";
export const UPLOAD_PDF_SUCCESS = "UPLOAD_PDF_SUCCESS";
export const UPLOAD_PDF_ERROR = "UPLOAD_PDF_ERROR";