export const FETCH_ALL_FANS_REQUEST = 'FETCH_ALL_FANS_REQUEST';
export const FETCH_ALL_FANS_SUCCESS = 'FETCH_ALL_FANS_SUCCESS';
export const FETCH_ALL_FANS_ERROR   = 'FETCH_ALL_FANS_ERROR';

export const FETCH_FAN_BY_FILTER_REQUEST = 'FETCH_FAN_BY_FILTER_REQUEST';
export const FETCH_FAN_BY_FILTER_SUCCESS = 'FETCH_FAN_BY_FILTER_SUCCESS';
export const FETCH_FAN_BY_FILTER_ERROR = 'FETCH_FAN_BY_FILTER_ERROR';

export const FETCH_FAN_BY_ID_REQUEST = 'FETCH_FAN_BY_ID_REQUEST';
export const FETCH_FAN_BY_ID_SUCCESS = 'FETCH_FAN_BY_ID_SUCCESS';
export const FETCH_FAN_BY_ID_ERROR = 'FETCH_FAN_BY_ID_ERROR';

export const DELETE_FAN_SUCCESS = 'DELETE_FAN_SUCCESS';
export const DELETE_FAN_ERROR = 'DELETE_FAN_ERROR';