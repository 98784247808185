export const FETCH_COLLEGE_REQUEST = 'FETCH_COLLEGE_REQUEST';
export const FETCH_COLLEGE_SUCCESS = 'FETCH_COLLEGE_SUCCESS';
export const FETCH_COLLEGE_ERROR   = 'FETCH_COLLEGE_ERROR';

export const UPDATE_COLLEGE_REQUEST = 'UPDATE_COLLEGE_REQUEST';
export const UPDATE_COLLEGE_SUCCESS = 'UPDATE_COLLEGE_SUCCESS';
export const UPDATE_COLLEGE_ERROR   = 'UPDATE_COLLEGE_ERROR';

export const DELETE_COLLEGE_REQUEST = 'DELETE_COLLEGE_REQUEST';
export const DELETE_COLLEGE_SUCCESS = 'DELETE_COLLEGE_SUCCESS';
export const DELETE_COLLEGE_ERROR   = 'DELETE_COLLEGE_ERROR';

export const CREATE_COLLEGE_REQUEST = 'CREATE_COLLEGE_REQUEST';
export const CREATE_COLLEGE_SUCCESS = 'CREATE_COLLEGE_SUCCESS';
export const CREATE_COLLEGE_ERROR   = 'CREATE_COLLEGE_ERROR';