import {
    ALL_EVENT_SUCCESS,
    ALL_EVENT_ERROR
} from '../constants/EventRegister.const';

const initialState = {
    tournamentList: null,
    tournamentError: null,

    showcaseList: null,
    showcaseError: null,

    bundleList: null,
    bundleError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        //get all event list
        case `TOURNAMENT_EVENT_SUCCESS`:
            return {
                ...state,
                tournamentList: action.payload,
            };
        case `TOURNAMENT_EVENT_ERROR`:
            return {
                ...state,
                tournamentError: action.error,
            };

        case `SHOWCASE_EVENT_SUCCESS`:
            return {
                ...state,
                showcaseList: action.payload,
            };
        case `SHOWCASE_EVENT_ERROR`:
            return {
                ...state,
                showcaseError: action.error,
            };

        case `BUNDLE_EVENT_SUCCESS`:
            return {
                ...state,
                bundleList: action.payload,
            };
        case `BUNDLE_EVENT_ERROR`:
            return {
                ...state,
                bundleError: action.error,
            };
        default:
            return state;
    }
}