import { 
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_ERROR,
    GET_TEAM_BY_ID_ERROR,
    GET_TEAM_BY_ID_REQUEST,
    GET_TEAM_BY_ID_SUCCESS,
    EDIT_TEAM_INFO_ERROR,
    EDIT_TEAM_INFO_REQUEST,
    EDIT_TEAM_INFO_SUCCESS,
    USER_EDIT_PROFILE_REQUEST,
    USER_EDIT_PROFILE_SUCCESS,
    USER_EDIT_PROFILE_ERROR
} from '../../../constants/Users/PopUp/EditProfile.const';

const initialState = {
    userPasswordLoader: false,
    userPasswordData: null,
    userPasswordError: null,

    teamInfoData: null,
    teamInfoError: null,

    teamEditData : null,
    teamEditError: null,
    
    userEditProfileLoader: false,
    userEditProfileData: null,
    userEditProfileError: null
};

export default function(state = initialState, action) {
  switch (action.type) {

    // reset pasasword of user
    case USER_RESET_PASSWORD_REQUEST:
        return {
            ...state,
            userPasswordLoader: true
        };
    case USER_RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            userPasswordData: action.payload
        };
    case USER_RESET_PASSWORD_ERROR:
        return {
            ...state,
            userPasswordError: action.error,
        };

    // edit profile of user
    case USER_EDIT_PROFILE_REQUEST:
        return {
            ...state,
            userEditProfileLoader: true
        };
    case USER_EDIT_PROFILE_SUCCESS:
        return {
            ...state,
            userEditProfileData: action.payload
        };
    case USER_EDIT_PROFILE_ERROR:
        return {
            ...state,
            userEditProfileError: action.error,
        }

     // get team data by id
    case GET_TEAM_BY_ID_SUCCESS:
    return {
        ...state,
        teamInfoData: action.payload
    };
    case GET_TEAM_BY_ID_ERROR:
    return {
        ...state,
        teamInfoError: action.error,
    };

    // get team data by id
    case EDIT_TEAM_INFO_SUCCESS:
        return {
            ...state,
            teamEditData: action.payload
        };
    case EDIT_TEAM_INFO_ERROR:
        return {
            ...state,
            teamEditError: action.error,
        };

    default:
      return state;
  }
}
