
export const FETCH_DC_OBJ_REQUEST = 'FETCH_DC_OBJ_REQUEST';
export const FETCH_DC_OBJ_SUCCESS = 'FETCH_DC_OBJ_SUCCESS';
export const FETCH_DC_OBJ_ERROR   = 'FETCH_DC_OBJ_ERROR';

export const UPDATE_DC_OBJ_REQUEST = 'UPDATE_DC_OBJ_REQUEST';
export const UPDATE_DC_OBJ_SUCCESS = 'UPDATE_DC_OBJ_SUCCESS';
export const UPDATE_DC_OBJ_ERROR   = 'UPDATE_DC_OBJ_ERROR';

//_____________________________________________________________________
export const FETCH_DK_OBJ_REQUEST = 'FETCH_DK_OBJ_REQUEST';
export const FETCH_DK_OBJ_SUCCESS = 'FETCH_DK_OBJ_SUCCESS';
export const FETCH_DK_OBJ_ERROR   = 'FETCH_DK_OBJ_ERROR';

export const UPDATE_DK_OBJ_REQUEST = 'UPDATE_DK_OBJ_REQUEST';
export const UPDATE_DK_OBJ_SUCCESS = 'UPDATE_DK_OBJ_SUCCESS';
export const UPDATE_DK_OBJ_ERROR   = 'UPDATE_DK_OBJ_ERROR';

//_____________________________________________________________________
export const FETCH_TM_OBJ_REQUEST = 'FETCH_TM_OBJ_REQUEST';
export const FETCH_TM_OBJ_SUCCESS = 'FETCH_TM_OBJ_SUCCESS';
export const FETCH_TM_OBJ_ERROR   = 'FETCH_TM_OBJ_ERROR';

export const UPDATE_TM_OBJ_REQUEST = 'UPDATE_TM_OBJ_REQUEST';
export const UPDATE_TM_OBJ_SUCCESS = 'UPDATE_TM_OBJ_SUCCESS';
export const UPDATE_TM_OBJ_ERROR   = 'UPDATE_TM_OBJ_ERROR';

//_____________________________________________________________________
export const FETCH_FS_OBJ_REQUEST = 'FETCH_FS_OBJ_REQUEST';
export const FETCH_FS_OBJ_SUCCESS = 'FETCH_FS_OBJ_SUCCESS';
export const FETCH_FS_OBJ_ERROR   = 'FETCH_FS_OBJ_ERROR';

export const UPDATE_FS_OBJ_REQUEST = 'UPDATE_FS_OBJ_REQUEST';
export const UPDATE_FS_OBJ_SUCCESS = 'UPDATE_FS_OBJ_SUCCESS';
export const UPDATE_FS_OBJ_ERROR   = 'UPDATE_FS_OBJ_ERROR';

//_____________________________________________________________________
export const FETCH_YT_OBJ_REQUEST = 'FETCH_YT_OBJ_REQUEST';
export const FETCH_YT_OBJ_SUCCESS = 'FETCH_YT_OBJ_SUCCESS';
export const FETCH_YT_OBJ_ERROR   = 'FETCH_YT_OBJ_ERROR';

export const UPDATE_YT_OBJ_REQUEST = 'UPDATE_YT_OBJ_REQUEST';
export const UPDATE_YT_OBJ_SUCCESS = 'UPDATE_YT_OBJ_SUCCESS';
export const UPDATE_YT_OBJ_ERROR   = 'UPDATE_YT_OBJ_ERROR';

//_____________________________________________________________________
export const FETCH_RAPSODO_OBJ_REQUEST = 'FETCH_RAPSODO_OBJ_REQUEST';
export const FETCH_RAPSODO_OBJ_SUCCESS = 'FETCH_RAPSODO_OBJ_SUCCESS';
export const FETCH_RAPSODO_OBJ_ERROR   = 'FETCH_RAPSODO_OBJ_ERROR';

export const UPDATE_RAPSODO_OBJ_REQUEST = 'UPDATE_RAPSODO_OBJ_REQUEST';
export const UPDATE_RAPSODO_OBJ_SUCCESS = 'UPDATE_RAPSODO_OBJ_SUCCESS';
export const UPDATE_RAPSODO_OBJ_ERROR   = 'UPDATE_RAPSODO_OBJ_ERROR';

//_____________________________________________________________________
export const FETCH_MEASUREMENT_OBJ_REQUEST = 'FETCH_MEASUREMENT_OBJ_REQUEST';
export const FETCH_MEASUREMENT_OBJ_SUCCESS = 'FETCH_MEASUREMENT_OBJ_SUCCESS';
export const FETCH_MEASUREMENT_OBJ_ERROR   = 'FETCH_MEASUREMENT_OBJ_ERROR';

export const UPDATE_MEASUREMENT_OBJ_REQUEST = 'UPDATE_MEASUREMENT_OBJ_REQUEST';
export const UPDATE_MEASUREMENT_OBJ_SUCCESS = 'UPDATE_MEASUREMENT_OBJ_SUCCESS';
export const UPDATE_MEASUREMENT_OBJ_ERROR   = 'UPDATE_MEASUREMENT_OBJ_ERROR';