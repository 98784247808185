import { 

    FETCH_REWARDS_REQUEST,
    FETCH_REWARDS_SUCCESS,
    FETCH_REWARDS_ERROR,

    FETCH_REWARDS_NAMES_REQUEST,
    FETCH_REWARDS_NAMES_SUCCESS,
    FETCH_REWARDS_NAMES_ERROR,
  
    UPDATE_REWARD_REQUEST,
    UPDATE_REWARD_SUCCESS,
    UPDATE_REWARD_ERROR,  
  
    CREATE_REWARD_REQUEST,
    CREATE_REWARD_SUCCESS,
    CREATE_REWARD_ERROR,  
  
    DELETE_REWARD_REQUEST,
    DELETE_REWARD_SUCCESS,
    DELETE_REWARD_ERROR  
  
  } from '../../constants/Marketing/Reward.const';
    
    const initialState = {
  
      rewards: [],
      rewardsError: null,
      rewardsLoader: false,

      rewardNames: [],
      rewardNamesError: null,
      rewardNamesLoader: false,
      isRewardNamesLoaded: false,
  
      reward: [],
      rewardError: null,
      rewardLoader: false,
  
      isRewardCreated: false,
      isRewardCreatedError: null,
      isRewardCreatedLoader: false,
  
      isRewardUpdated: false,
      isRewardUpdatedError: null,
      isRewardUpdatedLoader: false,
  
      isRewardDeleted: false,
      isRewardDeletedError: null,
      isRewardDeletedLoader: false,
  
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
    
        // For GET Reward Data
    
        case FETCH_REWARDS_REQUEST:
          return {
            ...state,
            rewardsLoader: true
          };
        case FETCH_REWARDS_SUCCESS:
          return {
            ...state,
            isRewardsLoaded: true,
            rewards: action.payload,
            rewardsLoader: false
          };
        case FETCH_REWARDS_ERROR:
          return {
            ...state,
            rewardsError: action.error.message,
            rewardsLoader: false
          };

        // For GET Reward Names
    
        case FETCH_REWARDS_NAMES_REQUEST:
          return {
            ...state,
            rewardNamesLoader: true
          };
        case FETCH_REWARDS_NAMES_SUCCESS:
          return {
            ...state,
            isRewardNamesLoaded: true,
            rewardNames: action.payload,
            rewardNamesLoader: false
          };
        case FETCH_REWARDS_NAMES_ERROR:
          return {
            ...state,
            rewardNamesError: action.error.message,
            rewardNamesLoader: false
          };
        
        // For CREATE Reward Data
    
        case CREATE_REWARD_REQUEST:
          return {
            ...state,
            isRewardCreatedLoader: true
          };
        case CREATE_REWARD_SUCCESS:
          return {
            ...state,
            isRewardCreated: true,
            isRewardCreatedLoader: false
          };
        case CREATE_REWARD_ERROR:
          return {
            ...state,
            isRewardCreatedError: action.error.message,
            isRewardCreatedLoader: false
          };
  
        // For UPDATE Reward Data
  
        case UPDATE_REWARD_REQUEST:
          return {
            ...state,
            isRewardUpdatedLoader: true
          };
        case UPDATE_REWARD_SUCCESS:
          return {
            ...state,
            isRewardUpdated: true,
            isRewardUpdatedLoader: false
          };
        case UPDATE_REWARD_ERROR:
          return {
            ...state,
            isRewardUpdatedError: action.error.message,
            isRewardUpdatedLoader: false
          };
  
  
        // For DELETE Reward Data
  
        case DELETE_REWARD_REQUEST:
          return {
            ...state,
            isRewardDeletedLoader: true
          };
        case DELETE_REWARD_SUCCESS:
          return {
            ...state,
            isRewardDeleted: true,
            isRewardDeletedLoader: false
          };
        case DELETE_REWARD_ERROR:
          return {
            ...state,
            isRewardDeletedError: action.error.message,
            isRewardDeletedLoader: false
          };
    
  
        default:
          return state;
      }
    }