export const VENUE_LIST_SUCCESS = 'VENUE_LIST_SUCCESS';
export const VENUE_LIST_ERROR   = 'VENUE_LIST_ERROR';

export const CREATE_VENUE_SUCCESS = 'CREATE_VENUE_SUCCESS';
export const CREATE_VENUE_ERROR   = 'CREATE_VENUE_ERROR';

export const GET_VENUE_BY_ID_SUCCESS = 'GET_VENUE_BY_ID_SUCCESS';
export const GET_VENUE_BY_ID_ERROR   = 'GET_VENUE_BY_ID_ERROR';

export const EDIT_VENUE_SUCCESS = 'EDIT_VENUE_SUCCESS';
export const EDIT_VENUE_ERROR   = 'EDIT_VENUE_ERROR';

export const DELETE_VENUE_SUCCESS = 'DELETE_VENUE_SUCCESS';
export const DELETE_VENUE_ERROR   = 'DELETE_VENUE_ERROR';

export const EDIT_FIELD_VENUE_SUCCESS = 'EDIT_FIELD_VENUE_SUCCESS';
export const EDIT_FIELD_VENUE_ERROR   = 'EDIT_FIELD_VENUE_ERROR';

export const DELETE_FIELD_VENUE_SUCCESS = 'DELETE_FIELD_VENUE_SUCCESS';
export const DELETE_FIELD_VENUE_ERROR   = 'DELETE_FIELD_VENUE_ERROR';