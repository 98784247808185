import { 
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS, 
    FORGOT_PASSWORD_ERROR,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS, 
    RESET_PASSWORD_ERROR,

  } from '../../constants/Authentication/ForgotPassword.const';
  
  const initialState = {

    isPasswordUpdated: false,
    forgotPasswordError: null,
    forgotPasswordLoader: false,
    authData: [],

    isPasswordReseted: false,
    passwordResetError: null,
    passwordResetLoader: false

  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
  
  
      // Get Forgot Password.
  
      case FORGOT_PASSWORD_REQUEST:
        return {
          ...state,
          forgotPasswordLoader: true
        };
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          authData: action.payload,
          isPasswordUpdated: true,
          forgotPasswordLoader: false
        };
      case FORGOT_PASSWORD_ERROR:
        return {
          ...state,
          forgotPasswordError: action.error,
          forgotPasswordLoader: false
        };

      // Get Reset Password.
  
      case RESET_PASSWORD_REQUEST:
        return {
          ...state,
          passwordResetLoader: true
        };
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isPasswordReseted: true,
          passwordResetLoader: false
        };
      case RESET_PASSWORD_ERROR:
        return {
          ...state,
          passwordResetError: action.error,
          passwordResetLoader: false
        };

  
      default:
        return state;
    }
  }