export const GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS";
export const GET_EVENT_LIST_ERROR = "GET_EVENT_LIST_ERROR";
export const GET_EVENT_LIST_REQUEST = "GET_EVENT_LIST_REQUEST";

export const GET_PARANT_TEAM_SUCCESS = "GET_PARANT_TEAM_SUCCESS";
export const GET_PARANT_TEAM_ERROR = "GET_PARANT_TEAM_ERROR";
export const GET_PARANT_TEAM_REQUEST = "GET_PARANT_TEAM_REQUEST";

export const GET_COACH_LIST_SUCCESS = "GET_COACH_LIST_SUCCESS";
export const GET_COACH_LIST_ERROR = "GET_COACH_LIST_ERROR";
export const GET_COACH_LIST_REQUEST = "GET_COACH_LIST_REQUEST";

export const POST_CREATE_EVENT_SUCCESS = "POST_CREATE_EVENT_SUCCESS";
export const POST_CREATE_EVENT_ERROR = "POST_CREATE_EVENT_ERROR";
export const POST_CREATE_EVENT_REQUEST = "POST_CREATE_EVENT_REQUEST";

export const SAVE_SOURCE_SUCCESS = "SAVE_SOURCE_SUCCESS";
export const SAVE_SOURCE_ERROR = "SAVE_SOURCE_ERROR";
export const SAVE_SOURCE_REQUEST = "SAVE_SOURCE_REQUEST";

export const SAVE_RAPSODO_SOURCE_SUCCESS = "SAVE_RAPSODO_SOURCE_SUCCESS";
export const SAVE_RAPSODO_SOURCE_ERROR = "SAVE_RAPSODO_SOURCE_ERROR";
export const SAVE_RAPSODO_SOURCE_REQUEST = "SAVE_RAPSODO_SOURCE_REQUEST";


export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_ERROR = "GET_DEVICE_ERROR";
export const GET_DEVICE_REQUEST = "GET_DEVICE_REQUEST";

export const GET_LINK_SUCCESS = "GET_LINK_SUCCESS";
export const GET_LINK_ERROR = "GET_LINK_ERROR";
export const GET_LINK_REQUEST = "GET_LINK_REQUEST";

export const GET_OPTION_SUCCESS = "GET_OPTION_SUCCESS";
export const GET_OPTION_ERROR = "GET_OPTION_ERROR";
export const GET_OPTION_REQUEST = "GET_OPTION_REQUEST";

export const GET_RMAP_SUCCESS = "GET_RMAP_SUCCESS";
export const GET_RMAP_ERROR = "GET_RMAP_ERROR";
export const GET_RMAP_REQUEST = "GET_RMAP_REQUEST";

export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";

export const GET_SOURCE_BY_EVENT_SUCCESS = "GET_SOURCE_BY_EVENT_SUCCESS";
export const GET_SOURCE_BY_EVENT_ERROR = "GET_SOURCE_BY_EVENT_ERROR";
export const GET_SOURCE_BY_EVENT_REQUEST = "GET_SOURCE_BY_EVENT_REQUEST";

export const POST_UPLOAD_VIDEO_SUCCESS = "POST_UPLOAD_VIDEO_SUCCESS";
export const POST_UPLOAD_VIDEO_ERROR = "POST_UPLOAD_VIDEO_ERROR";
export const POST_UPLOAD_VIDEO_REQUEST = "POST_UPLOAD_VIDEO_REQUEST";

export const ROW_DEVICE_DATA_SUCCESS = "ROW_DEVICE_DATA_SUCCESS";
export const ROW_DEVICE_DATA_ERROR = "ROW_DEVICE_DATA_ERROR";
export const ROW_DEVICE_DATA_REQUEST = "ROW_DEVICE_DATA_REQUEST";

export const GET_VIDEO_MAPPING_LIST_SUCCESS = "GET_VIDEO_MAPPING_LIST_SUCCESS";
export const GET_VIDEO_MAPPING_LIST_ERROR = "GET_VIDEO_MAPPING_LIST_ERROR";
export const GET_VIDEO_MAPPING_LIST_REQUEST = "GET_VIDEO_MAPPING_LIST_REQUEST";

export const MAP_VIDEO_FOR_PER_PITCH_SUCCESS = "MAP_VIDEO_FOR_PER_PITCH_SUCCESS";
export const MAP_VIDEO_FOR_PER_PITCH_ERROR = "MAP_VIDEO_FOR_PER_PITCH_ERROR";
export const MAP_VIDEO_FOR_PER_PITCH_REQUEST = "MAP_VIDEO_FOR_PER_PITCH_REQUEST";

export const UPDATE_DEVICE_DATA_SUCCESS = "UPDATE_DEVICE_DATA_SUCCESS";
export const UPDATE_DEVICE_DATA_ERROR = "UPDATE_DEVICE_DATA_ERROR";
export const UPDATE_DEVICE_DATA_REQUEST = "UPDATE_DEVICE_DATA_REQUEST";

export const GET_RAPSODO_OPTION_SUCCESS = "GET_RAPSODO_OPTION_SUCCESS";
export const GET_RAPSODO_OPTION_ERROR = "GET_RAPSODO_OPTION_ERROR";
export const GET_RAPSODO_OPTION_REQUEST = "GET_RAPSODO_OPTION_REQUEST";

export const MAP_RAPSODO_SUCCESS = "MAP_RAPSODO_SUCCESS";
export const MAP_RAPSODO_ERROR = "MAP_RAPSODO_ERROR";
export const MAP_RAPSODO_REQUEST = "MAP_RAPSODO_REQUEST";

export const TEAM_SOURCE_DETAILS_REQUEST = 'TEAM_SOURCE_DETAILS_REQUEST';
export const TEAM_SOURCE_DETAILS_SUCCESS = 'TEAM_SOURCE_DETAILS_SUCCESS';
export const TEAM_SOURCE_DETAILS_ERROR = 'TEAM_SOURCE_DETAILS_ERROR';

export const TEAM_SOURCE_MAP_REQUEST = 'TEAM_SOURCE_MAP_REQUEST';
export const TEAM_SOURCE_MAP_SUCCESS = 'TEAM_SOURCE_MAP_SUCCESS';
export const TEAM_SOURCE_MAP_ERROR = 'TEAM_SOURCE_MAP_ERROR';

export const DEL_SELL_VIDEO_REQUEST = 'DEL_SELL_VIDEO_REQUEST';
export const DEL_SELL_VIDEO_SUCCESS = 'DEL_SELL_VIDEO_SUCCESS';
export const DEL_SELL_VIDEO_ERROR = 'DEL_SELL_VIDEO_ERROR';

export const GET_TEAM_LIST_FOR_LINK_REQUEST = 'GET_TEAM_LIST_FOR_LINK_REQUEST';
export const GET_TEAM_LIST_FOR_LINK_SUCCESS = 'GET_TEAM_LIST_FOR_LINK_SUCCESS';
export const GET_TEAM_LIST_FOR_LINK_ERROR = 'GET_TEAM_LIST_FOR_LINK_ERROR';

export const LINK_TEAM_SCOUTING_REQUEST = 'LINK_TEAM_SCOUTING_REQUEST';
export const LINK_TEAM_SCOUTING_SUCCESS = 'LINK_TEAM_SCOUTING_SUCCESS';
export const LINK_TEAM_SCOUTING_ERROR = 'LINK_TEAM_SCOUTING_ERROR';