import {
    GET_ALL_EVENT_LIST_SUCCESS,
    GET_ALL_EVENT_LIST_ERROR
} from '../../constants/Scheduling/ShedulingDashboard.const'

const initialState={
    getalleventdata: [],
    getalleventdataerror: null,
}
export default function (state = initialState, action) {
    switch (action.type) {
       
        case GET_ALL_EVENT_LIST_SUCCESS:
            return {
                ...state,
                getalleventdata: action.payload
            }
        case GET_ALL_EVENT_LIST_ERROR:
            return {
                ...state,
                getalleventdataerror: action.error
            }

        default:
            return state;
    }
}