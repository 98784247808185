export const GET_PUBLISH_LIST_SUCCESS = "GET_PUBLISH_LIST_SUCCESS";
export const GET_PUBLISH_LIST_ERROR = "GET_PUBLISH_LIST_ERROR";

export const CREATE_PUBLISH_LIST_SUCCESS = "CREATE_PUBLISH_LIST_SUCCESS";
export const CREATE_PUBLISH_LIST_ERROR = "CREATE_PUBLISH_LIST_ERROR";

export const UMPIRE_SCHEDULE_REPORT = "UMPIRE_SCHEDULE_REPORT";
export const UMPIRE_SCHEDULE_REPORT_ERROR = "UMPIRE_SCHEDULE_REPORT_ERROR";

export const UMPIRE_CARDS_REPORT = "UMPIRE_CARDS_REPORT";
export const UMPIRE_CARDS_REPORT_ERROR = "UMPIRE_CARDS_REPORT_ERROR";

export const FIELD_SCHEDULE_REPORT = "FIELD_SCHEDULE_REPORT";
export const FIELD_SCHEDULE_REPORT_ERROR = "FIELD_SCHEDULE_REPORT_ERROR";

export const PUBLISH_RESPONSE_FILTERWISE = "PUBLISH_RESPONSE_FILTERWISE"

export const PRINT_UMPIRE_CARD_RESPONSE = "PRINT_UMPIRE_CARD_RESPONSE"

export const PRINT_UMPIRE_SCHEDULE_RESPONSE = "PRINT_UMPIRE_SCHEDULE_RESPONSE"

export const  SINGLE_PUBLISH_RESPONSE_FILTERWISE = "SINGLE_PUBLISH_RESPONSE_FILTERWISE"

export const SINGLE_PRINT_UMPIRE_CARD_RESPONSE = "SINGLE_PRINT_UMPIRE_CARD_RESPONSE"

export const  SEND_SCHEDULE_NOTIY_SUCCESS = "SEND_SCHEDULE_NOTIY_SUCCESS"
export const  SEND_SCHEDULE_NOTIY_ERROR = "SEND_SCHEDULE_NOTIY_ERROR"
