import {
  VENUE_LIST_SUCCESS,
  VENUE_LIST_ERROR,

  CREATE_VENUE_SUCCESS,
  CREATE_VENUE_ERROR,

  GET_VENUE_BY_ID_SUCCESS,
  GET_VENUE_BY_ID_ERROR,

  EDIT_VENUE_SUCCESS,
  EDIT_VENUE_ERROR,
  
  DELETE_VENUE_SUCCESS,
  DELETE_VENUE_ERROR,

  EDIT_FIELD_VENUE_SUCCESS,
  EDIT_FIELD_VENUE_ERROR,

  DELETE_FIELD_VENUE_SUCCESS,
  DELETE_FIELD_VENUE_ERROR
} from '../constants/Venue.const';

const initialState = {
  venueListData: null,
  venueListError: null,

  createVenueData: null,
  createVenueError: null,

  getVenueById: null,
  getVenueByIdError: null,

  editVenueData: null,
  editVenueError: null,

  deleteVenueData: null,
  deleteVenueError: null,

  editFieldData: null,
  editFieldError: null,

  deleteFieldData: null,
  deleteFieldError: null
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For get all venue list
    case VENUE_LIST_SUCCESS:
      return {
        ...state,
        venueListData: action.payload,
      };
    case VENUE_LIST_ERROR:
      return {
        ...state,
        venueListError: action.error,
      };

    // For create new venue
    case CREATE_VENUE_SUCCESS:
      return {
        ...state,
        createVenueData: action.payload,
      };
    case CREATE_VENUE_ERROR:
      return {
        ...state,
        createVenueError: action.error,
      };

    // For get venue details by id
    case GET_VENUE_BY_ID_SUCCESS:
      return {
        ...state,
        getVenueById: action.payload,
      };
    case GET_VENUE_BY_ID_ERROR:
      return {
        ...state,
        getVenueByIdError: action.error,
      };

    // For edit venue details 
    case EDIT_VENUE_SUCCESS:
      return {
        ...state,
        editVenueData: action.payload,
      };
    case EDIT_VENUE_ERROR:
      return {
        ...state,
        editVenueError: action.error,
      };

    // For edit venue details 
    case EDIT_VENUE_SUCCESS:
      return {
        ...state,
        editVenueData: action.payload,
      };
    case EDIT_VENUE_ERROR:
      return {
        ...state,
        editVenueError: action.error,
      };

    // for Delete venue 
    case DELETE_VENUE_SUCCESS:
      return {
        ...state,
        deleteVenueData: action.payload,
      };
    case DELETE_VENUE_ERROR:
      return {
        ...state,
        deleteVenueError: action.error,
      };
      
    // for edit field 
    case EDIT_FIELD_VENUE_SUCCESS:
      return {
        ...state,
        editFieldData: action.payload,
      };
    case EDIT_FIELD_VENUE_ERROR:
      return {
        ...state,
        editFieldError: action.error,
      };
            
    // for delete field 
    case DELETE_FIELD_VENUE_SUCCESS:
      return {
        ...state,
        deleteFieldData: action.payload,
      };
    case DELETE_FIELD_VENUE_ERROR:
      return {
        ...state,
        deleteFieldError: action.error,
      };

    default:
      return state;
  }
}