export const GET_TEAMLIST_FOR_COACH_REQUEST = 'GET_TEAMLIST_FOR_COACH_REQUEST';
export const GET_TEAMLIST_FOR_COACH_SUCCESS = 'GET_TEAMLIST_FOR_COACH_SUCCESS';
export const GET_TEAMLIST_FOR_COACH_ERROR = 'GET_TEAMLIST_FOR_COACH_ERROR';

export const UPDATE_COACH_REQUEST = 'UPDATE_COACH_REQUEST';
export const UPDATE_COACH_SUCCESS = 'UPDATE_COACH_SUCCESS';
export const UPDATE_COACH_ERROR = 'UPDATE_COACH_ERROR';

export const ADD_NEW_TEAM_REQUEST = 'ADD_NEW_TEAM_REQUEST';
export const ADD_NEW_TEAM_SUCCESS = 'ADD_NEW_TEAM_SUCCESS';
export const ADD_NEW_TEAM_ERROR = 'ADD_NEW_TEAM_ERROR';