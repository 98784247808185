export const FETCH_LESSON_LIBRARY_REQUEST = 'FETCH_LESSON_LIBRARY_REQUEST';
export const FETCH_LESSON_LIBRARY_SUCCESS = 'FETCH_LESSON_LIBRARY_SUCCESS';
export const FETCH_LESSON_LIBRARY_ERROR   = 'FETCH_LESSON_LIBRARY_ERROR';

export const DELETE_LESSON_LIBRARY_REQUEST = 'DELETE_LESSON_LIBRARY_REQUEST';
export const DELETE_LESSON_LIBRARY_SUCCESS = 'DELETE_LESSON_LIBRARY_SUCCESS';
export const DELETE_LESSON_LIBRARY_ERROR   = 'DELETE_LESSON_LIBRARY_ERROR';

export const UPDATE_LESSON_LIBRARY_REQUEST = 'UPDATE_LESSON_LIBRARY_REQUEST';
export const UPDATE_LESSON_LIBRARY_SUCCESS = 'UPDATE_LESSON_LIBRARY_SUCCESS';
export const UPDATE_LESSON_LIBRARY_ERROR   = 'UPDATE_LESSON_LIBRARY_ERROR';

export const CREATE_LESSON_LIBRARY_REQUEST = 'CREATE_LESSON_LIBRARY_REQUEST';
export const CREATE_LESSON_LIBRARY_SUCCESS = 'CREATE_LESSON_LIBRARY_SUCCESS';
export const CREATE_LESSON_LIBRARY_ERROR   = 'CREATE_LESSON_LIBRARY_ERROR';