import { 
    FETCH_FAQ_REQUEST,
    FETCH_FAQ_SUCCESS, 
    FETCH_FAQ_ERROR,

    REORDER_REQUEST, 
    REORDER_SUCCESS, 
    REORDER_ERROR,

    UPDATE_FAQ_REQUEST,
    UPDATE_FAQ_SUCCESS,
    UPDATE_FAQ_ERROR,

    DELETE_FAQ_REQUEST,
    DELETE_FAQ_SUCCESS,
    DELETE_FAQ_ERROR,

    CREATE_FAQ_REQUEST,
    CREATE_FAQ_SUCCESS,
    CREATE_FAQ_ERROR  
  
  } from '../constants/Faq.const';
  
  const initialState = {
    faqs: [],
    faqsError: null,
    faqsLoader: false,
    isFaqsLoaded: false,
  
    isDeleted: false,
    isDeletedError : null,
    isDeletedLoader : false,
  
    isCreated : false,
    isCreatedError : null,
    isCreatedLoader : false,
    
    isUpdated : false,
    isUpdatedError : null,
    isUpdatedLoader : false,

    isReOrdered: false,
    isReOrderedLoader: false,
    isReOrderedError: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {

      
    case REORDER_REQUEST:
      return {
        ...state,
        isReOrderedLoader: true
      };
    case REORDER_SUCCESS:
      return {
        ...state,
        isReOrdered: true,
        isReOrderedLoader: false
      };
    case REORDER_ERROR:
      return {
        ...state,
        isReOrderedError: action.error.message,
        isReOrderedLoader: false
      };
      
  
      // For GET Faq Data
  
      case FETCH_FAQ_REQUEST:
        return {
          ...state,
          faqsLoader: true
        };
      case FETCH_FAQ_SUCCESS:
        return {
          ...state,
          faqs: action.payload,
          faqsLoader: false,
          isFaqsLoaded: true

        };
      case FETCH_FAQ_ERROR:
        return {
          ...state,
          faqsError: action.error.message,
          faqsLoader: false
        };
  
      // For CREATE Faq Data
  
      case CREATE_FAQ_REQUEST:
        return {
          ...state,
          isCreatedLoader: true
        };
      case CREATE_FAQ_SUCCESS:
        return {
          ...state,
          isCreated: true,
          isCreatedLoader: false
        };
      case CREATE_FAQ_ERROR:
        return {
          ...state,
          isCreatedError: action.error,
          isCreatedLoader: false
        };
  
      // For UPDATE Faq Data
  
      case UPDATE_FAQ_REQUEST:
        return {
          ...state,
          isUpdatedLoader: true
        };
      case UPDATE_FAQ_SUCCESS:
        return {
          ...state,
          isUpdated: true,
          isUpdatedLoader: false
        };
      case UPDATE_FAQ_ERROR:
        return {
          ...state,
          isUpdatedError: action.error,
          isUpdatedLoader: false
        };

        // For DELETE Faq Data
  
      case DELETE_FAQ_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_FAQ_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isDeletedLoader: false
        };
      case DELETE_FAQ_ERROR:
        return {
          ...state,
          isDeletedError: action.error,
          isDeletedLoader: false
        };
  
      default:
        return state;
    }
  }