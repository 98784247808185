import {
    GET_REPORT_COUNT_REQUEST,
    GET_REPORT_COUNT_SUCCESS,
    GET_REPORT_COUNT_ERROR,

    GET_REPORT_GENERATED_REQUEST,
    GET_REPORT_GENERATED_SUCCESS,
    GET_REPORT_GENERATED_ERROR,

    EXPORT_REPORT_GENERATED_REQUEST,
    EXPORT_REPORT_GENERATED_SUCCESS,
    EXPORT_REPORT_GENERATED_ERROR,
} from '../../constants/BaseballCloud/ReportGenerate.const';

const initialState = {
    reportCountLoader: false,
    reportCount: null,
    reportCountError: null,

    reportGenerateLoader: false,
    reportGenerate: null,
    reportGenerateError: null,

    exportCsvAnalyzR: null,
    exportCsvAnalyzRError: null,
    exportCsvAnalyzRLoader: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // For report and count credits
        case GET_REPORT_COUNT_REQUEST:
            return {
                ...state,
                reportCountLoader: true
            };
        case GET_REPORT_COUNT_SUCCESS:
            return {
                ...state,
                reportCount: action.payload,
            };
        case GET_REPORT_COUNT_ERROR:
            return {
                ...state,
                reportCountError: action.error,
            };

        // For report generated 
        case GET_REPORT_GENERATED_REQUEST:
            return {
                ...state,
                reportGenerateLoader: true
            };
        case GET_REPORT_GENERATED_SUCCESS:
            return {
                ...state,
                reportGenerate: action.payload,
            };
        case GET_REPORT_GENERATED_ERROR:
            return {
                ...state,
                reportGenerateError: action.error.message,
            };

            
        // For export CSV AnalyzR
        case EXPORT_REPORT_GENERATED_REQUEST:
            return {
                ...state,
                exportCsvAnalyzRLoader: true
            };
        case EXPORT_REPORT_GENERATED_SUCCESS:
            return {
                ...state,
                exportCsvAnalyzR: action.payload,
            };
        case EXPORT_REPORT_GENERATED_ERROR:
            return {
                ...state,
                exportCsvAnalyzRError: action.error.message,
            };
        default:
            return state;
    }
}