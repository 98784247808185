export const GET_REPORT_COUNT_REQUEST = 'GET_REPORT_COUNT_REQUEST';
export const GET_REPORT_COUNT_SUCCESS = 'GET_REPORT_COUNT_SUCCESS';
export const GET_REPORT_COUNT_ERROR   = 'GET_REPORT_COUNT_ERROR';

export const GET_REPORT_GENERATED_REQUEST = 'GET_REPORT_GENERATED_REQUEST';
export const GET_REPORT_GENERATED_SUCCESS = 'GET_REPORT_GENERATED_SUCCESS';
export const GET_REPORT_GENERATED_ERROR   = 'GET_REPORT_GENERATED_ERROR';

export const EXPORT_REPORT_GENERATED_REQUEST = 'EXPORT_REPORT_GENERATED_REQUEST';
export const EXPORT_REPORT_GENERATED_SUCCESS = 'EXPORT_REPORT_GENERATED_SUCCESS';
export const EXPORT_REPORT_GENERATED_ERROR   = 'EXPORT_REPORT_GENERATED_ERROR';