import { 

    FETCH_ALL_COACHES_REQUEST,
    FETCH_ALL_COACHES_SUCCESS,
    FETCH_ALL_COACHES_ERROR,

    FETCH_COACH_REQUEST,
    FETCH_COACH_SUCCESS,
    FETCH_COACH_ERROR,

    FETCH_COACH_BY_FILTER_REQUEST,
    FETCH_COACH_BY_FILTER_SUCCESS,
    FETCH_COACH_BY_FILTER_ERROR,

    PAY_MULTIPLE_EVENT_REQUEST,
    PAY_MULTIPLE_EVENT_SUCCESS,
    PAY_MULTIPLE_EVENT_ERROR,
    DELETE_COACH_SUCCESS,
    DELETE_COACH_ERROR
} from '../../constants/Users/Coaches.const';

const initialState = {

  allCoaches: [],
  allCoachesError: null,
  allCoachesLoader: false,

  coach: [],
  coachError: null,
  coachLoader: false,
  isCoachLoaded: false,

  coachesByFilter: [],
  coachesByFilterError: null,
  coachesByFilterLoader: false,
  isCoachesLoaded: false,

  filters: {
    page: 0,
    name: '',
    role: 'Coach',
    sortOn: '0',
    // dateType: '0',
    dateFilter: {
      createdAt: 99,
      // lastLoginTime: 0,
      startDate: "",
      endDate: ""
    },
    isParent: "",
    state: "",
    "game-type": "",
    event_id:""
    },

    payMultipleEvent: null,
    payMultipleEventError: null,
    payMultipleEventLoader: false,

    deleteCoachData:null,
    deleteCoachDataError:null,
};

export default function(state = initialState, action) {
  switch (action.type) {

    // Fetch - ALL COACHES

    case FETCH_ALL_COACHES_REQUEST:
    return {
        ...state,
        allCoachesLoader: true
    };
    case FETCH_ALL_COACHES_SUCCESS:
    return {
        ...state,
        allCoaches: action.payload,
        allCoachesLoader: false
    };
    case FETCH_ALL_COACHES_ERROR:
    return {
        ...state,
        allCoachesError: action.error.message,
        allCoachesLoader: false
    };

    // Fetch - COACH BY ID

    case FETCH_COACH_REQUEST:
    return {
        ...state,
        coachLoader: true
    };
    case FETCH_COACH_SUCCESS:
    return {
        ...state,
        isCoachLoaded: true,
        coach: action.payload,
        coachLoader: false
    };
    case FETCH_COACH_ERROR:
    return {
        ...state,
        coachError: action.error.message,
        coachLoader: false
    };

    // Fetch - COACH BY FILTER

    case FETCH_COACH_BY_FILTER_REQUEST:
    return {
        ...state,
        coachesByFilterLoader: true
    };
    case FETCH_COACH_BY_FILTER_SUCCESS:
    return {
        ...state,
        isCoachesLoaded: true,
        coachesByFilter: action.payload,
        coachesByFilterLoader: false
    };
    case FETCH_COACH_BY_FILTER_ERROR:
    return {
        ...state,
        coachesByFilterError: action.error.message,
        coachesByFilterLoader: false
    };

    
    // pay multiple event
    case PAY_MULTIPLE_EVENT_REQUEST:
    return {
        ...state,
        payMultipleEventLoader: true
    };
    case PAY_MULTIPLE_EVENT_SUCCESS:
    return {
        ...state,
        payMultipleEvent: action.payload,
    };
    case PAY_MULTIPLE_EVENT_ERROR:
    return {
        ...state,
        payMultipleEventError: action.error,
    };

    //delete Coach
    case DELETE_COACH_SUCCESS:
      return {
        ...state,
        deleteCoachData: action.payload,
      };

    case DELETE_COACH_ERROR:
      return {
        ...state,
        deleteCoachDataError: action.error,
      };

    default:
      return state;
  }
}