export const FETCH_COUPON_CODE_REQUEST = 'FETCH_COUPON_CODE_REQUEST';
export const FETCH_COUPON_CODE_SUCCESS = 'FETCH_COUPON_CODE_SUCCESS';
export const FETCH_COUPON_CODE_ERROR   = 'FETCH_COUPON_CODE_ERROR';

export const FETCH_COUPON_CODE_LIST_REQUEST = 'FETCH_COUPON_CODE_LIST_REQUEST';
export const FETCH_COUPON_CODE_LIST_SUCCESS = 'FETCH_COUPON_CODE_LIST_SUCCESS';
export const FETCH_COUPON_CODE_LIST_ERROR   = 'FETCH_COUPON_CODE_ERROR';

export const FETCH_COUPON_CODE_BY_ID_REQUEST = 'FETCH_COUPON_CODE_BY_ID_REQUEST';
export const FETCH_COUPON_CODE_BY_ID_SUCCESS = 'FETCH_COUPON_CODE_BY_ID_SUCCESS';
export const FETCH_COUPON_CODE_BY_ID_ERROR   = 'FETCH_COUPON_CODE_BY_ID_ERROR';

export const UPDATE_COUPON_CODE_REQUEST = 'UPDATE_COUPON_CODE_REQUEST';
export const UPDATE_COUPON_CODE_SUCCESS = 'UPDATE_COUPON_CODE_SUCCESS';
export const UPDATE_COUPON_CODE_ERROR   = 'UPDATE_COUPON_CODE_ERROR';

export const DELETE_COUPON_CODE_REQUEST = 'DELETE_COUPON_CODE_REQUEST';
export const DELETE_COUPON_CODE_SUCCESS = 'DELETE_COUPON_CODE_SUCCESS';
export const DELETE_COUPON_CODE_ERROR   = 'DELETE_COUPON_CODE_ERROR';

export const CREATE_COUPON_CODE_REQUEST = 'CREATE_COUPON_CODE_REQUEST';
export const CREATE_COUPON_CODE_SUCCESS = 'CREATE_COUPON_CODE_SUCCESS';
export const CREATE_COUPON_CODE_ERROR   = 'CREATE_COUPON_CODE_ERROR';