import {
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  FETCH_POLICYPAGE_SUCCESS,
  FETCH_PAGE_ERROR,

  UPDATE_PAGE_REQUEST,
  UPDATE_PAGE_SUCCESS,
  UPDATE_POLICYPAGE_SUCCESS,
  UPDATE_PAGE_ERROR,

  DELETE_PAGE_REQUEST,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_ERROR,

  CREATE_PAGE_REQUEST,
  CREATE_PAGE_SUCCESS,
  CREATE_PAGE_ERROR

} from '../constants/Pages.const';

const initialState = {
  pages: [],
  pagesError: null,
  pagesLoader: false,
  isPagesLoaded: false,

  isDeleted: false,
  isDeletedError: null,
  isDeletedLoader: false,

  isCreated: false,
  isCreatedError: null,
  isCreatedLoader: false,

  isUpdated: false,
  isUpdatedError: null,
  isUpdatedLoader: false
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For GET Page Data

    case FETCH_PAGE_REQUEST:
      return {
        ...state,
        pagesLoader: true
      };
    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        pages: action.payload,
        pagesLoader: false,
        isPagesLoaded: true

      };
    case FETCH_POLICYPAGE_SUCCESS:
      return {
        ...state,
        pages: action.payload,
        pagesLoader: false,
        isPagesLoaded: true
      };
    case FETCH_PAGE_ERROR:
      return {
        ...state,
        pagesError: action.error.message,
        pagesLoader: false
      };

    // For CREATE Page Data

    case CREATE_PAGE_REQUEST:
      return {
        ...state,
        isCreatedLoader: true
      };
    case CREATE_PAGE_SUCCESS:
      return {
        ...state,
        isCreated: true,
        isCreatedLoader: false
      };
    case CREATE_PAGE_ERROR:
      return {
        ...state,
        isCreatedError: action.error,
        isCreatedLoader: false
      };

    // For UPDATE Page Data

    case UPDATE_PAGE_REQUEST:
      return {
        ...state,
        isUpdatedLoader: true
      };
    case UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_POLICYPAGE_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        isUpdatedLoader: false
      };
    case UPDATE_PAGE_ERROR:
      return {
        ...state,
        isUpdatedError: action.error,
        isUpdatedLoader: false
      };

    // For DELETE Page Data

    case DELETE_PAGE_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case DELETE_PAGE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case DELETE_PAGE_ERROR:
      return {
        ...state,
        isDeletedError: action.error,
        isDeletedLoader: false
      };

    default:
      return state;
  }
}