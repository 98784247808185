export const FETCH_APPLICANT_LIST_REQUEST = 'FETCH_APPLICANT_LIST_REQUEST';
export const FETCH_APPLICANT_LIST_SUCCESS = 'FETCH_APPLICANT_LIST_SUCCESS';
export const FETCH_APPLICANT_LIST_ERROR   = 'FETCH_APPLICANT_LIST_ERROR';

export const ADD_TEAM_EVENT_REQUEST = 'ADD_TEAM_EVENT_REQUEST';
export const ADD_TEAM_EVENT_SUCCESS = 'ADD_TEAM_EVENT_SUCCESS';
export const ADD_TEAM_EVENT_ERROR   = 'ADD_TEAM_EVENT_ERROR';

export const EDIT_TEAM_EVENT_REQUEST = 'EDIT_TEAM_EVENT_REQUEST';
export const EDIT_TEAM_EVENT_SUCCESS = 'EDIT_TEAM_EVENT_SUCCESS';
export const EDIT_TEAM_EVENT_ERROR   = 'EDIT_TEAM_EVENT_ERROR';

export const INVITE_TEAM_EVENT_REQUEST = 'INVITE_TEAM_EVENT_REQUEST';
export const INVITE_TEAM_EVENT_SUCCESS = 'INVITE_TEAM_EVENT_SUCCESS';
export const INVITE_TEAM_EVENT_ERROR   = 'INVITE_TEAM_EVENT_ERROR';

export const SHOWCASE_PLAYER_SUCCESS = 'SHOWCASE_PLAYER_SUCCESS';
export const SHOWCASE_PLAYER_ERROR   = 'SHOWCASE_PLAYER_ERROR';

export const SEND_MAIL_PAYMENT_SUCCESS = 'SEND_MAIL_PAYMENT_SUCCESS';
export const SEND_MAIL_PAYMENT_ERROR   = 'SEND_MAIL_PAYMENT_ERROR';

export const REQUEST_TO_PAY_SUCCESS = 'REQUEST_TO_PAY_SUCCESS';
export const REQUEST_TO_PAY_ERROR   = 'REQUEST_TO_PAY_ERROR';

export const REMOVE_TEAM_SUCCESS = 'REMOVE_TEAM_SUCCESS';
export const REMOVE_TEAM_ERROR   = 'REMOVE_TEAM_ERROR';

export const ADD_ROSTER_PLAYER_SUCCESS = 'ADD_ROSTER_PLAYER_SUCCESS';
export const ADD_ROSTER_PLAYER_ERROR   = 'ADD_ROSTER_PLAYER_ERROR';

export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_ERROR   = 'ADD_PAYMENT_ERROR';

export const SEND_NOTIFY_EVENT_SUCCESS = 'SEND_NOTIFY_EVENT_SUCCESS';
export const SEND_NOTIFY_EVENT_ERROR   = 'SEND_NOTIFY_EVENT_ERROR';

export const EVENT_EXPORT_CSV_SUCCESS = 'EVENT_EXPORT_CSV_SUCCESS';
export const EVENT_EXPORT_CSV_ERROR   = 'EVENT_EXPORT_CSV_ERROR';

export const ACCEPT_REQUEST_SUCCESS = 'ACCEPT_REQUEST_SUCCESS';
export const ACCEPT_REQUEST_ERROR   = 'ACCEPT_REQUEST_ERROR';

export const ROSTER_EDIT_SUCCESS = 'ROSTER_EDIT_SUCCESS';
export const ROSTER_EDIT_ERROR   = 'ROSTER_EDIT_ERROR';

export const EXPORT_3RD_PARTY_CSV_SUCCESS = 'EXPORT_3RD_PARTY_CSV_SUCCESS';
export const EXPORT_3RD_PARTY_CSV_ERROR   = 'EXPORT_3RD_PARTY_CSV_ERROR';

export const PERMANENT_DELETE_SUCCESS = 'PERMANENT_DELETE_SUCCESS';
export const PERMANENT_DELETE_ERROR   = 'PERMANENT_DELETE_ERROR';

export const EDIT_NUMBER_SUCCESS = 'EDIT_NUMBER_SUCCESS';
export const EDIT_NUMBER_ERROR   = 'EDIT_NUMBER_ERROR';