import { 
  GET_TIMELINE_REQUEST, 
  GET_TIMELINE_SUCCESS, 
  GET_TIMELINE_ERROR,
  REMOVE_TIMELINE_REQUEST,
  REMOVE_TIMELINE_SUCCESS,
  REMOVE_TIMELINE_ERROR,
  GET_MORE_TIMELINE_SUCCESS
} from '../constants/ClubhouseTimeline.const';

const initialState = {
  timelines: null,
  timelinesError: null,
  timelinesLoader: false,
  isLoadMore:false,
  hasMoreItem:true,
  isDeleted: null,
  isDeletedError: null,
  isDeletedLoader: false
};

export default function(state = initialState, action) {
  switch (action.type) {


    // GET Recent Timeline Posts

    case GET_TIMELINE_REQUEST:
      return {
        ...state,
        timelinesLoader: true
      };
    case GET_TIMELINE_SUCCESS:
      return {
        ...state,
        timelines: action.payload,
        timelinesLoader: false
      };
      case GET_MORE_TIMELINE_SUCCESS:
      var hasMore = action.payload.length > 0 ? true : false
        return {
          ...state,
          timelines: action.payload,
          timelinesLoader: false,
          isLoadMore:true,
          hasMoreItem: hasMore
        };
    case GET_TIMELINE_ERROR:
      return {
        ...state,
        timelinesError: action.error.message,
        timelinesLoader: false
      };

      // REMOVE Recent Timeline Posts

    case REMOVE_TIMELINE_REQUEST:
      return {
        ...state,
        isDeletedLoader: true
      };
    case REMOVE_TIMELINE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isDeletedLoader: false
      };
    case REMOVE_TIMELINE_ERROR:
      return {
        ...state,
        isDeletedError: action.error.message,
        isDeletedLoader: false
      };

    default:
      return state;
  }
}