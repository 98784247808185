export const FETCH_FAQ_REQUEST = 'FETCH_FAQ_REQUEST';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_ERROR   = 'FETCH_FAQ_ERROR';

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_ERROR   = 'UPDATE_FAQ_ERROR';

export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_ERROR   = 'DELETE_FAQ_ERROR';

export const CREATE_FAQ_REQUEST = 'CREATE_FAQ_REQUEST';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_ERROR   = 'CREATE_FAQ_ERROR';

export const REORDER_REQUEST = 'REORDER_REQUEST';
export const REORDER_SUCCESS = 'REORDER_SUCCESS';
export const REORDER_ERROR   = 'REORDER_ERROR';