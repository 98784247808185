export const FETCH_DIVISION_REQUEST = 'FETCH_DIVISION_REQUEST';
export const FETCH_DIVISION_SUCCESS = 'FETCH_DIVISION_SUCCESS';
export const FETCH_DIVISION_ERROR   = 'FETCH_DIVISION_ERROR';

export const UPDATE_DIVISION_REQUEST = 'UPDATE_DIVISION_REQUEST';
export const UPDATE_DIVISION_SUCCESS = 'UPDATE_DIVISION_SUCCESS';
export const UPDATE_DIVISION_ERROR   = 'UPDATE_DIVISION_ERROR';

export const DELETE_DIVISION_REQUEST = 'DELETE_DIVISION_REQUEST';
export const DELETE_DIVISION_SUCCESS = 'DELETE_DIVISION_SUCCESS';
export const DELETE_DIVISION_ERROR   = 'DELETE_DIVISION_ERROR';

export const CREATE_DIVISION_REQUEST = 'CREATE_DIVISION_REQUEST';
export const CREATE_DIVISION_SUCCESS = 'CREATE_DIVISION_SUCCESS';
export const CREATE_DIVISION_ERROR   = 'CREATE_DIVISION_ERROR';