export const LIST_SPONSOR_REQUEST = 'LIST_SPONSOR_REQUEST';
export const LIST_SPONSOR_SUCCESS = 'LIST_SPONSOR_SUCCESS';
export const LIST_SPONSOR_ERROR   = 'LIST_SPONSOR_ERROR';

export const FEATCH_SPONSOR_REQUEST = 'FEATCH_SPONSOR_REQUEST';
export const FEATCH_SPONSOR_SUCCESS = 'FEATCH_SPONSOR_SUCCESS';
export const FEATCH_SPONSOR_ERROR   = 'FEATCH_SPONSOR_ERROR';

export const CREATE_SPONSOR_REQUEST = 'CREATE_SPONSOR_REQUEST';
export const CREATE_SPONSOR_SUCCESS = 'CREATE_SPONSOR_SUCCESS';
export const CREATE_SPONSOR_ERROR   = 'CREATE_SPONSOR_ERROR';

export const UPDATE_SPONSOR_REQUEST = 'UPDATE_SPONSOR_REQUEST';
export const UPDATE_SPONSOR_SUCCESS = 'UPDATE_SPONSOR_SUCCESS';
export const UPDATE_SPONSOR_ERROR   = 'UPDATE_SPONSOR_ERROR';

export const DELETE_SPONSOR_REQUEST = 'DELETE_SPONSOR_REQUEST';
export const DELETE_SPONSOR_SUCCESS = 'DELETE_SPONSOR_SUCCESS';
export const DELETE_SPONSOR_ERROR   = 'DELETE_SPONSOR_ERROR';