import {
  FETCH_TEMPLATE_REPORT_REQUEST,
  FETCH_TEMPLATE_REPORT_SUCCESS,
  FETCH_TEMPLATE_REPORT_ERROR,

  DELETE_TEMPLATE_REPORT_REQUEST,
  DELETE_TEMPLATE_REPORT_SUCCESS,
  DELETE_TEMPLATE_REPORT_ERROR,

  FETCH_CUSTOM_REPORT_REQUEST,
  FETCH_CUSTOM_REPORT_SUCCESS,
  FETCH_CUSTOM_REPORT_ERROR,

  REPORT_UPLOAD_REQUEST,
REPORT_UPLOAD_SUCCESS,
REPORT_UPLOAD_ERROR,

EMAIL_CUSTOM_USER_REQUEST,
  EMAIL_CUSTOM_USER_SUCCESS,
  EMAIL_CUSTOM_USER_ERROR,

  GET_REPORT_BY_ID_REQUEST,
GET_REPORT_BY_ID_SUCCESS,
GET_REPORT_BY_ID_ERROR
} from "../../../constants/BaseballCloud/Analyzer/Reports.const";

const initialState = {
  templateData: [],
  templateError: null,
  templateLoader: false,
  isTemplateLoaded: false,

  isDeleted: null,
  isDeletedError: null,
  isDeletedLoader: false,

  customData: [],
  customError: null,
  customLoader: false,
  isCustomLoaded: false,

  uploadReportData: [],
  uploadReportError: null,
  uploadReportLoader: false,
  isUploadReportLoaded: false,

  mailData: [],
  mailError: null,
  mailLoader: false,
  isMailLoaded: false,

  reportIdData: [],
  reportIdError: null,
  reportIdLoader: false,
  isReportIdLoaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // get template report data in report analyzer
    case FETCH_TEMPLATE_REPORT_REQUEST:
      return {
        ...state,
        templateLoader: true,
      };
    case FETCH_TEMPLATE_REPORT_SUCCESS:
      return {
        ...state,
        templateData: action.payload,
        templateLoader: false,
        isTemplateLoaded: true,
      };
    case FETCH_TEMPLATE_REPORT_ERROR:
      return {
        ...state,
        templateError: action.error.message,
        templateLoader: false,
      };

      // For delete template user data

    case DELETE_TEMPLATE_REPORT_REQUEST:
        return {
          ...state,
          isDeletedLoader: true
        };
      case DELETE_TEMPLATE_REPORT_SUCCESS:
        return {
          ...state,
          isDeleted: action.payload,
          isDeletedLoader: false
        };
      case DELETE_TEMPLATE_REPORT_ERROR:
        return {
          ...state,
          isDeletedError: action.error,
          isDeletedLoader: false
        };

    // get custom report data in report analyzer
    case FETCH_CUSTOM_REPORT_REQUEST:
      return {
        ...state,
        customLoader: true,
      };
    case FETCH_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        customData: action.payload,
        customLoader: false,
        isCustomLoaded: true,
      };
    case FETCH_CUSTOM_REPORT_ERROR:
      return {
        ...state,
        customError: action.error.message,
        customLoader: false,
      };
      
      // custom user upload report
    case REPORT_UPLOAD_REQUEST:
      return {
        ...state,
        uploadReportLoader: true,
      };
    case REPORT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadReportData: action.payload,
        uploadReportLoader: false,
        isUploadReportLoaded: true,
      };
    case REPORT_UPLOAD_ERROR:
      return {
        ...state,
        uploadReportError: action.error.message,
        uploadReportLoader: false,
      };

       // Mail custom user
    case EMAIL_CUSTOM_USER_REQUEST:
      return {
        ...state,
        mailLoader: true,
      };
    case EMAIL_CUSTOM_USER_SUCCESS:
      return {
        ...state,
        mailData: action.payload,
        mailLoader: false,
        isMailLoaded: true,
      };
    case EMAIL_CUSTOM_USER_ERROR:
      return {
        ...state,
        mailError: action.error.message,
        mailLoader: false,
      };

        // GET CUSTOM USER REPORT BY ID
    case GET_REPORT_BY_ID_REQUEST:
      return {
        ...state,
        reportIdLoader: true,
      };
    case GET_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        reportIdData: action.payload,
        reportIdLoader: false,
        isReportIdLoaded: true,
      };
    case GET_REPORT_BY_ID_ERROR:
      return {
        ...state,
        reportIdError: action.error.message,
        reportIdLoader: false,
      };
    default:
      return state;
  }
}
