import {
  FETCH_MANAGEMENT_DATA_REQUEST,
  FETCH_MANAGEMENT_DATA_SUCCESS,
  FETCH_MANAGEMENT_DATA_ERROR,

  UPDATE_MANAGEMENT_DATA_REQUEST,
  UPDATE_MANAGEMENT_DATA_SUCCESS,
  UPDATE_MANAGEMENT_DATA_ERROR,

  FETCH_TEAM_DATA_REQUEST,
  FETCH_TEAM_DATA_SUCCESS,
  FETCH_TEAM_DATA_ERROR,

  DELETE_MANAGEMENT_DATE_REQUEST,
  DELETE_MANAGEMENT_DATE_SUCCESS,
  DELETE_MANAGEMENT_DATE_ERROR,

  GENERATE_CSV_REQUEST,
  GENERATE_CSV_SUCCESS,
  GENERATE_CSV_ERROR,
  
  FETCH_DATA_TOOL_REQUEST,
  FETCH_DATA_TOOL_SUCCESS,
  FETCH_DATA_TOOL_ERROR
} from '../../constants/BaseballCloud/DataManagment.const';

const initialState = {
  manageDataLoader: false,
  manageData: null,
  manageDataError: null,

  manageDataUpdateLoader: false,
  manageDataUpdate: null,
  manageDataUpdateError: null,

  getTeamDataLoader: false,
  getTeamData: null,
  getTeamDataError: null,

  distictLevelLoader: false,
  distictLevel: null,
  distictLevelError: null,

  distictLeagueLoader: false,
  distictLeague: null,
  distictLeagueError: null,
  
  pitchTypesLoader: false,
  pitchTypes: null,
  pitchTypesError: null,

  manageDataDeleteLoader: false,
  manageDataDelete: null,
  manageDataDeleteError: null,

  generateCSVLoader: false,
  generateCSV: null,
  generateCSVError: null,

  fetchDataTool: null,
  fetchDataToolError: null
};

export default function (state = initialState, action) {
  switch (action.type) {

    // For fetch Manage Data

    case FETCH_MANAGEMENT_DATA_REQUEST:
      return {
        ...state,
        manageDataLoader: true
      };
    case FETCH_MANAGEMENT_DATA_SUCCESS:
      return {
        ...state,
        manageData: action.payload,
      };
    case FETCH_MANAGEMENT_DATA_ERROR:
      return {
        ...state,
        manageDataError: action.error.message,
      };

    // For update Manage Data

    case UPDATE_MANAGEMENT_DATA_REQUEST:
      return {
        ...state,
        manageDataUpdateLoader: true
      };
    case UPDATE_MANAGEMENT_DATA_SUCCESS:
      return {
        ...state,
        manageDataUpdate: action.payload,
      };
    case UPDATE_MANAGEMENT_DATA_ERROR:
      return {
        ...state,
        manageDataUpdateError: action.error.message,
      };

    // For get team Data

    case FETCH_TEAM_DATA_REQUEST:
      return {
        ...state,
        getTeamDataLoader: true
      };
    case FETCH_TEAM_DATA_SUCCESS:
      return {
        ...state,
        getTeamData: action.payload,
      };
    case FETCH_TEAM_DATA_ERROR:
      return {
        ...state,
        getTeamDataError: action.error.message,
      };

      
    // For get team Data
    case `level_distict_data_request`:
      return {
        ...state,
        distictLevelLoader: true
      };
    case `level_distict_data_success`:
      return {
        ...state,
        distictLevel: action.payload,
      };
    case `level_distict_data_error`:
      return {
        ...state,
        distictLevelError: action.error.message,
      };

       // For get team Data
    case `league_distict_data_request`:
      return {
        ...state,
        distictLeagueLoader: true
      };
    case `league_distict_data_success`:
      return {
        ...state,
        distictLeague: action.payload,
      };
    case `league_distict_data_error`:
      return {
        ...state,
        distictLeagueError: action.error.message,
      };


      
       // For get pitch type
    case `pitch_type_distict_data_request`:
      return {
        ...state,
        pitchTypesLoader: true
      };
    case `pitch_type_distict_data_success`:
      return {
        ...state,
        pitchTypes: action.payload,
      };
    case `pitch_type_distict_data_error`:
      return {
        ...state,
        pitchTypesError: action.error.message,
      };

          // For delete manage data
    case DELETE_MANAGEMENT_DATE_REQUEST:
      return {
        ...state,
        manageDataDeleteLoader: true
      };
    case DELETE_MANAGEMENT_DATE_SUCCESS:
      return {
        ...state,
        manageDataDelete: action.payload,
      };
    case DELETE_MANAGEMENT_DATE_ERROR:
      return {
        ...state,
        manageDataDeleteError: action.error.message,
      };


    // For CSV generate
    case GENERATE_CSV_REQUEST:
      return {
        ...state,
        generateCSVLoader: true
      };
    case GENERATE_CSV_SUCCESS:
      return {
        ...state,
        generateCSV: action.payload,
      };
    case GENERATE_CSV_ERROR:
      return {
        ...state,
        generateCSVError: action.error.message,
      };


    // For fetch Data Tool
    case FETCH_DATA_TOOL_SUCCESS:
      return {
        ...state,
        fetchDataTool: action.payload,
      };
    case FETCH_DATA_TOOL_ERROR:
      return {
        ...state,
        fetchDataToolError: action.error.message,
      };
    default:
      return state;
  }
}