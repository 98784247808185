export const FETCH_ALL_COACHES_REQUEST = 'FETCH_ALL_COACHES_REQUEST';
export const FETCH_ALL_COACHES_SUCCESS = 'FETCH_ALL_COACHES_SUCCESS';
export const FETCH_ALL_COACHES_ERROR   = 'FETCH_ALL_COACHES_ERROR';

export const FETCH_COACH_REQUEST = 'FETCH_COACH_REQUEST';
export const FETCH_COACH_SUCCESS = 'FETCH_COACH_SUCCESS';
export const FETCH_COACH_ERROR   = 'FETCH_COACH_ERROR';

export const FETCH_COACH_BY_FILTER_REQUEST = 'FETCH_COACH_BY_FILTER_REQUEST';
export const FETCH_COACH_BY_FILTER_SUCCESS = 'FETCH_COACH_BY_FILTER_SUCCESS';
export const FETCH_COACH_BY_FILTER_ERROR = 'FETCH_COACH_BY_FILTER_ERROR';

export const PAY_MULTIPLE_EVENT_REQUEST = 'PAY_MULTIPLE_EVENT_REQUEST';
export const PAY_MULTIPLE_EVENT_SUCCESS = 'PAY_MULTIPLE_EVENT_SUCCESS';
export const PAY_MULTIPLE_EVENT_ERROR = 'PAY_MULTIPLE_EVENT_ERROR';

export const DELETE_COACH_SUCCESS = 'DELETE_COACH_SUCCESS';
export const DELETE_COACH_ERROR = 'DELETE_COACH_ERROR';